import { FunctionComponent, useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faDownload,
    faClone,
    faTrashCan,
    faEnvelope,
    faCheckCircle,
    faE,
    faEllipsisVertical,
    faMinusCircle,
    faPenToSquare,
    faRefresh,
} from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import { formatNumber } from '../../../../functions/format';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import useLanguageStore from '../../../../store/language';
import { ObjectKeys } from '../../../../types/objectKeys';
import { Link } from 'react-router-dom';
import useDropdown from '../../../../components/invoiceCard/useDropdown';
import Dropdown from '../../../../components/dropdown';
import getStatus from '../../../../components/invoiceCard/getStatus';
import { Icon } from '../../../../components/invoiceCard/invoiceActionsWithoutDropdown';

export enum EInvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoices = 'advanceInvoices',
}
interface Props {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
}
const EInvoiceCard: FunctionComponent<Props> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handlePayment,
    handleSend,
    handleClick,
    handleRefresh,
    cardDisplay,
    cardData,
    eInvoice,
}) => {
    const size = useWindowSize();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);

    const [isLaptopWidth, setIsLaptopWidth] = useState(!!size.width && size.width < 1560);
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        handleDownload,
        undefined,
        undefined,
        handleRefresh,
    );
    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        handleRefresh,
    );
    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
        handleRefresh,
    );
    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopy,
        undefined,
        handleSend,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        handleRefresh,
    );
    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        undefined,
        handleCancel,
        handleCopy,
        undefined,
        handleSend,
        handlePayment,
        handleDownload,
        undefined,
        undefined,
        handleRefresh,
    );
    useEffect(() => {
        setIsLaptopWidth(!!size.width && size.width < 1560);
    }, [size.width]);

    // On outside click close the menu
    const handleClickOutside = (event: MouseEvent): void => {
        if (!ref?.current?.contains(event.target as HTMLDivElement)) {
            setDropdownVisible(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, []);
    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper onClick={() => handleDownload && handleDownload(cardData?.id)} className="info-wrapper">
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">{cardData?.client?.data?.company_name}</div>
                    </div>
                    <div className="number-col">
                        <div className="label">
                            {t('pages.invoiceCard.infoWrapper.number').text +
                                ' ' +
                                (type === 'invoice' ? t('pages.invoiceCard.infoWrapper.invoice').text : '')}
                        </div>
                        <div className="number">
                            {cardData?.invoice_number
                                ? cardData?.invoice_number
                                : getStatus(cardData?.status.toLowerCase(), t)}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">{moment(cardData?.invoice_date)?.format('DD.MM.YYYY')}</div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    <div className="status-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                        <div className={`status ${cardData?.status}`}>
                            <span>{getStatus(cardData?.status.toLowerCase(), t)}</span>
                        </div>
                    </div>
                    {eInvoice && (
                        <div className="status-col">
                            <div className="label">{t('pages.eInvoices.status.title').text}</div>
                            <div className={`status ${cardData?.eInvoice?.data.status.toLowerCase()}`}>
                                <span>{getStatus(cardData?.eInvoice.data.status.toLowerCase(), t, eInvoice)}</span>
                            </div>
                        </div>
                    )}
                </InfoWrapper>
                <ActionWrapper>
                    <div className="label"></div>
                    <div className="action">
                        {isLaptopWidth ? (
                            <Action>
                                <FontAwesomeIcon
                                    icon={faEllipsisVertical}
                                    style={{ color: colors.gray }}
                                    className="cursor-pointer dots"
                                    onClick={() => {
                                        handleClick(cardData?.id);
                                        setDropdownVisible(!dropdownVisible);
                                    }}
                                />
                                {dropdownVisible && (
                                    <DropdownContainer
                                        ref={ref}
                                        onClick={(e: MouseEvent | any) => {
                                            handleClickOutside(e);
                                        }}
                                    >
                                        <Dropdown
                                            arrowRight="4px"
                                            itemList={
                                                cardData?.status === 'paid'
                                                    ? paidDropdown
                                                    : cardData?.status === 'sent'
                                                    ? sentDropdown
                                                    : cardData?.status === 'open'
                                                    ? draftDropdown
                                                    : cardData?.status === 'partially'
                                                    ? partiallyDropdown
                                                    : cardData?.status === 'cancelled' || cardData?.status === 'closed'
                                                    ? cancelledDropdown
                                                    : []
                                            }
                                        />
                                    </DropdownContainer>
                                )}
                            </Action>
                        ) : (
                            <ActionWithoutDropdown>
                                {cardData?.status === 'paid' ? (
                                    <>
                                        {eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleDownload && handleDownload(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.download').text}
                                            </div>
                                        </Icon>
                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.copy').text}
                                                </div>
                                            </Icon>
                                        </Link>
                                        {!eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.delete').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleSend && handleSend(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.send').text}
                                            </div>
                                        </Icon>
                                        <FontAwesomeIcon
                                            icon={faE}
                                            style={{ color: eInvoice ? colors.gray : colors.green }}
                                        />
                                    </>
                                ) : cardData?.status === 'open' ? (
                                    <>
                                        <FontAwesomeIcon
                                            icon={faPenToSquare}
                                            style={{ color: colors.blue }}
                                            onClick={(e) => handleChange && handleChange(e)}
                                        />
                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.copy').text}
                                                </div>
                                            </Icon>
                                        </Link>
                                        {!eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.delete').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleSend && handleSend(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.send').text}
                                            </div>
                                        </Icon>
                                        {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                    </>
                                ) : cardData?.status === 'sent' ? (
                                    <>
                                        {eInvoice &&
                                            (cardData.eInvoice.data.status === 'Sent' ||
                                                cardData.eInvoice.data.status === 'Sending' ||
                                                cardData.eInvoice.data.status === 'Approved' ||
                                                cardData.eInvoice.data.status === 'Rejected') && (
                                                <Icon>
                                                    <FontAwesomeIcon
                                                        icon={faRefresh}
                                                        style={{ color: colors.purple }}
                                                        onClick={(e) => handleRefresh && handleRefresh(e)}
                                                    />
                                                    <div className="label">
                                                        {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                    </div>
                                                </Icon>
                                            )}
                                        {!(
                                            eInvoice &&
                                            (cardData.eInvoice.data.status === 'Sent' ||
                                                cardData.eInvoice.data.status === 'Approved' ||
                                                cardData.eInvoice.data.status !== 'Sending' ||
                                                cardData.eInvoice.data.status === 'Rejected')
                                        ) && (
                                            <FontAwesomeIcon
                                                icon={faPenToSquare}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleChange && handleChange(e)}
                                            />
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleDownload && handleDownload(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.download').text}
                                            </div>
                                        </Icon>
                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.copy').text}
                                                </div>
                                            </Icon>
                                        </Link>
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faMinusCircle}
                                                style={{ color: colors.gray }}
                                                onClick={(e) => handleCancel && handleCancel(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.cancel').text}
                                            </div>
                                        </Icon>
                                        {!eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.delete').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleSend && handleSend(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.send').text}
                                            </div>
                                        </Icon>
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                style={{ color: colors.green }}
                                                onClick={(e) => handlePayment && handlePayment(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.payment').text}
                                            </div>
                                        </Icon>
                                        <FontAwesomeIcon
                                            icon={faE}
                                            style={{ color: eInvoice ? colors.gray : colors.green }}
                                        />
                                    </>
                                ) : cardData?.status === 'partially' ? (
                                    <>
                                        {eInvoice && cardData.eInvoice.data.status === 'Sent' && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleDownload && handleDownload(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.download').text}
                                            </div>
                                        </Icon>
                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.copy').text}
                                                </div>
                                            </Icon>
                                        </Link>
                                        {!eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.delete').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleSend && handleSend(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.send').text}
                                            </div>
                                        </Icon>
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faCheckCircle}
                                                style={{ color: colors.green }}
                                                onClick={(e) => handlePayment && handlePayment(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.payment').text}
                                            </div>
                                        </Icon>
                                        {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                    </>
                                ) : cardData?.status === 'cancelled' || cardData?.status === 'closed' ? (
                                    <>
                                        {eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faRefresh}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleRefresh && handleRefresh(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.refresh').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faDownload}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleDownload && handleDownload(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.download').text}
                                            </div>
                                        </Icon>
                                        <Link to={`/e-invoices/edit/${cardData.id}`}>
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faClone}
                                                    style={{ color: colors.purple }}
                                                    onClick={(e) => handleCopy && handleCopy(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.copy').text}
                                                </div>
                                            </Icon>
                                        </Link>
                                        {!eInvoice && (
                                            <Icon>
                                                <FontAwesomeIcon
                                                    icon={faTrashCan}
                                                    style={{ color: colors.danger }}
                                                    onClick={(e) => handleDelete && handleDelete(e)}
                                                />
                                                <div className="label">
                                                    {t('pages.invoiceCard.dropdownItems.delete').text}
                                                </div>
                                            </Icon>
                                        )}
                                        <Icon>
                                            <FontAwesomeIcon
                                                icon={faEnvelope}
                                                style={{ color: colors.blue }}
                                                onClick={(e) => handleSend && handleSend(e)}
                                            />
                                            <div className="label">
                                                {t('pages.invoiceCard.dropdownItems.send').text}
                                            </div>
                                        </Icon>
                                        {eInvoice && <FontAwesomeIcon icon={faE} style={{ color: colors.gray }} />}
                                    </>
                                ) : (
                                    <></>
                                )}
                            </ActionWithoutDropdown>
                        )}
                    </div>
                </ActionWrapper>
            </CardContent>
        </StyledInvoiceCard>
    );
};
const StyledInvoiceCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;

    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 768px) {
        align-items: start;
    }
    &.card-display-grid {
        display: flex;
        flex-direction: column;
        .card-content {
            grid-template-columns: 1fr;
            width: 100%;
            .info-wrapper {
                grid-template-columns: 2fr 2fr;
                > div {
                    &:nth-of-type(1) {
                        order: 3;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    &:nth-of-type(3) {
                        order: 4;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                    &.status-col {
                        order: 1;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: 1560px) {
        grid-template-columns: 8fr 1fr;
    }

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.status {
            text-transform: capitalize;
            &.sent {
                span {
                    background: var(--blue);
                }
            }
            &.paid,
            &.sending {
                span {
                    background: var(--green);
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--turquoise);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            span {
                padding: 0 10px 2px;
                background: var(--gray);
                color: var(--white);
                border-radius: 50px;
                font-size: 12px;
            }
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
const InfoWrapper = styled.div`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    @media screen and (max-width: 768px) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;
const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;
const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;
const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;
const ActionWithoutDropdown = styled.div`
    display: flex;
    gap: 10px;
`;
export default EInvoiceCard;
