import communication from '../../../communication';
import { Dispatch, SetStateAction } from 'react';
import { ObjectKeys } from '../../../types/objectKeys';

const getAllUsers = (setAllUsers: Dispatch<SetStateAction<Array<{ value: string; label: string }>>>): void => {
    communication.getAllUsers().then((res: ObjectKeys) => {
        if (res.status === 200) {
            const userData = res?.data?.map((user: ObjectKeys) => {
                return { value: user.id, label: user.email + '-' + user.pib };
            });

            setAllUsers([...userData]);
        }
    });
};
export default getAllUsers;
