export default {
    white: '#FFFFFF',
    black: '#4c595f',
    purple: '#9b7fe9',
    gray: '#92a5ac',
    lightGray: '#f3f6f8',
    blueGray: '#bfcbd9',
    borderColor: '#e7e7e7',
    danger: '#ff0042',
    green: '#53be69',
    lightGreen: '#edf8ef',
    lightBlue: '#66afe9',
    navbarBlack: '#2b2d39',
    navbarGray: '#606072',
    bodyGray: '#f7f8f9',
    blue: '#5fb6ff',
    midGray: '#f0f0f0',
    lightOrange: '#fff5e5',
    orange: '#ff9c00',
    lightViolet: '#e7d9e8',
    violet: '#b257b8',
    turquoise: '#54bfa5;',
};
