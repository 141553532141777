import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { every, isEmpty } from 'lodash';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { ObjectKeys } from '../../../../types/objectKeys';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import useLanguageStore from '../../../../store/language';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import ErrorModal from '../../../../components/errorModal';
import AddClientModal from '../../../pausal/clients/addClientModal';
import EditClientModal from '../../../pausal/clients/editClientModal';
import MobileHeader from '../../../pausal/einvoices/copyEInvoice/header/mobileHeader';
import WebHeader from '../../../pausal/einvoices/copyEInvoice/header/webHeader';
import WebFooter from '../../../pausal/einvoices/copyEInvoice/footer/webFooter';
import PreviewInvoiceModal from '../../../pausal/einvoices/copyEInvoice/previewInvoiceModal';
import InvoiceEdit from '../../../pausal/einvoices/copyEInvoice/invoiceEdit';
import MobileFooter from '../../../pausal/einvoices/copyEInvoice/footer/mobileFooter';
import { Body, Container, Invoice } from '../../../pausal/einvoices/copyEInvoice/style';
import getAllInvoices from '../../invoices/getAllInvoices';

const EditInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const size = useWindowSize();
    const { pathname } = useLocation();
    const invoiceId = useParams().invoiceId;
    const userId = useParams().userId;

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({});
    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCode, setQrCode] = useState('');

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteClient(clickedClient);
            if (res.status === 200) setDeleteClientModal(false);
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    const handleInvoiceDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                setDeleteInvoiceModal(false);
                navigate(-1);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    const handleUpdateInvoice = async (): Promise<void> => {
        if (invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
        } else if (every(invoice.services, (s) => s.pivot.quantity === '0' && s.pivot.price_per_unit === '0')) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
        } else {
            try {
                setLoading(true);
                const updateInvoice = { ...invoice };
                updateInvoice.services = updateInvoice.services.map((service: ObjectKeys, i: number) => ({
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: Number(service.pivot.quantity.replace(',', '.')),
                    price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
                }));
                (updateInvoice.invoice_number = invoice.invoice_number.split('/')[0]),
                    (updateInvoice.value = Number(updateInvoice.value));
                updateInvoice.value_in_rsd = Number(updateInvoice.value_in_rsd);
                updateInvoice.trading_date = moment(updateInvoice.trading_date).format('YYYY-MM-DD').toString();
                updateInvoice.rate = Number(updateInvoice.rate);
                updateInvoice.paid_date = moment(updateInvoice.paid_date).format('YYYY-MM-DD').toString();
                updateInvoice.invoice_date = moment(updateInvoice.invoice_date).format('YYYY-MM-DD').toString();
                updateInvoice.cancelled = false;
                updateInvoice.cancel_number = false;
                updateInvoice.custom_number = false;
                updateInvoice.is_custom = false;
                updateInvoice.custom_text = false;
                updateInvoice.auto_increment = true;
                updateInvoice.invoice_per_owner = true;

                const res = await communication.editInvoice(invoice.id, updateInvoice);

                if (res.status === 200) {
                    setLoading(false);
                    getAllInvoices(setInvoice, userId ? userId : '');
                    navigate(-1);
                }
            } catch (error: any) {
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        setLoading(true);
        if (invoiceId) {
            communication.getInvoice(invoiceId).then((res: ObjectKeys) => {
                setInvoice(res?.data?.data);
                setLoading(false);
                res?.data?.data &&
                    handleGenerateQRCode(
                        res?.data?.data?.original_data?.agency,
                        res?.data?.data?.client?.data?.company_name,
                        res?.data?.data?.value,
                        res?.data?.data?.id,
                        res?.data?.data?.bank_account,
                        res?.data?.data?.currency,
                        true,
                    );
            });
        }
    }, [invoiceId]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(agencyData.name, clientData, amount, invoiceNumber, bankAccount, currency, convert).then(
                (response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                },
            );
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            return newInvoiceData;
        });
        invoice &&
            handleGenerateQRCode(
                invoice?.original_data?.agency,
                client.company_name,
                invoice.value,
                invoice.id,
                invoice.bank_account,
                invoice.currency,
                true,
            );
    };

    return (
        <>
            {loading && <Loader />}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}

            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        userId={invoice?.original_data?.agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={0}
                        setNewCreatedClient={setNewCreatedClient}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={invoice?.original_data?.agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleClientDelete}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={t('pages.editInvoice.deleteClient').text}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleInvoiceDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    <PreviewInvoiceModal
                        agency={invoice?.original_data?.agency}
                        invoice={invoice}
                        title="invoice"
                        type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        note={invoice.comment}
                    />
                </Modal>
            )}

            <Container>
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={() => {
                            return;
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={() => {
                            return;
                        }}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={() => {
                            return;
                        }}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={() => {
                            return;
                        }}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                    />
                )}
                <Body className="edit-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={invoice?.original_data?.agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        userId={userId}
                        type={invoice?.type === 'national' ? 'domestic' : invoice?.type === 'foreign' ? 'foreign' : ''}
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {invoice && (
                        <Invoice>
                            <InvoiceEdit
                                qrCode={qrCode}
                                name="invoice"
                                formData={invoice}
                                setFormData={setInvoice}
                                agency={invoice?.original_data?.agency}
                                invoice={invoice}
                                handleTotal={(sum: any) => {
                                    setInvoice({
                                        ...invoice,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobileWidth={isMobileWidth}
                                setLoading={setLoading}
                                type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                                userId={userId}
                            />
                        </Invoice>
                    )}
                    {isMobileWidth ? (
                        <MobileFooter
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={() => {
                                return;
                            }}
                            handleDownloadInvoice={() => {
                                return;
                            }}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={() => {
                                return;
                            }}
                            handleDownloadInvoice={() => {
                                return;
                            }}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
        </>
    );
};
export default EditInvoice;
