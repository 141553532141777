import { FunctionComponent } from 'react';
import { ButtonsBottom } from '../../style';
import Button, { ButtonVariant } from '../../../../../../components/button';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import colors from '../../../../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faPlus, faPrint, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';

interface Props {
    t: Function;
    setPreviewModal: Function;
    setSendModal: Function;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
    name?: string;
    invoice?: ObjectKeys;
}

const WebFooter: FunctionComponent<Props> = ({
    t,
    setPreviewModal,
    setSendModal,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
    name,
    invoice,
}) => {
    const role = localStorage.getItem('role');
    return (
        <ButtonsBottom>
            {role !== 'admin' ? (
                <>
                    <Button
                        onClick={() => handleDownloadInvoice()}
                        variant={ButtonVariant.outlined}
                        color={colors.purple}
                        icon={true}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                        {t('pages.editInvoice.print').text}
                    </Button>
                    <Button
                        onClick={() => setSendModal(true)}
                        variant={ButtonVariant.solid}
                        color={colors.blue}
                        icon={true}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                        {t('pages.editInvoice.send').text}
                    </Button>
                </>
            ) : (
                <></>
            )}
            <Button
                onClick={() => handleUpdateInvoice()}
                variant={ButtonVariant.solid}
                color={colors.green}
                icon={true}
                size={'auto'}
            >
                <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                {t('pages.editInvoice.save').text}
            </Button>
            {name === 'proforma' ? (
                <Button
                    onClick={() => handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()}
                    variant={ButtonVariant.solid}
                    icon={true}
                    color={colors.green}
                    size={'auto'}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                    {t('pages.invoiceCard.dropdownItems.invoice').text}
                </Button>
            ) : (
                <></>
            )}
            {role !== 'admin' ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    icon={true}
                    size={'auto'}
                    onClick={() => setPreviewModal(true)}
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                    {t('pages.editInvoice.preview').text}
                </Button>
            ) : (
                <></>
            )}
            {invoice?.id ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.danger}
                    icon={true}
                    size={'auto'}
                    onClick={() => setDeleteInvoiceModal(true)}
                >
                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                    {t('pages.editInvoice.delete').text}
                </Button>
            ) : (
                <></>
            )}
        </ButtonsBottom>
    );
};

export default WebFooter;
