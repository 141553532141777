import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllClients from '../getAllClients';
type Props = {
    t: Function;
    clickedClient: string;
    closeGlobalModal: () => void;
    userId: string;
    setClients: Dispatch<SetStateAction<ObjectKeys>>;
    tabIndex: number;
};

const ArchiveModal: FunctionComponent<Props> = ({
    t,
    clickedClient,
    closeGlobalModal,
    setClients,
    tabIndex,
    userId,
}) => {
    const { addToast } = useToasts();

    const handleDeleteClient = (clientId: string): void => {
        communication
            .deleteClient(clientId)
            .then((res: ObjectKeys) => {
                if (res) {
                    closeGlobalModal();
                    addToast(t('pages.clients.archiveModal.message').text, {
                        appearance: 'success',
                        autoDismiss: true,
                    });
                }
            })
            .then(() => {
                getAllClients(setClients, userId, tabIndex);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    addToast(t('pages.tryAgain.message').text, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            });
    };
    return (
        <ArchiveModalWrapper>
            <ModalText>
                <h4>{t('pages.clients.archiveModal.title').text}</h4>
                <p>{t('pages.clients.archiveModal.description').text}</p>
            </ModalText>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleDeleteClient(clickedClient);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </ArchiveModalWrapper>
    );
};

const ArchiveModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;

export default ArchiveModal;
