import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { nanoid } from 'nanoid';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useAllUsersStore from '../../../store/admin/allUsers';
import useUserStore from '../../../store/user';
import { ObjectKeys } from '../../../types/objectKeys';
import communication from '../../../communication';
import SelectSearch from '../../../components/selectSearch';
import Loader from '../../../components/loader';
import ActionsForm from './actionsForm';

const Users: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { addToast } = useToasts();

    const { allUsers } = useAllUsersStore();

    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedUserInfo, setSelectedUserInfo] = useState<ObjectKeys>([]);
    const [personalInfo, setPersonalInfo] = useState<Array<{ value: string; label: string }>>([]);
    const [accountInfo, setAccountInfo] = useState<Array<{ value: string; label: string; className?: string }>>([]);
    const [agencyInfo, setAgencyInfo] = useState<Array<{ value: string; label: string }>>([]);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [tabIndex, setTabIndex] = useState(0);

    const { activityCodes } = useUserStore();

    const tabs = [
        t('pages.admin.users.tabs.personalInfo').text,
        t('pages.admin.users.tabs.accountInfo').text,
        t('pages.admin.users.tabs.agencyInfo').text,
        t('pages.admin.users.tabs.actions').text,
    ];

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib };
        });
        setSelectedUserId(allUsers[0]?.id);
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    useEffect(() => {
        setLoaderVisible(true);
        if (selectedUserId) {
            communication
                .getUsersById(selectedUserId)
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setSelectedUserInfo(res?.data?.data);
                        setLoaderVisible(false);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        addToast(error?.response?.data?.message, {
                            appearance: 'error',
                            autoDismiss: true,
                        });
                        setLoaderVisible(false);
                    }
                });
        }
    }, [selectedUserId]);

    useEffect(() => {
        setPersonalInfo([
            { label: t('pages.admin.users.tabsContent.personalInfo.name').text, value: selectedUserInfo?.first_name },
            {
                label: t('pages.admin.users.tabsContent.personalInfo.lastName').text,
                value: selectedUserInfo?.last_name,
            },
            {
                label: t('pages.admin.users.tabsContent.personalInfo.middleName').text,
                value: selectedUserInfo?.middle_name,
            },
            { label: t('pages.admin.users.tabsContent.personalInfo.personalId').text, value: selectedUserInfo?.jmbg },
            { label: t('pages.admin.users.tabsContent.personalInfo.birth').text, value: selectedUserInfo?.birth },
            {
                label: t('pages.admin.users.tabsContent.personalInfo.citizenship').text,
                value: selectedUserInfo?.citizenship,
            },
            { label: t('pages.admin.users.tabsContent.personalInfo.gender').text, value: selectedUserInfo?.gender },
            {
                label: t('pages.admin.users.tabsContent.personalInfo.education').text,
                value: selectedUserInfo?.education,
            },
            { label: t('pages.admin.users.tabsContent.personalInfo.address').text, value: selectedUserInfo?.address },
            { label: t('pages.admin.users.tabsContent.personalInfo.email').text, value: selectedUserInfo?.email },
        ]);
        setAccountInfo([
            {
                label: t('pages.admin.users.tabsContent.accountInfo.confirmedAccount').text,
                value: selectedUserInfo?.confirmed === true ? t('input.yes').text : t('input.no').text,
            },
            {
                label: t('pages.admin.users.tabsContent.accountInfo.active').text,
                value:
                    selectedUserInfo?.active === 1
                        ? t('pages.admin.users.tabsContent.active').text
                        : t('pages.admin.users.tabsContent.inactive').text,
                className: 'status',
            },
            {
                label: t('pages.admin.users.tabsContent.accountInfo.accountExpirationDate').text,
                value: moment(selectedUserInfo?.account_expiration_date).format('DD.MM.YYYY'),
            },
            {
                label: t('pages.admin.users.tabsContent.accountInfo.accountType').text,
                value: selectedUserInfo?.roles?.data[0]?.name,
            },
            {
                label: t('pages.admin.users.tabsContent.accountInfo.lastLogin').text,
                value: moment(selectedUserInfo?.last_login).format('DD.MM.YYYY'),
            },
        ]);
        setAgencyInfo([
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.agencyName').text,
                value: selectedUserInfo?.agency?.data[0]?.name,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.agencyFullName').text,
                value: selectedUserInfo?.agency?.data[0]?.full_name,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.address').text,
                value:
                    selectedUserInfo?.agency?.data[0]?.street_address +
                    ', ' +
                    selectedUserInfo?.agency?.data[0]?.street_number +
                    ', ' +
                    selectedUserInfo?.agency?.data[0]?.city,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.vatNo').text,
                value: selectedUserInfo?.agency?.data[0]?.pib,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.agencyIdNumber').text,
                value: selectedUserInfo?.agency?.data[0]?.identification_number,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.bankAccountNumber').text,
                value: selectedUserInfo?.agency?.data[0]?.first_account_number,
            },
            {
                label: '',
                value: selectedUserInfo?.agency?.data[0]?.second_account_number,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.bankAccountForeign').text,
                value: selectedUserInfo?.agency?.data[0]?.foreign_exchange_account_number,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.agencyDateOfRegistration').text,
                value: selectedUserInfo?.agency?.data[0]?.date_of_registration,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.employedInAnotherCompany').text,
                value:
                    selectedUserInfo?.agency?.data[0]?.employed_by_other_firm === 1
                        ? t('input.yes').text
                        : selectedUserInfo?.agency?.data[0]?.employed_by_other_firm === 0
                        ? t('input.no').text
                        : undefined,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.phoneNumber').text,
                value: selectedUserInfo?.agency?.data[0]?.phone,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.email').text,
                value: selectedUserInfo?.agency?.data[0]?.agency_email,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.iban').text,
                value: selectedUserInfo?.agency?.data[0]?.iban,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.swift').text,
                value: selectedUserInfo?.agency?.data[0]?.swift,
            },
            {
                label: t('pages.admin.users.tabsContent.agencyInfo.activityCode').text,
                value: activityCodes.find((x) => x.id === selectedUserInfo?.agency?.data[0]?.activity_code_id)?.name,
            },
        ]);
    }, [currentLang, selectedUserInfo]);

    return (
        <>
            {loaderVisible && <Loader />}
            <div className="users page">
                <Header>
                    <h1>{t('pages.admin.users.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SelectSearch
                        className="select-container"
                        optionList={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => setSelectedUserId(data.value)}
                        defaultValue={allUsersOptions[0]}
                    />
                </SelectWrapper>
                <h4>
                    {t('pages.admin.users.chosenUser').text} - {selectedUserInfo?.first_name}{' '}
                    {selectedUserInfo?.last_name}
                </h4>
                <TabsWrapper>
                    <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                        <TabList>
                            {tabs.map((tab, index) => (
                                <Tab key={index}>{tab}</Tab>
                            ))}
                        </TabList>
                        {tabs.map((tab, index) => (
                            <TabPanel key={index} className={tab}>
                                {index === 0 ? (
                                    personalInfo?.map((info: ObjectKeys) => {
                                        return (
                                            <TabItem key={nanoid()}>
                                                <Label>{info.label}</Label>
                                                <Value className={`${info.className ?? ''}`.trim()}>
                                                    {info.value ? info.value : '-'}
                                                </Value>
                                            </TabItem>
                                        );
                                    })
                                ) : index === 1 ? (
                                    accountInfo?.map((info: ObjectKeys) => {
                                        return (
                                            <TabItem key={nanoid()}>
                                                <Label>{info.label}</Label>
                                                <Value className={`${info.className ?? ''}`.trim()}>
                                                    {info.value ? info.value : '-'}
                                                </Value>
                                            </TabItem>
                                        );
                                    })
                                ) : index === 2 ? (
                                    agencyInfo?.map((info: ObjectKeys) => {
                                        return (
                                            <TabItem key={nanoid()}>
                                                <Label>{info.label}</Label>
                                                <Value className={`${info.className ?? ''}`.trim()}>
                                                    {info.value ? info.value : '-'}
                                                </Value>
                                            </TabItem>
                                        );
                                    })
                                ) : index === 3 ? (
                                    <ActionsForm
                                        selectedUserInfo={selectedUserInfo}
                                        setSelectedUserInfo={setSelectedUserInfo}
                                        setAllUsersOptions={setAllUsersOptions}
                                    />
                                ) : (
                                    <></>
                                )}
                            </TabPanel>
                        ))}
                    </Tabs>
                </TabsWrapper>
            </div>
        </>
    );
};

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: 30px;
    .react-tabs {
        width: 100%;
    }

    .react-tabs__tab-list {
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 0;
        .react-tabs__tab {
            font-size: 17px;
            padding: 20px;
            border-bottom: 2px solid transparent;
            @media only screen and (max-width: 600px) {
                width: 100%;
            }
            &.react-tabs__tab--selected {
                background: transparent;
                border: 0;
                background: var(--white);
                border-color: var(--purple);
                color: var(--black);
                border-radius: 0;
                border-bottom: 2px solid var(--purple);
                &:focus {
                    &:after {
                        content: none;
                    }
                }
            }
        }
        @media only screen and (max-width: 600px) {
            flex-direction: column;
        }
    }
    .react-tabs__tab-panel,
    .react-tabs__tab-panel--selected {
        background: var(--white);
        padding: 20px;
    }
`;
const Label = styled.p`
    text-transform: uppercase;
    color: var(--gray);
    font-size: 14px;
    margin-bottom: 6px;
`;
const Value = styled.div`
    color: var(--purple);
    font-size: 16px;
    font-weight: 600;
    &.status {
        border-radius: 10px;
        background-color: var(--green);
        color: var(--white);
        padding: 0 10px;
        display: inline-flex;
    }
`;
const TabItem = styled.div`
    margin-bottom: 15px;
`;

export default Users;
