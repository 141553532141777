import { FunctionComponent, useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList, faPrint } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useGeneralStore from '../../../store/general';
import useLanguageStore from '../../../store/language';
import useProfileStore from '../../../store/profile';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import { InvoiceCardComponentType } from '../../../components/invoiceCard';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Filters from './filters';
import DeleteModal from '../invoices/deleteModal';
import CancelModal from '../invoices/cancelModal';
import Modal from '../../../components/modal';
import Success from '../../../components/successModal';
import Sidebar from '../../../components/sideModal';
import AccountExpired from '../../../components/accountExpired';
import { SendDocumentFormData } from '../../../types/communication/document';
import SendDocumentModal from '../invoices/sendModal';
import PaymentModal from '../invoices/paymentModal';
import EInvoiceCard from './einvoiceCard';
import Dropdown from '../../../components/dropdown';
import { DropdownContainer } from '../invoices';
import ErrorModal from '../../../components/errorModal';

const EInvoices: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { profile } = useProfileStore();

    const token = getAccessToken();
    const isAccountActive = profile?.active === 1 ? true : false;

    const [invoices, setInvoices] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');

    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //send
    const [sendModal, setSendModal] = useState(false);
    const [clientEmail, setClientEmail] = useState('');
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    //pay
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [invoicePaidValue, setInvoicePaidValue] = useState('');

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: '',
        searchFields: {},
        searchJoin: 'and',
    });
    useEffect(() => {
        setLoaderVisible(true);
        communication
            .getInvoices({
                ...params,
                search: `is_prepaid:0;is_einvoice:1;${params.search}`,
                searchFields: 'is_prepaid:=;is_einvoice:=;' + formatParams(params.searchFields),
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setInvoices(res?.data);
                }
            });
    }, [params, success]);

    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/invoices/edit/${invoiceId}`);
    };

    // Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        communication.copyInvoice(invoiceId).then((res: ObjectKeys) => {
            if (res) {
                navigate(`/invoices/edit/${res.data.data.id}`);
            }
        });
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoiceNumber: string, id: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={id}
                invoiceNumber={invoiceNumber}
                closeGlobalModal={closeGlobalModal}
                setInvoices={function (_value: any): void {
                    throw new Error('Function not implemented.');
                }}
            />,
        );
    };

    //Invoice Cancel Modal
    const handleCancelModal = (invoiceId: string): void => {
        setOpenCancelModal(true);
        setClickedInvoice(invoiceId);
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
        ).then((res: ObjectKeys | any) => {
            if (res)
                communication.downloadInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD');
        });
    };

    //refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);
        const res = await communication.refreshEInvoice({ id: eInvoiceId });
        if (res.status === 200) setSuccess(true);
        setLoaderVisible(false);
    };

    //send eInvoice
    const handleSendModal = (invoiceId: string, invoiceClientEmail: string): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
    };
    const handleSendInvoice = (): void => {
        setClickedInvoice('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //payment
    const handlePaymentModal = (invoiceId: string, paidValue: string): void => {
        setOpenPaymentModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    const handleClickedInvoice = (invoiceId: string): void => {
        setClickedInvoice(invoiceId);
    };

    //Add new invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/invoices/add/${invoiceType}`);
    };

    const ttag = document.getElementById('noData');
    if (ttag)
        ttag.innerHTML =
            currentLangName === 'Srpski'
                ? 'Sistem eFaktura je <b>obavezan</b> za <b>obveznike PDV</b> i lica koja sarađuju sa <b>javnim preduzećima</b>, a možeš ga koristiti i <b>dobrovoljno.</b> <br/> Kao paušalac <b>nisi</b> u obavezi da koristiš sistem eFaktura <b>osim ako</b> ne radiš sa <b>javnim preduzećima</b> ili se ne registruješ kao <b>dobrovoljni</b> korisnik. U ovim slučajevima si u obavezi da šalješ fakture <b>svima</b> koji sistem koriste.'
                : 'The eInvoice system is <b>mandatory</b> for <b>VAT</b> payers and persons who cooperate with <b>public companies</b>, or you can use it <b>voluntarily.</b> <br/> As a lump sum, you are <b>not obliged</b> to use the eInvoice system <b>unless</b> you work with <b>public companies</b> or register as a voluntary user. In these cases, you are obliged to send invoices to <b>everyone</b> who uses the system.';
    return (
        <>
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {openCancelModal && (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={clickedInvoice}
                        setInvoices={function (_value: any): void {
                            throw new Error('Function not implemented.');
                        }}
                    />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success
                        close={() => setSuccess(false)}
                        message={successMessage ? successMessage : t('pages.eInvoices.refreshSuccess').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmail={clientEmail}
                        setInvoices={function (_value: any): void {
                            throw new Error('Function not implemented.');
                        }}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {openPaymentModal && (
                <Modal modalVisible={openPaymentModal} closeModal={() => setOpenPaymentModal(false)}>
                    <PaymentModal
                        setOpenPaymentModal={setOpenPaymentModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        success={() => {
                            setOpenPaymentModal(false);
                            setSuccess(true);
                            setSuccessMessage(t('pages.eInvoices.paidSuccess').text);
                        }}
                        setInvoices={setInvoices}
                    />
                </Modal>
            )}
            <PageWrapper className="e-invoices page">
                <Header>
                    <div>
                        <h1>{t('pages.eInvoices.title').text}</h1>
                        <div style={{ position: 'relative' }}>
                            <Button
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.invoices.newInvoice').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.invoice').text,
                                                handler: () => handleAddInvoice('domestic'),
                                            },
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.foreign').text,
                                                handler: () => handleAddInvoice('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </div>
                    </div>
                    <div>
                        {isAccountActive ? (
                            <Button
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => navigate('/clients')}
                            >
                                <FontAwesomeIcon className="icon" color={colors.purple} icon={faList} />
                                {t('buttons.clients').text}
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button
                            variant={ButtonVariant.outlined}
                            onClick={() => navigate('/e-invoices/kpo')}
                            color={colors.purple}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                            {t('pages.invoices.listOfBooksOfIncome').text}
                        </Button>
                    </div>
                </Header>
                {isAccountActive ? <></> : <AccountExpired />}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                    statusFilters={[
                        { value: '', label: t('pages.eInvoices.status.all').text },
                        { value: 'eInvoice.status:Sending;', label: t('pages.eInvoices.status.sending').text },
                        { value: 'eInvoice.status:Sent;', label: t('pages.eInvoices.status.sent').text },
                        { value: 'eInvoice.status:Approved;', label: t('pages.eInvoices.status.verified').text },
                        { value: 'eInvoice.status:Rejected;', label: t('pages.eInvoices.status.cancelled').text },
                        { value: 'eInvoice.status:Storno;', label: t('pages.eInvoices.status.storned').text },
                        { value: 'eInvoice.status:Draft,New;', label: t('pages.eInvoices.status.open').text },
                    ]}
                    showLabel={t('pages.eInvoices.eInvoice').text}
                />
                <InvoicesList className={`display-${display}`}>
                    {invoices?.data?.length > 0 ? (
                        invoices?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <EInvoiceCard
                                        eInvoice
                                        type={InvoiceCardComponentType.EInvoice}
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice.id)}
                                        handleDelete={() => handleDeleteModal(invoice?.invoice_number, invoice.id)}
                                        handleCancel={() => handleCancelModal(invoice.id)}
                                        handleDownload={() =>
                                            handleDownloadInvoice(
                                                invoice?.id,
                                                invoice?.original_data?.agency,
                                                invoice?.client?.data,
                                                invoice.value_in_rsd,
                                                invoice.invoice_number,
                                                invoice.bank_account,
                                                invoice.currency,
                                                true,
                                                invoice,
                                            )
                                        }
                                        handlePayment={() => handlePaymentModal(invoice.id, invoice.value)}
                                        handleSend={() => handleSendModal(invoice.id, invoice.client.data.email)}
                                        handleRefresh={() => handleRefreshEInvoice(invoice.id)}
                                        handleClick={() => handleClickedInvoice(invoice.id)}
                                        handleCopy={() => handleCopy(invoice.id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoData>
                            <p id="noData"></p>
                            <p>
                                {t('pages.eInvoices.noItems.third').text}{' '}
                                <Link to="/agency/e-invoices">{t('pages.eInvoices.noItems.link').text}</Link>
                            </p>
                        </NoData>
                    )}
                </InvoicesList>
                {invoices?.data?.length > 0 && invoices.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={invoices.meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${
                            invoices.meta?.pagination?.current_page === invoices.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${invoices.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={invoices.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
export default EInvoices;

const PageWrapper = styled.div``;
const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-items: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: 1700px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;
export const NoData = styled.div`
    flex: 1;
    text-align: center;
    margin: 60px 0;
    flex-wrap: wrap;
    align-items: center;
    margin: 30px 0;
    p {
        font-weight: 400;
        font-size: 18px;
        line-height: 1.4;
    }
    a {
        font-weight: bold;
    }
`;
