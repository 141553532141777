import { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import colors from '../../../global/colors';
import communication from '../../../communication';
import useVatNoValidation from '../../../hooks/useVatNoValidation';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import ToggleSwitch from '../../../components/input/toggleSwitch';
import SelectSearch from '../../../components/selectSearch';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useAgencyIdNumberValidation from '../../../hooks/useAgencyIdNumberValidation';
import useDateInFutureValidation from '../../../hooks/useDateInFutureValidation';
import useNumLengthValidation from '../../../hooks/useNumLengthValidation';
import { RegisterSecondFormData } from '../../../types/register/registerSecondFormData';
import { ObjectKeys } from '../../../types/objectKeys';

type SelectOption = {
    value?: string;
    label?: string | undefined;
};
interface Props {
    t: Function;
    showValidationMessage: boolean;
    formData: RegisterSecondFormData;
    setFormData: Dispatch<SetStateAction<RegisterSecondFormData>>;
    handleValidation: Function;
    clickedField: string;
    activityCodesOptions: SelectOption[];
    municipalitiesOptions: SelectOption[];
}

const SecondStep: FunctionComponent<Props> = ({
    t,
    showValidationMessage,
    formData,
    setFormData,
    handleValidation,
    clickedField,
    activityCodesOptions,
    municipalitiesOptions,
}) => {
    const { addToast } = useToasts();

    const isVatNoValid = useVatNoValidation(formData.vatNumber);
    const isAgencyNameValid = useCharLengthValidation(formData.agencyName, 3);
    const isAgencyFullNameValid = useCharLengthValidation(formData.agencyFullName, 3);
    const isAgencyIdNumberValid = useAgencyIdNumberValidation(formData.agencyIdNumber);
    const isBankAccountNumber1Valid = useNumLengthValidation(formData.bankAccountNumber1, 3);
    const isBankAccountNumber2Valid = useNumLengthValidation(formData.bankAccountNumber2, 13);
    const isBankAccountNumber3Valid = useNumLengthValidation(formData.bankAccountNumber3, 2);
    const isCityAndZipValid = useCharLengthValidation(formData.cityAndZip, 3);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(formData.addressOfHeadQuarters, 3);
    const isAddressNumberValid = useCharLengthValidation(formData.addressNumber, 1);
    const isAgencyDateOfRegistration = useDateInFutureValidation(formData.agencyDateOfRegistration);

    const handleGetAgencyData = async (): Promise<void> => {
        formData.vatNumber !== '' &&
            communication
                .getAgencyDataBasedOnVatNo(formData.vatNumber)
                .then((res: ObjectKeys) => {
                    const agencyData = res?.data?.data;
                    setFormData({
                        vatNumber: agencyData?.pib,
                        agencyName: agencyData?.company_name_short
                            ? agencyData?.company_name_short
                            : agencyData?.company_name,
                        agencyFullName: agencyData?.company_name,
                        agencyIdNumber: agencyData?.identification_number,
                        bankAccountNumber1: agencyData?.bank_account[0] ? agencyData?.bank_account[0] : '',
                        bankAccountNumber2: agencyData?.bank_account[1] ? agencyData?.bank_account[1] : '',
                        bankAccountNumber3: agencyData?.bank_account[2] ? agencyData?.bank_account[2] : '',
                        activityCodeId: agencyData?.activity_code_id,
                        activityCode: { label: '', value: agencyData?.activity_code_id },
                        agencyDateOfRegistration: null,
                        cityAndZip: agencyData?.city + ' ' + agencyData?.zip_code,
                        municipalities: { label: '', value: '' },
                        municipalityId: '',
                        addressOfHeadQuarters: agencyData?.address.replace(/[0-9]/g, ''),
                        addressNumber: agencyData?.address.replace(/\D/g, ''),
                        phoneNumber: agencyData?.phone,
                        employedInAnotherCompany: false,
                    });
                })
                .catch((error: ObjectKeys) => {
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                });
    };

    return (
        <SecondStepWrapper>
            <Input
                type={InputComponentType.Number}
                label={t('pages.register.rightColumn.form.secondStep.vatNumber').text}
                value={formData.vatNumber}
                validation={showValidationMessage || clickedField === 'vatNumber' ? isVatNoValid : ''}
                blurHandler={() => handleValidation('vatNumber')}
                onChange={(value: string) => {
                    setFormData({ ...formData, vatNumber: value });
                }}
                maxLength={9}
            />
            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'auto'}
                disabled={formData.vatNumber === ''}
                className="agency-info-btn"
                onClick={handleGetAgencyData}
            >
                {t('pages.register.rightColumn.form.secondStep.agencyInfo').text}
            </Button>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.agencyName').text}
                value={formData.agencyName}
                maxLength={150}
                validation={showValidationMessage || clickedField === 'agencyName' ? isAgencyNameValid : ''}
                blurHandler={() => handleValidation('agencyName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyName: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.agencyFullName').text}
                value={formData.agencyFullName}
                maxLength={150}
                validation={showValidationMessage || clickedField === 'agencyFullName' ? isAgencyFullNameValid : ''}
                blurHandler={() => handleValidation('agencyFullName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyFullName: value });
                }}
            />
            <Input
                type={InputComponentType.Number}
                label={t('pages.register.rightColumn.form.secondStep.agencyIdNumber').text}
                value={formData.agencyIdNumber}
                validation={showValidationMessage || clickedField === 'agencyIdNumber' ? isAgencyIdNumberValid : ''}
                blurHandler={() => handleValidation('agencyIdNumber')}
                onChange={(value: string) => {
                    setFormData({ ...formData, agencyIdNumber: value });
                }}
                maxLength={8}
            />
            <BankAccountNumber>
                <label>{t('pages.register.rightColumn.form.secondStep.bankAccountNumber').text}</label>
                <div>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber1}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber1'
                                ? isBankAccountNumber1Valid
                                : ''
                        }
                        blurHandler={() => handleValidation('bankAccountNumber1')}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber1: value });
                        }}
                        maxLength={3}
                        className="bank-acc-num-1"
                    />
                    <span>-</span>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber2}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber2'
                                ? isBankAccountNumber2Valid
                                : ''
                        }
                        hideBlurOnChange
                        blurHandler={() => {
                            if (formData.bankAccountNumber2.length < 13) {
                                setFormData({
                                    ...formData,
                                    bankAccountNumber2: formData.bankAccountNumber2.padStart(13, '0'),
                                });
                            }
                            handleValidation('bankAccountNumber2');
                        }}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber2: value });
                        }}
                        maxLength={13}
                        className="bank-acc-num-2"
                    />
                    <span>-</span>
                    <Input
                        type={InputComponentType.Number}
                        value={formData.bankAccountNumber3}
                        validation={
                            showValidationMessage || clickedField === 'bankAccountNumber3'
                                ? isBankAccountNumber3Valid
                                : ''
                        }
                        maxLength={2}
                        blurHandler={() => handleValidation('bankAccountNumber3')}
                        onChange={(value: string) => {
                            setFormData({ ...formData, bankAccountNumber3: value });
                        }}
                        className="bank-acc-num-3"
                    />
                </div>
            </BankAccountNumber>
            <SelectWrapper>
                <SelectSearch
                    optionList={activityCodesOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({ ...formData, activityCodeId: data.value, activityCode: data });
                    }}
                    validation={
                        showValidationMessage && !formData.activityCodeId
                            ? t('pages.validationMessages.chooseActivityCode').text
                            : ''
                    }
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.searchActivityCode').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text}
                    defaultValue={
                        formData.activityCodeId
                            ? {
                                  value: formData.activityCodeId,
                                  label: activityCodesOptions.find((ac) => ac?.value === formData.activityCodeId)
                                      ?.label,
                              }
                            : {
                                  label: t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text,
                                  value: '',
                              }
                    }
                />
            </SelectWrapper>
            <DateWrapper>
                <Input
                    type={InputComponentType.Date}
                    label={t('pages.register.rightColumn.form.secondStep.agencyDateOfRegistration').text}
                    date={formData.agencyDateOfRegistration}
                    validation={
                        showValidationMessage || clickedField === 'agencyDateOfRegistration'
                            ? isAgencyDateOfRegistration
                            : ''
                    }
                    blurHandler={() => handleValidation('agencyDateOfRegistration')}
                    onChange={(value: null) => {
                        setFormData({ ...formData, agencyDateOfRegistration: value });
                    }}
                />
            </DateWrapper>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.secondStep.cityAndZip').text}
                value={formData.cityAndZip}
                validation={showValidationMessage || clickedField === 'cityAndZip' ? isCityAndZipValid : ''}
                blurHandler={() => handleValidation('cityAndZip')}
                onChange={(value: string) => {
                    setFormData({ ...formData, cityAndZip: value });
                }}
            />
            <SelectWrapper>
                <SelectSearch
                    optionList={municipalitiesOptions}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        setFormData({ ...formData, municipalityId: data.value, municipalities: data });
                    }}
                    validation={
                        showValidationMessage && !formData.municipalityId
                            ? t('pages.validationMessages.chooseMunicipality').text
                            : ''
                    }
                    searchPlaceholder={t('pages.register.rightColumn.form.secondStep.searchMunicipality').text}
                    placeholder={t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text}
                    defaultValue={
                        formData.municipalityId
                            ? {
                                  value: formData.municipalityId,
                                  label: municipalitiesOptions.find((m) => m?.value === formData.municipalityId)?.label,
                              }
                            : {
                                  label: t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text,
                                  value: '',
                              }
                    }
                />
            </SelectWrapper>
            <Address>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.secondStep.addressOfHeadQuarters').text}
                    value={formData.addressOfHeadQuarters}
                    validation={
                        showValidationMessage || clickedField === 'addressOfHeadQuarters'
                            ? isAddressOfHeadQuartersValid
                            : ''
                    }
                    blurHandler={() => handleValidation('addressOfHeadQuarters')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, addressOfHeadQuarters: value });
                    }}
                    className="address-name"
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.secondStep.addressNumber').text}
                    value={formData.addressNumber}
                    validation={showValidationMessage || clickedField === 'addressNumber' ? isAddressNumberValid : ''}
                    blurHandler={() => handleValidation('addressNumber')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, addressNumber: value });
                    }}
                    className="address-num"
                />
            </Address>
            <Input
                type={InputComponentType.Number}
                label={t('pages.register.rightColumn.form.secondStep.phoneNumber').text}
                value={formData.phoneNumber}
                onChange={(value: string) => {
                    setFormData({ ...formData, phoneNumber: value });
                }}
            />
            <EmployedInAnotherCompany className="employed-in-another-company">
                <p>{t('pages.register.rightColumn.form.secondStep.employedInAnotherCompany').text}</p>
                <ToggleSwitch
                    defaultChecked={formData.employedInAnotherCompany}
                    onChange={() =>
                        setFormData({
                            ...formData,
                            employedInAnotherCompany: !formData.employedInAnotherCompany,
                        })
                    }
                />
            </EmployedInAnotherCompany>
        </SecondStepWrapper>
    );
};

export default SecondStep;

const SecondStepWrapper = styled.div`
    .agency-info-btn {
        margin-bottom: 20px;
        padding: 5px;
        height: auto;
        line-height: 15px;
    }
`;
const BankAccountNumber = styled.div`
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
    }
    > div {
        display: flex;
        align-items: center;
        span {
            margin: 0 10px;
            color: var(--border-color);
            width: 10px;
        }
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const Address = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
`;

const EmployedInAnotherCompany = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    p {
        color: var(--gray);
    }
`;
