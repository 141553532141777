import { FunctionComponent } from 'react';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../button';
import useLanguageStore from '../../store/language';
import useChangeModalStore from '../../store/changeModal';
import useTranslations from '../../hooks/useTranslation';

interface Props {
    close: Function;
    saveChanges: Function;
    message: string;
    active?: boolean;
    className?: string;
    children?: string | any;
    confirmText?: boolean;
    closeText?: boolean;
}

const ChangeModal: FunctionComponent<Props> = ({ close, saveChanges, message, children, confirmText, closeText }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    return (
        <>
            <TextContainer>
                <h3>{message}</h3>
            </TextContainer>
            {children && <>{children}</>}
            <ButtonContainer>
                <Button
                    textTransformNone
                    variant={ButtonVariant.solid}
                    color={'var(--purple)'}
                    onClick={() => {
                        useChangeModalStore.setState({ changeModalVisible: false });
                        saveChanges();
                    }}
                    size={'50%'}
                >
                    {confirmText ? confirmText : t('input.yes').text}
                </Button>

                <Button
                    textTransformNone
                    variant={ButtonVariant.outlined}
                    color={'var(--white)'}
                    onClick={() => {
                        useChangeModalStore.setState({ changeModalVisible: false });
                        close();
                    }}
                    size={'50%'}
                >
                    {closeText ? closeText : t('input.no').text}
                </Button>
            </ButtonContainer>
        </>
    );
};

export default ChangeModal;

const TextContainer = styled.div`
    padding: 35px 50px;
    text-align: center;
    min-height: 130px;
    display: flex;
    justify-content: center;
    h3 {
        padding-top: 0px;
        margin-top: 0px;
        color: var(--black);
        font-size: 20px;
    }
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    button {
        border-radius: 0px !important;
        :nth-child(2) {
            color: var(--black);
            border: 1px solid var(--border-color);
        }
    }
`;
