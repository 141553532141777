import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import useGeneralStore from '../../../../store/general';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllInvoices from '../getAllAInvoices';
type Props = {
    t: Function;
    setOpenEInvoiceModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: string;
    setInvoices: Dispatch<SetStateAction<any>>;
};

const EAdvanceModal: FunctionComponent<Props> = ({ t, setOpenEInvoiceModal, clickedInvoice, setInvoices }) => {
    const [invoiceDate, setInvoiceDate] = useState(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();

    //EAdvance
    const handleEAdvance = (invoiceId: string): void => {
        communication
            .eInvoice({
                invoice_id: invoiceId,
                due_date: moment(invoiceDate).format('YYYY-MM-DD'),
                send_to_cir: false,
            })
            .then((res: ObjectKeys) => {
                if (res) {
                    setOpenEInvoiceModal(false);
                }
            })
            .then(() => {
                getAllInvoices(setInvoices);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setOpenEInvoiceModal(false);
                    setGlobalModal(
                        <ModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ModalWrapper>,
                    );
                }
            });
    };
    return (
        <EAdvanceModalWrapper>
            <ModalText>
                <h4>{t('pages.advanceInvoices.eAdvanceModal.title').text}</h4>
                <p>{t('pages.advanceInvoices.eAdvanceModal.description').text}</p>
            </ModalText>
            <DateWrapper>
                <Input
                    type={InputComponentType.Date}
                    label={t('pages.advanceInvoices.eAdvanceModal.inputLabel').text}
                    date={invoiceDate}
                    onChange={(value: any) => {
                        setInvoiceDate(value);
                    }}
                />
            </DateWrapper>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleEAdvance(clickedInvoice);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    onClick={() => setOpenEInvoiceModal(false)}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </EAdvanceModalWrapper>
    );
};

const EAdvanceModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--light-gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        line-height: 1.3;
        color: var(--gray);
        font-size: 14px;
        margin-bottom: 10px;
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    padding: 10px 50px 35px 50px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
export default EAdvanceModal;
