import React, { FunctionComponent, useEffect, useState } from 'react';
import useWindowSize from '../../hooks/useResize';
import styled from 'styled-components';
import SelectComponent from '../../components/select';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import Input, { InputComponentType } from '../../components/input';
import { ObjectKeys } from '@/types/objectKeys';
import useNumLengthValidation from '../../hooks/useNumLengthValidation';
import communication from '../../communication';
import Loader from '../../components/loader';

interface Props {
    yearsOptions: Array<{ value: any; label: any }>;
    closeModal: Function;
    user?: ObjectKeys;
    setIsConfirmModal: Function;
    setData: Function;
    year: string;
}

export interface ChangeAmountTypesAdmin {
    year: string;
    user_id: string;
    model_number_recipient: number;
    call_number_recipient: string;
    total_amount: {
        doprinos_za_zdravstveno_osiguranje: number;
        doprinos_za_osiguranje_nezaposlenih: number;
        porez_na_prihode_od_samostalne_delatnosti: number;
        doprinos_za_pio: number;
    };
}

export interface HelpWithTaxTypes {
    year: string;
}

const ManageOrdersModalAdmin: FunctionComponent<Props> = ({
    yearsOptions,
    closeModal,
    user,
    setIsConfirmModal,
    setData,
    year,
}) => {
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [selectedYear, setSelectedYear] = useState<string>(year);
    const [loader, setLoader] = useState<boolean>(false);
    const [changeAmountsData, setChangeAmountsData] = useState<ChangeAmountTypesAdmin>({
        year: year,
        user_id: '',
        model_number_recipient: 97,
        call_number_recipient: '',
        total_amount: {
            doprinos_za_zdravstveno_osiguranje: 0,
            doprinos_za_osiguranje_nezaposlenih: 0,
            porez_na_prihode_od_samostalne_delatnosti: 0,
            doprinos_za_pio: 0,
        },
    });

    const { currentLang } = useLanguageStore();

    const t = useTranslations(currentLang);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        getCallNumber();
        setChangeAmountsData((prevState) => ({ ...prevState, year: selectedYear }));
        setChangeAmountsData((prevState) => ({ ...prevState, user_id: user?.agency_id }));
    }, [selectedYear, year, user]);

    const resetData = (): void => {
        setChangeAmountsData({
            year: selectedYear,
            user_id: '',
            model_number_recipient: 97,
            call_number_recipient: changeAmountsData.call_number_recipient,
            total_amount: {
                doprinos_za_pio: 0,
                porez_na_prihode_od_samostalne_delatnosti: 0,
                doprinos_za_zdravstveno_osiguranje: 0,
                doprinos_za_osiguranje_nezaposlenih: 0,
            },
        });
    };

    const callNoValidation = useNumLengthValidation(changeAmountsData.call_number_recipient, 19);
    const pioValidation = useNumLengthValidation(
        changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString(),
        undefined,
        undefined,
        true,
    );
    const formIncomeValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_pio.toString(),
        undefined,
        true,
        true,
    );
    const formUnemploymentValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih
            ? changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih.toString()
            : '',
        undefined,
        true,
        true,
    );
    const formHealthValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje
            ? changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje.toString()
            : '',
        undefined,
        true,
        true,
    );

    const getCallNumber = async (): Promise<void> => {
        if (user) {
            resetData();
            setLoader(true);
            const data = await communication.getCallNumberByYear(selectedYear, user?.agency_id);
            setChangeAmountsData((prevState) => ({
                ...prevState,
                call_number_recipient: data.data.call_number,
            }));
            // resetData();
            setLoader(false);
        }
    };
    return (
        <>
            {loader && <Loader />}
            <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
                <h1>{t('pages.transfers.manage_transfers').text}</h1>
                <div className={'change-amounts first-tab'}>
                    <h4>{t('pages.transfers.choose_year_for_generating').text}</h4>
                    <div className="select-year-container">
                        <article>
                            <div className={'select'}>
                                <label>{`${
                                    t('pages.transfers.edit_tax_transfers_modal_year').text
                                } ${selectedYear}`}</label>

                                <SelectComponent
                                    optionList={yearsOptions}
                                    defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                    className={'select-year'}
                                    placeholder={selectedYear}
                                    handleSelectedValue={(value: string) => setSelectedYear(value)}
                                />
                            </div>
                            <Input
                                type={InputComponentType.Number}
                                value={changeAmountsData.call_number_recipient}
                                label={`${
                                    t('pages.transfers.edit_tax_transfers_modal__call_number_year').text
                                } ${selectedYear}.`}
                                onChange={(value: string) => {
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        call_number_recipient: value,
                                    }));
                                }}
                                validation={
                                    changeAmountsData?.call_number_recipient?.length > 0 && callNoValidation
                                        ? callNoValidation
                                        : ''
                                }
                                maxLength={19}
                                className={'input'}
                            />
                            <Input
                                type={InputComponentType.Number}
                                label={`${t('pages.transfers.form_pio').text} `}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            doprinos_za_pio: value,
                                        },
                                    }));
                                }}
                                validation={pioValidation ? pioValidation : ''}
                                className={'input'}
                            />
                            <Input
                                type={InputComponentType.Number}
                                label={`${t('pages.transfers.form_health').text}`}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            doprinos_za_zdravstveno_osiguranje: value,
                                        },
                                    }));
                                }}
                                validation={formHealthValidation ? formHealthValidation : ''}
                                className={'input'}
                            />
                            <Input
                                type={InputComponentType.Number}
                                label={`${t('pages.transfers.form_unemployment').text}`}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            doprinos_za_osiguranje_nezaposlenih: value,
                                        },
                                    }));
                                }}
                                validation={formUnemploymentValidation ? formUnemploymentValidation : ''}
                                className={'input'}
                            />
                            <Input
                                type={InputComponentType.Number}
                                label={`${t('pages.transfers.form_income').text}`}
                                onChange={(value: string) => {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    setChangeAmountsData((prevState) => ({
                                        ...prevState,
                                        total_amount: {
                                            ...prevState.total_amount,
                                            porez_na_prihode_od_samostalne_delatnosti: value,
                                        },
                                    }));
                                }}
                                validation={formIncomeValidation ? formIncomeValidation : ''}
                                className={'input'}
                            />
                        </article>
                    </div>
                </div>
                <div className={'buttons'}>
                    <Button
                        variant={ButtonVariant.outlined}
                        size={'50%'}
                        color={colors.purple}
                        onClick={() => closeModal()}
                    >
                        {t('pages.transfers.close').text}
                    </Button>
                    <Button
                        variant={ButtonVariant.solid}
                        size={'50%'}
                        color={colors.purple}
                        onClick={() => {
                            setData(changeAmountsData);
                            closeModal();
                            setIsConfirmModal(true);
                        }}
                    >
                        {t('pages.transfers.send_solution').text}
                    </Button>
                </div>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
    }
    display: inline-block;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
    h1 {
        color: #4c595f;
        font-weight: 400;
        font-size: 20px;
        padding: 25px 50px 10px;
    }
    h4 {
        font-size: 18px;
        color: #9b7fe9;
    }
    .change-amounts {
        padding: 35px 20px 75px;
    }
    article {
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        position: relative;
        text-align: left;
        .select {
            color: #92a5ac;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            width: 100%;
            display: block;
        }
        .select-wrapper {
            width: 100%;
        }
    }
    .upload-tax {
        box-shadow: none !important;
        label {
            width: 100%;
        }
    }
    .description {
        display: none;
    }
    .modal-wrapper:has(> .change-amounts) {
        max-height: 90% !important;
        overflow: auto !important;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button:first-child {
            border-radius: 0 0 0 6px;
        }
        button:last-child {
            border-radius: 0 0 6px 0;
        }
    }
    .tabs {
        ul {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .first-tab {
        padding: 35px 50px 10px;
        input {
            width: 100%;
        }
    }
    .dropzone {
        max-width: 100%;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7 !important;
        color: #92a5ac;
        border-radius: 10px;
        margin: 20px 60px 10px;
        padding: 50px;
        img {
            width: 50px;
            margin-bottom: 50px;
        }
    }
`;

export default ManageOrdersModalAdmin;
