import communication from '../../../../../communication';
import { ObjectKeys } from '../../../../../types/objectKeys';

const getAdvances = (setAdvances: any, clientId: string, currency?: string): void => {
    communication
        .getInvoices({
            limit: '100',
            page: 1,
            search: `is_prepaid:1;client_id:${clientId};currency:${currency};status:sent,paid,partially;`,
            searchFields: 'is_prepaid:=;client_id:=;currency:=;status:in;',
            searchJoin: 'and',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setAdvances(
                    res?.data.data.map((advance: ObjectKeys) => {
                        return {
                            value: advance.formatted_invoice_number + '-' + advance.remaining_value + '&' + advance.id,
                            label: advance.formatted_invoice_number + ' ' + advance.remaining_value,
                        };
                    }),
                );
            }
        });
};
export default getAdvances;
