import { ObjectKeys } from '../types/objectKeys';
import download from './download';
import fetch from './fetch';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

export default class Kpo {
    public sendKpoToEmail(options: ObjectKeys, year: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.kpo.sendToEmail(year),
            data: options,
        });
    }
    public downloadKpo(year: string, token: string): ObjectKeys {
        return download(routes.kpo.downloadKpo(year), { token });
    }
}
