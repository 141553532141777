import { ObjectKeys } from '../types/objectKeys';
import fetch from './fetch';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';
export default class User {
    public updateUser(options: ObjectKeys, id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.user.put.concat(`/${id}`),
            data: options,
        });
    }
}
