import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import { ObjectKeys } from '../../../types/objectKeys';

const ConfirmEmail: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const navigate = useNavigate();

    const params = useParams();
    const token = params?.token;
    const [pleaseWait, setPleaseWait] = useState(true);

    useEffect(() => {
        async function confirmEmail(): Promise<void> {
            if (token) {
                await communication
                    .confirmEmailToken(token)
                    .then((res: ObjectKeys) => {
                        if (res.status === 200) {
                            navigate('/login');
                        }
                    })
                    .catch((error: ObjectKeys) => {
                        if (error) {
                            setPleaseWait(false);
                        }
                    });
            }
        }
        confirmEmail();
    }, []);

    return (
        <PageWrapper>
            <ConfirmEmailInner>
                {pleaseWait ? (
                    <h4>{t('pages.confirmEmail.pleaseWait').text}</h4>
                ) : (
                    <>
                        <h4>{t('pages.confirmEmail.title').text}</h4>
                        <p>{t('pages.confirmEmail.subtitle').text}</p>
                        <Link to="/account-activation"> {t('pages.confirmEmail.link').text}</Link>
                    </>
                )}
            </ConfirmEmailInner>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    background: var(--purple);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px;
`;
const ConfirmEmailInner = styled.div`
    background: var(--white);
    box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
    width: 450px;
    padding: 40px;
    text-align: center;
    p {
        margin: 15px 0;
        color: var(--gray);
        font-size: 12px;
    }
    a {
        font-size: 12px;
    }
`;
export default ConfirmEmail;
