import { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

interface Props {
    children?: ReactNode;
    readonly isOpen: boolean;
    readonly target?: ReactNode;
    readonly onClose: () => void;
}

export const Dropdown: FunctionComponent<Props> = ({ children, isOpen, target, onClose }) => {
    return (
        <Container>
            {target}
            {isOpen ? <Menu>{children}</Menu> : null}
            {isOpen ? <Blanket onClick={onClose} /> : null}
        </Container>
    );
};

const Container = styled.div`
    position: 'relative';
    box-shadow: 0 0 3px var(--white) inset, 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const Menu = styled.div`
    background-color: var(--white);
    border-radius: 4;
    margin-top: 8px;
    position: 'absolute';
    z-index: 2;
    box-shadow: 0 0 3px var(--white) inset, 0 1px 1px rgba(0, 0, 0, 0.1);
`;

const Blanket = styled.div`
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: 'fixed';
    z-index: 1;
    box-shadow: 0 0 3px var(--white) inset, 0 1px 1px rgba(0, 0, 0, 0.1);
`;
