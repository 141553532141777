import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import communication from '../../../communication';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import CityPhoto from '../../../assets/city.png';
import Logo from '../../../assets/logo.png';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import useConfirmPasswordValidation from '../../../hooks/useConfirmPasswordValidation';

const ResetPassword: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const navigate = useNavigate();

    const [firstPassword, setFirstPassword] = useState('');
    const firstPasswordValid = usePasswordValidation(firstPassword);

    const [secondPassword, setSecondPassword] = useState('');
    const secondPasswordValid = useConfirmPasswordValidation(firstPassword, secondPassword);

    const params = useParams();
    const token = params?.token;
    const [email, setEmail] = useState();

    const [loading, setLoading] = useState(false);
    const [touchedFirstInput, setTouchedFirstInput] = useState(false);
    const [touchedSecondInput, setTouchedSecondInput] = useState(false);

    const reset = async (): Promise<void> => {
        setLoading(true);

        if (secondPasswordValid === '' && firstPasswordValid === '') {
            const res = await communication.resetPassword({ email, password: firstPassword });
            if (res.status === 204 || res.status === 200) {
                navigate('/login');
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        async function getEmail(): Promise<void> {
            if (token) {
                const res = await communication.getEmailFromToken(token);
                setEmail(res.data.email);
            }
        }

        getEmail();
    }, []);

    return (
        <PageWrapper className="login page">
            <LoginInner>
                <RightColumn>
                    <div className="content">
                        <div className="top-block">
                            <h3>{t('pages.forgotPassword.title').text}</h3>
                            <img src={Logo} alt="logo" />
                        </div>
                        <div className="form">
                            <Input
                                type={InputComponentType.Password}
                                label={t('pages.forgotPassword.newPassword').text}
                                validation={touchedFirstInput ? firstPasswordValid : ''}
                                value={firstPassword}
                                onChange={(value: string) => {
                                    setFirstPassword(value);
                                }}
                                blurHandler={() => setTouchedFirstInput(true)}
                            />

                            <Input
                                type={InputComponentType.Password}
                                label={t('pages.forgotPassword.newPasswordAgain').text}
                                validation={touchedSecondInput ? secondPasswordValid : ''}
                                value={secondPassword}
                                onChange={(value: string) => {
                                    setSecondPassword(value);
                                }}
                                blurHandler={() => setTouchedSecondInput(true)}
                            />

                            <div className="reset-btn">
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    className="big"
                                    size={200}
                                    isLoading={loading}
                                    onClick={reset}
                                >
                                    {t('pages.forgotPassword.button').text}
                                </Button>
                            </div>
                        </div>

                        <div className="bottom-block">
                            <span>{t('pages.forgotPassword.back').text}</span>
                            <span onClick={() => navigate('/login')}>{t('pages.forgotPassword.login').text}</span>
                        </div>
                    </div>
                </RightColumn>
            </LoginInner>
        </PageWrapper>
    );
};
export default ResetPassword;

const PageWrapper = styled.div`
    background: var(--purple);
`;
const LoginInner = styled.div`
    display: flex;
    height: 100%;
    width: fit-content;
    max-width: 1800px;
    margin: 0 auto;
    padding: 10% 0px 0 0px;
    //Media Queries
    @media only screen and (max-width: 1000px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: 425px) {
        padding: 5% 20px 0 20px;
    }
`;

const RightColumn = styled.div`
    background: var(--purple);
    flex: 2 1 40%;
    width: 500px;

    p {
        color: var(--gray);
        margin-bottom: 20px;
    }

    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: 500px;
        //Media Queries
        @media only screen and (max-width: 1000px) {
            margin: 0 auto;
        }
        .success {
            padding: 40px;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 160px;
            padding: 30px;
            color: var(--navbar-gray);
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
                height: 130px;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px 10px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
            }
            .input-container {
                width: 100%;
            }
            .reset-btn {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            > div {
                margin-bottom: 0px;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 30px 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            //Media Queries
            @media only screen and (max-width: 500px) {
                padding: 20px 30px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                span {
                    margin-bottom: 10px;
                }
            }
            span {
                color: var(--gray);
                :nth-of-type(2) {
                    color: var(--purple);
                    margin-left: 5px;
                    :hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;
