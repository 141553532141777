import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import SelectSearch from '../../../components/selectSearch';
import useUserStore from '../../../store/user';
import useProfileStore from '../../../store/profile';
import communication from '../../../communication';
import { CreateHealthCardsType, HealthCardsType } from '../../../types/communication/healthCards';
import moment from 'moment';
import useNumLengthValidation from '../../../hooks/useNumLengthValidation';
import { ObjectKeys } from '../../../types/objectKeys';

interface Props {
    t: Function;
    close: Function;
    cardsLength?: number;
    loading: Function;
    finishLoading: Function;
    changeModal: Function;
    edit?: boolean;
    editData?: HealthCardsType;
    success?: Function;
    currentLang: string;
}

const NewHealthCardModal: FunctionComponent<Props> = ({
    t,
    close,
    cardsLength,
    loading,
    finishLoading,
    changeModal,
    edit,
    editData,
    currentLang,
}) => {
    const genders = [
        {
            value: 'male',
            label: t('pages.agency.personalInfo.genders.male').text,
        },
        {
            value: 'female',
            label: t('pages.agency.personalInfo.genders.female').text,
        },
    ];
    const municipalities = useUserStore.getState().municipalities;
    const [user, setUser] = useState<ObjectKeys>();
    const [showError, setShowError] = useState(false);
    const [formData, setFormData] = useState<CreateHealthCardsType>({
        first_name: '',
        last_name: '',
        parent_name: '',
        jmbg: '',
        street_address: '',
        street_number: '',
        city: '',
        education: '',
        municipality_id: '',
        citizenship: 'Srpsko',
        gender: '',
        start_date: '',
        insurance_type: '',
    });
    const [dataForEdit, setDataForEdit] = useState<HealthCardsType | CreateHealthCardsType>({
        first_name: '',
        last_name: '',
        parent_name: '',
        jmbg: '',
        street_address: '',
        street_number: '',
        city: '',
        education: '',
        municipality_id: '',
        citizenship: 'Srpsko',
        gender: '',
        start_date: '',
    });
    const [clickedField, setClickedField] = useState('');
    const { profile } = useProfileStore();

    useEffect(() => {
        setUser(profile);
        editData && setDataForEdit(editData);
    }, [cardsLength]);

    const insuranceType = [
        { label: t('pages.healthCards.insuranceType.child').text, value: 'child' },
        { label: t('pages.healthCards.insuranceType.spouse').text, value: 'spouse' },
        { label: t('pages.healthCards.insuranceType.carrier').text, value: 'insurance_carrier' },
    ];

    const createCarrierCard = async (): Promise<void> => {
        if (formData) {
            loading();
            const res = await communication.createHealthCard(formData);
            if (res.status === 200) {
                finishLoading();
                close();
            }
        }
    };

    const handleSave = async (): Promise<void> => {
        //insurance carrier
        if (cardsLength === 0) {
            if (isInsuranceTypeValid !== '' || isStartDateValid !== '') setShowError(true);
            else {
                if (
                    clickedField === 'firstName' ||
                    clickedField === 'lastName' ||
                    clickedField === 'parentName' ||
                    clickedField === 'jmbg' ||
                    clickedField === 'addressOfHeadQuarters' ||
                    clickedField === 'addressNumber' ||
                    clickedField === 'city' ||
                    clickedField === 'education' ||
                    clickedField === 'municipality'
                ) {
                    close();
                    changeModal(formData);
                } else {
                    createCarrierCard();
                }
            }
        }

        //spouse or child
        if (cardsLength !== 0) {
            if (
                isInsuranceTypeValid !== '' ||
                isStartDateValid !== '' ||
                isFirstNameValid !== '' ||
                isLastNameValid !== '' ||
                isParentNameValid !== '' ||
                isJmbgValid !== '' ||
                isCityValid !== '' ||
                isAddressOfHeadQuartersValid !== '' ||
                isAddressNumberValid !== '' ||
                isMunicipalityValid !== ''
            )
                setShowError(true);
            else {
                loading();
                const res = await communication.createHealthCard(formData);
                if (res.status === 200) {
                    finishLoading();
                    close();
                }
            }
        }
    };

    const handleUpdate = async (): Promise<void> => {
        close();
        changeModal(dataForEdit);
    };

    useEffect(() => {
        if (cardsLength === 0 && user) {
            const card: CreateHealthCardsType = {
                first_name: user.first_name,
                last_name: user.last_name,
                parent_name: user.middle_name,
                jmbg: user.jmbg,
                street_address: user.address,
                street_number: user.street_number,
                city: user.city,
                education: user.education,
                municipality_id: user.municipality_id,
                citizenship: 'Srpsko',
                gender: user.gender,
                start_date: formData.start_date,
                insurance_type: formData.insurance_type,
            };
            setFormData(card);
        }
    }, [user, cardsLength]);
    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    //validations
    const isFirstNameValid = useCharLengthValidation(formData?.first_name ? formData?.first_name : '', 3);
    const isLastNameValid = useCharLengthValidation(formData?.last_name ? formData?.last_name : '', 3);
    const isParentNameValid = useCharLengthValidation(formData?.parent_name ? formData?.parent_name : '', 3);
    const isJmbgValid = useNumLengthValidation(formData?.jmbg ? formData?.jmbg : '', 13);
    const isEducationValid = useCharLengthValidation(formData?.education ? formData?.education : '', 3);
    const isCityValid = useCharLengthValidation(formData?.city ? formData?.city : '', 3);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(
        formData?.street_address ? formData?.street_address : '',
        3,
    );
    const isAddressNumberValid = useCharLengthValidation(formData?.street_number ? formData?.street_number : '', 1);
    const isInsuranceTypeValid = useCharLengthValidation(formData?.insurance_type ? formData?.insurance_type : '', 1);
    const isStartDateValid = useCharLengthValidation(formData?.start_date ? formData?.start_date : '', 1);
    const isMunicipalityValid = useCharLengthValidation(formData?.municipality_id ? formData?.municipality_id : '', 1);

    return (
        <Container>
            <Header>
                <h2>
                    {edit
                        ? t('pages.healthCards.editCardModal.title').text
                        : t('pages.healthCards.newCardModal.title').text}
                </h2>
                <Icon onClick={() => close()}>
                    <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                </Icon>
                {!edit && <p>{t('pages.healthCards.newCardModal.subtitle').text}</p>}
            </Header>
            {!edit && (
                <SelectWrapper>
                    <p>{t('pages.healthCards.newCardModal.insurerType').text}</p>
                    <SelectSearch
                        optionList={cardsLength === 0 ? insuranceType.slice(2) : insuranceType.slice(0, 2)}
                        placeholder={t('pages.healthCards.newCardModal.choseType').text}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            setFormData({ ...formData, insurance_type: data.value });
                            handleValidation('insurerType');
                        }}
                        validation={showError ? isInsuranceTypeValid : ''}
                    />
                </SelectWrapper>
            )}
            <Input
                type={InputComponentType.Date}
                label={t('pages.healthCards.newCardModal.insuranceStart').text}
                date={
                    dataForEdit && dataForEdit.start_date
                        ? new Date(dataForEdit?.start_date)
                        : formData.start_date
                        ? new Date(formData.start_date)
                        : null
                }
                validation={showError ? isStartDateValid : ''}
                blurHandler={() => handleValidation('agencyDateOfRegistration')}
                onChange={(value: string) => {
                    edit
                        ? setDataForEdit({
                              ...dataForEdit,
                              start_date: moment(value).format('YYYY-MM-DD').toString(),
                          })
                        : setFormData({
                              ...formData,
                              start_date: moment(value).format('YYYY-MM-DD').toString(),
                          });
                }}
            />
            <Row>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.healthCards.name').text}
                    value={
                        edit ? dataForEdit?.first_name : cardsLength === 0 ? formData?.first_name : formData.first_name
                    }
                    onChange={(value: string) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, first_name: value })
                            : setFormData({ ...formData, first_name: value });
                    }}
                    validation={
                        cardsLength === 0
                            ? clickedField === 'firstName'
                                ? isFirstNameValid
                                : ''
                            : showError
                            ? isFirstNameValid
                            : ''
                    }
                    blurHandler={() => handleValidation('firstName')}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.healthCards.lastName').text}
                    value={edit ? dataForEdit?.last_name : cardsLength === 0 ? formData?.last_name : formData.last_name}
                    onChange={(value: string) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, last_name: value })
                            : setFormData({ ...formData, last_name: value });
                    }}
                    validation={
                        cardsLength === 0
                            ? clickedField === 'lastName'
                                ? isLastNameValid
                                : ''
                            : showError
                            ? isLastNameValid
                            : ''
                    }
                    blurHandler={() => handleValidation('lastName')}
                />
            </Row>
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.parentName').text}
                value={
                    edit ? dataForEdit?.parent_name : cardsLength === 0 ? formData?.parent_name : formData.parent_name
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, parent_name: value })
                        : setFormData({ ...formData, parent_name: value });
                }}
                validation={
                    cardsLength === 0
                        ? clickedField === 'parentName'
                            ? isParentNameValid
                            : ''
                        : showError
                        ? isParentNameValid
                        : ''
                }
                blurHandler={() => handleValidation('parentName')}
            />
            <SelectWrapper>
                <p> {t('pages.agency.personalInfo.gender').text}</p>
                <SelectSearch
                    optionList={genders}
                    defaultValue={{
                        label: edit
                            ? currentLang !== 'English'
                                ? dataForEdit.gender === 'male'
                                    ? t('pages.agency.personalInfo.genders.male').text
                                    : t('pages.agency.personalInfo.genders.female').text
                                : dataForEdit.gender
                            : currentLang !== 'English'
                            ? formData.gender === 'male'
                                ? t('pages.agency.personalInfo.genders.male').text
                                : t('pages.agency.personalInfo.genders.female').text
                            : formData.gender,
                        value: edit ? dataForEdit.gender : formData.gender,
                    }}
                    handleSelectedValue={(data: { value: string; label: string }) => {
                        editData
                            ? setDataForEdit({ ...dataForEdit, gender: data.value })
                            : setFormData({ ...formData, gender: data.value });
                        handleValidation('gender');
                    }}
                />
            </SelectWrapper>
            <Input
                type={InputComponentType.Number}
                label={t('pages.healthCards.id').text}
                value={edit ? dataForEdit?.jmbg : cardsLength === 0 ? formData?.jmbg : formData.jmbg}
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, jmbg: value })
                        : setFormData({ ...formData, jmbg: value });
                }}
                validation={showError ? isJmbgValid : ''}
                blurHandler={() => handleValidation('jmbg')}
                maxLength={13}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.address').text}
                value={
                    edit
                        ? dataForEdit?.street_address
                        : cardsLength === 0
                        ? formData?.street_address
                        : formData.street_address
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, street_address: value })
                        : setFormData({ ...formData, street_address: value });
                }}
                validation={
                    cardsLength === 0
                        ? clickedField === 'addressOfHeadQuarters'
                            ? isAddressOfHeadQuartersValid
                            : ''
                        : showError
                        ? isAddressOfHeadQuartersValid
                        : ''
                }
                blurHandler={() => handleValidation('addressOfHeadQuarters')}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.newCardModal.number').text}
                value={
                    edit
                        ? dataForEdit?.street_number
                        : cardsLength === 0
                        ? formData?.street_number
                        : formData.street_number
                }
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, street_number: value })
                        : setFormData({ ...formData, street_number: value });
                }}
                validation={
                    cardsLength === 0
                        ? clickedField === 'addressNumber'
                            ? isAddressNumberValid
                            : ''
                        : showError
                        ? isAddressNumberValid
                        : ''
                }
                blurHandler={() => handleValidation('addressNumber')}
            />{' '}
            <Input
                type={InputComponentType.Text}
                label={t('pages.healthCards.city').text}
                value={edit ? dataForEdit?.city : cardsLength === 0 ? formData?.city : formData.city}
                onChange={(value: string) => {
                    editData
                        ? setDataForEdit({ ...dataForEdit, city: value })
                        : setFormData({ ...formData, city: value });
                }}
                validation={
                    cardsLength === 0 ? (clickedField === 'city' ? isCityValid : '') : showError ? isCityValid : ''
                }
                blurHandler={() => handleValidation('city')}
            />
            {cardsLength === 0 && (
                <SelectWrapper>
                    <SelectSearch
                        optionList={[{ value: 'Srpsko', label: 'Srpsko' }]}
                        defaultValue={{ value: 'Srpsko', label: 'Srpsko' }}
                        handleSelectedValue={(data: { value: string; label: string }) => {
                            setFormData({ ...formData, citizenship: data.value });
                            handleValidation('citizenship');
                        }}
                    />
                </SelectWrapper>
            )}
            {cardsLength === 0 && (
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.agency.personalInfo.education').text}
                    value={cardsLength === 0 ? formData?.education : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, education: value });
                    }}
                    validation={clickedField === 'education' ? isEducationValid : ''}
                    blurHandler={() => handleValidation('education')}
                />
            )}
            {municipalities && (
                <SelectWrapper>
                    {cardsLength === 0 ? (
                        <SelectSearch
                            placeholder={t('pages.healthCards.newCardModal.choseMunicipality').text}
                            optionList={municipalities?.map((m: any) => {
                                return { value: m?.id, label: m?.name };
                            })}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                editData
                                    ? setDataForEdit({ ...dataForEdit, municipality_id: data.value })
                                    : setFormData({ ...formData, municipality_id: data.value });
                                handleValidation('municipality');
                            }}
                            defaultValue={
                                !edit
                                    ? municipalities.find((e) => e.id === formData?.municipality_id) && {
                                          value: formData?.municipality_id,
                                          label: municipalities.find((e) => e.id === formData?.municipality_id)?.name,
                                      }
                                    : municipalities.find((e) => e.id === dataForEdit?.municipality_id) && {
                                          value: dataForEdit?.municipality_id,
                                          label: municipalities.find((e) => e.id === dataForEdit?.municipality_id)
                                              ?.name,
                                      }
                            }
                        />
                    ) : (
                        <SelectSearch
                            placeholder={t('pages.healthCards.newCardModal.choseMunicipality').text}
                            optionList={municipalities?.map((m: any) => {
                                return { value: m?.id, label: m?.name };
                            })}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                editData
                                    ? setDataForEdit({ ...dataForEdit, municipality_id: data.value })
                                    : setFormData({ ...formData, municipality_id: data.value });
                                handleValidation('municipality');
                            }}
                            defaultValue={
                                municipalities.find((e) => e.id === dataForEdit?.municipality_id) && {
                                    value: dataForEdit?.municipality_id,
                                    label: municipalities.find((e) => e.id === dataForEdit?.municipality_id)?.name,
                                }
                            }
                            validation={showError ? isMunicipalityValid : ''}
                        />
                    )}
                </SelectWrapper>
            )}
            <Footer>
                {!edit && <p>{t('pages.healthCards.newCardModal.footer').text}</p>}
                {cardsLength === 0 && <p>{t('pages.healthCards.newCardModal.userFooter').text}</p>}
            </Footer>
            <div className="button-container">
                {edit ? (
                    <Button
                        onClick={handleUpdate}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                    >
                        {t('pages.healthCards.editCardModal.edit').text}
                    </Button>
                ) : (
                    <Button
                        onClick={handleSave}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                    >
                        {t('pages.healthCards.newCardModal.add').text}
                    </Button>
                )}
            </div>
        </Container>
    );
};

export default NewHealthCardModal;

const Container = styled.div`
    @media screen and (max-width: 600px) {
    }
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
        font-size: 13px;
    }
    p {
        color: var(--gray);
        font-size: 14px;
    }
    .date-container span.date-label {
        font-size: 13px;
    }
    input {
        color: var(--gray) !important;
    }
    .datepicker-input-wrapper {
        background: var(--white);
        border: 1px solid var(--border-color);
        margin-top: 5px;
        svg {
            margin-right: 5px;
        }
    }
`;
const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select {
        width: 100%;

        .css-1jqq78o-placeholder {
            color: var(--gray);
            font-size: 15px;
        }
    }
    .input-dropdown {
        height: 34px;
        color: var(--gray);
    }
    p {
        margin-bottom: 5px;
        font-size: 13px;
        :hover {
            color: var(--purple);
        }
    }
`;

const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    padding-bottom: 20px;

    h2 {
        font-size: 24px;
        color: var(--black);
        font-weight: 400;
        text-align: left;
        margin-bottom: 10px;
    }
`;

const Row = styled.div`
    display: flex;
    .input {
        :first-of-type {
            margin-right: 20px;
        }
        width: calc(50% - 20px);
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    display: flow-root;
    p {
        :first-of-type {
            margin-bottom: 10px;
        }
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
`;
