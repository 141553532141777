import { FunctionComponent, useEffect, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useWindowSize from '../../hooks/useResize';
import styled from 'styled-components';
import SelectComponent from '../../components/select';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import Input, { InputComponentType } from '../../components/input';
import communication from '../../communication';
import useAgencyStore from '../../store/agency';
import useProfileStore from '../../store/profile';
import { FileUploader } from 'react-drag-drop-files';
import { dragAndDropIcon } from '../../assets/base64';
import comunication from '../../communication';
// import moment from 'moment';
import { ObjectKeys } from '@/types/objectKeys';
import useNumLengthValidation from '../../hooks/useNumLengthValidation';
import Loader from '../../components/loader';
interface Props {
    yearsOptions: Array<{ value: any; label: any }>;
    closeModal: Function;
    setIsLoader: Function;
    setOpenInfoModal: Function;
    refresh: Function;
    callNumber: number;
    year: string;
}

export interface ChangeAmountTypes {
    year: string;
    user_id: string;
    model_number_recipient: number;
    call_number_recipient: string;
    total_amount: {
        doprinos_za_pio: number;
        porez_na_prihode_od_samostalne_delatnosti: number;
        doprinos_za_zdravstveno_osiguranje?: number;
        doprinos_za_osiguranje_nezaposlenih?: number;
    };
}

export interface HelpWithTaxTypes {
    year: string;
}

const ManageOrdersModal: FunctionComponent<Props> = ({
    yearsOptions,
    closeModal,
    setIsLoader,
    setOpenInfoModal,
    callNumber,
    refresh,
    year,
}) => {
    const [tabIndex, setTabIndex] = useState(0);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [selectedYear, setSelectedYear] = useState<string>(year);
    const agency = useAgencyStore((state) => state.agency);
    const fileTypes = ['pdf', 'doc', 'docx', 'xls', 'xlsx', 'jpeg', 'jpg', 'png', 'gif'];
    const [loader, setLoader] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const { profile } = useProfileStore();

    const [changeAmountsData, setChangeAmountsData] = useState<ChangeAmountTypes>({
        year: year,
        user_id: '',
        model_number_recipient: 97,
        call_number_recipient: callNumber ? callNumber.toString() : '',
        total_amount: {
            doprinos_za_pio: 0,
            porez_na_prihode_od_samostalne_delatnosti: 0,
            doprinos_za_zdravstveno_osiguranje: 0,
            doprinos_za_osiguranje_nezaposlenih: 0,
        },
    });

    const [_validation, setValidation] = useState<ObjectKeys>({
        year: true,
        user_id: true,
        model_number_recipient: true,
        call_number_recipient: true,
        total_amount: {
            doprinos_za_pio: true,
            porez_na_prihode_od_samostalne_delatnosti: true,
            doprinos_za_zdravstveno_osiguranje: true,
            doprinos_za_osiguranje_nezaposlenih: true,
        },
    });

    const callNoValidation = useNumLengthValidation(changeAmountsData.call_number_recipient, 19);
    const pioValidation = useNumLengthValidation(
        changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString() !== '0'
            ? changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString()
            : '',
        undefined,
        false,
        true,
    );
    const formIncomeValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_pio.toString() !== '0'
            ? changeAmountsData.total_amount.doprinos_za_pio.toString()
            : '',
        undefined,
        false,
        true,
    );
    const formUnemploymentValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih
            ? changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih.toString()
            : '',
        undefined,
        false,
        true,
    );
    const formHealthValidation = useNumLengthValidation(
        changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje
            ? changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje.toString()
            : '',
        undefined,
        false,
        true,
    );

    const [helpWithTaxData, setHelpWithTaxData] = useState<HelpWithTaxTypes>({
        year: year,
    });

    const { currentLang } = useLanguageStore();

    const t = useTranslations(currentLang);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    const getCallNumber = async (): Promise<void> => {
        setLoader(true);
        resetData();
        const data = await communication.getCallNumberByYear(selectedYear, agency.id);
        setChangeAmountsData((prevState) => ({
            ...prevState,
            call_number_recipient: data.data.call_number,
        }));
        setLoader(false);
    };

    useEffect(() => {
        getCallNumber();
    }, [selectedYear, year]);

    useEffect(() => {
        setChangeAmountsData((prevState) => ({ ...prevState, year: selectedYear }));
        setHelpWithTaxData((prevState) => ({ ...prevState, year: selectedYear }));
        setChangeAmountsData((prevState) => ({ ...prevState, user_id: agency?.user?.data?.id }));
    }, [selectedYear, year]);

    const resetData = (): void => {
        setHelpWithTaxData({ year: '' });
        setChangeAmountsData({
            year: selectedYear,
            user_id: '',
            model_number_recipient: 97,
            call_number_recipient: changeAmountsData.call_number_recipient,
            total_amount: {
                doprinos_za_pio: 0,
                porez_na_prihode_od_samostalne_delatnosti: 0,
                doprinos_za_zdravstveno_osiguranje: 0,
                doprinos_za_osiguranje_nezaposlenih: 0,
            },
        });
    };

    const sendNewTaxOrder = async (): Promise<void> => {
        setIsLoader(true);

        if (
            changeAmountsData.total_amount.doprinos_za_osiguranje_nezaposlenih === 0 ||
            changeAmountsData.total_amount.doprinos_za_pio === 0 ||
            changeAmountsData.total_amount.doprinos_za_zdravstveno_osiguranje === 0 ||
            changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti === 0
        ) {
            setError(true);
            setIsLoader(false);

            return;
        }
        if (changeAmountsData.call_number_recipient.length !== 19) {
            setValidation((prevState) => ({
                ...prevState,
                call_number_recipient: false,
            }));
            setIsLoader(false);
        } else {
            const updateData = { ...changeAmountsData };
            updateData.total_amount.doprinos_za_pio = Number(
                updateData.total_amount.doprinos_za_pio.toString().replace(',', '.'),
            );
            updateData.total_amount.porez_na_prihode_od_samostalne_delatnosti = Number(
                updateData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString().replace(',', '.'),
            );
            updateData.total_amount.doprinos_za_zdravstveno_osiguranje = Number(
                updateData.total_amount.doprinos_za_zdravstveno_osiguranje?.toString().replace(',', '.'),
            );
            updateData.total_amount.doprinos_za_osiguranje_nezaposlenih = Number(
                updateData.total_amount.doprinos_za_osiguranje_nezaposlenih?.toString().replace(',', '.'),
            );
            updateData.user_id = profile.id;
            const res = await communication.createTaxOrder(updateData);
            if (res.status === 200) {
                setValidation((prevState) => ({
                    ...prevState,
                    call_number_recipient: true,
                }));
                closeModal();
                setIsLoader(false);
                refresh();
                resetData();
            } else {
                setIsLoader(false);
            }
        }
    };

    const handleSaveTaxHelpWithTax = async (file: [Blob]): Promise<void> => {
        setIsLoader(true);
        const res = await comunication.uploadTaxSolutions(file[0], helpWithTaxData.year);
        if (res.status === 200) {
            closeModal();
            setOpenInfoModal(true);
        }
        setIsLoader(false);
    };
    const tabs = [t('pages.transfers.change_amounts').text, t('pages.transfers.help_upload_tax').text];

    return (
        <>
            {loader && <Loader />}
            <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
                <h1>{t('pages.transfers.manage_transfers').text}</h1>
                <Tabs className={'tabs'} selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <TabList>
                        {tabs.map((tab, index) => (
                            <Tab className={'custom-tab'} key={index}>
                                {tab}
                            </Tab>
                        ))}
                    </TabList>
                    <TabPanel>
                        <div className={'change-amounts first-tab'}>
                            <h4>{t('pages.transfers.choose_year_for_generating').text}</h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label>{`${
                                            t('pages.transfers.edit_tax_transfers_modal_year').text
                                        } ${selectedYear}`}</label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) => setSelectedYear(value)}
                                        />
                                    </div>
                                    <Input
                                        type={InputComponentType.Number}
                                        label={`${
                                            t('pages.transfers.edit_tax_transfers_modal__call_number_year').text
                                        } ${selectedYear}`}
                                        onChange={(value: string) => {
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                call_number_recipient: value.toString(),
                                            }));
                                        }}
                                        value={changeAmountsData.call_number_recipient}
                                        validation={
                                            changeAmountsData.call_number_recipient.length > 0 && callNoValidation
                                                ? callNoValidation
                                                : ''
                                        }
                                        maxLength={19}
                                        className={'input'}
                                    />
                                    <Input
                                        type={InputComponentType.Number}
                                        label={`${t('pages.transfers.form_pio').text}`}
                                        value={
                                            !changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti
                                                ? ''
                                                : changeAmountsData.total_amount.porez_na_prihode_od_samostalne_delatnosti.toString()
                                        }
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    porez_na_prihode_od_samostalne_delatnosti: value,
                                                },
                                            }));
                                        }}
                                        className={'input'}
                                        validation={error ? pioValidation : ''}
                                    />
                                    <Input
                                        type={InputComponentType.Number}
                                        label={`${t('pages.transfers.form_income').text}`}
                                        value={
                                            !changeAmountsData.total_amount.doprinos_za_pio
                                                ? ''
                                                : changeAmountsData.total_amount.doprinos_za_pio.toString()
                                        }
                                        onChange={(value: string) => {
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            setChangeAmountsData((prevState) => ({
                                                ...prevState,
                                                total_amount: {
                                                    ...prevState.total_amount,
                                                    doprinos_za_pio: value,
                                                },
                                            }));
                                        }}
                                        validation={error ? formIncomeValidation : ''}
                                        className={'input'}
                                    />
                                    {!agency.employed_by_other_firm ? (
                                        <>
                                            <Input
                                                type={InputComponentType.Number}
                                                label={`${t('pages.transfers.form_unemployment').text}`}
                                                value={
                                                    !changeAmountsData?.total_amount
                                                        ?.doprinos_za_osiguranje_nezaposlenih
                                                        ? ''
                                                        : changeAmountsData?.total_amount?.doprinos_za_osiguranje_nezaposlenih?.toString()
                                                }
                                                onChange={(value: string) => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    setChangeAmountsData((prevState) => ({
                                                        ...prevState,
                                                        total_amount: {
                                                            ...prevState.total_amount,
                                                            doprinos_za_osiguranje_nezaposlenih: value,
                                                        },
                                                    }));
                                                }}
                                                validation={error ? formUnemploymentValidation : ''}
                                                className={'input'}
                                            />
                                            <Input
                                                type={InputComponentType.Number}
                                                label={`${t('pages.transfers.form_health').text}`}
                                                value={
                                                    !changeAmountsData?.total_amount?.doprinos_za_zdravstveno_osiguranje
                                                        ? ''
                                                        : changeAmountsData?.total_amount?.doprinos_za_zdravstveno_osiguranje?.toString()
                                                }
                                                onChange={(value: string) => {
                                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                                    // @ts-ignore
                                                    setChangeAmountsData((prevState) => ({
                                                        ...prevState,
                                                        total_amount: {
                                                            ...prevState.total_amount,
                                                            doprinos_za_zdravstveno_osiguranje: value,
                                                        },
                                                    }));
                                                }}
                                                validation={error ? formHealthValidation : ''}
                                                className={'input'}
                                            />
                                        </>
                                    ) : null}
                                </article>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.close').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => sendNewTaxOrder()}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className={'change-amounts'}>
                            <h4>{t('pages.transfers.choose_year_for_uploading').text}</h4>
                            <div className="select-year-container">
                                <article>
                                    <div className={'select'}>
                                        <label>{`${
                                            t('pages.transfers.edit_tax_transfers_modal_year').text
                                        } ${selectedYear}`}</label>

                                        <SelectComponent
                                            optionList={yearsOptions}
                                            defaultSelectedOption={yearsOptions.find((option) => option.value === year)}
                                            className={'select-year'}
                                            placeholder={selectedYear}
                                            handleSelectedValue={(value: string) =>
                                                setHelpWithTaxData((prevState) => ({ ...prevState, year: value }))
                                            }
                                        />
                                    </div>
                                </article>
                            </div>
                            <div>
                                <FileUploader
                                    multiple={true}
                                    handleChange={handleSaveTaxHelpWithTax}
                                    name="files"
                                    types={fileTypes}
                                >
                                    <div className={isMobileWidth ? 'mobile dropzone' : 'dropzone'}>
                                        <img src={dragAndDropIcon} />
                                    </div>
                                </FileUploader>
                            </div>
                        </div>
                        <div className={'buttons'}>
                            <Button
                                variant={ButtonVariant.outlined}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => closeModal()}
                            >
                                {t('pages.transfers.back').text}
                            </Button>
                            <Button
                                variant={ButtonVariant.solid}
                                size={'50%'}
                                color={colors.purple}
                                onClick={() => handleSaveTaxHelpWithTax}
                            >
                                {t('pages.transfers.send_solution').text}
                            </Button>
                        </div>
                    </TabPanel>
                </Tabs>
            </Content>
        </>
    );
};
const Content = styled.div`
    text-align: center;
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 100%;
    }
    h1 {
        text-transform: uppercase;
    }
    display: inline-block;
    color: var(--black);
    .react-tabs__tab-list {
        border-bottom: none;
        .custom-tab {
            display: inline-block;
            position: relative;
            list-style: none;
            padding: 6px 12px;
            cursor: pointer;
            text-align: center;
            width: 50%;
            text-transform: uppercase;
        }
    }
    .react-tabs__tab--selected {
        border-right: none;
        border-left: none;
        border-top: none;
        border-bottom: 3px solid #9b7fe9 !important;
    }
    h1 {
        color: #4c595f;
        font-weight: 400;
        font-size: 20px;
        padding: 25px 50px 10px;
    }
    h4 {
        font-size: 18px;
        color: #9b7fe9;
    }
    .change-amounts {
        padding: 35px 20px 75px;
    }
    article {
        display: block;
        padding: 15px 0;
        vertical-align: middle;
        position: relative;
        text-align: left;
        .select {
            color: #92a5ac;
            margin-top: 20px;
            margin-bottom: 20px;
            text-align: left;
            width: 100%;
            display: block;
        }
        .select-wrapper {
            width: 100%;
        }
    }
    .upload-tax {
        box-shadow: none !important;
        label {
            width: 100%;
        }
    }
    .description {
        display: none;
    }
    .modal-wrapper:has(> .change-amounts) {
        max-height: 90% !important;
        overflow: auto !important;
    }
    .buttons {
        display: flex;
        align-items: center;
        justify-content: center;
        button:first-child {
            border-radius: 0 0 0 6px;
        }
        button:last-child {
            border-radius: 0 0 6px 0;
        }
    }
    .tabs {
        ul {
            border-bottom: 1px solid #e7e7e7;
        }
    }
    .first-tab {
        padding: 35px 50px 10px;
        input {
            width: 100%;
        }
    }
    .dropzone {
        max-width: 100%;
        position: relative;
        min-height: 220px;
        border: 3px dashed #e7e7e7 !important;
        color: #92a5ac;
        border-radius: 10px;
        margin: 20px 60px 10px;
        padding: 50px;
        img {
            width: 50px;
            margin-bottom: 50px;
        }
    }
`;

export default ManageOrdersModal;
