import { FunctionComponent, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import TextArea from '../../../../components/input/textarea';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import communication from '../../../../communication';
import Checkbox from '../../../../components/checkbox';
import useProfileStore from '../../../../store/profile';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllInvoices from '../getAllAInvoices';
import useSendMeEmail from '../../../../hooks/useSendMeEmail';

interface Props {
    t: Function;
    formData: ObjectKeys;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    id: string;
    clientEmail: string;
    setInvoices: Dispatch<SetStateAction<any>>;
    modalVisible: boolean;
}

const SendModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    id,
    clientEmail,
    setInvoices,
    modalVisible,
}) => {
    const isSubjectValid = useCharLengthValidation(formData.subject, 3);
    const isMessageValid = useCharLengthValidation(formData.message, 3);

    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<any>('');
    const [sendMe, setSendMe] = useState(false);
    const [addedFiles, setAddedFiles] = useState<any>([]);
    const [userEmail, setUserEmail] = useState('');
    const { profile } = useProfileStore();
    const [fromInput, setFromInput] = useState(false);

    useEffect(() => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
    }, [modalVisible]);

    useEffect(() => {
        if (clientEmail) {
            setShowEmails(true);
        }
        setEmails([...emails, clientEmail]);
    }, []);

    const send = async (): Promise<void> => {
        if (emails.length > 0 && isSubjectValid === '' && isMessageValid === '') {
            loading(true);
            const form = new FormData();
            for (let i = 0; i < addedFiles.length; i++) {
                const file = addedFiles[i];
                form.append('attachments[' + i + ']', file);
            }
            const requestData = {
                message: formData.message,
                subject: formData.subject,
                emails: emails,
            };
            form.append('request_body', JSON.stringify(requestData));
            const res = await communication.sendInvoice(id, form);

            if (res.status === 200) {
                loading(false);
                success();
                close();
                getAllInvoices(setInvoices);
            }
        } else setShowError(true);
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );

    const handleAttachInvoice = (event: any): void => {
        if (event?.target !== null && (event?.target as any)?.files[0]) {
            setAddedFiles([...addedFiles, event?.target?.files[0]]);
        }
    };
    return (
        <>
            <Container>
                <Header>
                    <h2>{t('pages.advanceInvoices.sendModal.title').text}</h2>
                    <Icon onClick={() => close()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>
                <label>{t('pages.advanceInvoices.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.map((e: string) => (
                            <Email key={e}>
                                {e}
                                <span onClick={() => handleRemoveEmail(e)}>x</span>
                            </Email>
                        ))}
                </div>
                <Input
                    className="email"
                    type={InputComponentType.Text}
                    value={formData.recipient ? formData.recipient : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, recipient: value });
                    }}
                    handleBlurAction={() => {
                        if (emails.includes(formData.recipient)) {
                            setFormData({ ...formData, recipient: '' });
                            return;
                        }
                        if (formData.recipient !== '') {
                            setFromInput(true);
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                    onEnter={() => {
                        if (formData.recipient !== '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                />

                <Input
                    type={InputComponentType.Text}
                    label={t('pages.advanceInvoices.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                    validation={showError ? isSubjectValid : ''}
                />
                <TextArea
                    label={t('pages.advanceInvoices.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    validation={showError ? isMessageValid : ''}
                />
                <Footer>
                    <div>
                        <Icon className="attach">
                            <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                            {t('pages.advanceInvoices.sendModal.attached').text}
                            {addedFiles.length > 0 ? (
                                <AttachedFiles>
                                    {addedFiles.map((file: ObjectKeys, index: number) => {
                                        return (
                                            <p key={index}>
                                                <FontAwesomeIcon
                                                    icon={faPaperclip}
                                                    style={{ color: 'var(--gray)', marginRight: '3px' }}
                                                />
                                                {file.name}
                                            </p>
                                        );
                                    })}
                                </AttachedFiles>
                            ) : (
                                <></>
                            )}
                        </Icon>
                        <div className="send-copy">
                            <Checkbox onChange={() => setSendMe(!sendMe)} />
                            {t('pages.advanceInvoices.sendModal.sendMeCopy').text}
                        </div>
                    </div>

                    <UploadFiles>
                        <input type="file" onChange={(e: any) => handleAttachInvoice(e)} />
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--black)', marginRight: '3px' }} />
                        <span>{t('pages.advanceInvoices.sendModal.attach').text}</span>
                    </UploadFiles>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={send}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'var(--white)', marginRight: '10px' }} />
                        {t('pages.advanceInvoices.sendModal.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }
`;

const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 30px;
    }
`;

const Footer = styled.div`
    > div {
        margin-top: 20px;
        font-size: 13px;
        color: var(--gray);
        display: flow-root;
        .send-copy {
            float: right;
            display: flex;
        }
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
const UploadFiles = styled.label`
    cursor: pointer;
    font-size: 13px;
    span {
        font-size: 13px;
        color: var(--black);
    }
    &:hover {
        span {
            color: var(--purple);
        }
    }
    input[type='file'] {
        display: none;
    }
`;
const AttachedFiles = styled.div`
    margin-top: 10px;
    p {
        font-size: 13px;
        color: var(--gray);
        margin-bottom: 5px;
    }
`;
