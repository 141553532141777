const getStatus = (status: string, t: Function, eInvoice?: boolean): any => {
    if (!eInvoice) {
        switch (status) {
            case 'sent':
                return t('pages.invoiceCard.status.sent').text;
            case 'paid':
                return t('pages.invoiceCard.status.paid').text;
            case 'partially':
                return t('pages.invoiceCard.status.partially').text;
            case 'cancelled':
                return t('pages.invoiceCard.status.cancelled').text;
            case 'open':
                return t('pages.invoiceCard.status.open').text;
            case 'closed':
                return t('pages.invoiceCard.status.closed').text;
        }
    } else {
        switch (status) {
            case 'sending':
                return t('pages.eInvoices.status.sending').text;
            case 'sent':
                return t('pages.eInvoices.status.sent').text;
            case 'approved':
                return t('pages.eInvoices.status.verified').text;
            case 'rejected':
                return t('pages.eInvoices.status.cancelled').text;
            case 'storno':
                return t('pages.eInvoices.status.storned').text;
            case 'open':
                return t('pages.eInvoices.status.open').text;
        }
    }
};
export default getStatus;
