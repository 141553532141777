export const getAllMeasurementUnits = (
    t: Function,
    invoiceType: string | undefined,
): Array<{ value: string; label?: Function | string }> => {
    if (invoiceType === 'domestic') {
        return [
            {
                value: 'per_piece',
                label: t('pages.editInvoice.units.piece').text,
            },
            {
                value: 'per_hour',
                label: t('pages.editInvoice.units.hour').text,
            },
            {
                value: 'meter',
                label: t('pages.editInvoice.units.m').text,
            },
            {
                value: 'square_meter',
                label: t('pages.editInvoice.units.m2').text,
            },
            {
                value: 'cubic_meter',
                label: t('pages.editInvoice.units.m3').text,
            },
            {
                value: 'kilo',
                label: t('pages.editInvoice.units.kg').text,
            },
            {
                value: 'liter',
                label: t('pages.editInvoice.units.l').text,
            },
            {
                value: 'pack',
                label: t('pages.editInvoice.units.pack').text,
            },
            {
                value: 'word',
                label: t('pages.editInvoice.units.word').text,
            },
            {
                value: 'per_day',
                label: t('pages.editInvoice.units.day').text,
            },
        ];
    } else
        return [
            {
                value: 'per_piece',
                label: 'Piece',
            },
            {
                value: 'per_hour',
                label: 'Hour',
            },
            {
                value: 'kilo',
                label: 'Kilo',
            },
            {
                value: 'per_day',
                label: 'Day',
            },
            {
                value: 'word',
                label: 'Word',
            },
        ];
};
export const getMeasurementUnit = (t: Function, unit: string): any => {
    switch (unit) {
        case 'per_piece':
            return t('pages.editInvoice.units.piece').text;
        case 'per_hour':
            return t('pages.editInvoice.units.hour').text;
        case 'meter':
            return t('pages.editInvoice.units.m').text;
        case 'square_meter':
            return t('pages.editInvoice.units.m2').text;
        case 'cubic_meter':
            return t('pages.editInvoice.units.m3').text;
        case 'kilo':
            return t('pages.editInvoice.units.kg').text;
        case 'liter':
            return t('pages.editInvoice.units.l').text;
        case 'pack':
            return t('pages.editInvoice.units.pack').text;
        case 'word':
            return t('pages.editInvoice.units.word').text;
        case 'per_day':
            return t('pages.editInvoice.units.day').text;
    }
};
