import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import { isEmpty, some } from 'lodash';
import { SendDocumentFormData } from '../../../../types/communication/document';
import { ObjectKeys } from '../../../../types/objectKeys';
import { generateQrCode } from '../../../../communication/qr';
import communication from '../../../../communication';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { getAccessToken } from '../../../../functions/auth';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import ErrorModal from '../../../../components/errorModal';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import EditClientModal from '../../clients/editClientModal';
import AddClientModal from '../../clients/addClientModal';
import SendModal from '../../invoices/sendModal';
import PreviewInvoiceModal from '../../einvoices/copyEInvoice/previewInvoiceModal';
import { Body, Container, Invoice } from '../../einvoices/copyEInvoice/style';
import MobileHeader from '../../einvoices/copyEInvoice/header/mobileHeader';
import WebHeader from '../../einvoices/copyEInvoice/header/webHeader';
import InvoiceEdit from '../../einvoices/copyEInvoice/invoiceEdit';
import MobileFooter from '../../einvoices/copyEInvoice/footer/mobileFooter';
import WebFooter from '../../einvoices/copyEInvoice/footer/webFooter';
import getAllClients from '../../clients/getAllClients';
import InvoiceForeignEdit from '../../einvoices/copyEInvoice/invoiceForeignEdit';

const NewInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const size = useWindowSize();
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const { addToast } = useToasts();
    const token = getAccessToken();

    const invoiceType = pathname.includes('domestic') ? 'domestic' : 'foreign';
    const isAdvance = pathname.includes('advance');

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',

        currency: invoiceType === 'domestic' ? 'RSD' : 'EUR',
        iban: agency.iban ? agency.iban : '',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        swift: agency.swift ? agency.swift : '',
        trading_place: agency.city ? agency.city : '',
    });
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const [qrCode, setQrCode] = useState('');

    useEffect(() => {
        setInvoice({
            ...invoice,
            comment:
                invoiceType === 'domestic'
                    ? t('pages.editInvoice.previewModal.due').text
                    : 'Payment deadline is 15 days',
        });
    }, [invoiceType, currentLang]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(agencyData.name, clientData, amount, invoiceNumber, bankAccount, currency, convert)
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleClientDelete = async (): Promise<void> => {
        await communication
            .deleteClient(clickedClient)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllClients(setClients, clickedClient, invoiceType === 'domestic' ? 0 : 1);
                    setDeleteClientModal(false);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            });
    };

    const handleInvoiceDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                setDeleteInvoiceModal(false);
                navigate(-1);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    //Add new invoice
    const handleAddNewInvoice = async (): Promise<any> => {
        if (invoice?.services?.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            sendModal && setSendModal(false);
        } else if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0,
            )
        ) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            sendModal && setSendModal(false);
        } else if (!invoice.client_id) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingClient').text);
            sendModal && setSendModal(false);
        } else {
            try {
                setLoading(true);
                const newInvoiceData = { ...invoice };

                newInvoiceData.services = newInvoiceData.services.map((service: ObjectKeys, i: number) => ({
                    index: i,
                    id: service.id,
                    measurement_unit: service.pivot.measurement_unit,
                    quantity: service.pivot.quantity.replace(',', '.'),
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                }));
                newInvoiceData.trading_date = moment(newInvoiceData.trading_date).format('YYYY-MM-DD').toString();
                newInvoiceData.rate = Number(newInvoiceData.rate);
                newInvoiceData.paid_date = moment(
                    newInvoiceData.paid_date ? newInvoiceData.paid_date : new Date('1970-01-01'),
                )
                    .format('YYYY-MM-DD')
                    .toString();
                newInvoiceData.invoice_date = moment(newInvoiceData.invoice_date).format('YYYY-MM-DD').toString();
                newInvoiceData.custom_number = false;
                newInvoiceData.is_custom = false;
                newInvoiceData.custom_text = false;
                newInvoiceData.invoice_per_owner = true;
                newInvoiceData.currency = invoice.currency;
                newInvoiceData.auto_increment = true;
                newInvoiceData.cancel_number = false;
                newInvoiceData.cancelled = false;
                newInvoiceData.status = 'open';
                newInvoiceData.user_id = agency?.user_id;
                newInvoiceData.trading_place = agency?.city;
                newInvoiceData.value = Number(newInvoiceData.value);
                newInvoiceData.client_id = invoice?.client?.data?.id;
                delete newInvoiceData.total_for_payment_in_rsd, newInvoiceData.total_for_payment;
                if (isAdvance) {
                    newInvoiceData.is_prepaid = true;
                }
                if (invoiceType === 'domestic') {
                    newInvoiceData.bank_account = invoice.bank_account
                        ? invoice.bank_account
                        : agency.first_account_number;
                } else {
                    newInvoiceData.swift = agency?.swift;
                    newInvoiceData.iban = agency?.iban;
                }

                const res = await communication.newInvoice(newInvoiceData, invoiceType);
                if (res) {
                    setLoading(false);
                    navigate(-1);
                    return res?.data?.data?.id;
                }
            } catch (e: any) {
                setErrorMessage(e.response.data.message);
                setOpenErrorModal(true);
                setLoading(false);
            }
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            return newInvoiceData;
        });
        agency &&
            invoice &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                invoice.value_in_rsd,
                invoice.invoice_number ? invoice.invoice_number : '',
                invoice.first_account_number ? invoice.first_account_number : agency.first_account_number,
                invoice.currency ? invoice.currency : 'RSD',
                true,
            );
    };

    //Invoice Download
    const handleDownloadInvoice = (): void => {
        if (invoice)
            generateQrCode(
                agency.name,
                invoice.client ? invoice.client.data : {},
                invoice.value_in_rsd,
                invoice.invoice_number ? invoice.invoice_number : '',
                agency.first_account_number,
                invoice.currency,
                true,
            )
                .then(async (res: ObjectKeys | any) => {
                    if (res) {
                        const newCreatedInvoiceId = await handleAddNewInvoice();
                        if (newCreatedInvoiceId) {
                            await communication.downloadInvoice(
                                newCreatedInvoiceId,
                                token,
                                invoice.invoice_number,
                                invoice.currency !== 'RSD',
                            );
                        }
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        console.error(error);
                    }
                });
    };

    return (
        <>
            {loading && <Loader />}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        handleAddNewInvoice={handleAddNewInvoice}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            setSendModal(false);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={''}
                        setInvoices={setInvoice}
                        qrCode={qrCode}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoiceType === 'domestic' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={invoiceType === 'domestic' ? 0 : 1}
                        setNewCreatedClient={setNewCreatedClient}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleClientDelete}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={t('pages.editInvoice.deleteClient').text}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleInvoiceDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    <PreviewInvoiceModal
                        agency={agency}
                        invoice={invoice}
                        title="invoice"
                        type={invoiceType}
                        note={invoice?.comment}
                    />
                </Modal>
            )}

            <Container>
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewInvoice}
                        invoice={invoice}
                        newInvoice={true}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleDownloadInvoice={handleDownloadInvoice}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewInvoice}
                        invoice={invoice}
                        newInvoice={true}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                        handleDownloadInvoice={handleDownloadInvoice}
                    />
                )}
                <Body className="new-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={
                            pathname.includes('foreign') ? 'foreign' : pathname.includes('domestic') ? 'domestic' : ''
                        }
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {agency && invoice && (
                        <Invoice>
                            {pathname.includes('domestic') ? (
                                <InvoiceEdit
                                    qrCode={qrCode}
                                    name="invoice"
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    newInvoice={true}
                                    type={invoiceType}
                                    setLoading={setLoading}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    qrCode={qrCode}
                                    name="invoice"
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    newInvoice={true}
                                    type={invoiceType}
                                    setLoading={setLoading}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobileWidth ? (
                        <MobileFooter
                            handleUpdateInvoice={handleAddNewInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={setSendModal}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={handleAddNewInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={setSendModal}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
        </>
    );
};
export default NewInvoice;
