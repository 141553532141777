import { FunctionComponent } from 'react';
import { Row } from '../style';
import { formatToSerbianNumber } from '../../../../../../functions/format';

interface Props {
    t: Function;
    total: string;
    currency: string;
    totalAdvances: number;
}

const TotalPayedAdvance: FunctionComponent<Props> = ({ t, total, currency, totalAdvances }) => {
    return (
        <Row className="right">
            <p style={{ width: '70%' }}>{t('pages.advanceInvoices.totalForPayment').text + '(' + currency + ')'}</p>
            <h3 style={{ width: '30%' }} className="right">
                {formatToSerbianNumber(Number(total.replace('.', '').replace(',', '.')) - Number(totalAdvances))}
            </h3>
        </Row>
    );
};
export default TotalPayedAdvance;
