import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import communication from '../../../communication';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useAllUsersStore from '../../../store/admin/allUsers';
import { ObjectKeys } from '../../../types/objectKeys';
import { formatParams } from '../../../functions/formatParams';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Pagination from '../../../components/pagination';
import Modal from '../../../components/modal';
import SelectSearch from '../../../components/selectSearch';
import Filters from './filters';
import DeleteModal from './deleteModal';
import ErrorModal from '../../../components/errorModal';

const Proforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { allUsers } = useAllUsersStore();

    const [proforma, setProforma] = useState<ObjectKeys>({});
    const [errorMessage, setErrorMessage] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [display, setDisplay] = useState('list');
    const [clickedProforma, setClickedProforma] = useState('');
    const [page, setPage] = useState(1);
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState('');

    const [params, setParams] = useState({
        page: 1,
        limit: '10',
        search: '',
        searchFields: {},
        searchJoin: 'and',
    });

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib };
        });
        setSelectedUserId(allUsers[0]?.id);
        setAllUsersOptions([...userData]);
    }, [allUsers]);

    useEffect(() => {
        setLoaderVisible(true);
        if (selectedUserId) {
            communication
                .getProformaByUserId({
                    ...params,
                    page: page,
                    search: `user_id:${selectedUserId};` + formatParams(params.search),
                    searchFields: 'user_id:=;' + formatParams(params.searchFields),
                })
                .then((res: ObjectKeys) => {
                    if (res.status === 200) {
                        setLoaderVisible(false);
                        setProforma(res?.data);
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setLoaderVisible(false);
                        setProforma([]);
                    }
                });
        }
    }, [selectedUserId, params, page]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    //Proforma Change
    const handleChange = (proformaId: string): void => {
        navigate(`/admin/proforma/edit/${proformaId}/${selectedUserId}`);
    };

    //Proforma Delete Modal
    const handleDeleteModal = (): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedProforma={clickedProforma}
                closeGlobalModal={closeGlobalModal}
                setProforma={setProforma}
                setLoaderVisible={setLoaderVisible}
            />,
        );
    };

    const handleClickedProforma = (proformaId: string): void => {
        setClickedProforma(proformaId);
    };

    // Create invoice from proforma
    const handleInvoice = (proformaData: ObjectKeys): void => {
        const proformaDataToSend = { ...proformaData };
        const proformaServices = proformaData.services.map((service: any, index: number) => {
            return {
                index: index,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: service.pivot.quantity,
                price_per_unit: service.pivot.price_per_unit,
            };
        });
        proformaDataToSend['invoice_date'] = proformaDataToSend.quotation_date;
        proformaDataToSend['services'] = proformaServices;
        proformaDataToSend['status'] = 'sent';

        if (proformaData.type === 'national') {
            communication
                .domesticProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        } else if (proformaData.type === 'foreign') {
            communication
                .foreignProforma(proformaDataToSend)
                .then((res: ObjectKeys) => {
                    if (res) {
                        navigate(`/invoices/edit/${proformaData?.id}`);
                    }
                })
                .catch((error: ObjectKeys) => {
                    setErrorMessage(error.response.data.message);
                    setOpenErrorModal(true);
                });
        }
    };

    return (
        <>
            {openErrorModal ? (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            ) : (
                <></>
            )}

            {loaderVisible && <Loader />}
            <div className="proforma page">
                <Header>
                    <h1>{t('pages.proforma.title').text}</h1>
                </Header>
                <SelectWrapper>
                    <p>{t('pages.admin.users.chooseUser').text}</p>
                    <SelectSearch
                        className="select-container"
                        optionList={allUsersOptions}
                        handleSelectedValue={(data: { value: string; label: string }) => setSelectedUserId(data.value)}
                        defaultValue={allUsersOptions[0]}
                    />
                </SelectWrapper>
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <InvoicesList className={`display-${display}`}>
                    {proforma?.data?.length > 0 ? (
                        proforma?.data?.map((pForma: ObjectKeys) => {
                            return (
                                <div key={pForma.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.Proforma}
                                        cardData={pForma}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(pForma.id)}
                                        handleCopy={() => {
                                            return <></>;
                                        }}
                                        handleDelete={() => handleDeleteModal()}
                                        handleDownload={() => {
                                            return <></>;
                                        }}
                                        handleInvoice={() => handleInvoice(pForma)}
                                        handleClick={() => handleClickedProforma(pForma.id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.proforma.noItems.description').text} />
                    )}
                </InvoicesList>
                {proforma?.data?.length > 0 && proforma.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={proforma.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            proforma.meta?.pagination?.current_page === proforma.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${proforma.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={proforma.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default Proforma;

const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
const SelectWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;
