import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
import { NotificationsType } from '../types/communication/notifications';
import download from './download';
import { SendDocumentType } from '../types/communication/document';
import { ChangePasswordRequest } from '../types/changePassword';
import getCurrentLanguage from '../functions/getCurrentLanguage';
export default class Agency {
    //profile and personal info

    public getUserProfile(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.profile.get,
        });
    }
    public getAgency(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.get,
        });
    }
    public deleteLogo(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.deleteLogo(id),
        });
    }
    public uploadAgencyLogo(file: any, agencyId: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.uploadLogo(agencyId),
            data: {
                logo: file,
            },
        });
    }

    public updateAgency(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.update.concat(`/${id}`),
            data: options,
        });
    }

    //send data
    public sendAgencyData(id: string, options: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendData.concat(`/${id}/send`),
            data: options,
        });
    }

    //notifications
    public getNotificationsForUser(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.getNotifications,
        });
    }
    public updateNotificationsForUser(options: NotificationsType): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.updateNotifications,
            data: options,
        });
    }

    //documents
    public getDocuments(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.getDocuments,
        });
    }
    public updateDocument(fileName: string, id: string): ObjectKeys {
        return fetch({
            method: 'PUT',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.updateDocument(id),
            data: { file_name: fileName },
        });
    }
    public downloadDocument(documentId: string, token: string): ObjectKeys {
        return download(routes.agency.downloadDocument(documentId, token), { token }, documentId, 'document');
    }
    public sendDataDocument(id: string, options: SendDocumentType): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendDataDocument(id),
            data: options,
        });
    }
    public deleteDocument(id: string): ObjectKeys {
        return fetch({
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.deleteDocument(id),
        });
    }
    public uploadDocument(file: Blob): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.uploadDocument,
            data: {
                user_file: file,
            },
        });
    }

    //change password
    public changePassword(options: ChangePasswordRequest): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.changePassword,
            data: options,
        });
    }

    //payment instructions
    public sendDocument(sendData: any, id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendDocument(id),
            data: sendData,
        });
    }

    public uploadForeginInstruction(file: any, type: string | undefined): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendForeginInstruction,
            data: {
                instruction: file,
                type: type,
            },
        });
    }
    public getAllInstructions(): ObjectKeys {
        return fetch({
            method: 'get',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.getAllInstruction,
        });
    }
    public deleteInstruction(id: string): ObjectKeys {
        return fetch({
            method: 'delete',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.deleteInstruction(id),
        });
    }

    public downloadInstruction(documentId: string, token: string | null): ObjectKeys {
        return download(routes.agency.downloadInstruction(documentId, token), { token }, documentId, 'instruction');
    }

    //tax solution
    public getAllTaxSolutions(searchYear?: string): ObjectKeys {
        return fetch({
            method: 'get',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.agency.getAllTaxSolution(searchYear),
        });
    }

    public uploadTaxSolutions(sendData: any, year: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendTaxSolutions,
            data: {
                solution: sendData,
                year: year,
            },
        });
    }

    public sendTaxSolutionNotification(): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.sendNotification,
        });
    }

    public deleteTaxSolution(id: string): ObjectKeys {
        return fetch({
            method: 'delete',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.deleteTaxSolution(id),
        });
    }
    public downloadTaxSolution(documentId: string, token: string | null): ObjectKeys {
        return download(routes.agency.downloadTaxSolution(documentId, token), { token }, documentId, 'taxSolution');
    }
    public updateEinvoice(id: string, apiKey: string, exceptionCode: string, vatSign: string): ObjectKeys {
        return fetch({
            method: 'put',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.agency.updateEinvoice(id),
            data: {
                e_invoice_api_key: apiKey,
                e_invoice_vat_exception_code: exceptionCode,
                e_invoice_vat_sign: vatSign,
            },
        });
    }
}
