import { FunctionComponent, MouseEventHandler, useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/pausal-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from '../../dropdown';
import Nbs from '../nbs';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Button, { ButtonVariant } from '../../button';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import useUserStore from '../../../store/user';
import useProfileStore from '../../../store/profile';
import SelectLang from '../../selectLang';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import Modal from '../../modal';
import ChangeModal from '../../changeModal';
import Success from '../../successModal';
import AccountExpiration from '../accountExpiration';

interface Props {
    icons: Icons[];
    userDropdown: UserDropdown[];
    role: string | null;
    expires: string | undefined;
    openSuggestionModal: Function;
    isAccountActive: boolean;
}

export interface Icons {
    text: string;
    icon: any;
    link?: string;
}

export interface UserDropdown {
    label: string;
    icon: any;
    link?: string;
    newTab?: boolean;
    handler?: MouseEventHandler;
}

const WebNavbar: FunctionComponent<Props> = ({
    icons,
    userDropdown,
    role,
    expires,
    openSuggestionModal,
    isAccountActive,
}) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [selectedItem, setSelectedItem] = useState(
        icons.findIndex((nav) => nav.link && pathname.includes(nav.link)) || 0,
    );
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [nbsDropdownVisible, setNbsDropdownVisible] = useState(false);
    const [suggestionVisible, setSuggestionVisible] = useState(false);
    const [accountExpires, setAccountExpires] = useState(false);

    const [changeVisible, setChangeVisible] = useState(useChangeModalStore.getState().changeModalVisible);
    const { updateUser } = useUserStore();
    const { updateAgency, profile } = useProfileStore();
    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);
    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const handleSelect = (i: number, text: string): void => {
        setChangeVisible(useChangeModalStore.getState().changeModalVisible);

        const ind = icons.findIndex((x) => x.text === text);
        if (icons[ind].link) {
            navigate(text);
            setSelectedItem(ind);
        }

        if (!useChangeModalStore.getState().changeModalVisible) {
            if (text === t('navbar.pausal.nbs').text) setNbsDropdownVisible(!nbsDropdownVisible);
            else if (text === t('navbar.pausal.suggestion').text) {
                setSuggestionVisible(!suggestionVisible);
                openSuggestionModal();
            } else setNbsDropdownVisible(false);
        }
    };

    useEffect(() => {
        setSelectedItem(icons.findIndex((nav) => nav.link && pathname.includes(nav.link)) || 0);
    }, [pathname]);

    const handleSave = (): void => {
        if (useChangeModalStore.getState().source === 'profile') {
            profile && updateAgency(profile.id, profile);
        } else if (useChangeModalStore.getState().source === 'personalInfo') {
            const userInfo = useUserStore.getState().userInfo;
            userInfo && updateUser(userInfo?.id, userInfo);
            useUserStore.setState({ userInfo: userInfo });
        }
        setChangeVisible(false);
        setSuccessfullySaved(true);
    };

    return (
        <Wrapper>
            {accountExpires && (
                <Modal modalVisible={true} closeModal={() => setAccountExpires(false)}>
                    <AccountExpiration close={() => setAccountExpires(false)} />
                </Modal>
            )}
            {changeVisible && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setChangeVisible(false);
                        useChangeModalStore.setState({ changeModalVisible: false });
                    }}
                >
                    <ChangeModal
                        saveChanges={handleSave}
                        close={() => {
                            setChangeVisible(false);
                        }}
                        message={t('pages.agency.profile.areYouSure').text}
                    />
                </Modal>
            )}
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <Container>
                <Header>
                    <Link style={{ cursor: 'pointer' }} to="/invoices">
                        <img src={logo} height={'32px'} />
                    </Link>
                    <UserPlace onClick={() => setDropdownVisible(!dropdownVisible)}>
                        <FontAwesomeIcon color="white" icon={faUser} />
                        <FontAwesomeIcon style={{ paddingLeft: '10px' }} color="white" icon={faCaretDown} />
                    </UserPlace>
                    {dropdownVisible && (
                        <DropdownContainer ref={ref} onClick={handleClickOutside}>
                            <Dropdown arrowRight="4px" itemList={userDropdown} />
                        </DropdownContainer>
                    )}
                </Header>
                {icons.map((i, index) => (
                    <div key={index}>
                        {!i.link ? (
                            <ListItem
                                onClick={() => handleSelect(index, i.text)}
                                className={
                                    (i.text === t('navbar.pausal.eInvoices').text ||
                                        i.text === t('navbar.pausal.ePrePayment').text) &&
                                    index === selectedItem
                                        ? 'efakture selected'
                                        : i.text === t('navbar.pausal.eInvoices').text ||
                                          i.text === t('navbar.pausal.ePrePayment').text
                                        ? 'efakture'
                                        : index === selectedItem
                                        ? 'selected'
                                        : ''
                                }
                                key={i.text}
                            >
                                <FontAwesomeIcon
                                    style={{ paddingLeft: '30px' }}
                                    className="icon"
                                    color="white"
                                    icon={i.icon}
                                />
                                <Label className="label">{i.text}</Label>
                            </ListItem>
                        ) : (
                            <Link to={i.link}>
                                <ListItem
                                    onClick={() => handleSelect(index, i.text)}
                                    className={
                                        (i.text === t('navbar.pausal.eInvoices').text ||
                                            i.text === t('navbar.pausal.ePrePayment').text) &&
                                        index === selectedItem
                                            ? 'efakture selected'
                                            : i.text === t('navbar.pausal.eInvoices').text ||
                                              i.text === t('navbar.pausal.ePrePayment').text
                                            ? 'efakture'
                                            : index === selectedItem
                                            ? 'selected'
                                            : ''
                                    }
                                    key={i.text}
                                >
                                    <FontAwesomeIcon
                                        style={{ paddingLeft: '30px' }}
                                        className="icon"
                                        color="white"
                                        icon={i.icon}
                                    />
                                    <Label className="label">{i.text}</Label>
                                </ListItem>
                            </Link>
                        )}

                        {nbsDropdownVisible && i.text === t('navbar.pausal.nbs').text && (
                            <NbsContainer>
                                <Nbs onClose={() => setNbsDropdownVisible(false)} />
                            </NbsContainer>
                        )}
                    </div>
                ))}

                {role && role !== 'admin' && (
                    <>
                        {isAccountActive ? (
                            <AccountExpires onClick={() => setAccountExpires(!accountExpires)}>
                                {t('navbar.pausal.expires').text + ' ' + moment(expires).format('DD.MM.YYYY')}
                            </AccountExpires>
                        ) : (
                            <AccountExpired>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.danger}
                                    size={190}
                                    onClick={() => setAccountExpires(!accountExpires)}
                                >
                                    {t('navbar.pausal.accountExpired').text}
                                </Button>
                            </AccountExpired>
                        )}

                        <SelectContainer>
                            <SelectLang />
                        </SelectContainer>
                        {isAccountActive ? (
                            <ButtonContainer>
                                <Link to="/independenceTest">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        className="small"
                                        size={190}
                                    >
                                        {t('navbar.pausal.independenceTest').text}
                                    </Button>
                                </Link>
                            </ButtonContainer>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </Container>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    .modal-wrapper {
        width: 800px;
    }
`;
const Container = styled.div`
    height: 100%;
    overflow-y: auto;
    width: 248px;
    position: fixed;
    padding: 0;
    display: inline-block;
    vertical-align: top;
    background: linear-gradient(180deg, var(--navbar-black) 0, var(--navbar-gray));
`;

const Header = styled.div`
    display: inline-block;
    vertical-align: top;
    background: var(--purple);
    width: 100%;
    border-bottom: 1px solid var(--white);
    padding: 30px 0 0 20px;
    position: relative;
    z-index: 54;
    margin-bottom: 30px;
    height: 84px;
`;

export const UserPlace = styled.div`
    float: right;
    margin: -16px 0 0 15px;
    width: 83px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    height: 39px;
    transition: all 0.2s ease;
    cursor: pointer;
    position: relative;

    :hover,
    &.selected {
        background-color: var(--white);
        border-left: 3px solid var(--purple);
        .label,
        .icon {
            color: var(--black);
        }
        &.efakture {
            margin-left: 10px;
        }
    }
    &.efakture {
        margin-left: 10px;
    }
`;

const Label = styled.p`
    color: var(--light-gray);
    margin-left: 10px;
    font-family: Open Sans, sans-serif;
    font-size: 14px;
`;

export const DropdownContainer = styled.div`
    margin-top: 33px;
    width: 182px;
    right: 10px;
    position: absolute;
`;

export const AccountExpires = styled.div`
    cursor: pointer;
    text-decoration: underline;
    margin: 25px 0px 25px 25px;
    border-left: 3px solid transparent;
    font-size: 14px;

    color: var(--white);
`;
export const AccountExpired = styled.div`
    margin: 25px 0px 25px 10px;
`;

const ButtonContainer = styled.div`
    height: 36px;
    padding-left: 10px;
    margin-top: 25px;
    button {
        font-size: 13px;
    }
`;

const SelectContainer = styled.div`
    padding-left: 30px;
    margin-top: 25px;

    > div {
        height: 36px;
        width: 100px;
    }
`;

const NbsContainer = styled.div`
    height: 500px;
    position: absolute;
`;

export default WebNavbar;
