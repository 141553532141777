import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';

const getAllInvoices = (setInvoices: Dispatch<SetStateAction<ObjectKeys>>, formattedParams?: string): void => {
    communication
        .getInvoices({
            limit: '10',
            page: 1,
            search: 'is_prepaid:0;' + formattedParams ? formattedParams : '',
            searchFields: 'is_prepaid:=;' + formattedParams ? formattedParams : '',
            searchJoin: 'and',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setInvoices(res?.data);
            }
        });
};
export default getAllInvoices;
