import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Input from '../../../components/input';
import Tooltip from '../../../components/tooltip';
import SelectComponent from '../../../components/select';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useProfileStore from '../../../store/profile';
import communication from '../../../communication';
import useWindowSize from '../../../hooks/useResize';

const Einvoices: FunctionComponent = () => {
    const profile = useProfileStore((props) => props.profile);
    const setData = useProfileStore((props) => props.setData);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [apiKey, setApiKey] = useState<string>('');
    const [vatExemption, setVatExemption] = useState<string>('');
    const [vatCategory, setVatCategory] = useState<string>('SS');
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);
    const vatExemptions = [
        {
            value: 'PDV-RS-33',
            label: 'PDV-RS-33',
        },
        {
            value: 'PDV-RS-35-7',
            label: 'PDV-RS-35-7',
        },
        {
            value: 'PDV-RS-36-5',
            label: 'PDV-RS-36-5',
        },
        {
            value: 'PDV-RS-36b-4',
            label: 'PDV-RS-36b-4',
        },
    ];

    const handleSave = async (): Promise<void> => {
        await setData({
            ...profile,
            e_invoice_api_key: apiKey,
            e_invoice_vat_exception_code: vatExemption,
            e_invoice_vat_sign: vatCategory,
        });
        await communication.updateAgency(profile.id, profile);
    };
    return (
        <Content className={isMobileWidth ? 'mobile-width' : 'desktop'}>
            <div className={'description'}>{t('pages.agency.eInvoices.description').text}</div>
            <div>
                <Tooltip label={t('pages.agency.eInvoices.apiKeyTooltip').text}>
                    <Input
                        type={'text'}
                        onChange={(e: string) => setApiKey(e)}
                        label={t('pages.agency.eInvoices.apiKeyLabel').text}
                        value={profile.e_invoice_api_key || apiKey}
                        inputClassName={'bordered'}
                    />
                </Tooltip>
                <Tooltip label={t('pages.agency.eInvoices.vatExemptionTooltip').text}>
                    <SelectComponent
                        className={'einvoices'}
                        width={'100%'}
                        value={vatExemption}
                        handleSelectedValue={(e: string) => setVatExemption(e)}
                        label={t('pages.agency.eInvoices.vatExemptionLabel').text}
                        optionList={vatExemptions}
                        defaultSelectedOption={vatExemptions[0]}
                    />
                </Tooltip>
                <Input
                    type={'text'}
                    value={profile.e_invoice_vat_sign || vatCategory}
                    onChange={(e: string) => setVatCategory(e)}
                    label={t('pages.agency.eInvoices.vatCategoryLabel').text}
                    inputClassName={'bordered'}
                />
                <div className={'button-container'}>
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        size={'200px'}
                        height={'68px'}
                        onClick={() => handleSave()}
                    >
                        {t('pages.agency.eInvoices.button').text}
                    </Button>
                </div>
            </div>
        </Content>
    );
};

const Content = styled.div`
    display: inline-block;
    vertical-align: top;
    &.desktop {
        width: 56%;
    }
    &.mobile {
        width: 100%;
    }
    .description {
        margin-bottom: 20px;
    }
    .button-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
`;
export default Einvoices;
