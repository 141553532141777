import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';
import download from '../download';

export default class AdminTaxSolutions {
    public getAllSolutions(page = '1'): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.getAllSolutions(page),
        });
    }
    public getSolutionsByUser(id: string, page = '1'): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.getSolutionsByUser(id, page),
        });
    }

    public downloadSolution(id: string, token: string): ObjectKeys {
        return download(routes.admin.tax.downloadSolution(id), { token }, id, 'pdf');
    }
    public uploadFile(id: string, file: Blob, year: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'multipart/form-data; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.tax.uploadSolution(id),
            data: {
                year: year,
                solution: file,
            },
        });
    }
}
