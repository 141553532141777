import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import useGeneralStore from '../../../../store/general';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllProforma from '../getAllProforma';
type Props = {
    t: Function;
    clickedProforma: string;
    closeGlobalModal: () => void;
    setProforma: Dispatch<SetStateAction<ObjectKeys>>;
    setLoaderVisible: Dispatch<SetStateAction<boolean>>;
};

const DeleteModal: FunctionComponent<Props> = ({
    t,
    clickedProforma,
    closeGlobalModal,
    setProforma,
    setLoaderVisible,
}) => {
    const { setGlobalModal } = useGeneralStore();

    const handleDeleteProforma = (proformaId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteProforma(proformaId)
            .then((res: ObjectKeys) => {
                if (res) {
                    closeGlobalModal();
                }
            })
            .then(() => {
                getAllProforma(setProforma);
                setLoaderVisible(false);
            })
            .catch((error: any) => {
                setLoaderVisible(false);
                if (error) {
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            });
    };
    return (
        <DeleteModalWrapper>
            <ModalText>
                <h4>{t('pages.proforma.deleteModal.title').text}</h4>
            </ModalText>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleDeleteProforma(clickedProforma);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </DeleteModalWrapper>
    );
};

const DeleteModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;

export default DeleteModal;
