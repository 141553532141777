import axios, { AxiosError } from 'axios';
import routes from './routes';
import getCurrentLanguage from '../functions/getCurrentLanguage';

const downloadInvoice: any = async (
    id: string | any,
    token: string,
    invoiceNo: string,
    foreign: boolean,
    proforma?: boolean,
) => {
    try {
        axios({
            url: proforma ? routes.proforma.downloadProforma(id) : routes.invoice.downloadInvoice(id),
            method: 'POST',
            responseType: 'blob',
            data: { token: token },
            headers: {
                'Accept-Language': !foreign ? getCurrentLanguage() : 'en',
            },
        }).then((response) => {
            const fileName = response?.headers['content-disposition']?.split('filename="')[1]?.split('"')[0];
            const href = URL.createObjectURL(response.data);
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    } catch (error) {
        throw error as AxiosError;
    }
};

export default downloadInvoice;
