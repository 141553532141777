import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';
import communication from '../communication';

interface UserState {
    userInfo:
        | {
              id: string;
              first_name: string;
              last_name: string;
              email: string;
              middle_name?: string;
              citizenship: string | null;
              jmbg?: string;
              city?: string;
              address?: string;
              street_number: string;
              education: string | null;
              nickname?: string;
              gender: string | null;
              municipality_id: string;
          }
        | null
        | ObjectKeys;
    municipalities: Array<ObjectKeys>;
    activityCodes: Array<ObjectKeys>;
    setFormData: (content: any) => void;
    updateUser: (id: string, content: any) => void;
}

const useStore = create<UserState>((set) => ({
    userInfo: null,
    municipalities: [],
    activityCodes: [],
    updateUser: async (id: string, content: any) => {
        await communication.updateUser(content, id);
    },
    setFormData: (content: any) => set({ userInfo: content }),
}));

export default useStore;
