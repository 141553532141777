import { FunctionComponent, useEffect, useState } from 'react';
import useLanguageStore from '../../store/language';
import { flatten } from '../../functions/flatten';
import srJson from '../language/sr.json';
import enJson from '../language/en.json';
import Select from '../select';
import colors from '../../global/colors';

interface Props {
    color?: string;
}

const SelectLang: FunctionComponent<Props> = ({ color }) => {
    const currentLangName = localStorage.getItem('language');

    //All options for languages
    const options = [
        { value: 'Srpski', label: 'Srpski' },
        { value: 'English', label: 'English' },
    ];
    const [selectedOption, setSelectedOption] = useState<Array<{ value: string; label: string }>>([]);

    //Check for current selected language
    useEffect(() => {
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
        }
    }, [currentLangName]);

    const handleClickOption = (name: string): void => {
        useLanguageStore.setState({ currentLangName: name });
        localStorage.setItem('language', name);
    };

    useEffect(() => {
        setSelectedOption(
            options
                .filter((option) => option.value === currentLangName)
                .map((option) => {
                    return option;
                }),
        );
    }, [currentLangName]);

    return (
        <Select
            isSearchable={false}
            optionList={options}
            handleSelectedValue={handleClickOption}
            defaultSelectedOption={selectedOption[0] ? selectedOption[0] : options[0]}
            color={color ? color : colors.white}
        />
    );
};
export default SelectLang;
