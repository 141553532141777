import { FunctionComponent } from 'react';
import { nanoid } from 'nanoid';
import styled from 'styled-components';
import useLanguageStore from '../../../../../store/language';
import useTranslations from '../../../../../hooks/useTranslation';
import { ObjectKeys } from '../../../../../types/objectKeys';
import moment from 'moment';
import { formatNumber } from '../../../../../functions/format';
import logo from '../../../../../assets/logo.png';
import { getMeasurementUnit } from '../measurementUnits';
import countTotal from '../invoiceEdit/countTotal';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    title: string;
    type?: string;
    note?: string;
}

const PreviewInvoiceModal: FunctionComponent<Props> = ({ invoice, agency, title, type, note }) => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const publicApiUrl = process.env.REACT_APP_PUBLIC_URL;

    const foreignInvoiceText =
        type === 'foreign'
            ? {
                  title:
                      title === 'invoice' ? 'Invoice / ' : title === 'proforma' ? 'Proforma / ' : 'Advance Invoice / ',
                  dated: 'Dated / ',
                  valueDate: 'Value date / ',
                  tradingPlace: 'Trading place / ',
                  from: 'From / ',
                  vatNo: 'Vat No / ',
                  idNo: 'ID No / ',
                  billTo: 'Bill to / ',
                  address: 'Address / ',
                  city: 'City / ',
                  country: 'Country / ',
                  item: 'Item',
                  unit: 'Unit',
                  quantity: 'Quantity',
                  price: 'Price',
                  total: 'Total',
                  amountDue: 'Total for payment / ',
                  note: 'Note / ',
                  identificationNum: 'Identification number / ',
                  notVatSystem: 'Not in the VAT system. / ',
                  vatCalc: 'VAT not calculated on the proforma according to article 33 of Law on value added tax. / ',
                  docValid: 'Document is valid without stamp and signature / ',
                  place: 'Place / ',
              }
            : {};

    return (
        <Container>
            {agency?.logo ? (
                <AgencyLogo>
                    <img src={publicApiUrl + agency?.logo} alt="Agency logo" />
                </AgencyLogo>
            ) : (
                <></>
            )}
            <Header>
                <Row>
                    <Row className="inv-no">
                        <h4 className="title">
                            {foreignInvoiceText?.title}
                            {title === 'invoice'
                                ? t('pages.invoices.addInvoiceDropdown.invoice').text
                                : title === 'proforma'
                                ? t('pages.proforma.addProformaDropdown.proforma').text
                                : t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text}
                            : {invoice.invoice_number ? invoice.invoice_number : 'xx/xxx'}
                        </h4>
                    </Row>
                    <Row className="date">
                        <p>
                            {foreignInvoiceText?.dated}
                            <br></br>
                            {title === 'invoice'
                                ? t('pages.editInvoice.previewModal.invoiceDate').text
                                : title === 'proforma'
                                ? t('pages.editProforma.proformaDate').text
                                : t('pages.editAdvanceInvoice.editAdvanceInvoice').text}{' '}
                            <br></br>{' '}
                            {invoice.created_at
                                ? moment(invoice.created_at).format('DD.MM.YYYY')
                                : moment(new Date()).format('DD.MM.YYYY')}
                        </p>
                    </Row>
                    <Row className="date">
                        <p>
                            {foreignInvoiceText?.valueDate} <br></br>
                            {t('pages.editInvoice.previewModal.valueDate').text} <br></br>
                            {invoice.trading_date
                                ? moment(invoice.trading_date).format('DD.MM.YYYY')
                                : moment(new Date()).format('DD.MM.YYYY')}
                            <br></br>
                            {foreignInvoiceText?.tradingPlace} <br></br>
                            {t('pages.editInvoice.previewModal.tradingPlace').text} <br></br> {invoice.trading_place}
                        </p>
                    </Row>
                </Row>
            </Header>
            {agency && (
                <Agency>
                    <div>
                        <div>
                            {foreignInvoiceText?.from}
                            {t('pages.editInvoice.previewModal.from').text}
                        </div>
                        <h4 className="agency-name">{agency.name}</h4>
                        <p>{agency.full_name}</p>
                        <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                        <p>{agency.city}</p>
                        <p>
                            {foreignInvoiceText?.vatNo}
                            {t('pages.editInvoice.previewModal.vatNo').text + '\xa0' + agency.pib}
                        </p>
                        <p>
                            {foreignInvoiceText?.idNo}
                            {t('pages.editInvoice.previewModal.jmbg').text + '\xa0' + agency.identification_number}
                        </p>
                        {type === 'foreign' ? (
                            <>
                                <p>
                                    {t('pages.agency.profile.iban').text}:{' '}
                                    {invoice.iban ? invoice.iban : agency?.iban ? agency?.iban : '/'}
                                </p>
                                <p>
                                    {t('pages.agency.profile.swift').text}:{' '}
                                    {invoice.swift ? invoice.swift : agency?.swift ? agency?.swift : '/'}
                                </p>
                            </>
                        ) : (
                            <p>
                                {t('pages.editInvoice.previewModal.bank').text}
                                {invoice?.bank_account ? invoice?.bank_account : agency.first_bank_account}
                            </p>
                        )}
                        <p>
                            {t('pages.editInvoice.previewModal.email').text +
                                '\xa0' +
                                (agency?.agency_email ? agency?.agency_email : '/')}
                        </p>
                    </div>

                    <div>
                        <div>
                            {foreignInvoiceText?.billTo}
                            {t('pages.editInvoice.previewModal.client').text}
                        </div>
                        <h4 className="client-name">
                            {invoice?.client?.data.company_name ? invoice?.client?.data.company_name : ''}
                        </h4>
                        <p>
                            {foreignInvoiceText?.address}
                            {t('pages.editInvoice.previewModal.address').text +
                                '\xa0' +
                                (invoice?.client?.data.address ? invoice?.client?.data.address : '/')}
                        </p>
                        <p>
                            {foreignInvoiceText?.city}
                            {t('pages.editInvoice.previewModal.city').text +
                                '\xa0' +
                                (invoice?.client?.data.city ? invoice?.client?.data.city : '/')}
                        </p>
                        <p>
                            {foreignInvoiceText?.vatNo}
                            {t('pages.editInvoice.previewModal.pib').text +
                                '\xa0' +
                                (invoice?.client?.data.pib
                                    ? invoice?.client?.data.pib
                                    : invoice?.client?.data.jmbg
                                    ? invoice?.client?.data.jmbg
                                    : '/')}
                        </p>
                        <p>
                            {foreignInvoiceText?.idNo}
                            {t('pages.editInvoice.previewModal.jmbg').text +
                                ' ' +
                                (invoice?.client?.data.identification_number
                                    ? invoice?.client?.data.identification_number
                                    : '/')}
                        </p>
                    </div>
                </Agency>
            )}
            <Row className="services">
                {type === 'domestic' ? (
                    <>
                        <h4 className="type">{t('pages.editInvoice.previewModal.item').text}</h4>
                        <h4 className="price">{t('pages.editInvoice.previewModal.unit').text}</h4>
                        <h4 className="price">{t('pages.editInvoice.previewModal.amount').text}</h4>
                        <h4 className="price">{t('pages.editInvoice.previewModal.unitPrice').text}</h4>
                        <h4 className="total">{t('pages.editInvoice.previewModal.total').text}</h4>
                    </>
                ) : (
                    <>
                        <div className="type">
                            <h4>{foreignInvoiceText?.item}</h4>
                            <span>( {t('pages.editInvoice.previewModal.item').text} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.unit}</h4>
                            <span>( {t('pages.editInvoice.previewModal.unit').text} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.quantity}</h4>
                            <span>( {t('pages.editInvoice.previewModal.amount').text} )</span>
                        </div>
                        <div className="price">
                            <h4>{foreignInvoiceText?.price}</h4>
                            <span>( {t('pages.editInvoice.previewModal.unitPrice').text} )</span>
                        </div>
                        <div className="total">
                            <h4>{foreignInvoiceText?.total}</h4>
                            <span>( {t('pages.editInvoice.previewModal.total').text} )</span>
                        </div>
                    </>
                )}
            </Row>
            {invoice?.services ? (
                <>
                    {invoice?.services?.map((service: ObjectKeys) => (
                        <div key={nanoid()}>
                            <Row className="services">
                                <p className="type">{service.name}</p>
                                <p className="price">{getMeasurementUnit(t, service.pivot.measurement_unit)}</p>
                                <p className="price">{formatNumber(service?.pivot?.quantity, 2, 3, '.', ',')}</p>
                                <p className="price">{formatNumber(service?.pivot?.price_per_unit, 2, 3, '.', ',')}</p>
                                <p className="total">{formatNumber(service?.pivot?.total_price, 2, 3, '.', ',')}</p>
                            </Row>
                        </div>
                    ))}
                    <Total>
                        <h4>
                            {foreignInvoiceText?.amountDue}
                            {t('pages.editInvoice.previewModal.totalPayment').text} ({invoice.currency})
                        </h4>
                        <p className="right">{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</p>
                    </Total>
                </>
            ) : (
                <></>
            )}
            <Row>
                <Comments>
                    <h4>
                        {foreignInvoiceText?.note}
                        {t('pages.editInvoice.previewModal.comment').text}
                    </h4>
                    <p>{note ? note : t('pages.editInvoice.previewModal.due').text}</p>
                    {type !== 'foreign' ? (
                        <p>
                            {title === 'proforma'
                                ? t('pages.editInvoice.previewModal.putCallNoProforma').text
                                : t('pages.editInvoice.previewModal.putCallNoInvoice').text}
                        </p>
                    ) : (
                        <></>
                    )}
                    <p>
                        {foreignInvoiceText?.identificationNum}
                        {t('pages.editInvoice.previewModal.id').text} <br></br>
                        {agency.signature}
                    </p>
                    <p>
                        {foreignInvoiceText?.docValid}
                        {currentLangName !== 'English'
                            ? title === 'invoice'
                                ? t('pages.editInvoice.previewModal.invoiceDocumentValid').text
                                : title === 'proforma'
                                ? t('pages.editInvoice.previewModal.proformaDocumentValid').text
                                : t('pages.editInvoice.previewModal.advanceInvoiceDocumentValid').text
                            : t('pages.editInvoice.previewModal.documentValid').text}
                    </p>
                    <p>
                        {foreignInvoiceText?.place}
                        {t('pages.editInvoice.previewModal.place').text}
                        {agency.city}
                    </p>
                    <br></br>
                    <h4>{t('pages.editInvoice.previewModal.note').text}</h4>
                    <p>
                        {foreignInvoiceText?.notVatSystem}
                        {t('pages.editInvoice.previewModal.note1').text}
                    </p>
                    <p>
                        {foreignInvoiceText?.vatCalc}
                        {title === 'invoice'
                            ? t('pages.editInvoice.previewModal.invoiceNote2').text
                            : title === 'proforma'
                            ? t('pages.editInvoice.previewModal.proformaNote2').text
                            : t('pages.editInvoice.previewModal.advanceInvoiceNote2').text}
                    </p>

                    <p>{t('pages.editInvoice.previewModal.documentValid').text}</p>
                </Comments>
            </Row>
            <Footer>
                <p>
                    {title === 'proforma'
                        ? t('pages.editInvoice.previewModal.footerProforma').text
                        : t('pages.editInvoice.previewModal.footerInvoice').text}
                    <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                        pausal.rs
                    </a>
                </p>
                <img src={logo} height={'30px'} />
            </Footer>
        </Container>
    );
};

export default PreviewInvoiceModal;

const Container = styled.div`
    padding: 80px 50px 80px 150px;
    color: var(--black);
    p {
        font-size: 12px;
        word-break: break-all;
    }
    h4 {
        font-weight: 600;
        font-size: 12px;
        &.title {
            font-size: 20px;
            font-weight: 700;
        }
        &.agency-name,
        &.client-name {
            font-size: 17px;
        }
    }
    @media only screen and (max-width: 768px) {
        padding: 30px;
    }
    @media only screen and (max-width: 425px) {
        padding: 10px;
    }
`;
const AgencyLogo = styled.div`
    position: absolute;
    left: 0;
    padding: 0 10px;
    img {
        width: 110px;
    }
    @media only screen and (max-width: 768px) {
        position: static;
        padding: 0;
        margin-bottom: 10px;
    }
`;

const Header = styled.div`
    width: 100%;
    padding-bottom: 20px;
    border-collapse: separate;
    border-bottom: 1px solid black;

    .inv-no {
        width: 50%;
    }

    .date {
        :nth-of-type(2) {
            width: 20%;
        }
        :nth-of-type(3) {
            width: 30%;
        }
        display: flex;
        justify-content: flex-end;
        p {
            line-height: 1.3;
        }
    }
`;
const Row = styled.div`
    display: flex;
    gap: 5px;
    &.right {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
        p {
            line-height: 1.2;
        }
    }
    &.services {
        text-align: end;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid black;
        h4 {
            text-transform: uppercase;
            line-height: 1.3;
            @media only screen and (max-width: 425px) {
                font-size: 10px;
            }
        }
        span {
            text-transform: uppercase;
            font-size: 10px;
        }
    }
    .type {
        width: 30%;
        text-align: start;
        line-height: 0.8;
    }
    .price {
        width: 15%;
        line-height: 0.8;
    }
    .total {
        width: 25%;
        line-height: 0.8;
    }
`;
const Agency = styled.div`
    border-bottom: 1px solid black;
    font-size: 12px;
    display: flex;
    gap: 5px;
    padding: 10px 0;
    > div {
        width: 50%;
    }
    h4 {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    p {
        margin-bottom: 8px;
        line-height: 1.3;
    }
`;
const Total = styled.div`
    width: 100%;
    font-weight: 700;
    display: flex;
    border-bottom: 1px solid var(--black);
    h4 {
        width: 60%;
        display: flex;
        align-items: center;
    }
    p {
        width: 40%;
        font-size: 25px;
        font-weight: 700;
        background-color: var(--light-gray);
        text-align: center;
        border: none;
        padding-top: 30px;
        padding-bottom: 30px;
        vertical-align: middle;
        color: var(--black);
    }
`;
const Comments = styled.div`
    padding-top: 20px;
    h4 {
        font-size: 12px;
        margin-bottom: 10px;
        text-transform: uppercase;
    }
    p {
        margin-top: 5px;
        line-height: 1.3;
        word-break: unset;
    }
`;
const Footer = styled.div`
    border-top: 1px solid var(--black);
    width: 100%;
    padding-top: 20px;
    font-size: 12px;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media only screen and (max-width: 425px) {
        flex-direction: column;
        img {
            margin-top: 10px;
            width: auto;
        }
    }
`;
