import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import { useLocation, useNavigate } from 'react-router-dom';
import QuillToolbar from '../toolbar';
import Input from '../../../components/input';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../components/loader';
import useAgencyStore from '../../../store/agency';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Sidebar from '../../../components/sideModal';
import SendDocumentModal from '../../myAgency/documents/sendModal';
import { SendDocumentFormData } from '../../../types/communication/document';

const publicPath = process.env.REACT_APP_PUBLIC_URL;

enum MemorandumStatus {
    sent = 'sent',
    preparation = 'preparation',
}

const NewMemorandum: FunctionComponent = () => {
    const [memorandum, setMemorandum] = useState<any>();
    const agency = useAgencyStore((props) => props.agency);
    const [editorValue, setEditorValue] = useState<any>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [memorandumName, setMemorandumName] = useState<string>('');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const quillRef = useRef<any>();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { state } = useLocation();
    const { name, template } = state;
    const navigate = useNavigate();

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    //QUILL Editor Configuration
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt'];
    const hr = Quill.import('blots/block/embed');
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    hr.blotName = 'divider';
    hr.tagName = 'hr';
    Quill.register(Size, true);
    const Break = Quill.import('blots/break');
    Break.blotName = 'break';
    Break.tagName = 'BR';
    Quill.register(Block, true);
    Quill.register({
        'formats/hr': hr,
    });

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'indent',
        'italic',
        'align',
        'list',
        'bullet',
        'image',
        'divider',
        'link',
    ];

    const MemorandumHeader = (): string =>
        `<p class="ql-align-center"><img src="${publicPath}/${
            agency?.logo
        }" /></p><h1 class="ql-align-center"><div style={{ fontSize: '22pt' }}>${
            agency?.full_name ? agency?.full_name : ''
        }</div></h1><p class="ql-align-center"><div class='ql-align-center' style={{ fontSize: '12pt' }}>${
            agency?.street_address ? agency.street_address : ''
        } ${agency?.street_number ? agency.street_number + ',' : ''} ${agency?.city ? agency.city : ''} ${'PIB'}: ${
            agency?.pib ? agency?.pib : ''
        } MB : ${
            agency?.identification_number ? agency?.identification_number : ''
        }</div></p><p class={'ql-align-center '}><div class='ql-align-center' style={{ fontSize: ' 12pt' }}>${
            agency?.agency_email ? agency?.agency_email : ''
        }</div></p><p class="ql-align-center"><div class='ql-align-center' style={{ fontSize: '12pt' }}> Telefon: ${
            agency?.phone ? agency?.phone : ''
        }</div></p><hr class="ql-hr" style={{height: '0px';marginTop: '10px';marginBottom: '10px'}}/>`;

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const getTemplate = (data: any) => {
        switch (template) {
            case '2':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Zahtev</div></h1><br><br><p class='ql-align-center'>Za preduzetničku agenciju ${
                        data?.full_name
                    } želim da otkupim devize u iznosu od: IZNOS VALUTA</p><p class='ql-align-center'>Uplatu izvršiti na račun br.: ${
                        data?.first_account_number
                    }</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '3':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Ponuda</div></h1><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Komitent</div><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Naziv</em><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Adresa</em><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>Grad</em><br><em style='color: rgb(153, 153, 153); font-size: 12pt;'>PIB </em></p><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Ponuda za obavljanje</div><em style='color: rgb(102, 102, 102); font-size: 12pt;'> [opis posla]</em><div style='color: rgb(0, 0, 0); font-size: 12pt;'> iznosi </div><em style='color: rgb(102, 102, 102); font-size: 12pt;'>[iznos i valuta] </em><div style='color: rgb(0, 0, 0); font-size: 12pt;'>po [satu, danu…].</div></p><br><p><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Ukoliko ste saglasni sa ponudom, na ime dogovorenog posla, uplatiti </div><em style='color: rgb(102, 102, 102); font-size: 12pt;'>[ukupan iznos i valuta]</em><div style='color: rgb(0, 0, 0); font-size: 12pt;'> na žiro račun: ${
                        data?.first_account_number
                    }.</div><br><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Prihvatanje ponude se realizuje uplatom po ovom dokumentu.</div><br><div style='color: rgb(0, 0, 0); font-size: 12pt;'>Dokument se ne može koristiti za knjiženje u poslovnim knjigama.</div></p>`,
                );
            case '4':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Izjava o kašnjenju</div></h1><br><br><p class='ql-align-center'>Izjavljujemo da kasnimo sa podnošenjem dokumentacije za vlasnika preduzetničke radnje ${
                        data?.full_name
                    }, JMBG: ${
                        data?.user.data.jmbg
                    }, radi dužeg boravka u inostranstvu vlasnika kao jedinog potpisnika dokumentacije.</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '5':
                return setEditorValue(
                    `${MemorandumHeader()}<br><h1 class='ql-align-center'><div style='font-size: 18pt;'>Odluka o službenom putovanju</div></h1><br><br><p class='ql-align-center'>Upućuje se ____________________ na službeni put u:</p><p class='ql-align-center'>Br. lične karte:</p><p class='ql-align-center'>Br. pasoša:</p><p class='ql-align-center'>Dan polaska:</p><p class='ql-align-center'>Dan povratka:</p><p class='ql-align-center'>____________________ putuje automobilom.</p><p class='ql-align-center'>Putni troškovi padaju na teret ${
                        data?.full_name
                    }</p><br><br><br><br><br><br><br><p>S poštovanjem,<br>________________<p><br>M.P.`,
                );
            case '6':
                return setEditorValue(
                    `${MemorandumHeader()}<br><br><p class='ql-align-center'>Na osnovu člana 9. Zakona o računovodstvu dana ______ godine preduzetnik ${
                        data?.full_name
                    } donosi sledeću</p><br><br><p class='ql-align-center'>ODLUKU</p><br><p class='ql-align-center'>O određivanju lica ovlašćenog za potpisivanje</p><br><br><p class='ql-align-center'>Ovom Odlukom određuje se lice ovlašćeno za sastavljanje, kontrolu verodostojnosti i za potpisivanje računovodstvenih isprava i to:</p><p class='ql-align-center'>${
                        data?.decisionStartText
                    } preduzetničke radnje ${
                        data?.full_name
                    }, ima neograničena ovlašćenja i potpisuje samostalno računovodstvene isprave.</p><p class='ql-align-center'>Identifikaciona oznaka ovlašćenog lica je: ${
                        data?.signature
                    }</p><br><br><br><br><br><br><br><p>Preduzetnik<br>________________ M.P.<p>`,
                );
            default:
                return setEditorValue(MemorandumHeader());
        }
    };

    const handleChange = (value: any): void => {
        setEditorValue(value);
    };

    const getMyAgency = async (): Promise<void> => {
        const response = await communication.getAgency();
        getTemplate(response.data.data);
    };

    useEffect(() => {
        setIsLoader(true);
        setMemorandumName(name);
        getMyAgency();
        setIsLoader(false);
    }, []);

    const handleSave = async (): Promise<void> => {
        setIsLoader(true);
        const data = {
            template: editorValue,
            status: MemorandumStatus.preparation,
            heading: memorandumName,
            memorandum_type: name,
        };
        const res = await communication.createMemorandum(data);
        setMemorandum(res.data.data);
        setIsLoader(false);
        navigate(-1);
    };

    const handlePrint = async (): Promise<void> => {
        const data = {
            template: editorValue,
            status: MemorandumStatus.preparation,
            heading: memorandumName,
            memorandum_type: name,
        };
        const res = await communication.createMemorandum(data);
        await communication.downloadMemorandum(res?.data?.data?.id, token);
        navigate(-1);
    };

    const handleSend = (): void => {
        setModalVisible(true);
    };

    // Modules object for setting up the Quill editor
    const modules = {
        toolbar: {
            container: '#toolbar',
            history: {
                delay: 0,
                maxStack: 100,
                userOnly: true,
            },
        },
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendDocumentModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={memorandum?.id}
                        success={() => {
                            setIsLoader(false);
                        }}
                        loading={() => setIsLoader(true)}
                        memorandum={true}
                        handleSaveMemorandum={handleSave}
                        modalVisible={modalVisible}
                    />
                </Sidebar>
            )}

            <Header>
                <div
                    className={'back-button'}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <FontAwesomeIcon className={'icon'} icon={faAngleLeft} color={colors.gray} />
                </div>
                <h1>Memorandumi</h1>
                <Button
                    className={'action-button'}
                    onClick={async () => {
                        await handleSave();
                    }}
                    color={colors.green}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                    {t('pages.editInvoice.save').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handlePrint();
                    }}
                    color={colors.purple}
                    variant={ButtonVariant.outlined}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    {t('pages.editInvoice.print').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSend();
                    }}
                    color={colors.blue}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    {t('pages.editInvoice.send').text}
                </Button>
            </Header>
            <Input
                type="text"
                label={'Name'}
                className={'input'}
                value={memorandumName}
                onChange={(value: string) => setMemorandumName(value)}
            />
            <QuillToolbar quillRef={quillRef} />
            <ReactQuill
                className={'ql-editor'}
                ref={quillRef}
                theme="snow"
                value={editorValue}
                onChange={handleChange}
                placeholder={''}
                modules={modules}
                formats={formats}
            />
        </PageWrapper>
    );
};
const PageWrapper = styled.div`
    min-height: 100vh;
    width: 60.67vw;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1079px) {
        width: 100%;
    }
    .back-button {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 3px;
        text-align: center;
        display: block;
    }
    .action-button {
        width: auto;
        padding: 15px 20px;
        margin-left: 20px;
    }
    .quill {
        width: 60.67vw;
    }

    .ql-container {
        height: 100vh;
    }

    .ql-editor {
        background-color: var(--white);
        padding: 0px;
        //display: grid;
        height: 100%;
        @media screen and (max-width: 1079px) {
            width: 100%;
        }
        .ql-container {
            font-family: 'Open Sans', sans-serif;
            padding: 12px 15px;
        }
        .span {
            font-size: 12pt;
            line-height: 2;
        }
        img {
            height: auto;
            width: 6.7067vw;
            margin-left: auto !important;
            margin-right: auto !important;
            display: block;
        }
        h1 {
            line-height: 1.3em;
            text-align: center;
            color: #000;
            font-weight: 700;
            margin: 0.67em 0;
        }
        p {
            font-size: 1.38vw;
            line-height: 1.785vw;
            color: #000;
        }
        hr {
            height: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    .input {
        input {
            background-color: var(--white);
            &:hover {
                border-bottom: 1px solid var(--border-color);
            }
            &:focus {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
`;
export default NewMemorandum;
