import { FunctionComponent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEye, faPrint, faSave, faTrashCan, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ButtonsBottom } from '../../style';
import Button, { ButtonVariant } from '../../../../../../components/button';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import colors from '../../../../../../global/colors';

interface Props {
    setPreviewModal: Function;
    setSendModal: Function;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
    name?: string;
    invoice?: ObjectKeys;
}

const MobileFooter: FunctionComponent<Props> = ({
    setPreviewModal,
    setSendModal,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
    name,
    invoice,
}) => {
    const role = localStorage.getItem('role');

    return (
        <ButtonsBottom>
            {role !== 'admin' ? (
                <>
                    <Button
                        onClick={() => handleDownloadInvoice()}
                        variant={ButtonVariant.outlined}
                        color={colors.purple}
                        size={'auto'}
                        className="print-btn"
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    </Button>
                    <Button
                        onClick={() => setSendModal(true)}
                        variant={ButtonVariant.solid}
                        color={colors.blue}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    </Button>
                </>
            ) : (
                <></>
            )}
            <Button
                onClick={() => handleUpdateInvoice()}
                variant={ButtonVariant.solid}
                color={colors.green}
                size={'auto'}
            >
                <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
            </Button>

            {name === 'proforma' ? (
                <Button
                    onClick={() => handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()}
                    variant={ButtonVariant.solid}
                    color={colors.green}
                    size={'auto'}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                </Button>
            ) : (
                <></>
            )}
            {role !== 'admin' ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.purple}
                    size={'auto'}
                    onClick={() => setPreviewModal(true)}
                    className="preview-btn"
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                </Button>
            ) : (
                <></>
            )}
            {invoice?.id ? (
                <Button
                    variant={ButtonVariant.outlined}
                    color={colors.danger}
                    size={'auto'}
                    onClick={() => setDeleteInvoiceModal(true)}
                    className="trash-btn"
                >
                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                </Button>
            ) : (
                <></>
            )}
        </ButtonsBottom>
    );
};

export default MobileFooter;
