import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ToastProvider } from 'react-toast-notifications';
import RouteContent from './routes';
import GlobalStyle from './global';
import { useEffect } from 'react';
import { flatten } from './functions/flatten';
import useLanguageStore from './store/language';
import useGeneralStore from './store/general';
import srJson from './components/language/sr.json';
import enJson from './components/language/en.json';
import GlobalModal from './components/globalModal';

const App: React.FunctionComponent = () => {
    const currentLangName = localStorage.getItem('language');
    const { closeGlobalModal } = useGeneralStore();

    //Check for current selected language
    useEffect(() => {
        if (currentLangName && currentLangName === 'English') {
            const flattenData = flatten(enJson);
            useLanguageStore.setState({ currentLang: flattenData });
        } else {
            const flattenData = flatten(srJson);
            useLanguageStore.setState({ currentLang: flattenData });
        }
    }, [currentLangName]);

    useEffect(() => {
        window.addEventListener('keyup', (e) => {
            if (e.key === 'Escape') {
                closeGlobalModal();
            }
        });
    }, []);

    return (
        <BrowserRouter>
            <ToastProvider placement="top-right">
                <GlobalStyle />
                <div className="app">
                    <GlobalModal />
                    <RouteContent />
                </div>
            </ToastProvider>
        </BrowserRouter>
    );
};

export default App;
