import { FunctionComponent, Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import colors from '../../../../global/colors';
import useGeneralStore from '../../../../store/general';
import Button, { ButtonVariant } from '../../../../components/button';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllInvoices from '../getAllAInvoices';
type Props = {
    t: Function;
    invoiceNumber: string;
    clickedInvoice: string;
    closeGlobalModal: () => void;
    setInvoices: Dispatch<SetStateAction<ObjectKeys>>;
};

const DeleteModal: FunctionComponent<Props> = ({ t, clickedInvoice, invoiceNumber, closeGlobalModal, setInvoices }) => {
    const { addToast } = useToasts();
    const { setGlobalModal } = useGeneralStore();

    const handleDeleteInvoice = (invoiceId: string): void => {
        communication
            .deleteInvoice(invoiceId)
            .then((res: ObjectKeys) => {
                closeGlobalModal();
                addToast(res.data, {
                    appearance: 'success',
                    autoDismiss: true,
                });
            })
            .then(() => {
                getAllInvoices(setInvoices);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setGlobalModal(
                        <ErrorModalWrapper>
                            <ModalText>
                                <h4>{t('modal.error.title').text}</h4>
                                <p>{error.response.data.message}</p>
                            </ModalText>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                size={'100%'}
                                onClick={() => {
                                    closeGlobalModal();
                                }}
                            >
                                {t('buttons.ok').text}
                            </Button>
                        </ErrorModalWrapper>,
                    );
                }
            });
    };
    return (
        <DeleteModalWrapper>
            <ModalText>
                {invoiceNumber ? (
                    <h4>{`${t('pages.advanceInvoices.deleteModal.title1').text} ${invoiceNumber}?`}</h4>
                ) : (
                    <h4>{t('pages.advanceInvoices.deleteModal.title2').text}</h4>
                )}
            </ModalText>
            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleDeleteInvoice(clickedInvoice);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => closeGlobalModal()}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </DeleteModalWrapper>
    );
};

const DeleteModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalText = styled.div`
    padding: 35px 50px;
    text-align: center;
    h4 {
        margin-bottom: 20px;
        line-height: 1.3;
    }
    p {
        font-size: 16px;
        line-height: 1.3;
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;

export default DeleteModal;
