import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useUserStore from '../../../store/user';
import useChangeModalStore from '../../../store/changeModal';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useEmailValidation from '../../../hooks/useEmailValidation';
import Loader from '../../../components/loader';
import { UserType } from '../../../types/user';
import SelectSearch from '../../../components/selectSearch';
import useNumLengthValidation from '../../../hooks/useNumLengthValidation';
import communication from '../../../communication';
import Modal from '../../../components/modal';
import Success from '../../../components/successModal';

const PersonalInformation: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [formData, setFormData] = useState<UserType>();
    const { municipalities } = useUserStore();

    useEffect(() => {
        async function getAgency(): Promise<void> {
            const res = await communication.getAgency();
            setFormData(res.data.data.user?.data);
        }

        getAgency();
    }, []);

    const [clickedField, setClickedField] = useState('');
    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };
    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const genders = [
        {
            value: 'male',
            label: t('pages.agency.personalInfo.genders.male').text,
        },
        {
            value: 'female',
            label: t('pages.agency.personalInfo.genders.female').text,
        },
    ];

    useEffect(() => {
        formData && useUserStore.setState({ userInfo: formData });
        if (clickedField !== '') useChangeModalStore.setState({ changeModalVisible: true, source: 'personalInfo' });
    }, [formData]);

    const update = async (): Promise<void> => {
        if (formData?.email !== '' && formData?.first_name !== '' && formData?.last_name !== '') {
            setLoaderVisible(true);
            if (formData) {
                const res = await communication.updateUser(formData, formData.id);
                if (res.status === 200) {
                    setSuccessfullySaved(true);
                }
            }
            setLoaderVisible(false);
            useChangeModalStore.setState({ changeModalVisible: false });
            setClickedField('');
        }
    };

    //validations
    const isFirstNameValid = useCharLengthValidation(formData?.first_name ? formData?.first_name : '', 3);
    const isLastNameValid = useCharLengthValidation(formData?.last_name ? formData?.last_name : '', 3);
    const isParentNameValid = useCharLengthValidation(formData?.middle_name ? formData?.middle_name : '', 3);
    const isJmbgValid = useNumLengthValidation(formData?.jmbg ? formData?.jmbg : '', 13);
    const isEducationValid = useCharLengthValidation(formData?.education ? formData?.education : '', 3);
    const isCityValid = useCharLengthValidation(formData?.city ? formData?.city : '', 3);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(formData?.address ? formData?.address : '', 3);
    const isAddressNumberValid = useCharLengthValidation(formData?.street_number ? formData?.street_number : '', 1);
    const isEmailValid = useEmailValidation(formData?.email ? formData?.email : '');

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {formData ? (
                <Content>
                    <Row>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.agency.personalInfo.name').text}
                            value={formData.first_name ? formData.first_name : ''}
                            validation={clickedField === 'firstName' ? isFirstNameValid : ''}
                            blurHandler={() => handleValidation('firstName')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, first_name: value });
                            }}
                            maxLength={50}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.agency.personalInfo.lastName').text}
                            validation={clickedField === 'lastName' ? isLastNameValid : ''}
                            blurHandler={() => handleValidation('lastName')}
                            value={formData.last_name ? formData.last_name : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, last_name: value });
                            }}
                            maxLength={50}
                        />
                    </Row>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.personalInfo.parentName').text}
                        validation={clickedField === 'parentName' ? isParentNameValid : ''}
                        blurHandler={() => handleValidation('parentName')}
                        value={formData.middle_name ? formData.middle_name : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, middle_name: value });
                        }}
                        maxLength={30}
                    />

                    <SelectWrapper>
                        <p> {t('pages.agency.personalInfo.citizenship').text}</p>
                        <SelectSearch
                            optionList={[{ value: 'Srpsko', label: 'Srpsko' }]}
                            defaultValue={{ value: 'Srpsko', label: 'Srpsko' }}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                setFormData({ ...formData, citizenship: data.value });
                                handleValidation('citizenship');
                            }}
                        />
                    </SelectWrapper>

                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.agency.personalInfo.jmbg').text}
                        value={formData.jmbg ? formData.jmbg : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, jmbg: value });
                        }}
                        validation={clickedField === 'jmbg' ? isJmbgValid : ''}
                        blurHandler={() => handleValidation('jmbg')}
                        maxLength={13}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.personalInfo.education').text}
                        validation={clickedField === 'education' ? isEducationValid : ''}
                        blurHandler={() => handleValidation('education')}
                        value={formData.education ? formData.education : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, education: value });
                        }}
                        maxLength={50}
                    />

                    <SelectWrapper>
                        <p> {t('pages.agency.personalInfo.gender').text}</p>
                        <SelectSearch
                            optionList={genders}
                            defaultValue={{
                                label:
                                    currentLang !== 'English'
                                        ? formData.gender === 'male'
                                            ? t('pages.agency.personalInfo.genders.male').text
                                            : t('pages.agency.personalInfo.genders.female').text
                                        : formData.gender,
                                value: formData.gender,
                            }}
                            handleSelectedValue={(data: { value: string; label: string }) => {
                                setFormData({ ...formData, gender: data.value });
                                handleValidation('gender');
                            }}
                        />
                    </SelectWrapper>

                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.personalInfo.city').text}
                        validation={clickedField === 'city' ? isCityValid : ''}
                        blurHandler={() => handleValidation('city')}
                        value={formData.city ? formData.city : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, city: value });
                        }}
                        maxLength={50}
                    />

                    {municipalities && (
                        <SelectWrapper>
                            <p> {t('pages.agency.profile.municipality').text}</p>
                            <SelectSearch
                                optionList={municipalities?.map((m: any) => {
                                    return { value: m?.id, label: m?.name };
                                })}
                                defaultValue={
                                    municipalities.find((e) => e.id === formData.municipality_id) && {
                                        value: formData.municipality_id,
                                        label: municipalities.find((e) => e.id === formData.municipality_id)?.name,
                                    }
                                }
                                placeholder={
                                    !formData.municipality_id
                                        ? t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text
                                        : ''
                                }
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setFormData({ ...formData, municipality_id: data.value });
                                    handleValidation('municipality');
                                }}
                            />
                        </SelectWrapper>
                    )}

                    <Address>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.agency.personalInfo.address').text}
                            value={formData.address ? formData.address : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, address: value });
                            }}
                            validation={clickedField === 'addressOfHeadQuarters' ? isAddressOfHeadQuartersValid : ''}
                            blurHandler={() => handleValidation('addressOfHeadQuarters')}
                            className="address-name"
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.agency.personalInfo.addressNum').text}
                            value={formData.street_number ? formData.street_number : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, street_number: value });
                            }}
                            validation={clickedField === 'addressNumber' ? isAddressNumberValid : ''}
                            blurHandler={() => handleValidation('addressNumber')}
                            className="address-num"
                        />
                    </Address>

                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.personalInfo.email').text}
                        value={formData.email ? formData.email : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, email: value });
                        }}
                        validation={clickedField === 'email' ? isEmailValid : ''}
                        blurHandler={() => handleValidation('email')}
                    />
                    <div className="button-container">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="big"
                            size={200}
                            onClick={update}
                        >
                            {t('pages.agency.profile.save').text}
                        </Button>
                    </div>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default PersonalInformation;

const Content = styled.div`
    width: 56%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .input-container {
        width: 100%;
        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: 768px) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }
    p {
        color: var(--gray);
        margin-bottom: 10px;
    }
    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    .input-container {
        :first-child {
            margin-right: 10px;
        }
    }
    .input {
        width: 50%;
    }
`;

const Address = styled.div`
    display: flex;
    flex-direction: row;
    .input-container {
        width: 100%;
    }
    @media screen and (max-width: 1580px) {
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select {
        width: 100%;

        .css-1jqq78o-placeholder {
            color: var(--gray);
            font-size: 15px;
        }
    }
`;
