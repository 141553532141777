import { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPrint } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import NoItems from '../../../components/noItems';
import moment from 'moment';
import communication from '../../../communication';
import TransferOrderPreviewModal from '../../../components/transferOrderPreviewModal';
import Modal from '../../../components/modal';
import useAgencyStore from '../../../store/agency';
import { ObjectKeys } from '../../../types/objectKeys';
import TransferOrderAdminCard from '../../../components/transferOrderAdminCard';
import { getAccessToken } from '../../../functions/auth';
import { generateQrCode } from '../../../communication/qr';
import ManageOrdersModalAdmin, { ChangeAmountTypesAdmin } from '../../../components/manageOrdersModalAdmin';
import ConfirmModal from '../../../components/confirmModal';
import useWindowSize from '../../../hooks/useResize';
import { range } from 'lodash';

export type SelectOption = {
    value: string;
    label: string;
};
const TransferOrdersAdmin: FunctionComponent = () => {
    // const [page, setPage] = useState<string>('1');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [transferTemplates, setTransferTemplates] = useState<ObjectKeys>([]);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [isConfirmModal, setIsConfirmModal] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [selectedUserAgencyId, setSelectedUserAgencyId] = useState<string>('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);
    const [selectedYear, setSelectedYear] = useState<string>(moment(agency.created_at).year().toString());
    const [selectUsersOptions, setSelectUsersOptions] = useState<SelectOption[]>([]);
    const [users, setUsers] = useState<ObjectKeys[]>([]);
    const [qrCodes, setQrCodes] = useState<ObjectKeys>({});
    const [selectedUser, setSelectedUser] = useState<ObjectKeys>();
    const [data, setData] = useState<ChangeAmountTypesAdmin>({
        year: '',
        user_id: '',
        model_number_recipient: 97,
        call_number_recipient: '',
        total_amount: {
            doprinos_za_zdravstveno_osiguranje: 0,
            doprinos_za_osiguranje_nezaposlenih: 0,
            porez_na_prihode_od_samostalne_delatnosti: 0,
            doprinos_za_pio: 0,
        },
    });

    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);
    const token = getAccessToken();

    const getAllUsers = async (): Promise<void> => {
        setIsLoader(true);
        const response = await communication.getAllUsers();
        setUsers(response?.data);
        response?.data?.map((user: { email: any; pib: any; agency_id: any }) =>
            setSelectUsersOptions((prevState) => [
                ...prevState,
                { label: `${user.email} - ${user.pib}`, value: user.agency_id },
            ]),
        );
        setSelectedUser(response?.data[0]);
        setSelectedUserAgencyId(response?.data[0]?.agency_id.toString());
        await getAllTransferTemplates();
        setIsLoader(false);
    };

    const getAllTransferTemplates = async (): Promise<void> => {
        setIsLoader(true);
        if (selectedUserAgencyId) {
            const res = await communication.getTransferByAgencyId(selectedUserAgencyId, selectedYear);
            setTransferTemplates(res.data.data);
        }
        setIsLoader(false);
    };
    const getAgency = async (): Promise<void> => {
        setIsLoader(true);
        if (selectedUserAgencyId) {
            const res = await communication.getAgencyById(selectedUserAgencyId);

            const thisYear = parseInt(moment().format('YYYY'));
            const firstYear = parseInt(moment(res.data.data?.date_of_registration).format('YYYY'));
            const yearsArray = range(firstYear, thisYear + 1)
                .reverse()
                .map((y) => {
                    return { label: y, value: y };
                });
            setYearsOptions(yearsArray);
        }
        setIsLoader(false);
    };

    useEffect(() => {
        getAllUsers();
        setIsInfoModalActive(true);
    }, []);

    useEffect(() => {
        getAgency();
        getAllTransferTemplates();
    }, [selectedYear, selectedUserAgencyId]);

    useEffect(() => {
        getQrCodesForPrint();
    }, [transferTemplates]);

    const downloadTaxes = async (): Promise<void> => {
        communication.downloadTaxesAdmin(selectedUser?.id, selectedYear, token, JSON.stringify(qrCodes));
    };

    const downloadTax = async (id: string): Promise<void> => {
        communication.downloadTaxAdmin(id, token);
    };

    const getQrCode = (transfer: ObjectKeys): void => {
        generateQrCode(
            transfer?.orderer,
            transfer?.recipient,
            transfer.total_amount,
            '0',
            transfer.recipient_account,
            transfer.currency,
            false,
            transfer.call_number_recipient,
        ).then((res: ObjectKeys | any) => {
            setQrCode(res?.i);
            setIsQrCode(true);
        });
    };

    const getQrCodesForPrint = (): void => {
        transferTemplates.map(
            (transfer: {
                orderer: ObjectKeys;
                recipient: ObjectKeys;
                total_amount: string;
                recipient_account: string;
                currency: string;
                call_number_recipient: string | null | undefined;
                id: any;
            }) => {
                generateQrCode(
                    transfer?.orderer,
                    transfer?.recipient,
                    transfer.total_amount,
                    '0',
                    transfer.recipient_account,
                    transfer.currency,
                    false,
                    transfer.call_number_recipient,
                ).then((res: ObjectKeys | any) => {
                    setQrCodes((prevState) => ({
                        ...prevState,
                        [transfer?.id]: res?.i ? `data:image/jpeg;base64${res?.i}` : '""',
                    }));
                });
            },
        );
    };

    const sendNewTaxOrder = async (): Promise<void> => {
        setIsLoader(true);
        const res = await communication.createTransferOrderAdmin(selectedUser?.agency_id, data);
        if (res.status === 200) {
            await getAllTransferTemplates();
        }
        setIsConfirmModal(false);
        setIsLoader(false);
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}

            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-modal '}
                >
                    <TransferOrderPreviewModal
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                        isQrCode={isQrCode}
                        downloadTax={downloadTax}
                    />
                </Modal>
            )}
            <Modal
                className={'manage-orders-modal '}
                modalVisible={isConfirmModal}
                closeModal={() => setIsConfirmModal(false)}
            >
                <ConfirmModal action={sendNewTaxOrder} close={() => setIsConfirmModal(false)}>
                    <div className={'info-modal-content'}>
                        <h2>{t('pages.transfers.changing_user_title').text}</h2>
                        <p>
                            {t('pages.transfers.changing_user_name').text} {selectedUser?.first_name}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_last_name').text} {selectedUser?.last_name}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_email').text} {selectedUser?.email}
                        </p>
                        <p>
                            {t('pages.transfers.changing_user_pib').text} {selectedUser?.pib}
                        </p>
                    </div>
                </ConfirmModal>
            </Modal>

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal '}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => setIsManageOrdersModalActive(false)}
                >
                    <ManageOrdersModalAdmin
                        closeModal={() => setIsManageOrdersModalActive(false)}
                        yearsOptions={yearsOptions}
                        setIsConfirmModal={setIsConfirmModal}
                        user={selectedUser}
                        setData={setData}
                        year={selectedYear}
                    />
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <div className={'info-modal-content'}>
                    <h2>{t('pages.transfers.changing_user_title').text}</h2>
                    <p>
                        {t('pages.transfers.changing_user_name').text} {selectedUser?.first_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_last_name').text} {selectedUser?.last_name}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_email').text} {selectedUser?.email}
                    </p>
                    <p>
                        {t('pages.transfers.changing_user_pib').text} {selectedUser?.pib}
                    </p>
                </div>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'100%'}
                    onClick={() => {
                        setIsInfoModalActive(false);
                    }}
                >
                    {t(['buttons.ok']).text}
                </Button>
            </Modal>
            <SelectUser>
                <label>{t('pages.transfers.title').text}</label>
                {selectUsersOptions?.length > 0 && (
                    <SelectComponent
                        className={'select-user'}
                        optionList={selectUsersOptions}
                        defaultSelectedOption={selectUsersOptions[0]}
                        handleSelectedValue={(value: string) => {
                            setSelectedUserAgencyId(value);
                            users.map((user: ObjectKeys) => {
                                user?.agency_id === value && setSelectedUser(user);
                            });

                            setIsInfoModalActive(true);
                        }}
                    />
                )}
            </SelectUser>
            <Header>
                <h1>{t('pages.transfers.title').text}</h1>
                <div className="header-right">
                    <div className="header-options">
                        <div className="header-options-buttons">
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => downloadTaxes()}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPrint} />
                                {t('pages.transfers.print_transfers').text}
                            </Button>
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => setIsManageOrdersModalActive(true)}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                                {t('pages.transfers.manage_transfers').text}
                            </Button>
                        </div>
                    </div>
                </div>
                <Filters>
                    <label>{`${t('pages.transfers.filter_first_description').text} ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions[0]}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => setSelectedYear(value)}
                        />
                    </div>
                </Filters>
            </Header>

            {transferTemplates.length === 0 && (
                <NoItems text={`${t('pages.transfers.no_tax_transfers').text} ${moment().year()}.`} />
            )}

            <TransferOrderTemplateContainer>
                {transferTemplates?.map((template: object, index: number) => (
                    <TransferOrderAdminCard
                        className={isMobileWidth ? 'mobile' : ''}
                        key={index}
                        template={template}
                        onClick={() => {
                            getQrCode(template);
                            setSelectedTransfer(template);
                            setPreviewModal(true);
                        }}
                    />
                ))}
            </TransferOrderTemplateContainer>
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 100vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow: auto;
        }
    }
    .info-modal-content {
        display: block;
        padding: 35px 50px 30px;
        text-align: center;
        min-height: 130px;
        h2 {
            font-size: 20px;
            color: #4c595f;
            font-weight: 400;
            margin-bottom: 30px;
            line-height: 1.5;
        }
        p {
            font-size: 16px;
            margin-top: 5px;
            line-height: 30px;
            white-space: pre-line;
        }
    }
`;

const SelectUser = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    label {
        display: inline-block;
        color: #92a5ac;
        font-size: 16px;
        position: relative;
        top: 2px;
        margin-right: 10px;
    }
    .select-wrapper {
        width: max-content;
        min-width: 257px;
        .select__menu {
            width: 100%;
        }
    }
`;

const TransferOrderTemplateContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    width: 100%;
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
    }
`;

const Filters = styled.div`
    height: 80px;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    @media (max-width: 670px) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: flex;
        float: right;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }

    .search-filter {
        float: right;
        margin-right: 10px;
        right: 0;
        top: 78px;
        width: 200px;
        @media (max-width: 1250px) {
            margin-right: 0;
        }
        @media (max-width: 670px) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
`;

export default TransferOrdersAdmin;
