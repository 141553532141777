import { FunctionComponent, useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { every, isEmpty } from 'lodash';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import Loader from '../../../../components/loader';
import Sidebar from '../../../../components/sideModal';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import ErrorModal from '../../../../components/errorModal';
import ChangeModal from '../../../../components/changeModal';
import InvoiceClients from '../../../../components/invoiceClients';
import AddClientModal from '../../clients/addClientModal';
import PreviewInvoiceModal from '../../einvoices/copyEInvoice/previewInvoiceModal';
import { Invoice } from '../../einvoices/copyEInvoice/style';
import InvoiceEdit from '../../einvoices/copyEInvoice/invoiceEdit';
import MobileFooter from '../../einvoices/copyEInvoice/footer/mobileFooter';
import WebFooter from '../../einvoices/copyEInvoice/footer/webFooter';
import MobileHeader from '../../einvoices/copyEInvoice/header/mobileHeader';
import WebHeader from '../../einvoices/copyEInvoice/header/webHeader';
import EditClientModal from '../../clients/editClientModal';
import getAllProforma from '../getAllProforma';
import SendModal from '../sendModal';
import { countTotalProforma } from '../../einvoices/copyEInvoice/invoiceEdit/countTotal';
import InvoiceForeignEdit from '../../einvoices/copyEInvoice/invoiceForeignEdit';

const NewProforma: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { pathname } = useLocation();
    const { agency } = useAgencyStore();
    const size = useWindowSize();
    const navigate = useNavigate();
    const token = getAccessToken();

    const proformaType = pathname.includes('domestic') ? 'domestic' : 'foreign';

    const [previewModal, setPreviewModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);

    const [qrCode, setQrCode] = useState('');
    const [clickedClient, setClickedClient] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [modalMessage, setModalMessage] = useState('');
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});
    const [clients, setClients] = useState<ObjectKeys>([]);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    const [proformaData, setProformaData] = useState<ObjectKeys>({
        client: { data: {} },
        comment: '',
        currency: proformaType === 'domestic' ? 'RSD' : 'EUR',
        paid_date: '',
        paid_value: false,
        prepaid_invoice_ids: [],
        rate: 1,
        seal_required: false,
        iban: agency.iban ? agency.iban : '',
        swift: agency.swift ? agency.swift : '',
        trading_place: agency.city ? agency.city : '',
    });

    useEffect(() => {
        setProformaData({
            ...proformaData,
            comment:
                proformaType === 'domestic'
                    ? t('pages.editInvoice.previewModal.due').text
                    : 'Payment deadline is 15 days',
        });
    }, [proformaType, currentLang]);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setProformaData((prev: ObjectKeys) => {
                const newProformaData = { ...prev };
                newProformaData.client_id = newCreatedClient && newCreatedClient?.id;
                newProformaData.client.data = newCreatedClient;
                return newProformaData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        const res = await communication.deleteClient(clickedClient);
        if (res.status === 200) setDeleteClientModal(false);
    };

    const handleProformaDelete = async (): Promise<void> => {
        const res = await communication.deleteProforma(proformaData.id);
        if (res.status === 200) {
            setDeleteInvoiceModal(false);
            navigate(-1);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        setProformaData((prev: ObjectKeys) => {
            const newProformaData = { ...prev };
            newProformaData.client_id = client?.id;
            newProformaData.client.data = client;
            return newProformaData;
        });
        agency &&
            proformaData &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                proformaData.value_in_rsd,
                proformaData.quotation_number ? proformaData.quotation_number : '',
                proformaData.first_account_number ? proformaData.first_account_number : agency.first_account_number,
                proformaData.currency ? proformaData.currency : 'RSD',
                true,
            );
    };

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        proformaNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(agencyData.name, clientData, amount, proformaNumber, bankAccount, currency, convert).then(
                (response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                },
            );
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleAddNewProforma = async (): Promise<any> => {
        if (proformaData?.services?.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
            sendModal && setSendModal(false);
        } else if (every(proformaData.services, (s) => s.pivot.quantity === '0' && s.pivot.price_per_unit === '0')) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
            sendModal && setSendModal(false);
        } else if (!proformaData.client_id) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingClient').text);
            sendModal && setSendModal(false);
        } else {
            try {
                setLoading(true);
                const newProformaData = { ...proformaData };

                newProformaData.services = newProformaData.services.map((service: ObjectKeys, i: number) => ({
                    index: i,
                    id: service.id,
                    measurement_unit:
                        service.pivot.measurement_unit === 'kilo' ? 'kilogram' : service.pivot.measurement_unit,
                    quantity: service.pivot.quantity.replace(',', '.'),
                    price_per_unit: service.pivot.price_per_unit.replace(',', '.'),
                }));
                newProformaData.trading_date = moment(newProformaData.trading_date).format('YYYY-MM-DD').toString();
                newProformaData.rate = Number(newProformaData.rate);
                newProformaData.paid_date = moment(
                    newProformaData.paid_date ? newProformaData.paid_date : new Date('1970-01-01'),
                )
                    .format('YYYY-MM-DD')
                    .toString();
                newProformaData.quotation_date = moment(newProformaData.invoice_date).format('YYYY-MM-DD').toString();
                newProformaData.custom_number = false;
                newProformaData.is_custom = false;
                newProformaData.custom_text = false;
                newProformaData.invoice_per_owner = true;
                newProformaData.auto_increment = true;
                newProformaData.cancel_number = false;
                newProformaData.cancelled = false;
                newProformaData.currency = proformaData.currency;
                newProformaData.status = 'open';
                newProformaData.user_id = agency?.user_id;
                newProformaData.trading_place = agency?.city;
                newProformaData.value = Number(countTotalProforma(newProformaData.services).replace(',', '.'));
                newProformaData.client_id = proformaData?.client?.data?.id;
                delete newProformaData.total_for_payment_in_rsd, newProformaData.total_for_payment;
                if (proformaType === 'domestic') {
                    newProformaData.bank_account = agency.first_account_number;
                } else {
                    newProformaData.swift = agency?.swift;
                    newProformaData.iban = agency?.iban;
                }

                const res = await communication.newProforma(newProformaData, proformaType);
                if (res) {
                    setLoading(false);
                    getAllProforma(setProformaData);
                    navigate(-1);
                    return res?.data?.data?.id;
                }
            } catch (e: any) {
                setErrorMessage(e.response.data.errors ? e.response.data.errors.client_id[0] : e.response.data.message);
                setOpenErrorModal(true);
                setLoading(false);
            }
        }
    };

    //Proforma Download
    const handleDownloadProforma = (): void => {
        generateQrCode(
            agency.name,
            proformaData.client ? proformaData.client.data : {},
            proformaData.value_in_rsd ? proformaData.value_in_rsd.toString() : '0',
            proformaData.quotation_number ? proformaData.quotation_number : '',
            proformaData.bank_account,
            proformaData.currency,
            proformaData.value ? false : true,
        )
            .then(async (res: ObjectKeys | any) => {
                if (res) {
                    const newCreatedProformaId = await handleAddNewProforma();
                    if (newCreatedProformaId) {
                        await communication.downloadProforma(
                            newCreatedProformaId,
                            token,
                            proformaData.quotation_number,
                            proformaData.currency !== 'RSD',
                        );
                    }
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    console.error(error);
                }
            });
    };

    return (
        <>
            {loading && <Loader />}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={proformaType === 'domestic' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={0}
                        setNewCreatedClient={setNewCreatedClient}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleClientDelete}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={t('pages.editInvoice.deleteClient').text}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleProformaDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.newProforma.deleteProforma').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={proformaData.id}
                        success={() => {
                            setSuccess(true);
                            navigate(-1);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={''}
                        setProforma={setProformaData}
                        handleAddNewProforma={handleAddNewProforma}
                        qrCode={qrCode}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    <PreviewInvoiceModal
                        agency={agency}
                        invoice={proformaData}
                        title="proforma"
                        note={proformaData.comment}
                    />
                </Modal>
            )}
            <PageWrapper className="new-proforma page">
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        newInvoice={true}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleAddNewProforma}
                        invoice={proformaData}
                        newInvoice={true}
                        title={'proforma'}
                        handleDownloadInvoice={handleDownloadProforma}
                    />
                )}
                <InvoiceClients
                    t={t}
                    setClients={setClients}
                    clients={clients}
                    setAddClientModal={setAddClientModal}
                    agency={agency}
                    handleSelectedClient={handleSelectedClient}
                    addClientModal={addClientModal}
                    deleteClientModal={deleteClientModal}
                    editClientModal={editClientModal}
                    setClickedClient={setClickedClient}
                    setEditClientModal={setEditClientModal}
                    setDeleteClientModal={setDeleteClientModal}
                    invoice={proformaData}
                    type={pathname.includes('foreign') ? 'foreign' : pathname.includes('domestic') ? 'domestic' : ''}
                    loading={(isLoading: boolean) => setLoading(isLoading)}
                />
                {agency && proformaData && (
                    <Invoice>
                        {pathname.includes('domestic') ? (
                            <InvoiceEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobileWidth={isMobileWidth}
                                newInvoice={true}
                                type={proformaType}
                                setLoading={setLoading}
                                qrCode={qrCode}
                            />
                        ) : (
                            <InvoiceForeignEdit
                                name="proforma"
                                formData={proformaData}
                                setFormData={setProformaData}
                                agency={agency}
                                invoice={proformaData}
                                handleTotal={(sum: ObjectKeys) => {
                                    setProformaData({
                                        ...proformaData,
                                        value: sum,
                                        value_in_rsd: sum,
                                        total_for_payment: sum,
                                        total_for_payment_in_rsd: sum,
                                    });
                                }}
                                isMobileWidth={isMobileWidth}
                                newInvoice={true}
                                type={proformaType}
                                setLoading={setLoading}
                                qrCode={qrCode}
                            />
                        )}
                    </Invoice>
                )}
                {isMobileWidth ? (
                    <MobileFooter
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        handleDownloadInvoice={handleDownloadProforma}
                        invoice={proformaData}
                    />
                ) : (
                    <WebFooter
                        t={t}
                        handleUpdateInvoice={handleAddNewProforma}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        handleDownloadInvoice={handleDownloadProforma}
                        invoice={proformaData}
                    />
                )}
            </PageWrapper>
        </>
    );
};
export default NewProforma;
const PageWrapper = styled.div``;
