import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../hooks/useTranslation';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import useUserStore from '../../store/user';
import useChangeModalStore from '../../store/changeModal';
import CityPhoto from '../../assets/city.png';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import SelectComponent from '../../components/select';
import useWindowSize from '../../hooks/useResize';
import Modal from '../../components/modal';
import ChangeModal from '../../components/changeModal';
import Success from '../../components/successModal';

interface Props {
    saveChanges?: Function;
}

const MyAgency: FunctionComponent<Props> = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const { updateUser, userInfo } = useUserStore();
    const { updateAgency, profile } = useProfileStore();

    const { pathname } = useLocation();
    const navigate = useNavigate();
    const navigation = useMemo(
        () => [
            { label: t('pages.agency.navbar.profile').text, link: '/agency/profile', value: '/agency/profile' },
            { label: t('pages.agency.navbar.personal').text, link: '/agency/user', value: '/agency/user' },
            { label: t('pages.agency.navbar.tax').text, link: '/agency/tax-solution', value: '/agency/tax-solution' },
            {
                label: t('pages.agency.navbar.paymentInstr').text,
                link: '/agency/payment-instructions',
                value: '/agency/payment-instructions',
            },
            { label: t('pages.agency.navbar.sendInfo').text, link: '/agency/send-data', value: '/agency/send-data' },
            {
                label: t('pages.agency.navbar.notifications').text,
                link: '/agency/notifications',
                value: '/agency/notifications',
            },
            {
                label: t('pages.agency.navbar.changePassword').text,
                link: '/agency/change-password',
                value: '/agency/change-password',
            },
            { label: t('pages.agency.navbar.addLogo').text, link: '/agency/add-logo', value: '/agency/add-logo' },
            { label: t('pages.agency.navbar.documents').text, link: '/agency/documents', value: '/agency/documents' },
            { label: t('pages.agency.navbar.einvoices').text, link: '/agency/e-invoices', value: '/agency/e-invoices' },
        ],
        [t],
    );

    const [selected, setSelected] = useState(navigation.findIndex((nav) => pathname.includes(nav.link)) || 0);
    const [changeVisible, setChangeVisible] = useState(useChangeModalStore.getState().changeModalVisible);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [successfullySaved, setSuccessfullySaved] = useState(false);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
        setChangeVisible(useChangeModalStore.getState().changeModalVisible);
    }, [size.width, selected]);

    const handleSave = (): void => {
        if (useChangeModalStore.getState().source === 'profile') {
            profile && updateAgency(profile.id, profile);
        } else if (useChangeModalStore.getState().source === 'personalInfo') {
            userInfo && updateUser(userInfo?.id, userInfo);
            useUserStore.setState({ userInfo: userInfo });
        }
        setChangeVisible(false);
        setSuccessfullySaved(true);
    };

    return (
        <Container>
            {changeVisible && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setChangeVisible(false);
                        useChangeModalStore.setState({ changeModalVisible: false });
                    }}
                >
                    <ChangeModal
                        saveChanges={handleSave}
                        close={() => {
                            setChangeVisible(false);
                        }}
                        message={t('pages.agency.profile.areYouSure').text}
                    />
                </Modal>
            )}
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            <HeaderContainer>
                <Header />
            </HeaderContainer>

            <Inner>
                <h2>{navigation[navigation.findIndex((nav) => pathname.includes(nav.link))].label}</h2>
                {!isMobileWidth && (
                    <Navigation>
                        {navigation?.map((i, index) => (
                            <Link
                                key={index}
                                onClick={() => {
                                    if (!changeVisible) setSelected(index);
                                }}
                                className={
                                    index === navigation.findIndex((nav) => pathname.includes(nav.link))
                                        ? 'selected'
                                        : ''
                                }
                                to={i.link}
                            >
                                <div>{i.label?.toUpperCase()}</div>
                            </Link>
                        ))}
                    </Navigation>
                )}

                {isMobileWidth && (
                    <SelectContainer>
                        <SelectComponent
                            handleSelectedValue={(v: string) => {
                                if (!changeVisible) {
                                    const ind = navigation.findIndex((x) => x.link === v);
                                    navigate(v);
                                    setSelected(ind);
                                }
                            }}
                            optionList={navigation}
                            width="100%"
                            defaultSelectedOption={navigation[selected]}
                            isSearchable={false}
                        />
                    </SelectContainer>
                )}

                <Outlet />
            </Inner>
        </Container>
    );
};
export default MyAgency;

const Container = styled.div`
    background: var(--white);
    @media screen and (max-width: 768px) {
        background: var(--body-gray);
    }
    .select__control {
        background: var(--light-gray);
    }
`;

const HeaderContainer = styled.div`
    background: var(--body-gray);

    @media only screen and (max-width: 1079px) {
        margin-top: 100px;
    }
    @media screen and (max-width: 768px) {
        margin-top: 0px;
    }
`;

const Header = styled.div`
    height: 160px;
    background: url(${CityPhoto}) bottom no-repeat;
    background-size: 100% auto;
    border-bottom: 2px solid var(--border-color);
    position: relative;
    text-align: center;
    @media screen and (max-width: 768px) {
        height: 60px;
    }
`;

const Inner = styled.div`
    height: 100%;
    max-width: 1800px;
    margin: 0 auto;

    padding: 20px 50px 50px;
    clear: both;
    overflow: hidden;

    justify-content: center;
    align-items: center;

    /* position: relative; */
    h2 {
        text-align: center;
        margin-bottom: 30px;
        font-size: 32px;
    }
    //Media Queries
    @media only screen and (max-width: 1000px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: 425px) {
        padding: 5% 0px;
    }
`;

const Navigation = styled.div`
    width: 25%;
    height: 100%;
    float: left;
    color: var(--black);
    padding-right: 60px;

    a {
        font-size: 14px;
        display: block;
        padding: 15px;
        color: var(--black);
        &.selected {
            border-left: 3px solid var(--purple);
            background: var(--body-gray);
        }
    }

    @media screen and (max-width: 1580px) {
        width: 39%;
    }
`;

const SelectContainer = styled.div`
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    > div {
        background: var(--white);
    }
    text-transform: uppercase;
`;
