import { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import colors from '../../global/colors';
import useTranslations from '../../hooks/useTranslation';
import useEmailValidation from '../../hooks/useEmailValidation';
import useLanguageStore from '../../store/language';
import communication from '../../communication';
import Input, { InputComponentType } from '../../components/input';
import Button, { ButtonVariant } from '../../components/button';
import CityPhoto from '../../assets/city.png';
import Logo from '../../assets/logo.png';
import ErrorModal from '../../components/errorModal';
import Modal from '../../components/modal';

const ForgotPassword: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [touchedInput, setTouchedInput] = useState(false);
    const isEmailValid = useEmailValidation(email);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const reset = async (): Promise<void> => {
        setLoading(true);
        if (isEmailValid === '') {
            try {
                const res = await communication.sendResetPassword({ email });
                if (res.status === 200 || res.status === 204) setSuccess(true);
            } catch (error: any) {
                setErrorMessage(
                    currentLangName !== 'English' ? t('pages.forgotPassword.error').text : error.response.data.message,
                );
                setOpenErrorModal(true);
            }
        }
        setLoading(false);
    };
    return (
        <>
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            <PageWrapper className="login page">
                <LoginInner>
                    <RightColumn>
                        <div className="content">
                            <div className="top-block">
                                <h4>{t('pages.forgotPassword.title').text}</h4>
                                <img src={Logo} alt="logo" />
                            </div>

                            {success ? (
                                <div className="success">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.green}
                                        className="big"
                                        size={'100%'}
                                        isLoading={loading}
                                        textTransformNone
                                    >
                                        {t('pages.forgotPassword.success').text}
                                    </Button>
                                </div>
                            ) : (
                                <div className="form">
                                    <p>{t('pages.forgotPassword.subtitle').text}</p>

                                    <Input
                                        type={InputComponentType.Text}
                                        label={t('pages.login.rightColumn.form.email').text}
                                        validation={touchedInput ? isEmailValid : ''}
                                        value={email}
                                        onChange={(value: string) => {
                                            setEmail(value);
                                        }}
                                        blurHandler={() => setTouchedInput(true)}
                                    />

                                    <div className="reset-btn">
                                        <Button
                                            variant={ButtonVariant.solid}
                                            color={colors.purple}
                                            className="big"
                                            size={200}
                                            isLoading={loading}
                                            onClick={reset}
                                        >
                                            {t('pages.forgotPassword.button').text}
                                        </Button>
                                    </div>
                                </div>
                            )}

                            <div className="bottom-block">
                                <span>{t('pages.forgotPassword.back').text}</span>
                                <span onClick={() => navigate('/login')}>{t('pages.forgotPassword.login').text}</span>
                            </div>
                        </div>
                    </RightColumn>
                </LoginInner>
            </PageWrapper>
        </>
    );
};
export default ForgotPassword;

const PageWrapper = styled.div`
    background: var(--purple);
`;
const LoginInner = styled.div`
    display: flex;
    height: 100%;
    width: fit-content;
    max-width: 1800px;
    margin: 0 auto;
    padding: 10% 0px 0 0px;
    //Media Queries
    @media only screen and (max-width: 1000px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: 425px) {
        padding: 5% 20px 0 20px;
    }
`;

const RightColumn = styled.div`
    background: var(--purple);
    flex: 2 1 40%;
    width: 500px;

    p {
        color: var(--gray);
        margin-bottom: 20px;
    }

    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: 500px;
        //Media Queries
        @media only screen and (max-width: 1000px) {
            margin: 0 auto;
        }
        .success {
            padding: 40px;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 180px;
            padding: 55px 45px 20px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
                height: 130px;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
            }
            .input-container {
                width: 100%;
            }
            .reset-btn {
                display: flex;
                justify-content: center;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 30px 45px;
            display: flex;
            align-items: center;
            justify-content: center;
            //Media Queries
            @media only screen and (max-width: 500px) {
                padding: 20px 30px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                span {
                    margin-bottom: 10px;
                }
            }
            span {
                color: var(--gray);
                :nth-of-type(2) {
                    color: var(--purple);
                    margin-left: 5px;
                    :hover {
                        cursor: pointer;
                    }
                }
            }
        }
    }
`;
