import { AxiosRequestConfig } from 'axios';
import fetch from './fetch';
import Auth from './auth';
import Currency from './currency';
import Years from './years';
import User from './user';
import ForgotPassword from './forgotPassword';
import Agency from './agency';
import Proforma from './proforma';
import Suggestion from './suggestion';
import Memorandums from './memorandums';
import Invoices from './invoices';
import Clients from './clients';
import HealthCards from './healthCards';
import AccountExpiration from './accountExpiration';
import Transfers from './transfers';
import PaymentAccounts from './paymentAccounts';
import Kpo from './kpo';
import Service from './services';
import Users from './admin/users';
import InvoicesAdmin from './admin/invoices';
import ProformaAdmin from './admin/proforma';
import TransactionsAdmin from './admin/transactions';
import Suggestions from './admin/suggestions';
import Notifications from './admin/notifications';
import Statistics from './admin/statistics';
import AdminTaxSolutions from './admin/tax';
import Reports from './reports';

const apiUrl = process.env.REACT_APP_API_URL;

const applyMixins = (derivedCtor: any, constructors: any[]): void => {
    constructors.forEach((baseCtor) => {
        Object.getOwnPropertyNames(baseCtor.prototype).forEach((name) => {
            Object.defineProperty(
                derivedCtor.prototype,
                name,
                Object.getOwnPropertyDescriptor(baseCtor.prototype, name) || Object.create(null),
            );
        });
    });
};

class Communication {
    public send(options: AxiosRequestConfig): any {
        options.url = `${apiUrl}${options.url}`;
        return fetch(options);
    }
}

/**
 * The new interface is defined with the exact same name as the class Communication.
 * This is crucial because this interface is extending all communication classes.
 * This means the interfaces will merge their method definition into a single construct (the interface),
 * while at the same time merging into the class definition with the same name.
 *
 * Due to declaration merging, the Communication class will be merged with the Communication interface.
 */
interface Communication
    extends Auth,
        User,
        Currency,
        Years,
        ForgotPassword,
        Invoices,
        Clients,
        Proforma,
        Agency,
        Suggestion,
        HealthCards,
        AccountExpiration,
        Memorandums,
        Transfers,
        PaymentAccounts,
        Memorandums,
        Service,
        Kpo,
        Users,
        InvoicesAdmin,
        ProformaAdmin,
        Suggestions,
        TransactionsAdmin,
        Notifications,
        AdminTaxSolutions,
        Reports,
        Statistics,
        AdminTaxSolutions {}

applyMixins(Communication, [
    Auth,
    User,
    Currency,
    Years,
    ForgotPassword,
    Invoices,
    Clients,
    Proforma,
    Agency,
    Suggestion,
    HealthCards,
    AccountExpiration,
    Kpo,
    Memorandums,
    Transfers,
    PaymentAccounts,
    Service,
    Users,
    InvoicesAdmin,
    ProformaAdmin,
    Suggestions,
    TransactionsAdmin,
    Notifications,
    Statistics,
    AdminTaxSolutions,
    Reports,
]);

export default new Communication();
