import { ObjectKeys } from '@/types/objectKeys';
import { AxiosError } from 'axios';

export const generateQrCode = async (
    agency: ObjectKeys,
    client: ObjectKeys,
    totalInvoice: string,
    invoiceNumber: string,
    account: string,
    currency: string,
    convert = false,
    callNumber: string | null = null,
): Promise<void> => {
    try {
        const payload = {
            account: account ? account.replaceAll('-', '') : '',
            agency_name: agency.slice(0, 70).trimEnd(),
            total: convert
                ? parseFloat(totalInvoice).toFixed(2).replace('.', ',')
                : totalInvoice === '0'
                ? '0,00'
                : parseFloat(totalInvoice.replace('.', '').replace(',', '.')).toFixed(2).replaceAll('.', ','),
            client_name: client,
            currency: currency.toUpperCase(),
            invoiceNumber: invoiceNumber,
            callNumber: callNumber,
        };
        const request = `K:PR|V:01|C:1|R:${payload.account}|N:${
            payload.agency_name
        }|S:${'Promet robe i usluga - finalna potr.'.slice(0, 35)}|I:${payload.currency}${payload.total}|SF:221|P:${
            payload.client_name
        }${(payload.callNumber && `|RO:${payload.callNumber}`) || ''}`;
        const response = await fetch('https://nbs.rs/QRcode/api/qr/generate/', {
            method: 'POST',
            mode: 'cors',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json',
            },
            body: request,
        });
        return response.json();
    } catch (e) {
        throw e as AxiosError;
    }
};
