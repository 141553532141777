import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useUserStore from '../../../store/user';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import Input, { InputComponentType } from '../../../components/input';
import { ChangePasswordType } from '../../../types/changePassword';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import useConfirmPasswordValidation from '../../../hooks/useConfirmPasswordValidation';
import communication from '../../../communication';
import Modal from '../../../components/modal';
import ErrorModal from '../../../components/errorModal';
import Success from '../../../components/successModal';

const ChangePassword: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [openSuccessModal, setOpenSuccessModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const { userInfo } = useUserStore();

    const [formData, setFormData] = useState<ChangePasswordType>({
        oldPassword: '',
        newPassword: '',
        newPasswordRepeated: '',
    });
    const [clickedField, setClickedField] = useState('');

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const isOldPasswordValid = usePasswordValidation(formData.oldPassword);
    const isNewPasswordValid = usePasswordValidation(formData.newPassword);
    const isNewPasswordRepeatValid = useConfirmPasswordValidation(formData.newPassword, formData.newPasswordRepeated);

    const changePassword = async (): Promise<void> => {
        try {
            if (userInfo) {
                const res =
                    userInfo &&
                    (await communication.changePassword({
                        email: userInfo?.email,
                        old_password: formData.oldPassword,
                        new_password: formData.newPassword,
                        confirm: formData.newPasswordRepeated,
                    }));
                if (res?.status === 200) setOpenSuccessModal(true);
            } else {
                const userData = await communication.getProfile();
                const res = await communication.changePassword({
                    email: userData.data.data.email,
                    old_password: formData.oldPassword,
                    new_password: formData.newPassword,
                    confirm: formData.newPasswordRepeated,
                });
                if (res?.status === 200) setOpenSuccessModal(true);
            }
        } catch (err: any) {
            setErrorMessage(
                currentLangName === 'English' ? t('pages.agency.changePassword.error').text : err.response.data.message,
            );
            setOpenErrorModal(true);
        }
    };

    return (
        <>
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {openSuccessModal && (
                <Modal modalVisible={true} closeModal={() => setOpenSuccessModal(false)}>
                    <Success
                        close={() => setOpenSuccessModal(false)}
                        message={t('pages.agency.changePassword.success').text}
                    />
                </Modal>
            )}
            <Content>
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.oldPassword').text}
                    value={formData?.oldPassword}
                    onChange={(value: string) => {
                        setFormData({ ...formData, oldPassword: value });
                    }}
                    validation={clickedField === 'oldPassword' ? isOldPasswordValid : ''}
                    blurHandler={() => handleValidation('oldPassword')}
                />
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.newPassword').text}
                    value={formData?.newPassword}
                    onChange={(value: string) => {
                        setFormData({ ...formData, newPassword: value });
                    }}
                    validation={clickedField === 'newPassword' ? isNewPasswordValid : ''}
                    blurHandler={() => handleValidation('newPassword')}
                />
                <Input
                    type={InputComponentType.Password}
                    label={t('pages.agency.changePassword.newPasswordAgain').text}
                    value={formData?.newPasswordRepeated}
                    onChange={(value: string) => {
                        setFormData({ ...formData, newPasswordRepeated: value });
                    }}
                    validation={clickedField === 'newPasswordRepeated' ? isNewPasswordRepeatValid : ''}
                    blurHandler={() => handleValidation('newPasswordRepeated')}
                />

                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="big"
                        size={200}
                        onClick={changePassword}
                    >
                        {t('pages.agency.changePassword.change').text}
                    </Button>
                </div>
            </Content>
        </>
    );
};
export default ChangePassword;

const Content = styled.div`
    width: 56%;
    display: inline-block;
    vertical-align: top;

    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 50px;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .input-container {
        width: 100%;
        label {
            color: var(--gray);
        }
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }

    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: 768px) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }
`;
