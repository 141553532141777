import { Dispatch, FunctionComponent, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useEmailValidation from '../../../../hooks/useEmailValidation';
import useVatNoValidation from '../../../../hooks/useVatNoValidation';
import useNumLengthValidation from '../../../../hooks/useNumLengthValidation';
import useCurrencyStore from '../../../../store/currency';
import useLanguageStore from '../../../../store/language';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import SelectComponent from '../../../../components/select';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllClients from '../getAllClients';
type Props = {
    t: Function;
    clickedClient: string;
    userId: string;
    setEditClientModal: Dispatch<SetStateAction<boolean>>;
    setClients: Dispatch<SetStateAction<ObjectKeys[]>>;
    tabIndex: number;
};

const EditClientModal: FunctionComponent<Props> = ({
    t,
    clickedClient,
    userId,
    setEditClientModal,
    setClients,
    tabIndex,
}) => {
    const { currentLang } = useLanguageStore();
    const { currency } = useCurrencyStore();
    const { addToast } = useToasts();
    const [formData, setFormData] = useState<any>({
        name: '',
        taxNumber: '',
        identificationNumber: '',
        phoneNumber: '',
        email: '',
        address: '',
        city: '',
        state: '',
        clientCategory: '',
        personalId: null,
        vatNumber: '',
        jbkjs: null,
        currency: '',
    });
    const [currencies, setCurrencies] = useState<Array<{ value: string; label: string }>>([]);
    const [clickedField, setClickedField] = useState('');
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [clientInfo, setClientInfo] = useState<any>({});

    const isNameValid = useCharLengthValidation(formData.name, 3);
    const isEmailValid = useEmailValidation(formData.email);
    const isAddressValid = useCharLengthValidation(formData.address, 3);
    const isCityValid = useCharLengthValidation(formData.city, 3);
    const isStateValid = useCharLengthValidation(formData.state, 3);
    const isVatNoValid = useVatNoValidation(formData.vatNumber);
    const isIdentificationNumValid = useNumLengthValidation(formData.identificationNumber, 8);
    const isJbkjsValid = useNumLengthValidation(formData.identificationNumber, 5);
    const isPersonalIdValid = useNumLengthValidation(formData.personalId, 13);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    useEffect(() => {
        if (clickedClient) {
            communication.getClient(clickedClient).then((res: ObjectKeys) => {
                if (res) {
                    setClientInfo(res?.data?.data);
                    const clientData = res?.data?.data;
                    setFormData({
                        name: clientData?.company_name,
                        taxNumber: clientData?.pib,
                        identificationNumber: clientData?.identification_number,
                        phoneNumber: clientData?.phone,
                        email: clientData?.email,
                        address: clientData?.address,
                        city: clientData?.city,
                        state: clientData?.country,
                        personalId: clientData?.jmbg,
                        vatNumber: clientData?.pib,
                        jbkjs: clientData?.public_company_id,
                        currency:
                            clientData?.currency !== null
                                ? {
                                      value: clientData?.currency,
                                      label: clientData?.currency,
                                  }
                                : null,
                    });
                }
            });
        }
    }, [clickedClient]);

    const changeClient = (): void => {
        communication
            .editClient(clickedClient, {
                address: formData.address,
                city: formData.city,
                company_name: formData.name,
                country: clientInfo?.is_foreign === 1 ? formData?.state : 'RSD',
                currency: clientInfo?.is_foreign === 1 ? formData?.currency : 'RSD',
                email: formData.email,
                identification_number: formData.identificationNumber,
                is_foreign: clientInfo?.is_foreign ? true : false,
                jmbg: formData.personalId,
                phone: formData.phoneNumber,
                pib: clientInfo?.is_foreign === 1 ? formData.taxNumber : formData.vatNumber,
                public_company_id: formData.jbkjs,
                type: clientInfo?.is_foreign === 1 ? null : formData.clientCategory,
                user_id: userId,
                created_at: clientInfo.created_at,
                deleted_at: null,
                object: clientInfo.object,
                updated_at: new Date(),
            })
            .then((res: ObjectKeys) => {
                if (res) {
                    setShowValidationMessage(false);
                    setEditClientModal(false);
                }
            })
            .then(() => {
                getAllClients(setClients, userId, tabIndex);
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    addToast(t('pages.tryAgain.message').text, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            });
    };

    return (
        <EditClientModalWrapper>
            <ModalText>
                <h4>
                    {t('pages.clients.editClientModal.title').text}{' '}
                    <FontAwesomeIcon
                        icon={faXmark}
                        style={{ color: 'var(--gray)' }}
                        onClick={() => setEditClientModal(false)}
                    />
                </h4>
                <p>{t('pages.clients.editClientModal.description').text}</p>
            </ModalText>
            <Form>
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.name').text}
                    validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                    value={formData.name}
                    blurHandler={() => handleValidation('name')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, name: value });
                    }}
                />
                {clientInfo?.is_foreign === 0 && (
                    <>
                        {clientInfo?.type !== 'civil' ? (
                            <Input
                                type={InputComponentType.Number}
                                label={t('pages.clients.modal.form.domestic.legal.vatNumber').text}
                                validation={showValidationMessage || clickedField === 'vatNumber' ? isVatNoValid : ''}
                                blurHandler={() => handleValidation('vatNumber')}
                                value={formData.vatNumber}
                                onChange={(value: string) => {
                                    setFormData({ ...formData, vatNumber: value });
                                }}
                                maxLength={9}
                            />
                        ) : (
                            <Input
                                type={InputComponentType.Number}
                                label={t('pages.clients.modal.form.domestic.civil.personalId').text}
                                validation={
                                    showValidationMessage || clickedField === 'personalId' ? isPersonalIdValid : ''
                                }
                                blurHandler={() => handleValidation('personalId')}
                                value={formData.personalId}
                                onChange={(value: string) => {
                                    setFormData({ ...formData, personalId: value });
                                }}
                                maxLength={13}
                            />
                        )}
                    </>
                )}
                {clientInfo?.is_foreign === 1 && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.taxNumber').text}
                            value={formData.taxNumber}
                            blurHandler={() => handleValidation('taxNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, taxNumber: value });
                            }}
                            maxLength={9}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                            value={formData.identificationNumber}
                            blurHandler={() => handleValidation('identificationNumber')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, identificationNumber: value });
                            }}
                            maxLength={9}
                        />
                    </>
                )}
                {clientInfo?.type !== 'civil' && clientInfo?.is_foreign === 0 && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.legal.identificationNumber').text}
                        validation={
                            showValidationMessage || clickedField === 'identificationNumber'
                                ? isIdentificationNumValid
                                : ''
                        }
                        blurHandler={() => handleValidation('identificationNumber')}
                        value={formData.identificationNumber}
                        onChange={(value: string) => {
                            setFormData({ ...formData, identificationNumber: value });
                        }}
                        maxLength={9}
                    />
                )}
                {clientInfo?.type === 'public' && (
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.clients.modal.form.domestic.public.jbkjs').text}
                        validation={showValidationMessage || clickedField === 'jbkjs' ? isJbkjsValid : ''}
                        blurHandler={() => handleValidation('jbkjs')}
                        value={formData.jbkjs}
                        onChange={(value: string) => {
                            setFormData({ ...formData, jbkjs: value });
                        }}
                        maxLength={9}
                    />
                )}
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.phoneNumber').text}
                    value={formData.phoneNumber}
                    blurHandler={() => handleValidation('phoneNumber')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, phoneNumber: value });
                    }}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.email').text}
                    validation={clickedField === 'email' && formData?.email?.length > 1 ? isEmailValid : ''}
                    value={formData.email}
                    blurHandler={() => handleValidation('email')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, email: value });
                    }}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.address').text}
                    value={formData.address}
                    validation={showValidationMessage || clickedField === 'address' ? isAddressValid : ''}
                    blurHandler={() => handleValidation('address')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, address: value });
                    }}
                />
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.clients.modal.form.city').text}
                    value={formData.city}
                    validation={showValidationMessage || clickedField === 'city' ? isCityValid : ''}
                    blurHandler={() => handleValidation('city')}
                    onChange={(value: string) => {
                        setFormData({ ...formData, city: value });
                    }}
                />
                {clientInfo?.is_foreign === 1 && (
                    <>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.clients.modal.form.foreign.state').text}
                            value={formData.state}
                            validation={showValidationMessage || clickedField === 'state' ? isStateValid : ''}
                            blurHandler={() => handleValidation('state')}
                            onChange={(value: string) => {
                                setFormData({ ...formData, state: value });
                            }}
                        />
                        <SelectComponent
                            className="select-container currency-select"
                            optionList={currencies}
                            value={formData.currency}
                            label={t('pages.clients.modal.form.foreign.currency').text}
                            handleSelectedValue={(value: string) => {
                                setFormData({ ...formData, currency: value });
                            }}
                            defaultSelectedOption={currencies[0]}
                        />
                    </>
                )}
            </Form>
            <ButtonWrapper>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    size={'130px'}
                    onClick={() => {
                        changeClient();
                    }}
                >
                    {t('buttons.save').text}
                </Button>
            </ButtonWrapper>
        </EditClientModalWrapper>
    );
};

const EditClientModalWrapper = styled.div`
    .select-wrapper {
        width: 100%;
        margin-bottom: 20px;
        label {
            font-size: 15px;
            color: var(--gray);
            margin-bottom: 2px;
            display: flex;
        }
        .select__single-value,
        .select__placeholder,
        .select__input-container {
            color: var(--black);
        }
        .currency-select {
            display: flex;
            align-items: center;
            .select__single-value,
            .select__placeholder,
            .select__input-container {
                text-transform: uppercase;
                font-size: 14px;
            }
            div {
                .select__option {
                    text-transform: uppercase;
                }
            }
        }
        div {
            font-size: 15px;
            color: var(--gray);
            .select__menu-list {
                .select__option {
                    &:hover {
                        color: var(--white);
                        background: var(--purple);
                    }
                    &.select__option--is-selected {
                        color: var(--white);
                        background: var(--purple);
                        border-radius: 0;
                    }
                }
            }
        }
    }
`;

const ModalText = styled.div`
    h4 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        svg {
            font-size: 30px;
        }
    }
    p {
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
    }
`;
const Form = styled.div`
    margin-top: 30px;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
`;
export default EditClientModal;
