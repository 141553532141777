import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import Input, { InputComponentType } from '../../../components/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../../components/select';
import communication from '../../../communication';
import { getAccessToken } from '../../../functions/auth';
import useUserProfileStore from '../../../store/profile';
import SelectSearch from '../../../components/selectSearch';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useWindowSize from '../../../hooks/useResize';
// import { saveAs } from 'file-saver';

interface Props {
    onFilterChange: Function;
}
interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const ReportFilters: FunctionComponent<Props> = ({ onFilterChange }) => {
    const [clients, setClients] = useState<Array<{ value: string; label: string }>>([]);
    const token = getAccessToken();
    const user = useUserProfileStore.getState();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    const [filters, setFilters] = useState<FilterProps>({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
        type: 'total',
    });
    const searchTypes = [
        { value: 'total', label: 'Ukupni prihodi' },
        { value: 'per_client', label: 'Prihodi po komitetu' },
    ];

    const currencyTypes = [
        { value: '', label: 'SVE' },
        { value: 'rsd', label: 'RSD' },
        { value: 'eur', label: 'EUR' },
        { value: 'usd', label: 'USD' },
        { value: 'chf', label: 'CHF' },
        { value: 'gbp', label: 'GBP' },
        { value: 'aud', label: 'AUD' },
        { value: 'cad', label: 'CAD' },
        { value: 'nok', label: 'NOK' },
        { value: 'rub', label: 'RUB' },
    ];

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        setClients([{ value: 'all', label: 'All' }]);
        communication.getAllClientsForStatistic(user?.profile.id).then((response: any) => {
            response.data.data.map((client: any) => {
                setClients((prevState) => [...prevState, { value: client.id, label: client.company_name }]);
            });
        });
    }, []);

    useEffect(() => {
        onFilterChange(filters);
    }, [filters]);

    // const downloadPDF = (pdf: string): void => {
    //     const linkSource = `data:application/pdf;base64,${pdf}`;
    //     const downloadLink = document.createElement('a');
    //     const fileName = 'file.pdf';
    //
    //     downloadLink.href = linkSource;
    //     downloadLink.download = fileName;
    //     downloadLink.click();
    // };
    //
    // const convertPDFStreamToPDF = async (data: any): Promise<void> => {
    //     const enc = new TextEncoder();
    //
    //     const buffer = await enc.encode(data);
    //     // 4.
    //     const byteArray = new Uint8Array(buffer);
    //     // 5.
    //     const charArray = Array.from(byteArray, (byte) => String.fromCharCode(byte));
    //     // 6.
    //     const binaryString = charArray.join('');
    //     // 7.
    //     const theImage = btoa(binaryString);
    //     downloadPDF(theImage);
    // };

    return (
        <FiltersWrapper>
            <article className={'filter-field'}>
                <div className={'statistics-form-container'}>
                    {isMobileWidth ? (
                        <Row>
                            <div>
                                <div className={'label-text large'}>{t('pages.reports.period_from').text}</div>
                                <Input
                                    type={InputComponentType.Date}
                                    className={'datepicker'}
                                    date={!filters.start_date ? null : moment(filters.start_date).toDate()}
                                    onChange={(value: Date | undefined | null) => {
                                        setFilters((prevState) => ({
                                            ...prevState,
                                            start_date: moment(value).format('YYYY-MM-DD'),
                                        }));
                                    }}
                                />
                            </div>
                            <div style={{ marginTop: '12px' }}>
                                <div className={'label-text '}>{t('pages.reports.period_to').text}</div>
                                <Input
                                    type={InputComponentType.Date}
                                    className={'datepicker'}
                                    date={!filters.end_date ? null : moment(filters.end_date).toDate()}
                                    onChange={(value: Date | undefined | null) =>
                                        setFilters((prevState) => ({
                                            ...prevState,
                                            end_date: moment(value).format('YYYY-MM-DD'),
                                        }))
                                    }
                                />
                            </div>
                        </Row>
                    ) : (
                        <>
                            <div className={'label-text large'}>{t('pages.reports.period_from').text}</div>
                            <Input
                                type={InputComponentType.Date}
                                className={'datepicker'}
                                date={!filters.start_date ? null : moment(filters.start_date).toDate()}
                                onChange={(value: Date | undefined | null) => {
                                    setFilters((prevState) => ({
                                        ...prevState,
                                        start_date: moment(value).format('YYYY-MM-DD'),
                                    }));
                                }}
                            />
                            <div className={'label-text '}>{t('pages.reports.period_to').text}</div>
                            <Input
                                type={InputComponentType.Date}
                                className={'datepicker'}
                                date={!filters.end_date ? null : moment(filters.end_date).toDate()}
                                onChange={(value: Date | undefined | null) =>
                                    setFilters((prevState) => ({
                                        ...prevState,
                                        end_date: moment(value).format('YYYY-MM-DD'),
                                    }))
                                }
                            />
                        </>
                    )}
                </div>
                <div
                    className={'download-main'}
                    onClick={async () => {
                        const response = await communication.downloadPdf(token);
                        // Creating new object of PDF file
                        const file = new Blob([response?.data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        // Setting various property values
                        const alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Izvestaj.pdf';
                        alink.click();
                        // console.log(JSON.stringify(response, null, 2));
                        // convertPDFStreamToPDF(response?.data);
                    }}
                >
                    <p>{t('pages.reports.download_pdf').text}</p>
                    <FontAwesomeIcon
                        icon={faDownload}
                        style={{ color: 'var(--black)' }}
                        width={'33px'}
                        height={'22px'}
                    />
                </div>
            </article>
            <article className={'filter-field'}>
                <div className={'statistics-form-container'}>
                    <div className={'label-text large'}>{t('pages.reports.search_type').text} </div>
                    <SelectComponent
                        className={'select-type'}
                        optionList={searchTypes}
                        defaultSelectedOption={searchTypes[0]}
                        handleSelectedValue={(value: string) =>
                            setFilters((prevState) => ({ ...prevState, type: value }))
                        }
                    />
                    {filters.type === 'per_client' && (
                        <div className="select-search-container">
                            <SelectSearch
                                className={'select-client'}
                                optionList={clients}
                                defaultValue={clients[0]}
                                handleSelectedValue={(value: any) =>
                                    setFilters((prevState) => ({ ...prevState, client_id: value.value }))
                                }
                            />
                        </div>
                    )}
                </div>
                <div
                    className={'download-main'}
                    onClick={async () => {
                        const response = await communication.downloadXlsx(token);
                        // Creating new object of PDF file
                        const file = new Blob([response?.data], { type: 'application/pdf' });
                        const fileURL = window.URL.createObjectURL(file);
                        // Setting various property values
                        const alink = document.createElement('a');
                        alink.href = fileURL;
                        alink.download = 'Izvestaj.xlsx';
                        alink.click();
                    }}
                >
                    <p>{t('pages.reports.download_xlsx').text}</p>
                    <FontAwesomeIcon
                        icon={faDownload}
                        style={{ color: 'var(--black)' }}
                        width={'33px'}
                        height={'22px'}
                    />
                </div>
            </article>
            <article className={'filter-field'}>
                <div className={'statistics-form-container'}>
                    <div className={'label-text large'}>{t('pages.reports.invoices_in_value').text} </div>
                    <SelectComponent
                        className={'select-type'}
                        optionList={currencyTypes}
                        defaultSelectedOption={currencyTypes[0]}
                        handleSelectedValue={(value: string) =>
                            setFilters((prevState) => ({ ...prevState, currency: value }))
                        }
                    />
                </div>
            </article>
        </FiltersWrapper>
    );
};
const FiltersWrapper = styled.div`
    background-color: var(--purple);
    padding: 10px 0;
    .filter-field {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        @media (max-width: 670px) {
            flex-direction: column;
        }
        padding: 5px 15px 5px 0;
        vertical-align: middle;
        position: relative;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
    }
    .select-search-container {
        position: absolute;
        margin-top: 4px;
        margin-left: 5px;
        text-align-last: left;
        display: inline-block;
        font-family: inherit;
        border-radius: 5px;
        border-style: hidden;
        font-size: 16px;
        cursor: pointer;
        flex-direction: column;
        width: 200px;
    }

    .statistics-form-container {
        position: relative;
        .select-wrapper {
            display: inline-block;
            background: white;
            color: var(--gray);
            font-size: 14px;

            .select-type {
                text-transform: uppercase;
            }
        }
        @media (max-width: 670px) {
            text-align: end;
        }
    }
    .datepicker-input-wrapper {
        padding-right: 5px;
        padding-left: 5px;
        input {
            font-size: 14px;
        }
    }
    .label-text {
        color: var(--white);
        font-size: 14px;
        line-height: 12px;
        text-transform: uppercase;
        padding: 10px 10px 10px 15px;
        display: inline-block;
        vertical-align: middle;
    }
    .large {
        width: 155px;
    }
    .datepicker {
        display: inline-block;
        width: auto;
        min-width: 252px;
        position: relative;
        vertical-align: middle;
        font-size: 14px;
        color: var(--gray);
        line-height: 20px;
        text-transform: uppercase;
        float: none;
        margin: 0;
        div:first-child {
            margin-bottom: 0;
            padding-right: 10px;
        }
        .date-container {
            background-color: var(--white);
        }
        .datepicker-input-wrapper {
            input {
                color: var(--gray);
            }
        }
    }
    .download-main {
        display: flex;
        align-items: center;
        cursor: pointer;
        @media (max-width: 670px) {
            justify-content: flex-end;
            margin-top: 12px;
        }
        p {
            color: var(--white);
            font-size: 16px;
        }
    }
`;
const Row = styled.div`
    @media (max-width: 670px) {
        display: flex;
        flex-direction: column;
        .datepicker {
            width: 190px;
            float: right;
            min-width: unset;
        }
    }
`;

export default ReportFilters;
