import isEmpty from 'lodash/isEmpty';
export const formatParams = (params: any): string => {
    let result = '';

    if (!isEmpty(params)) {
        for (const key in params) {
            if (params[key] !== '') {
                result += `${key}:${params[key]};`;
            } else {
                result = '';
            }
        }

        return result;
    } else return '';
};
