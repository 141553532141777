import React, { FunctionComponent } from 'react';
import { ObjectKeys } from '@/types/objectKeys';
import styled from 'styled-components';
import moment from 'moment';

interface Props {
    transaction: ObjectKeys;
    index: number;
    handleEdit: Function;
    handleDelete: Function;
}

const TransactionCardAdmin: FunctionComponent<Props> = ({ transaction, index, handleEdit, handleDelete }) => {
    return (
        <Card>
            <div className="header">
                <p>Redni Broj: {index}</p>
                <p>
                    PIB:
                    <span>{transaction.pib}</span>
                </p>
                <p>{transaction.user.data.email}</p>
            </div>
            <div className="details">
                <div className="dates">
                    <p>
                        Datum Uplate <br /> <span>{moment(transaction.transaction_date).format('DD.MM.yyyy')}</span>
                    </p>
                    <p>
                        Datum Isplate <br />
                        <span>{moment(transaction.user.data.account_expiration_date).format('DD.MM.yyyy')}</span>
                    </p>
                </div>
                <div className="payment-info">
                    <p>
                        Iznos:
                        <span> {transaction.amount}</span>
                    </p>
                    <p>
                        Paket:
                        <span> {transaction.package}</span>
                    </p>
                </div>
                <div className="bank-info">{transaction.bank.data.name}</div>
                <div className="payment-method">
                    <p>Način plaćanja: {transaction.type}</p>
                </div>
            </div>
            <div className="actions">
                <span onClick={() => handleEdit()}>IZMENI</span>
                <span onClick={() => handleDelete()}>OBRISI</span>
            </div>
        </Card>
    );
};

const Card = styled.article`
    background-color: var(--white);
    border-radius: 5px;
    margin-bottom: 24px;
    width: 350px;
    font-size: 16px;
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 16px;
    .header {
        background-color: var(--purple);
        padding: 16px;
        text-align: center;
        p:first-child {
            margin-bottom: 8px;
        }
        p {
            color: var(--white);
            font-size: 16px;
            span {
                margin-left: 4px;
            }
        }
    }
    .details {
        padding: 24px 16px;
        .dates {
            display: flex;
            margin-bottom: 24px;
            p {
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
            }
        }
        .payment-info {
            display: flex;
            margin-bottom: 24px;
            p {
                -webkit-box-flex: 1;
                -ms-flex-positive: 1;
                flex-grow: 1;
            }
        }
        .bank-info {
            text-align: center;
            min-height: 32px;
        }
        .payment-method {
            margin-top: 5px;
            text-align: center;
            p {
                font-size: 14px;
            }
        }
    }
    .actions {
        text-align: right;
        padding: 16px;
        font-size: 14px;
        span {
            text-transform: uppercase;
            cursor: pointer;
            font-weight: 600;
        }
        span:first-child {
            color: var(--green);
        }
        span:last-child {
            color: var(--danger);
            margin-left: 16px;
        }
    }
`;
export default TransactionCardAdmin;
