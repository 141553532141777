import { FunctionComponent } from 'react';
import { Row } from '../style';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import { formatToSerbianNumber } from '../../../../../../functions/format';
import TotalPayedAdvance from './totalPayedAdvance';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../../../global/colors';
import styled from 'styled-components';

interface Props {
    t: Function;
    closedAdvances: ObjectKeys[];
    total: string;
    currency: string;
    onDelete: Function;
}

const PayedAdvance: FunctionComponent<Props> = ({ t, closedAdvances, total, currency, onDelete }) => {
    const totalAdvances = closedAdvances.reduce((acc: number, object: ObjectKeys) => {
        return (
            acc +
            Number(object.value.includes(',') ? object.value.replace('.', '').replace(',', '.') : Number(object.value))
        );
    }, 0);

    function handleDelete(advance: ObjectKeys): any {
        onDelete(advance);
    }

    return (
        <div>
            {closedAdvances.map((advance, index) => (
                <Row style={{ padding: '5px 5px 5px 0', marginBottom: '5px' }} key={index} className="right">
                    <ItemTitle>
                        <Delete onClick={() => handleDelete(advance)}>
                            <FontAwesomeIcon icon={faX} color={colors.gray} />
                        </Delete>
                        <p style={{ marginTop: '8px' }}>
                            {t('pages.advanceInvoices.payedByAdvance').text + advance.invoice_number}
                        </p>
                    </ItemTitle>
                    <h3 style={{ width: '30%', fontSize: '20px' }} className="right">
                        {advance.value.includes(',')
                            ? formatToSerbianNumber(Number(advance.value.replace('.', '').replace(',', '.')))
                            : formatToSerbianNumber(Number(advance.value))}
                    </h3>
                </Row>
            ))}
            <TotalPayedAdvance t={t} total={total} totalAdvances={totalAdvances} currency={currency} />
        </div>
    );
};

export default PayedAdvance;

const Delete = styled.div`
    border-radius: 50%;
    background: var(--white);
    width: 22px;
    text-align: center;
    margin-top: 2px;
    :hover {
        background: var(--purple);
        svg {
            color: var(--white);
        }
    }
`;

const ItemTitle = styled.div`
    display: flex;
    width: 70%;
    justify-content: flex-end;
`;
