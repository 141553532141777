import { create } from 'zustand';
import { ObjectKeys } from '../types/objectKeys';

interface YearsState {
    years: Array<ObjectKeys>;
}

const useStore = create<YearsState>(() => ({
    years: [],
}));

export default useStore;
