import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import Input, { InputComponentType } from '../input';
import useNumLengthValidation from '../../hooks/useNumLengthValidation';
import { BankAccountNumberFormData } from '../../types/bankAccountNumberFormData';

interface Props {
    t: Function;
    showValidationMessage: boolean;
    formData: BankAccountNumberFormData;
    setFormData: Dispatch<SetStateAction<BankAccountNumberFormData>>;
    label?: string;
    handleValidation?: Function;
    type?: string;
    clickedField: string;
    showError: boolean;
}

const BankAccountNumber: FunctionComponent<Props> = ({
    t,
    showValidationMessage,
    formData,
    setFormData,
    label,
    handleValidation,
    type,
    clickedField,
    showError,
}) => {
    const isBankAccountNumber1Valid = useNumLengthValidation(formData.bankAccountNumber1, 3);
    const isBankAccountNumber2Valid = useNumLengthValidation(formData.bankAccountNumber2, 13);
    const isBankAccountNumber3Valid = useNumLengthValidation(formData.bankAccountNumber3, 2);

    return (
        <>
            {handleValidation && (
                <Container>
                    <label>
                        {label ? label : t('pages.register.rightColumn.form.secondStep.bankAccountNumber').text}
                    </label>
                    <div>
                        <Input
                            type={InputComponentType.Number}
                            value={formData.bankAccountNumber1}
                            validation={
                                type === 'first' ||
                                (showError &&
                                    (formData.bankAccountNumber2 !== '' || formData.bankAccountNumber3 !== ''))
                                    ? isBankAccountNumber1Valid
                                    : formData.bankAccountNumber2 !== '' ||
                                      formData.bankAccountNumber3 !== '' ||
                                      (showValidationMessage && clickedField === `${type && type}bankAccountNumber1`)
                                    ? isBankAccountNumber1Valid
                                    : ''
                            }
                            onChange={(value: string) => {
                                setFormData({ ...formData, bankAccountNumber1: value });
                            }}
                            maxLength={3}
                            className="bank-acc-num-1"
                            blurHandler={() => {
                                handleValidation(`${type && type}bankAccountNumber1`);
                            }}
                        />
                        <span>-</span>
                        <Input
                            type={InputComponentType.Number}
                            value={formData.bankAccountNumber2}
                            validation={
                                type === 'first' ||
                                (showError &&
                                    (formData.bankAccountNumber1 !== '' || formData.bankAccountNumber3 !== ''))
                                    ? isBankAccountNumber2Valid
                                    : formData.bankAccountNumber3 !== '' ||
                                      (showValidationMessage && clickedField === `${type && type}bankAccountNumber2`)
                                    ? isBankAccountNumber2Valid
                                    : ''
                            }
                            onChange={(value: string) => {
                                handleValidation(`${type && type}bankAccountNumber2`);
                                setFormData({ ...formData, bankAccountNumber2: value });
                            }}
                            maxLength={13}
                            className="bank-acc-num-2"
                            hideBlurOnChange
                            blurHandler={() => {
                                handleValidation(`${type && type}bankAccountNumber2`);
                                if (formData.bankAccountNumber2.length < 13 && formData.bankAccountNumber2 !== '') {
                                    setFormData({
                                        ...formData,
                                        bankAccountNumber2: formData.bankAccountNumber2.padStart(13, '0'),
                                    });
                                }
                            }}
                        />
                        <span>-</span>
                        <Input
                            type={InputComponentType.Number}
                            value={formData.bankAccountNumber3}
                            validation={
                                type === 'first' ||
                                (showError &&
                                    (formData.bankAccountNumber1 !== '' || formData.bankAccountNumber2 !== ''))
                                    ? isBankAccountNumber3Valid
                                    : showValidationMessage && clickedField === `${type && type}bankAccountNumber3`
                                    ? isBankAccountNumber3Valid
                                    : ''
                            }
                            maxLength={2}
                            onChange={(value: string) => setFormData({ ...formData, bankAccountNumber3: value })}
                            className="bank-acc-num-3"
                            blurHandler={() => handleValidation(`${type && type}bankAccountNumber3`)}
                        />
                    </div>
                </Container>
            )}
        </>
    );
};

export default BankAccountNumber;

const Container = styled.div`
    label {
        font-size: 15px;
        color: var(--gray);
        margin-bottom: 2px;
        &:hover {
            color: var(--purple);
        }
    }
    .input-container {
        width: -webkit-fill-available;
    }
    > div {
        display: flex;
        align-items: center;
        span {
            margin: 0 10px;
            color: var(--border-color);
        }
    }
`;
