import { FunctionComponent, SetStateAction, Dispatch } from 'react';
import { Link } from 'react-router-dom';
import useEmailValidation from '../../../hooks/useEmailValidation';
import usePasswordValidation from '../../../hooks/usePasswordValidation';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useConfirmPasswordValidation from '../../../hooks/useConfirmPasswordValidation';
import Input, { InputComponentType } from '../../../components/input';
import Checkbox from '../../../components/checkbox';
import { RegisterFirstFormData } from '../../../types/register/registerFirstFormData';
type Props = {
    t: Function;
    showValidationMessage: boolean;
    formData: RegisterFirstFormData;
    setFormData: Dispatch<SetStateAction<RegisterFirstFormData>>;
    handleValidation: Function;
    clickedField: string;
};

const FirstStep: FunctionComponent<Props> = ({
    t,
    showValidationMessage,
    formData,
    setFormData,
    handleValidation,
    clickedField,
}) => {
    const isEmailValid = useEmailValidation(formData.email);
    const isPasswordValid = usePasswordValidation(formData.password);
    const isNameValid = useCharLengthValidation(formData.name, 3);
    const isLastNameValid = useCharLengthValidation(formData.lastName, 3);
    const isConfirmPasswordValid = useConfirmPasswordValidation(formData.password, formData.confirmPassword);

    return (
        <>
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.email').text}
                validation={showValidationMessage || clickedField === 'email' ? isEmailValid : ''}
                value={formData.email}
                blurHandler={() => handleValidation('email')}
                onChange={(value: string) => {
                    setFormData({ ...formData, email: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.name').text}
                validation={showValidationMessage || clickedField === 'name' ? isNameValid : ''}
                value={formData.name}
                blurHandler={() => handleValidation('name')}
                onChange={(value: string) => {
                    setFormData({ ...formData, name: value });
                }}
            />
            <Input
                type={InputComponentType.Text}
                label={t('pages.register.rightColumn.form.firstStep.lastName').text}
                validation={showValidationMessage || clickedField === 'lastName' ? isLastNameValid : ''}
                value={formData.lastName}
                blurHandler={() => handleValidation('lastName')}
                onChange={(value: string) => {
                    setFormData({ ...formData, lastName: value });
                }}
            />
            <Input
                type={InputComponentType.Password}
                label={t('pages.register.rightColumn.form.firstStep.password').text}
                validation={showValidationMessage || clickedField === 'password' ? isPasswordValid : ''}
                value={formData.password}
                blurHandler={() => handleValidation('password')}
                onChange={(value: string) => {
                    setFormData({ ...formData, password: value });
                }}
            />
            <Input
                type={InputComponentType.Password}
                label={t('pages.register.rightColumn.form.firstStep.confirmPassword').text}
                validation={showValidationMessage || clickedField === 'confirmPassword' ? isConfirmPasswordValid : ''}
                value={formData.confirmPassword}
                blurHandler={() => handleValidation('confirmPassword')}
                onChange={(value: string) => {
                    setFormData({ ...formData, confirmPassword: value });
                }}
            />
            {formData.isPromoCodeChecked ? (
                <Input
                    type={InputComponentType.Text}
                    label={t('pages.register.rightColumn.form.firstStep.typePromoCode').text}
                    value={formData.promoCode}
                    onChange={(value: string) => {
                        setFormData({ ...formData, promoCode: value });
                    }}
                />
            ) : (
                <></>
            )}
            <Checkbox
                defaultChecked={formData.isPromoCodeChecked}
                onChange={() => setFormData({ ...formData, isPromoCodeChecked: !formData.isPromoCodeChecked })}
                label={t('pages.register.rightColumn.form.firstStep.promoCode').text}
            />
            <Checkbox
                defaultChecked={formData.isTermsChecked}
                onChange={() => setFormData({ ...formData, isTermsChecked: !formData.isTermsChecked })}
                validation={
                    showValidationMessage && !formData.isTermsChecked
                        ? t('pages.validationMessages.termsServices').text
                        : ''
                }
                label={
                    <Link target="_blank" to="http://www.pausal.rs/assets/PAUSAL_Uslovi_Koriscenja.pdf">
                        {t('pages.register.rightColumn.form.firstStep.iAgree').text}
                    </Link>
                }
            />
        </>
    );
};
export default FirstStep;
