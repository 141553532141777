import { Dispatch, FunctionComponent, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import NoDataPhoto from '../../../assets/no-transfer-order.png';
import MagicPhoto from '../../../assets/magic.png';
import Button, { ButtonVariant } from '../../../components/button';
import ManageOrdersModal from '../../../components/manageOrdersModal';
import { SelectOption } from '../../../components/select';
import communication from '../../../communication';
import useAgencyStore from '../../../store/agency';
// import moment from 'moment';

interface Props {
    t: Function;
    currentLangName: string;
    close: Function;
    yearsOptions: SelectOption[];
    setIsLoader: Function;
    setIsInfoModalActive: Dispatch<SetStateAction<boolean>>;
    handleRefresh: Function;
    transferModalData: boolean;
    year: string;
}

const NoTransferOrderModal: FunctionComponent<Props> = ({
    t,
    currentLangName,
    close,
    yearsOptions,
    setIsLoader,
    setIsInfoModalActive,
    handleRefresh,
    transferModalData,
    year,
}) => {
    const [info, setInfo] = useState(false);
    const [transferModal, setTransferModal] = useState(transferModalData);
    const [callNo, setCallNo] = useState(0);

    const { agency } = useAgencyStore();

    useEffect(() => {
        async function getAgency(): Promise<void> {
            const res = await communication.getCallNumber(year, agency.id);
            setCallNo(res.data.call_number);
        }

        getAgency();
    }, [transferModalData]);

    return (
        <Wrapper>
            {info ? (
                <>
                    <div className="content">
                        <img src={MagicPhoto} alt="photo" />
                        {currentLangName === 'Srpski' ? (
                            <p>
                                {t('pages.transfers.noDataModal.info1').text}
                                <a href="https://blog.pausal.rs/kako-da-preuzmem-svoje-poresko-resenje/">
                                    {t('pages.transfers.noDataModal.here').text}
                                </a>
                                {t('pages.transfers.noDataModal.info2').text}
                                <a href="mailto:office@pausal.rs">office@pausal.rs</a>
                            </p>
                        ) : (
                            <p>
                                {t('pages.transfers.noDataModal.info1').text}
                                <a href="https://www.purs.gov.rs/biro-za-informisanje/Strana2/Strana6/4061/obavestenje-za-obveznike-koji-poreze-i-doprinose-za-obavezno-socijalno-osiguranje-placaju-na-pausalno-utvrdjeni-prihod.html">
                                    {t('pages.transfers.noDataModal.here').text}
                                </a>
                                {t('pages.transfers.noDataModal.info2').text}
                            </p>
                        )}
                    </div>
                    <Footer>
                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(false);
                            }}
                            size={'50%'}
                            className="back"
                        >
                            {t('buttons.back').text}
                        </Button>

                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(false);
                                close();
                            }}
                            size={'50%'}
                        >
                            {t('buttons.ok').text}
                        </Button>
                    </Footer>
                </>
            ) : transferModal ? (
                <ManageOrdersModal
                    closeModal={() => {
                        close();
                        setTransferModal(false);
                    }}
                    yearsOptions={yearsOptions}
                    setIsLoader={setIsLoader}
                    setOpenInfoModal={setIsInfoModalActive}
                    callNumber={callNo}
                    refresh={() => handleRefresh()}
                    year={year}
                />
            ) : (
                <>
                    <div className="content">
                        <img src={NoDataPhoto} alt="photo" />
                        <h3>{t('pages.transfers.noDataModal.title').text}</h3>
                        <p>{t('pages.transfers.noDataModal.data').text}</p>
                    </div>
                    <Footer>
                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setInfo(true);
                            }}
                            size={'50%'}
                        >
                            {t('input.no').text}
                        </Button>

                        <Button
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={'var(--purple)'}
                            onClick={() => {
                                setTransferModal(true);
                            }}
                            size={'50%'}
                        >
                            {t('input.yes').text}
                        </Button>
                    </Footer>
                </>
            )}
        </Wrapper>
    );
};

export default NoTransferOrderModal;

const Wrapper = styled.div`
    text-align: center;
    width: 100%;

    .content {
        padding: 35px 50px 30px;
        text-align: center;
        min-height: 130px;

        img {
            height: 200px;
            width: auto;
            margin-bottom: 30px;
        }

        h3 {
            color: var(--black);
            font-weight: 400;
            font-size: 20px;
        }

        p {
            margin-top: 25px;
            line-height: 30px;
            font-size: 16px;
            white-space: pre-wrap;
            color: var(--gray);
        }
    }
`;

const Footer = styled.div`
    border-top: 1px solid var(--border-color);
    overflow: hidden;

    display: flex;
    flex-direction: row;
    button {
        border-radius: 0px !important;
        :nth-child(2) {
            opacity: 0.7;
        }
        &.back {
            background-color: var(--white);
            color: var(--purple);
            border: none;
        }
    }
`;
