import { Dispatch, FunctionComponent, SetStateAction, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import colors from '../../../../global/colors';
import useGeneralStore from '../../../../store/general';
import Button, { ButtonVariant } from '../../../../components/button';
import Input, { InputComponentType } from '../../../../components/input';
import useDateOfInvoiceCancelation from '../../../../hooks/useDateOfInvoiceCancelation';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllInvoices from '../getAllAInvoices';
type Props = {
    t: Function;
    setOpenCancelModal: Dispatch<SetStateAction<boolean>>;
    clickedInvoice: string;
    setInvoices: Dispatch<SetStateAction<any>>;
};

const CancelModal: FunctionComponent<Props> = ({ t, setOpenCancelModal, clickedInvoice, setInvoices }) => {
    const [invoiceCancelDate, setInvoiceCancelDate] = useState(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const [showValidationMessage, setShowValidationMessage] = useState(false);

    const isInvoiceCancelDateValid = useDateOfInvoiceCancelation(invoiceCancelDate);

    const handleCancelInvoice = (invoiceId: string): void => {
        setShowValidationMessage(true);
        if (invoiceCancelDate !== null && isInvoiceCancelDateValid === '') {
            communication
                .cancelInvoice(invoiceId, {
                    cancel_date: moment(invoiceCancelDate).format('YYYY-MM-DD'),
                    e_invoice_cancel_comment: '',
                })
                .then((res: ObjectKeys) => {
                    if (res) {
                        setOpenCancelModal(false);
                    }
                })
                .then(() => {
                    getAllInvoices(setInvoices);
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        setGlobalModal(
                            <ErrorModalWrapper>
                                <ModalText>
                                    <h4>{t('modal.error.title').text}</h4>
                                    <p>{error.response.data.message}</p>
                                </ModalText>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    size={'100%'}
                                    onClick={() => {
                                        closeGlobalModal();
                                    }}
                                >
                                    {t('buttons.ok').text}
                                </Button>
                            </ErrorModalWrapper>,
                        );
                    }
                });
        }
    };

    return (
        <CancelModalWrapper>
            <ModalContent>
                <h4>{t('pages.advanceInvoices.cancelModal.title').text}</h4>
                <p>{t('pages.advanceInvoices.cancelModal.description').text}</p>
                <DateWrapper>
                    <Input
                        type={InputComponentType.Date}
                        label={t('pages.advanceInvoices.cancelModal.inputLabel').text}
                        date={invoiceCancelDate}
                        validation={showValidationMessage ? isInvoiceCancelDateValid : ''}
                        onChange={(value: any) => {
                            setInvoiceCancelDate(value);
                        }}
                        blurHandler={() => setShowValidationMessage(true)}
                    />
                </DateWrapper>
            </ModalContent>

            <ButtonContainer>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        handleCancelInvoice(clickedInvoice);
                    }}
                    size={'100%'}
                >
                    {t('input.yes').text}
                </Button>
                <Button
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => setOpenCancelModal(false)}
                    size={'100%'}
                >
                    {t('input.no').text}
                </Button>
            </ButtonContainer>
        </CancelModalWrapper>
    );
};

const CancelModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ButtonContainer = styled.div`
    display: flex;
    width: 50%;
    button {
        border: none;
    }
    button:first-child {
        border-radius: 0px 0px 0px 5px !important;
    }
    button:last-child {
        background-color: var(--white);
        color: var(--black);
        border-radius: 0px 0px 5px 0px !important;
        border-top: 1px solid var(--gray);
    }
`;
const ModalContent = styled.div`
    padding: 35px 50px;
    text-align: center;

    h4 {
        margin-bottom: 20px;
    }
    p {
        color: var(--gray);
        font-size: 16px;
        line-height: 1.3;
    }
`;
const DateWrapper = styled.div`
    margin-bottom: 20px;
    margin-top: 20px;
    .input {
        > div {
            margin-bottom: 0;
        }
    }
`;
const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;
export default CancelModal;
