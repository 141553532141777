import React, { FunctionComponent, useState, useEffect } from 'react';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import styled from 'styled-components';
import { ObjectKeys } from '../../../types/objectKeys';
import SelectComponent from '../../../components/select';
import Loader from '../../../components/loader';
import communication from '../../../communication';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useAgencyStore from '../../../store/agency';
import moment from 'moment';

interface Props {
    onClose: Function;
    paymentAccounts: ObjectKeys;
    selectedTransfer: ObjectKeys;
    action: string;
    refresh: Function;
}

interface SelectProps {
    value: string | number;
    label: string;
}

const AddOrEditModal: FunctionComponent<Props> = ({ onClose, paymentAccounts, selectedTransfer, action, refresh }) => {
    const [loader, setLoader] = useState<boolean>(false);
    const [accounts, setAccounts] = useState<SelectProps[]>([]);
    const [selectedAccount, setSelectedAccount] = useState<string>('');
    const [selectedAccountBankNumbers, setSelectedBankNumbers] = useState<SelectProps[]>([]);
    const agency = useAgencyStore((state) => state.agency);
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    console.log(agency);
    const [newTransferOrder, setNewTransferOrder] = useState<ObjectKeys>({
        payment_slip_heading: '',
        orderer: `${agency?.full_name}, ${agency?.street_address} ${agency?.street_number}, ${agency?.city} `,
        purpose_of_payment: '',
        recipient: '',
        currency: 'RSD',
        total_amount: 24,
        debtor_account: agency?.first_account_number,
        payment_code: 221,
        recipient_account: '',
        call_number_debtor: '',
        model_number_debtor: '',
        call_number_recipient: '',
        model_number_recipient: '',
        year: moment().year(),
    });
    const [validation, setValidations] = useState<ObjectKeys>({
        payment_slip_heading: '',
        orderer: '',
        purpose_of_payment: '',
        recipient: '',
        currency: '',
        total_amount: '',
        debtor_account: '',
        payment_code: '',
        recipient_account: '',
        call_number_debtor: '',
        model_number_debtor: '',
        call_number_recipient: '',
        model_number_recipient: '',
        year: '',
    });

    const [agencyBankAccount, setAgencyBankAccount] = useState<ObjectKeys>({
        first: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[0] : '',
        second: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[1] : '',
        third: newTransferOrder?.debtor_account?.length > 0 ? newTransferOrder?.debtor_account?.split('-')[2] : '',
    });

    const [recipientBankAccount, setRecipientBankAccount] = useState<ObjectKeys>({
        first:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[0] : '',
        second:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[1] : '',
        third:
            newTransferOrder?.recipient_account?.length > 0 ? newTransferOrder?.recipient_account?.split('-')[2] : '',
    });

    useEffect(() => {
        if (action === 'edit') setNewTransferOrder(selectedTransfer);
    }, []);

    useEffect(() => {
        setRecipientBankAccount({
            first: newTransferOrder?.recipient_account?.split('-')[0] || '',
            second: newTransferOrder?.recipient_account?.split('-')[1] || '',
            third: newTransferOrder?.recipient_account?.split('-')[2] || '',
        });
    }, [newTransferOrder.recipient_account]);

    useEffect(() => {
        if (action !== 'edit') {
            setNewTransferOrder((prevState) => ({
                ...prevState,
                debtor_account: agency.first_account_number,
                orderer: agency.name,
            }));
        }
    }, [agency]);

    useEffect(() => {
        if (selectedTransfer.id) {
            setLoader(true);
            paymentAccounts?.map((acc: { name: any; id: any }) => {
                setAccounts((prevState) => [...prevState, { label: acc.name, value: acc.name }]);
            });
            setNewTransferOrder(selectedTransfer);
            setLoader(false);
        }
    }, [selectedTransfer]);

    useEffect(() => {
        setSelectedBankNumbers([]);
        const acc = paymentAccounts?.find((element: { name: string }) => element.name === selectedAccount);
        acc?.accounts?.map((number: string) =>
            setSelectedBankNumbers((prevState) => [...prevState, { value: number, label: number }]),
        );
    }, [selectedAccount]);

    const validate = (): boolean => {
        newTransferOrder?.payment_slip_heading?.length === 0 || newTransferOrder?.payment_slip_heading === ''
            ? setValidations((prevState) => ({ ...prevState, payment_slip_heading: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, payment_slip_heading: '' }));
        newTransferOrder?.orderer?.length === 0 || newTransferOrder?.orderer === ''
            ? setValidations((prevState) => ({ ...prevState, orderer: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, orderer: '' }));
        newTransferOrder?.purpose_of_payment?.length === 0 || newTransferOrder?.purpose_of_payment === ''
            ? setValidations((prevState) => ({ ...prevState, purpose_of_payment: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, purpose_of_payment: '' }));
        newTransferOrder?.recipient?.length === 0 || newTransferOrder?.recipient === ''
            ? setValidations((prevState) => ({ ...prevState, recipient: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, recipient: '' }));
        newTransferOrder?.total_amount?.length === 0 || newTransferOrder?.total_amount === ''
            ? setValidations((prevState) => ({ ...prevState, total_amount: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, total_amount: '' }));
        newTransferOrder?.debtor_account?.length === 0 || newTransferOrder?.debtor_account === ''
            ? setValidations((prevState) => ({ ...prevState, debtor_account: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, debtor_account: '' }));
        newTransferOrder?.recipient_account?.length === 0 || newTransferOrder?.recipient_account === ''
            ? setValidations((prevState) => ({ ...prevState, recipient_account: 'Ovo polje je obavezno.' }))
            : setValidations((prevState) => ({ ...prevState, recipient_account: '' }));
        newTransferOrder?.call_number_debtor?.length === 0 || newTransferOrder?.call_number_debtor === ''
            ? setValidations((prevState) => ({ ...prevState, call_number_debtor: 'Ova polja su obavezna.' }))
            : setValidations((prevState) => ({ ...prevState, call_number_debtor: '' }));
        newTransferOrder?.model_number_debtor?.length === 0 || newTransferOrder?.model_number_debtor === ''
            ? setValidations((prevState) => ({ ...prevState, model_number_debtor: 'Ova polja su obavezna.' }))
            : setValidations((prevState) => ({ ...prevState, model_number_debtor: '' }));
        newTransferOrder?.call_number_recipient?.length === 0 || newTransferOrder?.call_number_recipient === ''
            ? setValidations((prevState) => ({ ...prevState, call_number_recipient: 'Ova polja su obavezna.' }))
            : setValidations((prevState) => ({ ...prevState, call_number_recipient: '' }));
        newTransferOrder?.model_number_recipient?.length === 0 || newTransferOrder?.model_number_recipient === ''
            ? setValidations((prevState) => ({ ...prevState, model_number_recipient: 'Ova polja su obavezna.' }))
            : setValidations((prevState) => ({ ...prevState, model_number_recipient: '' }));

        return (
            validation.payment_slip_heading.length === 0 ||
            validation.orderer.length === 0 ||
            validation.purpose_of_payment.length === 0 ||
            validation.recipient.length === 0 ||
            validation.total_amount.length === 0 ||
            validation.debtor_account.length === 0 ||
            validation.recipient_account.length === 0 ||
            validation.call_number_debtor.length === 0 ||
            validation.model_number_debtor.length === 0 ||
            validation.call_number_recipient.length === 0 ||
            validation.model_number_recipient.length === 0
        );
    };

    const save = async (): Promise<void> => {
        const validFail = validate();
        console.log(validFail, validation, newTransferOrder, action);
        if (validFail) {
            return;
        }
        setLoader(true);
        setNewTransferOrder((prevState) => ({
            ...prevState,
            recipient_account: `${recipientBankAccount.first}-${agencyBankAccount.second}-${agencyBankAccount.third}`,
        }));
        setNewTransferOrder((prevState) => ({
            ...prevState,
            debtor_account: `${agencyBankAccount.first}-${agencyBankAccount.second}-${agencyBankAccount.third}`,
        }));
        if (action === 'edit') {
            const res = await communication.updateTransferOrder(newTransferOrder, selectedTransfer.id);
            if (res.data.data.id) setLoader(false);
        } else {
            const res = await communication.createTransferOrder(newTransferOrder);
            if (res.data.data.id) setLoader(false);
        }
        onClose();
        refresh();
    };

    const setData = (field: string, value: string | number): void => {
        setNewTransferOrder((prevState) => ({ ...prevState, [field]: value }));
    };

    return (
        <Content>
            <Header>
                <h1>{t('pages.transfers.preview_title').text}</h1>
                <button
                    className={'close_button'}
                    onClick={() => {
                        setSelectedAccount('');
                        setSelectedBankNumbers([]);
                        onClose();
                    }}
                />
            </Header>

            {loader ? (
                <Loader />
            ) : (
                <Body>
                    <div className={'top'}>
                        <div className={'title'}>
                            <h2>{t('pages.transfers.transfer_name').text}</h2>
                            <input
                                className={'input'}
                                value={newTransferOrder?.payment_slip_heading}
                                onChange={(event) => setData('payment_slip_heading', event.target.value)}
                            />{' '}
                            <span className={'validation'}>{validation?.payment_slip_heading}</span>
                        </div>
                    </div>
                    <div className={'accounts'}>
                        <h2>{t('pages.transfers.choose_bank_account').text}</h2>
                        <div className={'select-accounts'}>
                            {accounts?.length > 0 && (
                                <SelectComponent
                                    key={accounts?.length}
                                    className={'bank-name'}
                                    optionList={accounts}
                                    placeholder={t('pages.transfers.choose_account').text}
                                    handleSelectedValue={(value: string) => {
                                        setSelectedAccount(value);
                                        setData('recipient', value);
                                    }}
                                />
                            )}

                            {selectedAccountBankNumbers.length > 0 && (
                                <SelectComponent
                                    className={'bank-number'}
                                    placeholder={t('pages.transfers.choose_account').text}
                                    optionList={selectedAccountBankNumbers}
                                    handleSelectedValue={(value: string) => setData('recipient_account', value)}
                                />
                            )}
                        </div>
                    </div>
                    <div className={'left-side'}>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.orderer').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={'Naziv firme'}
                                value={newTransferOrder.orderer}
                                onChange={(event) => setData('orderer', event.target.value)}
                            />
                            <span className={'validation'}>{validation.orderer}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.purpose_of_payment').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder?.purpose_of_payment}
                                onChange={(event) => setData('purpose_of_payment', event.target.value)}
                            />{' '}
                            <span className={'validation'}>{validation.purpose_of_payment}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recipient').text}</span>
                            <textarea
                                className={'input'}
                                placeholder={''}
                                value={newTransferOrder?.recipient}
                                disabled
                            />
                            <span className={'validation'}>{validation.recipient}</span>
                        </div>
                    </div>
                    <div className={'right-side'}>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.payment_code').text}</span>
                            <input
                                className={'input'}
                                value={newTransferOrder?.payment_code}
                                maxLength={3}
                                onChange={(event) => setData('payment_code', event.target.value)}
                            />
                            <span className={'validation'}>{validation.payment_code}</span>
                        </div>
                        <div className={'field m'}>
                            <span className={'label'}>{t('pages.transfers.currency').text}</span>
                            <input className={'input'} disabled value={newTransferOrder.currency} />
                            <span className={'validation'}>{validation.currency}</span>
                        </div>
                        <div className={'field l'}>
                            <span className={'label'}>{t('pages.transfers.amount').text}</span>
                            <input
                                className={'input'}
                                value={newTransferOrder?.total_amount}
                                onChange={(event) => setData('total_amount', event.target.value)}
                            />
                            <span className={'validation'}>{validation.total_amount}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.debtor_account').text}</span>
                            <input
                                className={'input s'}
                                value={agencyBankAccount.first}
                                maxLength={3}
                                onChange={(event) =>
                                    setAgencyBankAccount((prevState) => ({ ...prevState, first: event.target.value }))
                                }
                            />
                            <input
                                className={'input xl input-left-margin '}
                                value={agencyBankAccount.second}
                                maxLength={13}
                                onChange={(event) => {
                                    setAgencyBankAccount((prevState) => ({ ...prevState, second: event.target.value }));
                                }}
                            />
                            <input
                                className={'input s input-left-margin '}
                                value={agencyBankAccount.third}
                                maxLength={2}
                                onChange={(event) =>
                                    setAgencyBankAccount((prevState) => ({ ...prevState, third: event.target.value }))
                                }
                            />
                            <span className={'validation'}>{validation.debtor_account}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.model_and_call_number_debtor').text}</span>

                            <input
                                className={'input s'}
                                value={newTransferOrder?.model_number_debtor}
                                maxLength={2}
                                onChange={(event) => setData('model_number_debtor', event.target.value)}
                            />
                            <input
                                className={'input xxl input-left-margin '}
                                value={newTransferOrder?.call_number_debtor}
                                maxLength={20}
                                onChange={(event) => setData('call_number_debtor', event.target.value)}
                            />
                            <span className={'validation'}>
                                {validation.call_number_debtor || newTransferOrder?.model_number_debtor}
                            </span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.recepient_bank_account').text}</span>

                            <input
                                className={'input s'}
                                value={recipientBankAccount?.first}
                                maxLength={3}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        first: event.target.value,
                                    }))
                                }
                            />
                            <input
                                className={'input xl input-left-margin '}
                                value={recipientBankAccount?.second}
                                maxLength={13}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        second: event.target.value,
                                    }))
                                }
                            />
                            <input
                                className={'input s input-left-margin '}
                                value={recipientBankAccount?.third}
                                maxLength={2}
                                onChange={(event) =>
                                    setRecipientBankAccount((prevState) => ({
                                        ...prevState,
                                        third: event.target.value,
                                    }))
                                }
                            />
                            <span className={'validation'}>{validation.recepient_bank_account}</span>
                        </div>
                        <div className={'field'}>
                            <span className={'label'}>{t('pages.transfers.model_and_call_number_recipient').text}</span>

                            <input
                                className={'input s'}
                                value={newTransferOrder?.model_number_recipient}
                                onChange={(event) => setData('model_number_recipient', event.target.value)}
                            />

                            <input
                                className={'input xxl input-left-margin'}
                                value={newTransferOrder?.call_number_recipient}
                                onChange={(event) => setData('call_number_recipient', event.target.value)}
                            />
                            <span className={'validation'}>
                                {validation.call_number_recipient || newTransferOrder?.model_number_recipient}
                            </span>
                        </div>
                    </div>
                    <div className={'footer'}>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_order_signature').text}</span>
                        </div>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_order_date_time').text}</span>
                        </div>
                        <div className={'signature'}>
                            <span>{t('pages.transfers.transfer_execution').text}</span>
                        </div>
                    </div>
                    <div className={'qr-code'}>
                        <Button variant={ButtonVariant.outlined} color={colors.purple} onClick={() => save()}>
                            Sacuvaj
                        </Button>
                    </div>
                </Body>
            )}
        </Content>
    );
};

const Content = styled.div`
    overflow: auto;
`;

const Header = styled.div`
    position: relative;
    -ms-flex: 1 1 100%;
    flex: 1 1 100%;
    padding: 2px 15px 20px;
    h1 {
        font-size: 24px;
        text-transform: uppercase;
        color: #4c595f;
        font-weight: 400;
    }
    .close_button {
        position: absolute;
        top: 10px;
        right: 10px;
        display: block;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
            no-repeat;
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        text-indent: -9999px;
        border: 0;
        &:hover {
            cursor: pointer;
        }
    }
`;

const Body = styled.div`
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    width: 100%;
    textarea {
        resize: none;
    }
    .input {
        color: #6e7883;
    }
    .top {
        width: 100%;
        .title {
            margin-bottom: 35px;
            color: gray;
        }
        h2 {
            font-size: 18px;
            margin: 10px 10px;
        }
        input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: -webkit-fill-available;
            margin: 10px 10px;
        }
    }

    .accounts {
        margin-bottom: 30px;
        width: 100%;
        h2 {
            font-size: 18px;
            margin: 10px 10px;
        }
        .select-accounts {
            display: flex;
            justify-content: space-between;
            width: 100%;
            .select-wrapper {
                display: flex;
                margin: 10px 10px;
                width: 49%;
            }
            .select-wrapper:last-child {
                align-self: flex-end;
            }
        }
        .bank-name {
            width: 100%;
        }
        .bank-number {
            width: 100%;
        }
    }
    .left-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;
        .input {
            display: block;
            font-size: 12px;
            font-weight: 700;
            min-height: 88px;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
            color: #6e7883;
        }
    }
    .right-side {
        -ms-flex: 1 1 50%;
        flex: 1 1 50%;

        .input {
            display: inline-block;
            font-size: 12px;
            font-weight: 700;
            min-height: auto;
            padding: 5px 5px 5px 10px;
            border: 1px solid #adbcc1;
            line-height: 25px;
            width: 100%;
            color: #6e7883;
        }
    }
    .footer {
        margin: 10px 10px 20px;
        width: 100%;
        .signature {
            border: 0;
            border-top: 1px solid #000;
            width: 170px;
            margin-top: 40px;
            display: block;
            margin-right: 40px;
            float: left;
        }
    }
    .qr-code {
        display: -ms-flexbox;
        display: flex;
        flex: 1;
        -ms-flex-direction: row;
        flex-direction: row;
        -ms-flex-pack: center;
        justify-content: center;
        -ms-flex-align: center;
        align-items: center;
        img {
            width: 200px;
        }
    }
    .field {
        -ms-flex: 1 1 100%;
        flex: 1 1 100%;
        margin: 10px 10px 2px 10px;
        overflow: hidden;

        .label {
            font-size: 13px;
            color: #92a5ac;
            display: block;
            margin-bottom: 5px;
        }
    }

    .s {
        display: inline-table;
        float: left;
        width: 70px !important;
    }
    .m {
        display: inline-table;
        width: calc(29% - 10px) !important;
        margin-right: 0;
    }
    .l {
        display: inline-table;
        width: calc(38% - 10px) !important;
        margin-right: 0;
    }

    .xl {
        float: left;
        width: calc(100% - 160px) !important;
    }
    .xxl {
        float: left;
        width: calc(100% - 80px) !important;
    }

    .input-left-margin {
        margin-left: 10px;
    }

    textarea:disabled,
    input:disabled {
        background-color: white;
    }
    .validation {
        color: #ff0042;
        font-size: 12px;
        margin-top: 2px;
        margin-left: 10px;
    }
`;
export default AddOrEditModal;
