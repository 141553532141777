import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import useUserStore from '../../../store/user';
import useProfileStore from '../../../store/profile';
import useAgencyStore from '../../../store/agency';
import useChangeModalStore from '../../../store/changeModal';
import communication from '../../../communication';
import Input, { InputComponentType } from '../../../components/input';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import BankAccountNumber from '../../../components/bankAccountNumber';
import { BankAccountNumberFormData } from '../../../types/bankAccountNumberFormData';
import SelectSearch from '../../../components/selectSearch';
import ToggleSwitch from '../../../components/input/toggleSwitch';
import moment from 'moment';
import Success from '../../../components/successModal';
import Modal from '../../../components/modal';
import Loader from '../../../components/loader';
import useCharLengthValidation from '../../../hooks/useCharLengthValidation';
import useEmailValidation from '../../../hooks/useEmailValidation';
import useVatNoValidation from '../../../hooks/useVatNoValidation';
import useAgencyIdNumberValidation from '../../../hooks/useAgencyIdNumberValidation';
import useNumLengthValidation from '../../../hooks/useNumLengthValidation';
import ErrorModal from '../../../components/errorModal';
import { ObjectKeys } from '../../../types/objectKeys';

const AgencyProfile: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [clickedField, setClickedField] = useState('');

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const { activityCodes, municipalities } = useUserStore();

    const [successfullySaved, setSuccessfullySaved] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);

    const [firstBankAcc, setFirstBankAcc] = useState<BankAccountNumberFormData>({
        bankAccountNumber1: '',
        bankAccountNumber2: '',
        bankAccountNumber3: '',
    });
    const [secondBankAcc, setSecondBankAcc] = useState<BankAccountNumberFormData>({
        bankAccountNumber1: '',
        bankAccountNumber2: '',
        bankAccountNumber3: '',
    });
    const [foreignBankAcc, setForeignBankAcc] = useState<BankAccountNumberFormData>({
        bankAccountNumber1: '',
        bankAccountNumber2: '',
        bankAccountNumber3: '',
    });
    const [formData, setFormData] = useState<ObjectKeys>();
    const [showError, setShowError] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    //get agency
    useEffect(() => {
        async function getAgency(): Promise<void> {
            const response = await communication.getAgency();
            const res = response.data.data;

            setFirstBankAcc({
                ...firstBankAcc,
                bankAccountNumber1: res.first_account_number.split('-')[0],
                bankAccountNumber2: res.first_account_number.split('-')[1],
                bankAccountNumber3: res.first_account_number.split('-')[2],
            });

            setSecondBankAcc({
                ...secondBankAcc,
                bankAccountNumber1: res.second_account_number ? res.second_account_number.split('-')[0] : '',
                bankAccountNumber2: res.second_account_number ? res.second_account_number.split('-')[1] : '',
                bankAccountNumber3: res.second_account_number ? res.second_account_number.split('-')[2] : '',
            });

            setForeignBankAcc({
                ...secondBankAcc,
                bankAccountNumber1: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[0]
                    : '',
                bankAccountNumber2: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[1]
                    : '',
                bankAccountNumber3: res.foreign_exchange_account_number
                    ? res.foreign_exchange_account_number.split('-')[2]
                    : '',
            });

            setFormData({
                ...res.user.data,
                id: res.id,
                name: res.name,
                full_name: res.full_name,
                pib: res.pib,
                identification_number: res.identification_number,
                iban: res.iban,
                activity: {
                    value: res.activityCode?.data.id,
                    label: res.activityCode?.data.code + ' ' + res.activityCode?.data.name,
                },
                date_of_registration: res.date_of_registration,
                city: res.city,
                swift: res.swift,
                employed_by_other_firm: res.employed_by_other_firm === 0 ? false : true,
                municipality_id: res.municipality_id,
                phone: res.phone,
                agency_email: res.agency_email,
                street_address: res.street_address,
                street_number: res.street_number,
                first_account_number: res.first_account_number,
                employment_changed: false,
                activity_code_id: res.activity_code_id,
                e_invoice_api_key: '',
                e_invoice_vat_exception_code: '',
                e_invoice_vat_sign: '',
            });
        }
        getAgency();
    }, []);

    useEffect(() => {
        formData && useProfileStore.setState({ profile: formData });
        if (clickedField !== '') useChangeModalStore.setState({ changeModalVisible: true, source: 'profile' });
    }, [formData]);

    const update = async (): Promise<void> => {
        if (
            // clickedField !== '' &&
            formData?.name !== '' &&
            formData?.full_name !== '' &&
            formData?.identification_number !== '' &&
            formData?.pib !== '' &&
            formData?.first_account_number !== '' &&
            formData?.street_address !== '' &&
            formData?.street_number !== '' &&
            formData?.agency_email !== '' &&
            formData?.first_account_number?.length === 20 &&
            (formData?.second_account_number?.length === 20 || formData?.second_account_number?.length === 0) &&
            (formData?.foreign_exchange_account_number?.length === 20 ||
                formData?.foreign_exchange_account_number?.length === 0)
        ) {
            try {
                setLoaderVisible(true);
                if (formData) {
                    const res = await communication.updateAgency(formData.id, formData);
                    if (res.status === 200) {
                        setSuccessfullySaved(true);
                        useAgencyStore.setState({ agency: res.data.data });
                    }
                }
                setLoaderVisible(false);
                useChangeModalStore.setState({ changeModalVisible: false });
                setClickedField('');
            } catch (error: any) {
                setLoaderVisible(false);
                setErrorMessage(
                    currentLangName === 'Srpski'
                        ? error.response.data.message
                        : 'You have entered an invalid bank account number. Please try again!',
                );
                setOpenErrorModal(true);
            }
        } else {
            setShowError(true);
        }
    };

    //updating bank accounts
    useEffect(() => {
        const firstAcc = [
            firstBankAcc.bankAccountNumber1,
            firstBankAcc.bankAccountNumber2,
            firstBankAcc.bankAccountNumber3,
        ];
        const first: string = firstAcc.join('-');

        const secondAcc = [
            secondBankAcc.bankAccountNumber1,
            secondBankAcc.bankAccountNumber2,
            secondBankAcc.bankAccountNumber3,
        ];
        const second: string = secondAcc.join('-');

        const foreignAcc = [
            foreignBankAcc.bankAccountNumber1,
            foreignBankAcc.bankAccountNumber2,
            foreignBankAcc.bankAccountNumber3,
        ];
        const foreign: string = foreignAcc.join('-');

        if (formData) {
            setFormData({
                ...formData,
                first_account_number: first,
                second_account_number: second.length === 2 ? '' : second,
                foreign_exchange_account_number: foreign.length === 2 ? '' : foreign,
            });
        }
    }, [firstBankAcc, secondBankAcc, foreignBankAcc]);

    //validations
    const isNameValid = useCharLengthValidation(formData?.name ? formData?.name : '', 3);
    const isEmailValid = useEmailValidation(formData?.agency_email ? formData?.agency_email : '');
    const isAgencyFullNameValid = useCharLengthValidation(formData?.full_name ? formData?.full_name : '', 3);
    const isVatNoValid = useVatNoValidation(formData?.pib ? formData?.pib : '');
    const isAgencyIdNumberValid = useAgencyIdNumberValidation(
        formData?.identification_number ? formData?.identification_number : '',
    );
    const isIbanValid = useNumLengthValidation(formData?.iban ? formData?.iban : '', 3, true);
    const isCityAndZipValid = useCharLengthValidation(formData?.city ? formData?.city : '', 3);
    const isAddressOfHeadQuartersValid = useCharLengthValidation(
        formData?.street_address ? formData?.street_address : '',
        3,
    );
    const isAddressNumberValid = useCharLengthValidation(formData?.street_number ? formData?.street_number : '', 1);

    return (
        <>
            {successfullySaved && (
                <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                    <Success
                        close={() => setSuccessfullySaved(false)}
                        message={t('pages.agency.profile.saveSuccess').text}
                    />
                </Modal>
            )}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            {formData ? (
                <Content>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.agencyName').text}
                        value={formData.name ? formData.name : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, name: value });
                        }}
                        validation={clickedField === 'name' ? isNameValid : ''}
                        blurHandler={() => handleValidation('name')}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.agencyFullName').text}
                        value={formData.full_name ? formData.full_name : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, full_name: value });
                        }}
                        validation={clickedField === 'agencyFullName' ? isAgencyFullNameValid : ''}
                        blurHandler={() => handleValidation('agencyFullName')}
                    />
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.register.rightColumn.form.secondStep.vatNumber').text}
                        value={formData.pib ? formData.pib : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, pib: value });
                        }}
                        validation={clickedField === 'vatNumber' ? isVatNoValid : ''}
                        blurHandler={() => handleValidation('vatNumber')}
                        maxLength={9}
                    />
                    <Input
                        type={InputComponentType.Number}
                        label={t('pages.register.rightColumn.form.secondStep.agencyIdNumber').text}
                        value={formData.identification_number ? formData.identification_number : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, identification_number: value });
                        }}
                        validation={clickedField === 'agencyIdNumber' ? isAgencyIdNumberValid : ''}
                        blurHandler={() => handleValidation('agencyIdNumber')}
                        maxLength={8}
                    />
                    <BankAccountNumber
                        showValidationMessage={
                            clickedField === 'firstbankAccountNumber1' ||
                            clickedField === 'firstbankAccountNumber2' ||
                            clickedField === 'firstbankAccountNumber3'
                        }
                        t={t}
                        formData={firstBankAcc}
                        setFormData={setFirstBankAcc}
                        label={t('pages.agency.profile.firstBankAcc').text}
                        handleValidation={(field: string) => handleValidation(field)}
                        type="first"
                        clickedField={clickedField}
                        showError={showError}
                    ></BankAccountNumber>
                    <BankAccountNumber
                        showValidationMessage={
                            (secondBankAcc.bankAccountNumber1 !== '' && clickedField === 'secondbankAccountNumber1') ||
                            (secondBankAcc.bankAccountNumber2 !== '' && clickedField === 'secondbankAccountNumber2') ||
                            (secondBankAcc.bankAccountNumber3 !== '' && clickedField === 'secondbankAccountNumber3')
                        }
                        t={t}
                        formData={secondBankAcc}
                        setFormData={setSecondBankAcc}
                        label={t('pages.agency.profile.secondBankAcc').text}
                        handleValidation={(field: string) => handleValidation(field)}
                        type="second"
                        clickedField={clickedField}
                        showError={showError}
                    ></BankAccountNumber>
                    <BankAccountNumber
                        showValidationMessage={
                            (foreignBankAcc.bankAccountNumber1 !== '' &&
                                clickedField === 'foreignbankAccountNumber1') ||
                            (foreignBankAcc.bankAccountNumber2 !== '' &&
                                clickedField === 'foreignbankAccountNumber2') ||
                            (foreignBankAcc.bankAccountNumber3 !== '' && clickedField === 'foreignbankAccountNumber3')
                        }
                        t={t}
                        formData={foreignBankAcc}
                        setFormData={setForeignBankAcc}
                        label={t('pages.agency.profile.bankAccForeign').text}
                        handleValidation={(field: string) => handleValidation(field)}
                        type="foreign"
                        clickedField={clickedField}
                        showError={showError}
                    ></BankAccountNumber>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.profile.iban').text}
                        value={formData.iban ? formData.iban : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, iban: value });
                            if (!formData.swift && formData.iban !== '') {
                                setFormData({ ...formData, swift: '-1' });
                            }
                        }}
                        validation={clickedField === 'iban' ? isIbanValid : ''}
                        blurHandler={() => handleValidation('iban')}
                        maxLength={30}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.agency.profile.swift').text}
                        value={formData.swift ? formData.swift : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, swift: value });
                        }}
                        maxLength={30}
                    />
                    {activityCodes && (
                        <SelectWrapper>
                            <p> {t('pages.agency.profile.activityCode').text}</p>
                            <SelectSearch
                                optionList={activityCodes?.map((ac: any) => {
                                    return { value: ac?.id, label: ac?.code + ' ' + ac?.name };
                                })}
                                defaultValue={
                                    formData.activity && {
                                        label: formData.activity.label,
                                        value: formData.activity.value,
                                    }
                                }
                                placeholder={
                                    !formData.activity
                                        ? t('pages.register.rightColumn.form.secondStep.chooseActivityCode').text
                                        : ''
                                }
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setFormData({ ...formData, activity_code_id: data.value, activity: data });
                                    handleValidation('activityCode');
                                }}
                            />
                        </SelectWrapper>
                    )}
                    {formData.date_of_registration && (
                        <Input
                            type={InputComponentType.Date}
                            label={t('pages.register.rightColumn.form.secondStep.agencyDateOfRegistration').text}
                            date={new Date(formData.date_of_registration)}
                            onChange={(value: string) => {
                                setFormData({
                                    ...formData,
                                    date_of_registration: moment(value).format('YYYY-MM-DD').toString(),
                                });
                            }}
                            blurHandler={() => handleValidation('agencyDateOfRegistration')}
                        />
                    )}
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.cityAndZip').text}
                        value={formData.city ? formData.city : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, city: value });
                        }}
                        validation={clickedField === 'cityAndZip' ? isCityAndZipValid : ''}
                        blurHandler={() => handleValidation('cityAndZip')}
                    />
                    <EmployedInAnotherCompany className="employed-in-another-company">
                        <p>{t('pages.register.rightColumn.form.secondStep.employedInAnotherCompany').text}</p>
                        <ToggleSwitch
                            defaultChecked={formData.employed_by_other_firm}
                            onChange={(value: boolean) => {
                                setFormData(() => ({
                                    ...formData,
                                    employed_by_other_firm: value,
                                    employment_changed: true,
                                }));
                            }}
                        />
                    </EmployedInAnotherCompany>
                    {municipalities && (
                        <SelectWrapper>
                            <p> {t('pages.agency.profile.municipality').text}</p>
                            <SelectSearch
                                optionList={municipalities?.map((m: any) => {
                                    return { value: m?.id, label: m?.name };
                                })}
                                defaultValue={
                                    municipalities.find((e) => e.id === formData.municipality_id) && {
                                        value: formData.municipality_id,
                                        label: municipalities.find((e) => e.id === formData.municipality_id)?.name,
                                    }
                                }
                                placeholder={
                                    !formData.municipality_id
                                        ? t('pages.register.rightColumn.form.secondStep.chooseMunicipality').text
                                        : ''
                                }
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setFormData({ ...formData, municipality_id: data.value });
                                    handleValidation('municipality');
                                }}
                            />
                        </SelectWrapper>
                    )}
                    <Address>
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.register.rightColumn.form.secondStep.addressOfHeadQuarters').text}
                            value={formData.street_address ? formData.street_address : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, street_address: value });
                            }}
                            className="address-name"
                            validation={clickedField === 'addressOfHeadQuarters' ? isAddressOfHeadQuartersValid : ''}
                            blurHandler={() => handleValidation('addressOfHeadQuarters')}
                        />
                        <Input
                            type={InputComponentType.Text}
                            label={t('pages.register.rightColumn.form.secondStep.addressNumber').text}
                            value={formData.street_number ? formData.street_number : ''}
                            onChange={(value: string) => {
                                setFormData({ ...formData, street_number: value });
                            }}
                            className="address-num"
                            validation={clickedField === 'addressNumber' ? isAddressNumberValid : ''}
                            blurHandler={() => handleValidation('addressNumber')}
                        />
                    </Address>
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.secondStep.phoneNumber').text}
                        value={formData.phone ? formData.phone : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, phone: value });
                        }}
                    />
                    <Input
                        type={InputComponentType.Text}
                        label={t('pages.register.rightColumn.form.firstStep.email').text}
                        value={formData.agency_email ? formData.agency_email : ''}
                        onChange={(value: string) => {
                            setFormData({ ...formData, agency_email: value });
                        }}
                        validation={clickedField === 'email' ? isEmailValid : ''}
                        blurHandler={() => handleValidation('email')}
                    />
                    <div className="button-container">
                        <Button
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            className="big"
                            size={200}
                            onClick={update}
                        >
                            {t('pages.agency.profile.save').text}
                        </Button>
                    </div>
                </Content>
            ) : (
                <Loader />
            )}
        </>
    );
};
export default AgencyProfile;

const Content = styled.div`
    width: 56%;
    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .button-container {
        display: flex;
        justify-content: center;
        align-items: center;

        @media screen and (max-width: 768px) {
            padding-bottom: 80px;
            margin-top: 20px;
        }
    }
    .bank-number {
        width: 400px;
        position: relative;
    }
    .input-container {
        width: -webkit-fill-available;
        @media screen and (max-width: 768px) {
            margin-bottom: 0px;
            label {
                margin-bottom: 10px;
            }
        }
    }

    input {
        @media screen and (max-width: 768px) {
            border: 1px solid var(--border-color);
            background: var(--white);
            margin-bottom: 20px;
        }
    }

    p {
        font-size: 15px;
        color: var(--gray);
        display: block;
        width: 100%;
        margin: 0;
        padding: 0 0 2px;
        border: 0;
        margin-bottom: 10px;
        :hover {
            color: var(--purple);
        }
    }

    @media screen and (max-width: 1580px) {
        width: 59%;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    .select {
        width: 100%;

        .css-1jqq78o-placeholder {
            color: var(--gray);
            font-size: 15px;
        }
    }
`;

const EmployedInAnotherCompany = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid var(--border-color);
    p {
        color: var(--gray);
    }
`;
const Address = styled.div`
    display: flex;
    align-items: center;
`;
