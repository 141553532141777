import React, { FunctionComponent, useEffect, useState } from 'react';
import Loader from '../../components/loader';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faPlus, faPrint, faSearch } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../components/select';
import Input, { InputComponentType } from '../../components/input';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import NoItems from '../../components/noItems';
import moment from 'moment';
import communication from '../../communication';
import TransferOrderCard from '../../components/transferOrderCard';
import TransferOrderPreviewModal from '../../components/transferOrderPreviewModal';
import Modal from '../../components/modal';
import useAgencyStore from '../../store/agency';
import { generateQrCode } from '../../communication/qr';
import { ObjectKeys } from '../../types/objectKeys';
import AddOrEditModal from './addOrEditModal';
import TransferOrderTemplate from '../../components/transferOrderTemplate';
import Pagination from '../../components/pagination';
import ConfirmModal from '../../components/confirmModal';
import ManageOrdersModal from '../../components/manageOrdersModal';
import InfoModal from '../../components/infoModal';
import { getAccessToken } from '../../functions/auth';

import NoTransferOrderModal from './noTransferOrderModal';
interface SelectProps {
    label: string;
    value: string;
}
const TransferOrders: FunctionComponent = () => {
    const [limit, setLimit] = useState<any>('10');
    const [find, setFind] = useState<string>('');
    // const [page, setPage] = useState<string>('1');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [previewModal, setPreviewModal] = useState<boolean>(false);
    const [orderTransfers, setOrderTransfers] = useState<ObjectKeys[]>();
    const [selectedTransfer, setSelectedTransfer] = useState<ObjectKeys>({});
    const [addOrEditModal, setAddOrEditModal] = useState<boolean>(false);
    const [yearsOptions, setYearsOptions] = useState<Array<{ value: any; label: any }>>([]);
    const [paymentAccounts, setPaymentAccounts] = useState<SelectProps[]>([]);
    const [transferTemplates, setTransferTemplates] = useState<ObjectKeys>([]);
    const [page, setPage] = useState<string>('1');
    const [pagination, setPagination] = useState<any>({});
    const [isDeleteModalActive, setIsDeleteModalActive] = useState<boolean>(false);
    const [isInfoModalActive, setIsInfoModalActive] = useState<boolean>(false);
    const [manageOrdersModalActive, setIsManageOrdersModalActive] = useState<boolean>(false);
    const [transferModal, setTransferModal] = useState(false);
    const [transferAction, setTransferAction] = useState<string>('new');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [qrCode, setQrCode] = useState<string>('');
    const [qrCodes, setQrCodes] = useState<ObjectKeys>({});
    const [isQrCode, setIsQrCode] = useState<boolean>(false);
    const agency = useAgencyStore((props) => props.agency);
    const [selectedYear, setSelectedYear] = useState<string>(moment().year().toString());

    const token = getAccessToken();
    const showSelectOptions = [
        {
            value: '10',
            label: '10',
        },
        {
            value: '20',
            label: '20',
        },
        {
            value: '30',
            label: '30',
        },
        {
            value: '40',
            label: '40',
        },
    ];

    const getAlltransfers = async (): Promise<void> => {
        setIsLoader(true);
        const response = await communication.getAllTransfers(page, limit);
        setPagination(response.data.meta.pagination);
        setOrderTransfers(response.data.data);
    };

    const searchTransfers = async (): Promise<void> => {
        const response = await communication.searchTransfers(find);
        setPagination(response.data.meta.pagination);
        setOrderTransfers(response.data.data);
    };

    const getAllTransferTemplates = async (): Promise<void> => {
        const res = await communication.getTransferTemplates(selectedYear);
        setTransferTemplates(res.data.data);
        await getQrCodesForPrint();
        setIsLoader(false);
    };

    const getAllPaymentAccounts = async (): Promise<void> => {
        const res = await communication.getAllPaymentAccounts();
        setPaymentAccounts(res.data.data);
    };

    const generateYearsForFilters = (): void => {
        setYearsOptions([]);
        const currentYear = moment().year();
        const createYear = moment(agency.date_of_registration).year();
        // eslint-disable-next-line for-direction
        for (let i = currentYear; i >= createYear; i--) {
            setYearsOptions((prevState) => [...prevState, { label: i, value: i }]);
        }
    };
    const handleRefresh = async (): Promise<void> => {
        await getAllPaymentAccounts();
        await getAlltransfers();
        await generateYearsForFilters();
        await getAllTransferTemplates();
    };

    useEffect(() => {
        handleRefresh();
    }, []);

    useEffect(() => {
        getAlltransfers();
        getAllTransferTemplates();
    }, [selectedYear, page]);

    useEffect(() => {
        setPage('1');
        getAlltransfers();
        getAllTransferTemplates();
    }, [limit]);

    useEffect(() => {
        getQrCodesForPrint();
    }, [transferTemplates]);

    useEffect(() => {
        searchTransfers();
    }, [find]);

    const getQrCode = async (transfer: ObjectKeys): Promise<void> => {
        await generateQrCode(
            transfer?.orderer,
            transfer?.recipient,
            transfer.total_amount,
            '0',
            transfer.recipient_account,
            transfer.currency,
            false,
            transfer.call_number_recipient,
        ).then((res: ObjectKeys | any) => {
            setQrCode(res?.i);
            setIsQrCode(true);
        });
    };

    const getQrCodesForPrint = async (): Promise<void> => {
        setQrCodes({});
        transferTemplates.map(
            async (transfer: {
                orderer: ObjectKeys;
                recipient: ObjectKeys;
                total_amount: string;
                recipient_account: string;
                currency: string;
                call_number_recipient: string | null | undefined;
                id: any;
            }) => {
                setQrCodes((prevState) => ({
                    ...prevState,
                    [transfer.id]: '""',
                }));
                await generateQrCode(
                    transfer?.orderer,
                    transfer?.recipient,
                    transfer.total_amount,
                    '0',
                    transfer.recipient_account,
                    transfer.currency,
                    false,
                    transfer.call_number_recipient,
                )
                    .then((res: ObjectKeys | any) => {
                        setQrCodes((prevState) => ({
                            ...prevState,
                            [transfer.id]: res?.i ? `data:image/jpeg;base64${res.id}` : '""',
                        }));
                    })
                    .catch((err: any) => {
                        console.log(err);
                    });
            },
        );
    };

    const downloadTaxes = async (): Promise<void> => {
        communication.downloadTaxes(selectedYear, token, JSON.stringify(qrCodes));
    };
    const downloadTax = async (id: string): Promise<void> => {
        communication.downloadTax(id, token, `data:image/jpeg;base64,${qrCode}`);
    };

    const handleClickOnCard = async (transfer: ObjectKeys, action: string): Promise<void> => {
        setSelectedTransfer(transfer);
        switch (action) {
            case 'preview':
                setPreviewModal(true);
                await getQrCode(transfer);
                return;
            case 'edit':
                setTransferAction('edit');
                setAddOrEditModal(true);
                return;
            case 'delete':
                setIsDeleteModalActive(true);
                return;
            case 'print':
                await downloadTax(transfer?.id);
                return;
            default:
                break;
        }
    };

    const handleDelete = async (): Promise<void> => {
        setIsLoader(true);
        const res = await communication.deleteTransferOrder(selectedTransfer?.id);
        if (res.data.message === 'TemplateTransfer () Deleted Successfully.') {
            await getAlltransfers();
            await getAllTransferTemplates();
        }
        setIsDeleteModalActive(false);
        setIsLoader(false);
    };

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}

            {previewModal && (
                <Modal
                    modalVisible={previewModal}
                    closeModal={() => {
                        setPreviewModal(false);
                        setIsQrCode(false);
                    }}
                    size={'800px'}
                    className={'preview-modal '}
                >
                    <TransferOrderPreviewModal
                        downloadTax={downloadTax}
                        isQrCode={isQrCode}
                        image={qrCode}
                        onClose={() => {
                            setPreviewModal(false);
                        }}
                        transfer={selectedTransfer}
                    />
                </Modal>
            )}

            {paymentAccounts.length > 0 && addOrEditModal && (
                <Modal
                    modalVisible={addOrEditModal}
                    closeModal={() => setAddOrEditModal(false)}
                    size={'800px'}
                    className={'preview-modal '}
                >
                    <AddOrEditModal
                        onClose={() => setAddOrEditModal(false)}
                        paymentAccounts={paymentAccounts}
                        selectedTransfer={selectedTransfer}
                        action={transferAction}
                        refresh={handleRefresh}
                    />
                </Modal>
            )}

            {manageOrdersModalActive && (
                <Modal
                    className={'manage-orders-modal '}
                    modalVisible={manageOrdersModalActive}
                    closeModal={() => {
                        setIsManageOrdersModalActive(false);
                        setTransferModal(false);
                    }}
                >
                    {transferTemplates.length > 0 ? (
                        <ManageOrdersModal
                            closeModal={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            year={selectedYear}
                            setIsLoader={setIsLoader}
                            setOpenInfoModal={setIsInfoModalActive}
                            callNumber={transferTemplates[0].call_number_recipient}
                            refresh={() => handleRefresh()}
                        />
                    ) : (
                        <NoTransferOrderModal
                            currentLangName={currentLangName}
                            t={t}
                            close={() => setIsManageOrdersModalActive(false)}
                            yearsOptions={yearsOptions}
                            setIsLoader={setIsLoader}
                            setIsInfoModalActive={setIsInfoModalActive}
                            handleRefresh={() => handleRefresh()}
                            transferModalData={transferModal}
                            year={selectedYear}
                        />
                    )}
                </Modal>
            )}

            <Modal
                className={'info-modal '}
                modalVisible={isInfoModalActive}
                closeModal={() => setIsInfoModalActive(false)}
            >
                <InfoModal
                    setOpenModal={setIsInfoModalActive}
                    message={t('pages.transfers.transfer_upload_success').text}
                    buttonText={t('buttons.ok').text}
                />
            </Modal>

            <Modal modalVisible={isDeleteModalActive} closeModal={() => setIsDeleteModalActive(false)}>
                <ConfirmModal
                    close={() => setIsDeleteModalActive(false)}
                    action={() => handleDelete()}
                    message={'asd'}
                />
            </Modal>

            <Header>
                <h1>{t('pages.transfers.title').text}</h1>
                <div className="header-right">
                    <div className="header-options">
                        <div className="header-options-buttons">
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => downloadTaxes()}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPrint} />
                                {t('pages.transfers.print_transfers').text}
                            </Button>
                            <Button
                                className={'button'}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                onClick={() => setIsManageOrdersModalActive(true)}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPencil} />
                                {t('pages.transfers.manage_transfers').text}
                            </Button>
                        </div>
                    </div>
                </div>
                <Filters>
                    <label>{`${t('pages.transfers.filter_first_description').text} ${selectedYear}`}</label>
                    <div className="search-filter">
                        <SelectComponent
                            optionList={yearsOptions}
                            defaultSelectedOption={yearsOptions[0]}
                            className={'select-year'}
                            placeholder={selectedYear}
                            handleSelectedValue={(value: string) => setSelectedYear(value)}
                        />
                    </div>
                </Filters>
            </Header>

            {transferTemplates.length === 0 && (
                <NoItems text={`${t('pages.transfers.no_tax_transfers').text} ${moment().year()}.`} />
            )}

            <TransferOrderTemplateContainer>
                {transferTemplates?.map((template: object, index: number) => {
                    if (agency?.employed_by_other_firm) {
                        if (index < 2)
                            return (
                                <TransferOrderTemplate
                                    key={index}
                                    template={template}
                                    onClick={() => {
                                        setSelectedTransfer(template);
                                        setPreviewModal(true);
                                    }}
                                />
                            );
                    } else {
                        return (
                            <TransferOrderTemplate
                                key={index}
                                template={template}
                                onClick={() => {
                                    setSelectedTransfer(template);
                                    setPreviewModal(true);
                                }}
                            />
                        );
                    }
                })}
            </TransferOrderTemplateContainer>
            <Header>
                <Filters>
                    <div className="filter">
                        <label>{t('pages.transfers.show').text}</label>
                        <SelectComponent
                            optionList={showSelectOptions}
                            defaultSelectedOption={showSelectOptions[0]}
                            color={colors.gray}
                            className={'select'}
                            handleSelectedValue={(value: any) => {
                                setLimit(value);
                            }}
                        />
                    </div>
                    <label>{t('pages.transfers.show_transfers').text}</label>
                    <div className="search-filter">
                        <Input
                            type={InputComponentType.Text}
                            value={find}
                            onChange={(value: string) => setFind(value)}
                            // className={'find'}
                            inputClassName={'find'}
                            placeholder={t('pages.memos.search').text}
                        >
                            <FontAwesomeIcon className={'find-icon'} icon={faSearch} color={colors.gray} />
                        </Input>
                    </div>
                </Filters>
                <Button
                    className={'button-add'}
                    variant={ButtonVariant.solid}
                    color={colors.purple}
                    onClick={() => {
                        console.log(transferAction);
                        setAddOrEditModal(true);
                    }}
                    icon={true}
                    height={35}
                >
                    <FontAwesomeIcon icon={faPlus} />
                    {t('pages.transfers.add_transfer').text}
                </Button>
            </Header>
            <Content>
                {orderTransfers?.map((transfer: any) => (
                    <TransferOrderCard
                        key={transfer.id}
                        title={transfer.payment_slip_heading}
                        price={transfer.total_amount}
                        currency={transfer.currency}
                        onClick={() => handleClickOnCard(transfer, 'preview')}
                        onEdit={() => handleClickOnCard(transfer, 'edit')}
                        onDelete={() => handleClickOnCard(transfer, 'delete')}
                        onPrint={() => handleClickOnCard(transfer, 'print')}
                    />
                ))}
            </Content>
            <Pagination
                pageCount={pagination?.total_pages}
                initialPage={0}
                onPageChange={(e: object) => {
                    handlePageClick(e);
                }}
                nextLabel={(pagination?.current_page === pagination?.total_pages ? '' : '>').toString()}
                previousLabel={(pagination?.current_page === 1 ? '' : '<').toString()}
            />
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
    .button-add {
        margin-top: 20px;
    }
    .preview-modal {
        .modal-wrapper {
            width: 800px;
            padding: 15px;
            overflow: auto;
            max-height: 100vh;
        }
    }
    .manage-orders-modal {
        .modal-wrapper {
            max-height: 90%;
            overflow: auto;
        }
    }
    label {
        font-size: 18px;
        line-height: 34px;
    }
`;

const Content = styled.div``;

const TransferOrderTemplateContainer = styled.div`
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 auto 25px;
    width: 100%;
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
        border: none;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
    .header-options {
        display: flex;
    }
    .header-options-buttons {
        display: flex;
        button {
            margin-left: 20px;
        }
        @media (max-width: 670px) {
            flex-direction: column;
            button {
                margin-top: 12px;
            }
        }
    }
`;

const Filters = styled.div`
    height: 80px;
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding: 20px 20px;
    background-color: var(--purple);
    color: var(--white);
    vertical-align: middle;
    @media (max-width: 670px) {
        height: auto;
    }
    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: flex;
        float: right;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }

    .search-filter {
        float: right;
        right: 0;
        top: 78px;
        margin-top: 1.5px;
        @media (max-width: 1250px) {
            margin-right: 0;
        }
        @media (max-width: 670px) {
            width: 100%;
            float: left;
            margin-top: 20px;
            .select-year {
                float: left;
            }
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
        input {
            font-size: 14px;
            width: 150px;
        }
        .input {
            width: 150px;
        }
    }
    .select-year {
        background-color: var(--white);
        color: var(--gray);
        float: right;
        width: fit-content;
    }
    .select__control {
        border-radius: 0px;
    }
`;
export default TransferOrders;
