import { Dispatch, FunctionComponent, SetStateAction } from 'react';
import styled from 'styled-components';
import colors from '../../global/colors';
import Button, { ButtonVariant } from '../button';
type Props = {
    t: Function;
    setOpenModal: Dispatch<SetStateAction<boolean>>;
    errorMessage: string;
};

const ErrorModal: FunctionComponent<Props> = ({ t, setOpenModal, errorMessage }) => {
    return (
        <ErrorModalWrapper>
            <ModalText>
                <h4>{t('modal.error.title').text}</h4>
                <p>{errorMessage}</p>
            </ModalText>
            <Button
                variant={ButtonVariant.solid}
                color={colors.purple}
                size={'100%'}
                onClick={() => {
                    setOpenModal(false);
                }}
            >
                {t('buttons.ok').text}
            </Button>
        </ErrorModalWrapper>
    );
};

const ErrorModalWrapper = styled.div`
    text-align: center;
    button {
        border-radius: 0 0 5px 5px;
    }
`;
const ModalText = styled.div`
    padding: 35px 50px 30px;
    h4 {
        margin-bottom: 30px;
    }
    p {
        line-height: 30px;
    }
`;

export default ErrorModal;
