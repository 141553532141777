import { FunctionComponent, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import TextArea from '../../../../components/input/textarea';
import useCharLengthValidation from '../../../../hooks/useCharLengthValidation';
import useEmailValidation from '../../invoices/sendModal/useEmailValidation';
import communication from '../../../../communication';
import Checkbox from '../../../../components/checkbox';
import useProfileStore from '../../../../store/profile';
import { ObjectKeys } from '../../../../types/objectKeys';
import getAllProforma from '../getAllProforma';
import useSendMeEmail from '../../../../hooks/useSendMeEmail';

interface Props {
    t: Function;
    formData: ObjectKeys;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    clientEmail: string;
    id: string;
    setProforma: Dispatch<SetStateAction<ObjectKeys>>;
    modalVisible: boolean;
    handleAddNewProforma?: Function;
    qrCode?: string;
}

const SendModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    id,
    clientEmail,
    setProforma,
    handleAddNewProforma,
    qrCode,
    modalVisible,
}) => {
    const isSubjectValid = useCharLengthValidation(formData.subject, 3);
    const isMessageValid = useCharLengthValidation(formData.message, 3);

    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState<Array<string>>([]);
    const [sendMe, setSendMe] = useState(false);
    const { profile } = useProfileStore();
    const [userEmail, setUserEmail] = useState('');
    const isEmailValid = useEmailValidation(formData.recipient, emails);
    const [fromInput, setFromInput] = useState(false);

    useEffect(() => {
        if (clientEmail) {
            setShowEmails(true);
            setEmails([...emails, clientEmail]);
        }
    }, []);

    useEffect(() => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
    }, [modalVisible]);

    const handleDataToSend = async (proformaId?: string): Promise<void> => {
        if (isEmailValid === '' && emails?.length > 0 && proformaId) {
            loading(true);

            const requestData = {
                message: formData.message,
                subject: formData.subject,
                emails: emails,
                qr_code: qrCode ? `data:image/jpeg;base64,${qrCode}` : '',
            };
            const res = await communication.sendProforma(proformaId, requestData);

            if (res.status === 200) {
                loading(false);
                success();
                close();
                getAllProforma(setProforma);
            }
        } else setShowError(true);
    };

    const sendEmail = async (): Promise<void> => {
        setShowError(true);

        if (handleAddNewProforma !== undefined) {
            const newProformaId = await handleAddNewProforma();
            handleDataToSend(newProformaId);
        } else {
            handleDataToSend(id);
        }
    };
    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );
    return (
        <>
            <Container>
                <Header>
                    <h2>{t('pages.proforma.sendModal.title').text}</h2>
                    <Icon onClick={() => close()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>
                <label>{t('pages.proforma.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails
                            ?.filter((email: string) => email !== '')
                            .map((e: string) => (
                                <Email key={e}>
                                    {e}
                                    <span onClick={() => handleRemoveEmail(e)}>x</span>
                                </Email>
                            ))}
                </div>
                <Input
                    className="email"
                    type={InputComponentType.Text}
                    value={formData.recipient ? formData.recipient : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, recipient: value });
                    }}
                    onEnter={() => {
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    validation={showError ? isEmailValid : ''}
                    handleBlurAction={() => {
                        if (emails.includes(formData.recipient)) {
                            setFormData({ ...formData, recipient: '' });
                            return;
                        }
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setFromInput(true);
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                />

                <Input
                    type={InputComponentType.Text}
                    label={t('pages.proforma.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                    validation={showError ? isSubjectValid : ''}
                />
                <TextArea
                    label={t('pages.proforma.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    validation={showError ? isMessageValid : ''}
                />
                <Footer>
                    <div>
                        <Icon className="attach">
                            <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                            {t('pages.proforma.sendModal.attached').text}
                        </Icon>
                        <div className="send-copy">
                            <Checkbox onChange={() => setSendMe(!sendMe)} />
                            {t('pages.proforma.sendModal.sendMeCopy').text}
                        </div>
                    </div>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={sendEmail}
                    >
                        <FontAwesomeIcon icon={faPaperPlane} style={{ color: 'var(--white)', marginRight: '10px' }} />
                        {t('pages.proforma.sendModal.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }
`;

const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    margin-bottom: 30px;
    h2 {
        font-size: 24px;
        font-weight: 400;
        text-align: left;
    }
    p {
        width: 90%;
        font-size: 14px;
        line-height: 20px;
        margin-top: 10px;
        color: var(--gray);
        margin-bottom: 30px;
    }
`;

export const Footer = styled.div`
    > div {
        margin-top: 20px;
        font-size: 13px;
        color: var(--gray);
        display: flow-root;
        .send-copy {
            float: right;
            display: flex;
        }
    }
`;

export const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
