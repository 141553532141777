import React, { FunctionComponent, useEffect, useState } from 'react';
import SelectSearch from '../../../components/selectSearch';
import { ObjectKeys } from '../../../types/objectKeys';
import Pagination from '../../../components/pagination';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useAllUsersStore from '../../../store/admin/allUsers';
import communication from '../../../communication';
import Loader from '../../../components/loader';
import TransactionCardAdmin from '../../../components/transactionCardAdmin';
import SelectComponent from '../../../components/select';
import Sidebar from '../../../components/sideModal';
import Button, { ButtonVariant } from '../../../components/button';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faClose } from '@fortawesome/free-solid-svg-icons';
import Input, { InputComponentType } from '../../../components/input';
import Search from '../../../components/input/search';
import moment from 'moment';

type NewTransactionTypes = {
    amount: string;
    transaction_date: Date | undefined | null | string;
    type: string;
    package: string;
    user_id: string;
};
type NewTransactionValidationTypes = {
    amount: string;
    transaction_date: string;
    type: string;
    package: string;
    user_id: string;
};

const Transactions: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { allUsers } = useAllUsersStore();
    const transactionDataInitial = {
        amount: '',
        transaction_date: null,
        type: '',
        package: '1',
        user_id: '',
    };

    const validatorDataInitial = {
        amount: '',
        transaction_date: '',
        type: '',
        package: '',
        user_id: '',
    };
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [addTransactionModal, setAddTransactionModal] = useState(false);
    const [editTransactionModal, setEditTransactionModal] = useState(false);
    const [page, setPage] = useState('1');
    const [allUsersOptions, setAllUsersOptions] = useState<Array<{ value: string; label: string; id: string }>>([]);
    const [selectedUserId, setSelectedUserId] = useState('');
    const [selectedTransactionId, setSelectedTransactionId] = useState('');
    const [selectedType, setSelectedType] = useState<string>('');
    const [transactions, setTransactions] = useState<ObjectKeys>([]);
    const [userPib, setUserPib] = useState<string>('');
    const [usersByPib, setUsersByPib] = useState<ObjectKeys>([]);
    const [editTransactionDate, setEditTransactionDate] = useState<string>('');
    const [selectedUser, setSelectedUser] = useState<ObjectKeys>({});

    const [newTransactionData, setNewTransactionData] = useState<NewTransactionTypes>(transactionDataInitial);

    const [newTransactionDataValidation, setNewTransactionDataValidation] =
        useState<NewTransactionValidationTypes>(validatorDataInitial);

    const types = [
        { value: 'all', label: 'All' },
        { value: 'payment', label: 'Payment' },
        { value: 'trial', label: 'Trial' },
        { value: 'promo', label: 'Promo' },
    ];
    const packageOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
        { value: '12', label: '12' },
    ];

    useEffect(() => {
        const userData = allUsers?.map((user) => {
            return { value: user.id, label: user.email + '-' + user.pib, id: user.id };
        });
        setAllUsersOptions([{ value: '', label: 'All Users', id: '' }, ...userData]);
    }, [allUsers]);

    const updateData = async (): Promise<void> => {
        setLoaderVisible(true);
        if (selectedUserId.length > 0) {
            await communication.getFilteredTransaction(selectedUserId, selectedType, page).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setTransactions(res?.data);
                    setLoaderVisible(false);
                }
            });
        } else {
            await communication.getAllTransactions(page, selectedType).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setTransactions(res?.data);
                    setLoaderVisible(false);
                }
            });
        }
    };

    useEffect(() => {
        updateData();
    }, [page, selectedUserId, selectedType]);

    const getUsersByPib = (pib: string): void => {
        setUserPib(pib);
        if (pib.length > 2) {
            communication.getUsersByPib(pib).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setUsersByPib(res?.data);
                    setLoaderVisible(false);
                }
            });
        }
    };

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    const validateData = (): boolean => {
        newTransactionData.amount.length < 0 || newTransactionData.amount === ''
            ? setNewTransactionDataValidation((prevState) => ({ ...prevState, amount: 'Iznos je obavezan' }))
            : setNewTransactionDataValidation((prevState) => ({ ...prevState, amount: '' }));

        newTransactionData.transaction_date === undefined || newTransactionData.transaction_date === null
            ? setNewTransactionDataValidation((prevState) => ({ ...prevState, transaction_date: 'Datum je obavezan' }))
            : setNewTransactionDataValidation((prevState) => ({ ...prevState, transaction_date: '' }));

        newTransactionData.user_id === '' || newTransactionData.user_id.length < 1
            ? setNewTransactionDataValidation((prevState) => ({ ...prevState, user_id: 'Korisnik nije izabran' }))
            : setNewTransactionDataValidation((prevState) => ({ ...prevState, user_id: '' }));

        newTransactionData.package === ''
            ? setNewTransactionDataValidation((prevState) => ({ ...prevState, package: 'Paket nije izabran' }))
            : setNewTransactionDataValidation((prevState) => ({ ...prevState, package: '' }));

        newTransactionData.type === ''
            ? setNewTransactionDataValidation((prevState) => ({ ...prevState, type: 'Tip nije izabran' }))
            : setNewTransactionDataValidation((prevState) => ({ ...prevState, type: '' }));

        return !(
            newTransactionDataValidation.transaction_date.length > 0 ||
            newTransactionDataValidation.amount.length > 0 ||
            newTransactionDataValidation.package.length > 0 ||
            newTransactionDataValidation.user_id.length > 0 ||
            newTransactionDataValidation.package.length > 0
        );
    };

    const resetData = (): void => {
        setNewTransactionData(transactionDataInitial);
        setNewTransactionDataValidation(validatorDataInitial);
        setEditTransactionDate('');
        setSelectedTransactionId('');
    };

    const handleCreateNewTransaction = async (): Promise<void> => {
        setLoaderVisible(true);
        const validator = validateData();
        if (validator)
            await communication.createNewTransactions(newTransactionData).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setUsersByPib(res?.data);
                    setAddTransactionModal(false);
                    resetData();
                    updateData();
                    setLoaderVisible(false);
                }
            });
    };

    const handleUpdateTransaction = (): void => {
        setLoaderVisible(true);
        communication.updateTransaction(selectedTransactionId, editTransactionDate).then((res: ObjectKeys) => {
            if (res.status === 200) {
                setEditTransactionModal(false);
                resetData();
                updateData();
                setLoaderVisible(false);
            }
        });
    };

    const handleDeleteTransaction = (id: string): void => {
        setLoaderVisible(true);
        communication.deleteTransaction(id).then((res: ObjectKeys) => {
            if (res.status === 200 || res.status === 202) {
                setEditTransactionModal(false);
                resetData();
                updateData();
                setLoaderVisible(false);
            }
        });
    };
    return (
        <>
            {loaderVisible && <Loader />}
            <PageWrapper className=" page">
                {editTransactionModal && (
                    <Sidebar close={() => setEditTransactionModal(false)}>
                        <SidebarContent>
                            <div className="header">
                                <h1>Uredi transakciju</h1>
                                <button className={'close_button'} onClick={() => setEditTransactionModal(false)} />
                            </div>
                            <Input
                                type={InputComponentType.Date}
                                label={'Datum'}
                                validation={newTransactionDataValidation.transaction_date}
                                date={!editTransactionDate ? null : moment(editTransactionDate).toDate()}
                                onChange={(value: Date | undefined | null) =>
                                    setEditTransactionDate(moment(value).format('YYYY-MM-DD').toString())
                                }
                            />
                            <div className={'button-wrapper'}>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    onClick={() => {
                                        handleUpdateTransaction();
                                    }}
                                >
                                    Uredi Transakciju
                                </Button>
                            </div>
                        </SidebarContent>
                    </Sidebar>
                )}

                {addTransactionModal && (
                    <Sidebar close={() => setAddTransactionModal(false)}>
                        <SidebarContent>
                            <div className="header">
                                <h1>Dodaj transakciju</h1>
                                <button className={'close_button'} onClick={() => setAddTransactionModal(false)} />
                            </div>
                            <div className="content">
                                <Search
                                    label={'PIB'}
                                    value={userPib}
                                    options={usersByPib}
                                    onChange={(value: string) => getUsersByPib(value)}
                                    onSelect={(data: ObjectKeys) => {
                                        setSelectedUser(data);
                                        setUserPib(data.pib);
                                        setNewTransactionData((prevState) => ({ ...prevState, user_id: data.user_id }));
                                    }}
                                />
                                {selectedUser.pib && (
                                    <span className={'selected-user'}>
                                        {selectedUser.pib} - {selectedUser.email}{' '}
                                        <FontAwesomeIcon
                                            icon={faClose}
                                            color={colors.gray}
                                            className="delete-selected-user"
                                            onClick={() => setSelectedUser({})}
                                        />
                                    </span>
                                )}

                                <Input
                                    validation={newTransactionDataValidation.amount}
                                    label={'IZNOS'}
                                    value={newTransactionData.amount}
                                    onChange={(value: string) =>
                                        setNewTransactionData((prevState) => ({ ...prevState, amount: value }))
                                    }
                                />
                                <Input
                                    type={InputComponentType.Date}
                                    label={'Datum'}
                                    validation={newTransactionDataValidation.transaction_date}
                                    date={
                                        !newTransactionData.transaction_date
                                            ? null
                                            : moment(newTransactionData.transaction_date).toDate()
                                    }
                                    onChange={(value: Date | undefined | null) =>
                                        setNewTransactionData((prevState) => ({
                                            ...prevState,
                                            transaction_date: moment(value).format('YYYY-MM-DD').toString(),
                                        }))
                                    }
                                />
                                <SelectComponent
                                    label={'Type: '}
                                    optionList={types.filter((type) => type.value !== 'all')}
                                    handleSelectedValue={(value: string) => {
                                        setNewTransactionData((prevState) => ({ ...prevState, type: value }));
                                    }}
                                />
                                <SelectComponent
                                    label={'Package: '}
                                    optionList={packageOptions}
                                    handleSelectedValue={(value: string) =>
                                        setNewTransactionData((prevState) => ({ ...prevState, package: value }))
                                    }
                                />
                                <div className={'button-wrapper'}>
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        onClick={() => handleCreateNewTransaction()}
                                    >
                                        Dodaj Transakciju
                                    </Button>
                                </div>
                            </div>
                        </SidebarContent>
                    </Sidebar>
                )}
                <Header>
                    <h1>{t('pages.admin.transactions.title').text}</h1>
                    <Button
                        className={'create-button'}
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        onClick={() => setAddTransactionModal(true)}
                        icon={true}
                    >
                        <FontAwesomeIcon style={{ paddingLeft: '10px' }} color={colors.white} icon={faPlus} />
                        Nova Transakcija
                    </Button>
                </Header>
                <SelectWrapper>
                    <div className="agency-select-wrapper">
                        <label>{t('pages.admin.users.chooseUser').text}</label>
                        <div className="select-container">
                            <SelectSearch
                                optionList={allUsersOptions}
                                handleSelectedValue={(data: { value: string; label: string }) => {
                                    setSelectedUserId(data.value);
                                }}
                                defaultValue={allUsersOptions[0]}
                            />
                        </div>
                    </div>
                    <div className="selecttype">
                        <label>TYPE: </label>
                        <SelectComponent
                            optionList={types}
                            defaultSelectedOption={types[0]}
                            handleSelectedValue={(value: string) => setSelectedType(value)}
                        />
                    </div>
                </SelectWrapper>

                {transactions?.data?.length > 0 &&
                    transactions?.data.map((transaction: ObjectKeys, index: number) => (
                        <TransactionCardAdmin
                            key={transaction.id}
                            transaction={transaction}
                            index={index + 1}
                            handleDelete={() => {
                                handleDeleteTransaction(transaction.id);
                            }}
                            handleEdit={() => {
                                setSelectedTransactionId(transaction.id);
                                setEditTransactionModal(true);
                            }}
                        />
                    ))}
                {transactions?.data?.length > 0 && transactions.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={transactions.meta?.pagination?.total_pages}
                        onPageChange={(e: object) => handlePageClick(e)}
                        nextLabel={`${
                            transactions.meta?.pagination?.current_page === transactions.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${transactions.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={transactions.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    .create-button {
        text-transform: uppercase;
        font-size: 12px;
        padding: 8px 20px;
        display: inline-block;
        vertical-align: top;
        margin: 4px 0 0 35px;
        border: 1px solid var(--purple);
        border-radius: 100px;
        cursor: pointer;
        background: var(--purple);
        color: var(--white);
    }
`;

const SidebarContent = styled.div`
    .button-wrapper {
        display: flex;
        justify-content: center;
        button {
            border-radius: 100px;
        }
    }
    .header {
        position: relative;
        margin-bottom: 20px;
        h1 {
            font-size: 24px;
            color: #4c595f;
            font-weight: 400;
            display: inline;
        }
        .close_button {
            position: absolute;
            top: 10px;
            right: 10px;
            display: block;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAfCAYAAACGVs+MAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAs1JREFUeNq0l81LVFEYxu+cJkkTG5hFIOmmFi6iRS6EKIoGYygbK/qQiMQ25UJ3NdBfENmmJCJa9EH0JSXaB1NiJBLVIok2FbQIowIhsJJRxsqeF56By3DeM/c6txd+szjv13Mu9555T+zlmw8Jz/POg13gGzgL+r3/Yz0gC5aDB6A3jp+LYD8DVoNzoAGciLj5KTYv2iFQZfDTZgk+TiGxCBrHWCtr8WVEwCfH47pQoYgYa/Qo/s8i4CT4owQcBVfAkkU0l5zLrGEz6ZkVAUPgIJhXAg+D6yAeonmcOZ2Kf549Bw0X7oC9oKAkdIDb8tIEaF7F2A7FLz32sadnfI5h0A5mlcQ94B5Y5mguvruMtdksewwVF0xJQA7sBHmlwA4KrbH4auhrU3LzrJ3zLxpL4ChIg59KoVbwCNT61mq51qrk/GLN0VKHURLGwTYwrfg3g8dgBclxzWbTFDauva2avQIp8AQkLf4Nvh01KzW+cyMTWhNT5o2WxK1gSvE3O5pPMXfC1cAE+Kze8vF+DXEOSOwW5nqVChB7TxGTAWInGfsuSGETYlcfeSqWs07GelELWAOuBYi7ythIBTSBMdAYILaRsU1RCVgLnoH6EE+rnjnrKhWwnoVWKv7XxGaS85Q1FiWghQdNUvG/4EElPFdikqzRElbARp6ACcU/xhPuB0lzzWYJ1toUVECKZ3udUnAEbAczvrUZro0oOXWsmSonQHZyn2OzzeQfL6P8Xefpe6jk1rB2WhOQ4aBQrRQYBLvBnOO9mfMNLjarZo/2UgFyLxhwjFy3GFMI8AlKzAHmaCPbQPEuEqeaG47JV06/I47J2Wa/efEoKMf3UvYsyBM442h+CXSFbO4fu7t489LG9tMiYJUS0M+Z/m8FFxPJ7XbcNRsM38xS65OLI1iI4Gq2wFp9Ft+w4S5v8jP6QsVRX0w91uxmjzx7HvsnwADMMpd5tpG+eAAAAABJRU5ErkJggg==)
                no-repeat;
            background-size: 16px 16px;
            width: 16px;
            height: 16px;
            text-indent: -9999px;
            border: 0;
            &:hover {
                cursor: pointer;
            }
        }
    }
    .content {
        .select-search {
            margin-bottom: 20px;
        }
        .select-wrapper {
            width: 100%;
            label {
                font-size: 15px;
                color: var(--gray);
                padding-left: 0;
            }
            .select__control {
                margin-top: 5px;
                margin-bottom: 20px;
            }
        }
        .search-wrapper {
            margin-bottom: 20px;
            margin-top: 5px;
        }

        .options-list {
            z-index: 1;
            top: 9em;
        }
    }
    .selected-user {
        display: block;
        margin-top: 15px;
        margin-bottom: 20px;
        font-size: 15px;
        color: var(--gray);
        .delete-selected-user {
            float: right;
            cursor: pointer;
        }
    }
`;

const SelectWrapper = styled.div`
    margin-bottom: 20px;
    &.full-width {
        > div {
            width: 100%;
        }
    }
    p {
        color: var(--gray);
    }
    .select-container {
        width: 350px;
        margin-left: 10px;
        @media only screen and (max-width: 600px) {
            width: 200px;
        }
    }
    .select-search {
        width: 100%;
        .selected-value,
        .select-with-search__input-container {
            color: var(--black);
        }

        div {
            font-size: 15px;
            color: var(--gray);
            .select-with-search__menu-list {
                .select-with-search__option {
                    &:hover {
                        color: var(--white);
                    }
                    &.select-with-search__option--is-selected {
                        color: var(--white);
                        border-radius: 0;
                    }
                }
            }
        }
    }
    .agency-select-wrapper {
        display: inline-block;
        width: 100%;
        margin-bottom: 25px;
        label {
            display: inline-block;
            color: #92a5ac;
            font-size: 16px;
            position: relative;
            top: 2px;
            margin-right: 10px;
        }
        .select-container {
            width: 350px;
            display: inline-block;
        }
    }
    .selecttype {
        display: flex;
        align-items: center;
        margin-left: 20px;

        label {
            text-transform: uppercase;
            line-height: 20px;
            font-size: 14px;
            margin-right: 6px;
            color: var(--gray);
        }
    }
`;

export default Transactions;
