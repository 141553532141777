import fetch from '../fetch';
import routes from '../routes';
import { ObjectKeys } from '../../types/objectKeys';
import getCurrentLanguage from '../../functions/getCurrentLanguage';

export default class Statistics {
    public getBanks(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.statistics.getBanks,
        });
    }
    public getUsers(): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.statistics.getUsers,
        });
    }
    public getUsersByBank(bankId: string, params: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.statistics.getUsersByBank(bankId),
            params,
        });
    }
    public getPayments(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.statistics.transactions,
            data,
        });
    }
    public getRegistrationStatistics(data: ObjectKeys): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.statistics.registration,
            data,
        });
    }
    public replyOnSuggestion(data: ObjectKeys, id: string): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.admin.suggestions.sendResponse(id),
            data,
        });
    }
}
