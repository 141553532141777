import { FunctionComponent, useState, useRef } from 'react';
import styled from 'styled-components';
import logo from '../../../assets/pausal-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import colors from '../../../global/colors';
import Dropdown from '../../dropdown';
import { faCaretDown, faBars } from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { AccountExpires, Icons, UserDropdown, AccountExpired } from '../web';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useChangeModalStore from '../../../store/changeModal';
import useUserStore from '../../../store/user';
import useProfileStore from '../../../store/profile';
import SelectLang from '../../selectLang';
import Nbs from '../nbs';
import { Link } from 'react-router-dom';
import Button, { ButtonVariant } from '../../button';
import Modal from '../../modal';
import ChangeModal from '../../changeModal';
import Success from '../../successModal';
import AccountExpiration from '../accountExpiration';
import moment from 'moment';

interface Props {
    icons: Icons[];
    userDropdown: UserDropdown[];
    role: string | null;
    openSuggestionModal: Function;
    expires: string | undefined;
    isAccountActive: boolean;
}

const MobileNavbar: FunctionComponent<Props> = ({
    icons,
    userDropdown,
    role,
    openSuggestionModal,
    expires,
    isAccountActive,
}) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);

    const [selectedItem, setSelectedItem] = useState(0);
    const [userDropdownVisible, setUserDropdownVIsible] = useState(false);
    const [menuDropdownVisible, setMenuDropdownVIsible] = useState(false);

    const [nbsDropdownVisible, setNbsDropdownVisible] = useState(false);
    const [accountExpires, setAccountExpires] = useState(false);

    const [changeVisible, setChangeVisible] = useState(useChangeModalStore.getState().changeModalVisible);
    const { updateUser } = useUserStore();
    const { updateAgency, profile } = useProfileStore();
    const { handleClickOutside } = useOutsideClick(ref, setUserDropdownVIsible);

    const [successfullySaved, setSuccessfullySaved] = useState(false);

    const handleSelect = (i: number, text: string): void => {
        setChangeVisible(useChangeModalStore.getState().changeModalVisible);

        setSelectedItem(i);
        if (!useChangeModalStore.getState().changeModalVisible) {
            if (text === t('navbar.pausal.nbs').text) {
                setNbsDropdownVisible(true);
                return;
            } else if (text === t('navbar.pausal.suggestion').text) openSuggestionModal();
            setMenuDropdownVIsible(false);
        }
    };

    const handleSave = (): void => {
        if (useChangeModalStore.getState().source === 'profile') {
            profile && updateAgency(profile.id, profile);
        } else if (useChangeModalStore.getState().source === 'personalInfo') {
            const userInfo = useUserStore.getState().userInfo;
            userInfo && updateUser(userInfo?.id, userInfo);
            useUserStore.setState({ userInfo: userInfo });
        }
        setChangeVisible(false);
        setSuccessfullySaved(true);
        setMenuDropdownVIsible(false);
    };

    return (
        <>
            {accountExpires && <AccountExpiration close={() => setAccountExpires(false)} />}
            <MobileContainer>
                {changeVisible && (
                    <Modal
                        modalVisible={true}
                        closeModal={() => {
                            setChangeVisible(false);
                            useChangeModalStore.setState({ changeModalVisible: false });
                        }}
                    >
                        <ChangeModal
                            saveChanges={handleSave}
                            close={() => {
                                setChangeVisible(false);
                            }}
                            message={t('pages.agency.profile.areYouSure').text}
                        />
                    </Modal>
                )}
                {successfullySaved && (
                    <Modal modalVisible={true} closeModal={() => setSuccessfullySaved(false)}>
                        <Success
                            close={() => setSuccessfullySaved(false)}
                            message={t('pages.agency.profile.saveSuccess').text}
                        />
                    </Modal>
                )}
                <Nav className={accountExpires ? 'hide' : ''}>
                    <Container>
                        <LeftSide>
                            <BurgerPlace
                                onClick={() => {
                                    setMenuDropdownVIsible(!menuDropdownVisible);
                                    setUserDropdownVIsible(false);
                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faBars} style={{ fontSize: '26px' }} />
                            </BurgerPlace>
                            <Border />
                            <UserPlace
                                onClick={() => {
                                    setMenuDropdownVIsible(false);
                                    setUserDropdownVIsible(!userDropdownVisible);
                                }}
                            >
                                <FontAwesomeIcon color="white" icon={faUser} />
                                <FontAwesomeIcon style={{ paddingLeft: '10px' }} color="white" icon={faCaretDown} />
                            </UserPlace>
                            {userDropdownVisible && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown arrowLeft="53px" itemList={userDropdown} />
                                </DropdownContainer>
                            )}
                        </LeftSide>

                        <Link style={{ cursor: 'pointer' }} to="/invoices">
                            <Image src={logo} height={'40px'} />
                        </Link>
                    </Container>

                    {menuDropdownVisible && (
                        <BurgerContainer>
                            {icons.map((i, index) => (
                                <div style={{ position: 'relative' }} key={i.text}>
                                    {!i.link ? (
                                        <ListItem
                                            onClick={() => handleSelect(index, i.text)}
                                            className={
                                                (i.text === t('navbar.pausal.eInvoices').text ||
                                                    i.text === t('navbar.pausal.ePrePayment').text) &&
                                                index === selectedItem
                                                    ? 'efakture selected'
                                                    : i.text === t('navbar.pausal.eInvoices').text ||
                                                      i.text === t('navbar.pausal.ePrePayment').text
                                                    ? 'efakture'
                                                    : index === selectedItem
                                                    ? 'selected'
                                                    : ''
                                            }
                                        >
                                            <FontAwesomeIcon
                                                style={{ paddingLeft: '30px' }}
                                                className="icon"
                                                color="white"
                                                icon={i.icon}
                                            />
                                            <Label className="label">{i.text}</Label>
                                        </ListItem>
                                    ) : (
                                        <Link to={i.link}>
                                            <ListItem
                                                onClick={() => handleSelect(index, i.text)}
                                                className={
                                                    (i.text === t('navbar.pausal.eInvoices').text ||
                                                        i.text === t('navbar.pausal.ePrePayment').text) &&
                                                    index === selectedItem
                                                        ? 'efakture selected'
                                                        : i.text === t('navbar.pausal.eInvoices').text ||
                                                          i.text === t('navbar.pausal.ePrePayment').text
                                                        ? 'efakture'
                                                        : index === selectedItem
                                                        ? 'selected'
                                                        : ''
                                                }
                                            >
                                                <FontAwesomeIcon
                                                    style={{ paddingLeft: '30px' }}
                                                    className="icon"
                                                    color="white"
                                                    icon={i.icon}
                                                />
                                                <Label className="label">{i.text}</Label>
                                            </ListItem>
                                        </Link>
                                    )}
                                    {nbsDropdownVisible && i.text === t('navbar.pausal.nbs').text && (
                                        <NbsContainer>
                                            <Nbs
                                                height="auto"
                                                width="100%"
                                                onClose={() => setNbsDropdownVisible(false)}
                                            />
                                        </NbsContainer>
                                    )}
                                </div>
                            ))}
                            {role && role !== 'admin' && (
                                <>
                                    {isAccountActive ? (
                                        <AccountExpires onClick={() => setAccountExpires(true)}>
                                            {t('navbar.pausal.expires').text +
                                                ' ' +
                                                moment(expires).format('DD.MM.YYYY')}
                                        </AccountExpires>
                                    ) : (
                                        <AccountExpired>
                                            <Button
                                                variant={ButtonVariant.solid}
                                                color={colors.danger}
                                                size={190}
                                                onClick={() => setAccountExpires(true)}
                                            >
                                                {t('navbar.pausal.accountExpired').text}
                                            </Button>
                                        </AccountExpired>
                                    )}
                                    <SelectContainer>
                                        <SelectLang />
                                    </SelectContainer>
                                    {isAccountActive ? (
                                        <ButtonContainer>
                                            <Link to="/independenceTest">
                                                <p>{t('navbar.pausal.independenceTest').text}</p>
                                            </Link>
                                        </ButtonContainer>
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </BurgerContainer>
                    )}
                </Nav>
            </MobileContainer>
        </>
    );
};
const MobileContainer = styled.div`
    .modal-wrapper {
        max-width: 100%;
    }

    @media only screen and (max-width: 425px) {
    }
`;
const Nav = styled.div`
    &.hide {
        display: none;
    }
`;

const Container = styled.div`
    top: 0;
    z-index: 3;
    position: fixed;
    display: inline-block;
    width: 100%;
    max-width: 100%;
    background: var(--purple);
    overflow: visible;
    padding: 22px 30px;
    height: 84px;
    border-bottom: 1px solid var(--border-color);
`;

const Image = styled.img`
    float: right;
`;

const UserPlace = styled.div`
    margin: -16px 0 0 15px;
    margin-left: 34px;
    margin-top: -12px;
    width: 83px;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;

const BurgerPlace = styled.div`
    margin-top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    :hover {
        opacity: 0.5;
        cursor: pointer;
    }
`;
const Border = styled.div`
    border-right: 1px solid var(--border-color);
    height: 84px;
    position: absolute;
    top: 0;
    left: 88px;
`;

const LeftSide = styled.div`
    float: left;
    display: flex;
    flex-direction: row;
`;

const DropdownContainer = styled.div`
    top: 98px;
    width: 182px;
    left: 88px;
    position: absolute;
`;

const BurgerContainer = styled.div`
    width: 100%;
    background: var(--purple);
    margin-top: 84px;
    padding-bottom: 124px;

    height: 100vh;
    overflow-y: scroll;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 555;
    float: none;

    position: fixed;
`;

const ListItem = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 51px;
    transition: all 0.2s ease;
    border-bottom: 1px solid var(--white);
    cursor: pointer;

    :hover,
    &.selected {
        background-color: var(--white);
        border-left: 3px solid var(--purple);
        .label,
        .icon {
            color: var(--black);
        }
    }

    &.efakture {
        margin-left: 10px;
    }
`;

const Label = styled.p`
    color: var(--white);
    margin-left: 10px;
    font-family: Open Sans, sans-serif;
`;

const SelectContainer = styled.div`
    padding-left: 30px;
    margin-top: 25px;

    > div {
        height: 36px;
        width: 100px;
    }
`;

const ButtonContainer = styled.div`
    padding-left: 30px;
    margin-top: 25px;
    margin-bottom: 50px;
    height: 100px;
    p {
        width: auto;
        font-size: 22px;
        font-weight: 400px;
        padding: 20px 10px;
        color: var(--white);
        border-bottom: 1px solid var(--white);
        text-transform: uppercase;
    }
`;

const NbsContainer = styled.div`
    height: 680px;
    position: absolute;
    width: 90%;
    margin-top: -30px;
`;
export default MobileNavbar;
