import { FunctionComponent } from 'react';
import styled from 'styled-components';
interface Props {
    modalVisible: boolean;
    closeModal: Function;
    children: any;
    size?: number | string;
    className?: string | '';
    width?: string;
}
const Modal: FunctionComponent<Props> = ({ className, modalVisible, closeModal, children, size, width }) => {
    return (
        <StyledModal
            className={`${className ? className : ''} ${modalVisible ? 'visible' : ''}`}
            closeModal={closeModal}
            size={size}
            width={width}
            modalVisible={modalVisible}
        >
            <div className="modal-backdrop" onClick={() => closeModal()} />
            <div className="modal-wrapper">{children}</div>
        </StyledModal>
    );
};
const StyledModal = styled.div<Props>`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    opacity: 0;
    display: flex;
    pointer-events: none;
    justify-content: center;
    align-items: center;
    transition: 0.2s all ease-in-out;
    &.visible {
        opacity: 1;
        pointer-events: all;
    }
    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);

            @media only screen and (max-width: 425px) {
                display: block;
                position: fixed;
                top: 0;
                left: 0;
                z-index: 900;
                height: 100%;
                width: 100%;
                background: rgba(0, 0, 0, 0.6);
                overflow: auto;
            }
        }
    }

    .modal-wrapper {
        background: var(--white);
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 50%;
        width: ${(props) => (props.width ? props.width : '495px')};
        max-width: 100%;
        border-radius: 6px;
        background-color: var(--white);
        -webkit-box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.5);

        animation: slide-up 0.25s ease;
        transform: translateY(-50%);

        @keyframes slide-up {
            0% {
                opacity: 0;
                top: 0;
                transform: translateY(0);
            }
            100% {
                opacity: 1;
                transform: translateY(-50%);
                top: 50%;
            }
        }

        @media only screen and (max-width: 425px) {
            max-width: 90vw;
            margin: 0 auto;

            top: 50%;
            transform: translateY(-50%);
            position: fixed;
            z-index: 200000;
            left: 0;
            right: 0;
        }
    }
    &.preview-modal {
        .modal-wrapper {
            height: 100%;
            overflow-y: auto;
        }
    }
`;
export default Modal;
