import { FunctionComponent, Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';
import { ObjectKeys } from '../../../../../types/objectKeys';
import useTranslations from '../../../../../hooks/useTranslation';
import useLanguageStore from '../../../../../store/language';
import SelectComponent from '../../../../../components/select';

interface Props {
    setParams: Dispatch<SetStateAction<any>>;
    params: ObjectKeys;
    years: any;
    banks: ObjectKeys[];
}

const FiltersStatistics: FunctionComponent<Props> = ({ setParams, params, years, banks }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [date, setDate] = useState({ year: '2023', month: '12' });

    function daysInMonth(month: number, year: number): number {
        return new Date(year, month, 0).getDate();
    }

    const months = [
        { label: '1', value: '01', days: '31' },
        { label: '2', value: '02', days: '28' },
        { label: '3', value: '03', days: '31' },
        { label: '4', value: '04', days: '30' },
        { label: '5', value: '05', days: '31' },
        { label: '6', value: '06', days: '30' },
        { label: '7', value: '07', days: '31' },
        { label: '8', value: '08', days: '31' },
        { label: '9', value: '09', days: '30' },
        { label: '10', value: '10', days: '31' },
        { label: '11', value: '11', days: '30' },
        { label: '12', value: '12', days: '31' },
    ];

    return (
        <>
            {years && (
                <FiltersWrapper>
                    <TopFilters>
                        <FiltersInner>
                            <SelectComponent
                                className="select-container_bank"
                                optionList={banks.map((b: ObjectKeys) => {
                                    return { value: b.id, label: b.name };
                                })}
                                label={t('pages.admin.statistics.usersBank.bank').text}
                                handleSelectedValue={(value: string) => {
                                    setParams({
                                        ...params,
                                        bank_id: value,
                                    });
                                }}
                                defaultSelectedOption={
                                    banks.map((b: ObjectKeys) => {
                                        return { value: b.id, label: b.name };
                                    })[0]
                                }
                            />
                            <SelectComponent
                                className="select-container"
                                optionList={years?.map((year: any) => {
                                    return { value: year, label: year };
                                })}
                                label={t('pages.admin.statistics.usersBank.year').text}
                                handleSelectedValue={(value: string) => {
                                    setDate({ ...date, year: value });
                                    const start = value + `-${date.month}-01` + ' 00:00:00';

                                    const end =
                                        value +
                                        `-${date.month}-${daysInMonth(Number(date.month), Number(value))}` +
                                        ' 23:59:59';
                                    setParams({
                                        ...params,
                                        start_date: start,
                                        end_date: end,
                                    });
                                }}
                                defaultSelectedOption={years
                                    ?.map((year: any) => {
                                        return { value: year, label: year };
                                    })
                                    .pop()}
                            />
                            <SelectComponent
                                className="select-container"
                                optionList={months}
                                label={t('pages.admin.statistics.usersBank.month').text}
                                handleSelectedValue={(value: string) => {
                                    setDate({ ...date, month: value });
                                    const start = date.year + `-${value}-01` + ' 00:00:00';

                                    const end =
                                        date.year +
                                        `-${value}-${daysInMonth(Number(value), Number(date.year))}` +
                                        ' 23:59:59';
                                    setParams({
                                        ...params,
                                        start_date: start,
                                        end_date: end,
                                    });
                                }}
                                defaultSelectedOption={months[11]}
                            />
                        </FiltersInner>
                    </TopFilters>
                </FiltersWrapper>
            )}
        </>
    );
};

const FiltersWrapper = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    .select-container_bank {
        :nth-child(1) {
            > div {
                width: 200px;
            }
        }
    }
    .select-wrapper {
        flex-direction: row;
        align-items: center;
        text-transform: uppercase;
        label {
            font-size: 14px;
            line-height: 20px;
            padding: 0;
            margin-right: 6px;
        }
        .select__control {
            background: var(--white);
            color: var(--gray);
        }
        > div {
            display: flex;
            align-items: center;
        }
    }
`;
const TopFilters = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 20px;
    background: var(--purple);
    color: var(--white);
    justify-content: space-between;
    .select-wrapper {
        width: 100%;
        margin-right: 20px;
        @media screen and (max-width: 768px) {
            margin-bottom: 10px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        margin-bottom: 20px;
    }
`;
const FiltersInner = styled.div`
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
        margin-bottom: 20px;
        flex-direction: column;
    }

    .select-container {
        > div {
            width: 120px;
        }
        div {
            font-size: 14px;
        }
    }
`;

export default FiltersStatistics;
