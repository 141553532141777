import { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Input, { InputComponentType } from '../../../../../components/input';
import Button, { ButtonVariant } from '../../../../../components/button';
import colors from '../../../../../global/colors';
import communication from '../../../../../communication';
import { ObjectKeys } from '../../../../../types/objectKeys';
import SelectSearch from '../../../../../components/selectSearch';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loader from '../../../../../components/loader';
import ErrorModal from '../../../../../components/errorModal';

interface Props {
    closeModal: Function;
    changeValue: Function;
    t: Function;
    invoice: ObjectKeys;
    newRow?: boolean;
    data?: ObjectKeys;
    setData?: Function;
    changed?: Function;
    nameRequiredModal?: Function;
    index?: number;
    services: ObjectKeys;
    newInvoice?: boolean;
}

const ServiceModal: FunctionComponent<Props> = ({
    closeModal,
    changeValue,
    t,
    invoice,
    newRow,
    setData,
    index,
    services,
    newInvoice,
    nameRequiredModal,
    changed,
}) => {
    const [selected, setSelected] = useState(0);
    const [loading, setLoading] = useState(false);
    const [newService, setNewService] = useState('');
    const [newProduct, setNewProduct] = useState('');
    const [deleting, setDeleting] = useState(false);

    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const serviceOptions =
        services.length > 0
            ? services
                  ?.filter((f: ObjectKeys) => f.type === 'service')
                  .map((s: ObjectKeys) => ({
                      label: s.name,
                      value: s.id,
                      icon: <FontAwesomeIcon icon={faTrash} color={colors.danger} />,
                  }))
            : [];
    serviceOptions.unshift({
        label: t('pages.editInvoice.addServiceModal.chooseService').text,
        value: t('pages.editInvoice.addServiceModal.chooseService').text,
    });

    const productOptions =
        services.length > 0
            ? services
                  ?.filter((f: ObjectKeys) => f.type === 'product')
                  .map((s: ObjectKeys) => ({
                      label: s.name,
                      value: s.id,
                      icon: <FontAwesomeIcon icon={faTrash} color={colors.danger} />,
                  }))
            : [];
    productOptions.unshift({
        label: t('pages.editInvoice.addServiceModal.chooseService').text,
        value: t('pages.editInvoice.addServiceModal.chooseService').text,
    });

    const handleDeleteService = async (id: string): Promise<void> => {
        setLoading(true);
        const res = await communication.deleteService(id);
        if (res.status === 202) {
            closeModal();
            setLoading(false);
            changed && changed();
        }
    };

    const handleCreateService = async (name: string, type: string, id?: string): Promise<void> => {
        if (name !== '')
            try {
                setLoading(true);
                if (newService) {
                    const res = await communication.createService({ is_foreign: 0, name, new: true, type });
                    changeValue({ label: name, id: res?.data?.data?.id });
                    changed && changed();
                    if (res.status === 200) {
                        closeModal();
                        setLoading(false);
                        if (newRow)
                            setData &&
                                setData((prev: ObjectKeys) => {
                                    const newServiceData = { ...prev };
                                    if (newInvoice) {
                                        if (!newServiceData.services) newServiceData.services = [];
                                    }
                                    newServiceData?.services?.push({
                                        id: res?.data?.data?.id,
                                        name: res?.data?.data?.name,
                                        pivot: {
                                            index: invoice.services?.length,
                                            measurement_unit: 'per_piece',
                                            price_per_unit: '0',
                                            quantity: '0',
                                        },
                                    });
                                    return newServiceData;
                                });
                    }
                } else
                    setData &&
                        setData((prev: ObjectKeys) => {
                            const newServiceData = { ...prev };
                            if (newInvoice) {
                                if (!newServiceData.services) newServiceData.services = [];
                            }
                            changed && changed();
                            newServiceData?.services?.push({
                                id: id,
                                name: name,
                                pivot: {
                                    index: invoice.services?.length,
                                    measurement_unit: 'per_piece',
                                    price_per_unit: '0',
                                    quantity: '0',
                                },
                            });
                            return newServiceData;
                        });
            } catch (error: any) {
                setLoading(false);
                setErrorMessage(error.response.data.message);
                setOpenErrorModal(true);
                closeModal();
            }
        else {
            closeModal();
            nameRequiredModal && nameRequiredModal(t('pages.editInvoice.addServiceModal.nameRequired').text);
        }
    };

    const handleUpdateService = (v: ObjectKeys): void => {
        setData &&
            setData((prev: ObjectKeys) => {
                const copy = { ...prev };
                if (index) copy.services[index].id = v.id;
                return copy;
            });
    };

    const handleSelect = (v: ObjectKeys, shouldDelete?: boolean): void => {
        if (!shouldDelete && v.label !== t('pages.editInvoice.addServiceModal.chooseService').text) {
            if (!newRow) handleUpdateService(v);
            else handleCreateService(v.label, 'service', v.value);
            closeModal();
            changeValue(v);
        }
    };

    return (
        <>
            {openErrorModal && <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />}
            <BackDrop onClick={() => closeModal()} />

            {loading && <Loader />}
            {serviceOptions.length > 0 && (
                <Container>
                    <Header>
                        <div className={selected === 0 ? 'tabs selected' : 'tabs'} onClick={() => setSelected(0)}>
                            {t('pages.editInvoice.addServiceModal.services').text}
                        </div>
                        <div className={selected === 1 ? 'tabs selected' : 'tabs'} onClick={() => setSelected(1)}>
                            {t('pages.editInvoice.addServiceModal.products').text}
                        </div>
                    </Header>
                    <Body>
                        {selected === 0 ? (
                            <>
                                <div className="select">
                                    <label>{t('pages.editInvoice.addServiceModal.chooseService').text}</label>
                                    <SelectSearch
                                        deleteOption
                                        color={colors.gray}
                                        placeholder={t('pages.editInvoice.addServiceModal.chooseService').text}
                                        deleteItemHandler={(e: any) => {
                                            setDeleting(true);
                                            handleDeleteService(e);
                                            handleSelect(e, true);
                                        }}
                                        handleSelectedValue={(v: ObjectKeys) => {
                                            handleSelect(v, deleting);
                                        }}
                                        optionList={serviceOptions}
                                    />
                                </div>
                                <Input
                                    type={InputComponentType.Text}
                                    label={t('pages.editInvoice.addServiceModal.addService').text}
                                    value={newService}
                                    onChange={(v: string) => setNewService(v)}
                                />
                            </>
                        ) : (
                            <>
                                <div className="select">
                                    <label>{t('pages.editInvoice.addServiceModal.chooseProduct').text}</label>
                                    <SelectSearch
                                        deleteOption
                                        deleteItemHandler={(e: any) => {
                                            setDeleting(true);
                                            handleDeleteService(e);
                                            handleSelect(e, true);
                                        }}
                                        handleSelectedValue={(v: ObjectKeys) => {
                                            handleSelect(v, deleting);
                                        }}
                                        color={colors.gray}
                                        placeholder={t('pages.editInvoice.addServiceModal.chooseProduct').text}
                                        optionList={productOptions}
                                    />
                                </div>

                                <Input
                                    type={InputComponentType.Text}
                                    label={t('pages.editInvoice.addServiceModal.addProduct').text}
                                    value={newProduct}
                                    onChange={(v: string) => setNewProduct(v)}
                                />
                            </>
                        )}
                    </Body>
                    <Buttons>
                        <Button
                            onClick={() => closeModal()}
                            textTransformNone
                            color={colors.white}
                            variant={ButtonVariant.solid}
                            size={'auto'}
                        >
                            {t('pages.editInvoice.addServiceModal.close').text}
                        </Button>
                        <Button
                            onClick={() => {
                                selected === 0
                                    ? handleCreateService(newService, 'service')
                                    : handleCreateService(newProduct, 'product');
                            }}
                            textTransformNone
                            variant={ButtonVariant.solid}
                            color={colors.purple}
                            size={'auto'}
                        >
                            {t('pages.editInvoice.addServiceModal.enter').text}
                        </Button>
                    </Buttons>
                </Container>
            )}
        </>
    );
};
export default ServiceModal;
const BackDrop = styled.div`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;
`;
const Container = styled.div`
    z-index: 2000;
    display: block;
    position: fixed;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    width: 495px;
    max-width: 90%;
    background: var(--white);
    border-radius: 6px;
    box-shadow: 0 0 5px 5px var(--light-gray);

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;

const Header = styled.div`
    padding-top: 30px;
    .tabs {
        width: 50%;
        display: inline-block;
        float: left;
        line-height: 30px;
        text-transform: uppercase;
        text-align: center;
        font-size: 20px;
        border-bottom: 3px solid var(--border-color);
        margin-bottom: 20px;
        cursor: pointer;
    }
    .selected {
        border-bottom: 3px solid #9b7fe9;
    }
`;
const Body = styled.div`
    padding: 0 50px 50px;
    label {
        color: var(--gray);
        font-size: 15px;
    }
    .select-search {
        .input-dropdown {
            margin-top: 10px;
            height: 34px;
        }
        width: 100%;
        margin-bottom: 20px;
        .select-with-search__option {
            svg {
                float: right;
                margin-top: -15px;
                z-index: 900;
            }
        }
    }
`;
const Buttons = styled.div`
    border-top: 1px solid var(--border-color);
    display: flex;
    button {
        border-radius: 0px;
        width: 50%;
        :first-of-type {
            color: var(--purple);
        }
    }
`;
