import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import Input from '../../../components/input';
import TextArea from '../../../components/input/textarea';
import SelectComponent from '../../../components/select';
import Button, { ButtonVariant } from '../../../components/button';
import communication from '../../../communication';
import colors from '../../../global/colors';
import { ObjectKeys } from '@/types/objectKeys';
import Loader from '../../../components/loader';

type DataProps = {
    body: string;
    setting_name: string;
    title: string;
};

const AdminNotifications: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState<boolean>(false);

    const [data, setData] = useState<DataProps>({
        body: '',
        setting_name: 'new_features',
        title: '',
    });
    const [validation, setValidation] = useState<DataProps>({
        body: '',
        setting_name: 'new_features',
        title: '',
    });

    const types = [
        { label: 'New features', value: 'new_features' },
        { label: 'Promena Zakona', value: 'laws' },
    ];

    const resetData = (): void => {
        setData({
            body: '',
            setting_name: 'new_features',
            title: '',
        });
        setValidation({ body: '', setting_name: 'new_features', title: '' });
    };

    const validateData = (): boolean => {
        data.body.length === 0 || data.body === ''
            ? setValidation((prevState) => ({ ...prevState, body: 'Poruka je obavezana' }))
            : setValidation((prevState) => ({ ...prevState, body: '' }));
        data.title.length === 0 || data.title === ''
            ? setValidation((prevState) => ({ ...prevState, title: 'Naslov je obavezana' }))
            : setValidation((prevState) => ({ ...prevState, body: '' }));

        return !(data.body.length > 0 || data.title.length > 0);
    };

    const sendNotification = async (): Promise<void> => {
        const validator = validateData();

        if (!validator) {
            setLoaderVisible(true);
            await communication.sendNotifications(data).then((res: ObjectKeys) => {
                if (res.status === 200) {
                    resetData();
                    setLoaderVisible(false);
                }
            });
        }
    };
    return (
        <>
            {loaderVisible && <Loader />}
            <PageWrapper className="proforma page">
                <Header>
                    <h1>{t('pages.admin.notifications.title').text}</h1>
                </Header>
                <Content>
                    <Input
                        validation={validation.title}
                        className="input"
                        label={t('pages.admin.notifications.inputTitle').text}
                        value={data.title}
                        onChange={(value: string) => setData((prevState) => ({ ...prevState, title: value }))}
                    />
                    <TextArea
                        validation={validation.body}
                        label={t('pages.admin.notifications.inputMessage').text}
                        onChange={(value: string) => setData((prevState) => ({ ...prevState, body: value }))}
                        value={data.body}
                    />
                    <SelectComponent
                        label={t('pages.admin.notifications.type').text}
                        optionList={types}
                        handleSelectedValue={(value: string) => {
                            setData((prevState) => ({ ...prevState, setting_name: value }));
                        }}
                        defaultSelectedOption={types[0]}
                    />
                    <Button variant={ButtonVariant.solid} color={colors.purple} onClick={() => sendNotification()}>
                        {t('pages.admin.notifications.buttonTitle').text}
                    </Button>
                </Content>
            </PageWrapper>
        </>
    );
};

const PageWrapper = styled.div``;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;

const Content = styled.div`
    input,
    textarea {
        background-color: var(--white);
    }
    label {
        color: var(--gray);
        font-size: 15px;
    }
    .select-wrapper {
        margin-top: 20px;
        label {
            padding-left: 0px;
        }
        .select__control {
            margin-top: 5px;
            background-color: var(--white);
        }
    }
    button {
        display: block;
        float: right;
        margin: auto;
        padding: 0;
        width: 200px;
        height: 40px;
        border: 2px solid var(--purple);
        border-radius: 40px;
        background: var(--purple);
        color: var(--white);
        font-size: 14px;
        text-transform: uppercase;
    }
`;
export default AdminNotifications;
