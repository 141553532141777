import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import Dropdown from '../../components/dropdown';
import Button, { ButtonVariant } from '../../components/button';
import colors from '../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from '../../components/select';
import MemorandumCard from '../../components/memorandumCard';
import communication from '../../communication';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../components/pagination';
import Loader from '../../components/loader';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import Input, { InputComponentType } from '../../components/input';
import ConfirmModal from '../../components/confirmModal';
import Modal from '../../components/modal';
import Sidebar from '../../components/sideModal';
import SendDocumentModal from '../myAgency/documents/sendModal';
import { SendDocumentFormData } from '../../types/communication/document';

export interface EmailOption {
    label: string;
    value: string;
}

const Memorandums: FunctionComponent = () => {
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [memorandums, setMemorandums] = useState<Array<Object>>([]);
    const [pagination, setPagination] = useState<any>({});
    const [limit, setLimit] = useState<any>('');
    const [status, setStatus] = useState<any>('all');
    const [find, setFind] = useState<string>('');
    const [page, setPage] = useState<string>('1');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [selectedMemorandumId, setSelectedMemorandumId] = useState<string | undefined>('');
    const [isConfirmModalActive, setIsConfirmModalActive] = useState<boolean>(false);
    const navigate = useNavigate();
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const items = [
        {
            label: t('pages.memos.newMemo').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.newMemo').text, template: '1' },
                }),
        },
        {
            label: t('pages.memos.claim').text,
            handler: () =>
                navigate('/memorandums/new', { state: { name: t('pages.memos.claim').text, template: '2' } }),
        },
        {
            label: t('pages.memos.bid').text,
            handler: () => navigate('/memorandums/new', { state: { name: t('pages.memos.bid').text, template: '3' } }),
        },
        {
            label: t('pages.memos.statementOfDelay').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfDelay').text, template: '4' },
                }),
        },
        {
            label: t('pages.memos.decisionOfTravel').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.decisionOfTravel').text, template: '5' },
                }),
        },
        {
            label: t('pages.memos.statementOfSigning').text,
            handler: () =>
                navigate('/memorandums/new', {
                    state: { name: t('pages.memos.statementOfSigning').text, template: '6' },
                }),
        },
    ];
    const showSelectOptions = [
        {
            value: '10',
            label: '10',
        },
        {
            value: '20',
            label: '20',
        },
        {
            value: '30',
            label: '30',
        },
        {
            value: '40',
            label: '40',
        },
    ];
    const statusSelectOptions = [
        {
            value: 'all',
            label: t('pages.memos.all').text,
        },
        {
            value: 'preparation',
            label: t('pages.memos.preparation').text,
        },
        {
            value: 'sent',
            label: t('pages.memos.sent').text,
        },
    ];

    const getMemorandums = async (): Promise<void> => {
        setIsLoader(true);
        const res = await communication.getAllMemorandums(page, limit, status, find);
        setPagination(res.data.meta.pagination);
        setMemorandums(res.data.data);
        setIsLoader(false);
    };

    useEffect(() => {
        getMemorandums();
    }, [page, status, limit, find]);

    const handlePageClick = (e: any): void => {
        setPage(e.selected + 1);
    };

    const handleEdit = (id: string | undefined): void => {
        navigate(`/memorandums/edit/${id}`);
    };

    const handleSend = async (id: string | undefined): Promise<void> => {
        setSelectedMemorandumId(id);
        setModalVisible(true);
    };

    const handleDelete = async (id: string | undefined): Promise<void> => {
        setSelectedMemorandumId(id);
        setIsConfirmModalActive(true);
    };

    const deleteMemorandum = async (): Promise<void> => {
        setIsLoader(true);
        await communication.deleteMemorandum(selectedMemorandumId);
        setIsConfirmModalActive(false);
        getMemorandums();
    };

    return (
        <PageWrapper>
            {isLoader && <Loader />}
            <Modal modalVisible={isConfirmModalActive} closeModal={() => setIsConfirmModalActive(false)}>
                <ConfirmModal
                    close={() => setIsConfirmModalActive(false)}
                    message={t('pages.memos.deleteMemo').text}
                    action={deleteMemorandum}
                />
            </Modal>
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendDocumentModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            setIsLoader(false);
                        }}
                        loading={() => setIsLoader(true)}
                        memorandum={true}
                        memoId={selectedMemorandumId}
                        modalVisible={modalVisible}
                    />
                </Sidebar>
            )}
            <Header>
                <h1>{t('pages.memos.titleSingle').text}</h1>
                <div className="header-right">
                    <div className="header-options">
                        <div className="header-options-buttons">
                            <Button
                                className={'button'}
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                                icon={true}
                                height={35}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                {t('pages.memos.newMemo').text}
                            </Button>
                            {isDropdownVisible && (
                                <DropdownContainer>
                                    <Dropdown arrowLeft="53px" itemList={items} />
                                </DropdownContainer>
                            )}
                        </div>
                    </div>
                </div>
                <Filters>
                    <div className="filter">
                        <label>{t('pages.memos.show').text}</label>
                        <SelectComponent
                            optionList={showSelectOptions}
                            color={colors.gray}
                            className={'select'}
                            handleSelectedValue={(value: any) => {
                                setLimit(value);
                            }}
                        />
                    </div>
                    <div className="filter">
                        <label>Status</label>
                        <SelectComponent
                            optionList={statusSelectOptions}
                            color={colors.gray}
                            className={'select'}
                            defaultSelectedOption={{
                                value: 'all',
                                label: t('pages.memos.all').text,
                            }}
                            handleSelectedValue={(value: any) => {
                                setStatus(value);
                            }}
                        />
                    </div>
                    <div className="search-filter">
                        <Input
                            type={InputComponentType.Text}
                            value={find}
                            onChange={(value: string) => setFind(value)}
                            // className={'find'}
                            inputClassName={'find'}
                            placeholder={t('pages.memos.search').text}
                        >
                            <FontAwesomeIcon className={'find-icon'} icon={faSearch} />
                        </Input>
                    </div>
                </Filters>
            </Header>
            <Content>
                {memorandums.map((memorandum, index) => (
                    <MemorandumCard
                        key={index}
                        memorandum={memorandum}
                        handleSend={handleSend}
                        handleEdit={handleEdit}
                        handleDelete={handleDelete}
                    />
                ))}
            </Content>
            {memorandums?.length > 0 && pagination?.total_pages > 1 ? (
                <Pagination
                    pageCount={pagination?.total_pages}
                    initialPage={0}
                    onPageChange={(e: object) => {
                        handlePageClick(e);
                    }}
                    nextLabel={(pagination?.current_page === pagination?.total_pages ? '' : '>').toString()}
                    previousLabel={(pagination?.current_page === 1 ? '' : '<').toString()}
                />
            ) : (
                <></>
            )}
        </PageWrapper>
    );
};

const PageWrapper = styled.div`
    min-height: 100vh;
    display: inline-block;
    vertical-align: top;
    width: -webkit-fill-available;
    width: -moz-available;
`;
const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: inline-block;
    width: 100%;
    @media (max-width: 700px) {
        flex-direction: column;
    }
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    button {
        font-size: 12px;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
    .input-container {
        margin-bottom: 0;
    }
    .find {
        background-color: var(--white);
        padding: 20px 0px 20px 30px;
    }
    .find-icon {
        position: relative;
        align-self: flex-start;
        justify-self: center;
        bottom: 27px;
        left: 9px;
    }
`;
const DropdownContainer = styled.div`
    top: 60px;
    width: 200px;
    right: 0px;
    position: absolute;
    background-color: var(--white);
`;

const Content = styled.div``;

const Filters = styled.div`
    display: inline-block;
    width: 100%;
    margin-top: 20px;
    padding: 20px 0;
    background-color: var(--purple);

    .select {
        width: 100%;
        display: inline-block;
        background-color: var(--white);
        border-radius: 5px;
    }
    .select-wrapper {
        display: inline-block;
    }
    .filter {
        display: inline;
        align-items: center;
        margin-right: 10px;
        max-width: 200px;
        @media (max-width: 600px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        @media (max-width: 1240px) {
            display: flex;
            margin-top: 20px;
            margin-right: 0;
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
            width: 80px;
        }
    }
    .search-filter {
        float: right;
        margin-right: 10px;
        right: 0;
        top: 78px;
        width: 200px;
        @media (max-width: 1250px) {
            width: 100%;
            margin-top: 20px;
            margin-right: 0;
        }
        label {
            display: inline-block;
            padding-left: 20px;
            font-weight: 400;
            color: #fff;
            font-size: 14px;
            margin-right: 5px;
        }
        .select-wrapper {
            display: inline-block;
        }
    }
`;

export default Memorandums;
