import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import useProfileStore from '../../../store/profile';
import Button, { ButtonVariant } from '../../../components/button';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import AccountExpired from '../../../components/accountExpired';
import Filters from './filters';
import CancelModal from './cancelModal';
import EAdvanceModal from './eAdvanceModal';
import DeleteModal from './deleteModal';
import SendDocumentModal from './sendModal';
import ErrorModal from '../../../components/errorModal';
import NoEInvoiceApiModal from '../invoices/noEinvoiceApiModal';

const AdvanceInvoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const ref = useRef<HTMLDivElement>(null);
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const { profile } = useProfileStore();

    const token = getAccessToken();
    const isAccountActive = profile?.active === 1 ? true : false;

    const [advanceInvoices, setAdvanceInvoices] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEAvanceModal, setOpenEAvanceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);

    const [clientEmail, setClientEmail] = useState('');
    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    useEffect(() => {
        setLoaderVisible(true);
        communication
            .getInvoices({
                ...params,
                page: formattedParams ? 1 : params.page,
                search: 'is_prepaid:1;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setAdvanceInvoices(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setAdvanceInvoices([]);
                }
            });
    }, [formattedParams, params.limit, params.page]);

    //Advance Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/advance-invoices/edit/${invoiceId}`);
    };

    //Advance Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        communication.copyInvoice(invoiceId).then((res: ObjectKeys) => {
            if (res) {
                navigate(`/advance-invoices/edit/${invoiceId}`);
            }
        });
    };

    //AInvoice Delete Modal
    const handleDeleteModal = (invoiceNumber: string, id: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                invoiceNumber={invoiceNumber}
                clickedInvoice={id}
                closeGlobalModal={closeGlobalModal}
                setInvoices={setAdvanceInvoices}
            />,
        );
    };

    //AInvoice Cancel Modal
    const handleCancelModal = (invoiceId: string): void => {
        setOpenCancelModal(true);
        setClickedInvoice(invoiceId);
    };

    //AInvoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
        ).then((res: ObjectKeys | any) => {
            if (res)
                communication.downloadInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD');
        });
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEAvanceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEAdvanceModal = async (invoiceId: string): Promise<void> => {
        if (profile.agency)
            if (profile.agency.data[0]?.e_invoice_api_key) handleOpenEInvoiceModal(invoiceId);
            else setNoEInvoiceApiModal(true);
        else {
            const prof = await communication.getProfile();
            if (prof.data.data.agency.data[0]?.e_invoice_api_key) handleOpenEInvoiceModal(invoiceId);
            else setNoEInvoiceApiModal(true);
        }
    };

    const handleClickedInvoice = (invoiceId: string): void => {
        setClickedInvoice(invoiceId);
    };
    const handleSendModal = (invoiceId: string, invoiceClientEmail: string): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
    };
    const handleSendInvoice = (): void => {
        setClickedInvoice('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new advance invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/advance-invoices/add/${invoiceType}`);
    };

    return (
        <>
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {noEInvoiceApiModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setNoEInvoiceApiModal(false);
                    }}
                >
                    <NoEInvoiceApiModal
                        saveChanges={() => navigate('/agency/e-invoices')}
                        close={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                        message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                    />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        setInvoices={setAdvanceInvoices}
                        clientEmail={clientEmail}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {openCancelModal ? (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={clickedInvoice}
                        setInvoices={setAdvanceInvoices}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openEAvanceModal ? (
                <Modal modalVisible={openEAvanceModal} closeModal={() => setOpenEAvanceModal(false)}>
                    <EAdvanceModal
                        t={t}
                        setOpenEInvoiceModal={setOpenEAvanceModal}
                        clickedInvoice={clickedInvoice}
                        setInvoices={setAdvanceInvoices}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {loaderVisible && <Loader />}
            <div className="advance-invoices page">
                <Header>
                    <div>
                        <h1>{t('pages.advanceInvoices.title').text}</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.advanceInvoices.newInvoice').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text,
                                                handler: () => handleAddInvoice('domestic'),
                                            },
                                            {
                                                label: t('pages.advanceInvoices.addInvoiceDropdown.foreign').text,
                                                handler: () => handleAddInvoice('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                    <div>
                        {isAccountActive ? (
                            <Button
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => navigate('/clients')}
                            >
                                <FontAwesomeIcon
                                    className="icon"
                                    color={colors.purple}
                                    icon={faList}
                                    style={{ marginRight: '5px' }}
                                />
                                {t('buttons.clients').text}
                            </Button>
                        ) : (
                            <></>
                        )}
                    </div>
                </Header>
                {isAccountActive ? <></> : <AccountExpired />}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <InvoicesList className={`display-${display}`}>
                    {advanceInvoices?.data?.length > 0 ? (
                        advanceInvoices?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <InvoiceCard
                                        type={InvoiceCardComponentType.AdvanceInvoice}
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice.id)}
                                        handleCopy={() => handleCopy(invoice.id)}
                                        handleDelete={() => handleDeleteModal(invoice?.invoice_number, invoice.id)}
                                        handleCancel={() => handleCancelModal(invoice.id)}
                                        handleDownload={() =>
                                            handleDownloadInvoice(
                                                invoice?.id,
                                                invoice?.original_data?.agency,
                                                invoice?.client?.data,
                                                invoice.value_in_rsd,
                                                invoice.invoice_number,
                                                invoice.bank_account,
                                                invoice.currency,
                                                true,
                                                invoice,
                                            )
                                        }
                                        handleEInvoice={() => handleEAdvanceModal(invoice.id)}
                                        handleSend={() =>
                                            handleSendModal(
                                                invoice.id,
                                                invoice.client.data.email ? invoice.client.data.email : '',
                                            )
                                        }
                                        handleClick={() => handleClickedInvoice(invoice.id)}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.advanceInvoices.noItems.description').text} />
                    )}
                </InvoicesList>
                {advanceInvoices?.data?.length > 0 && advanceInvoices.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={advanceInvoices.meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${
                            advanceInvoices.meta?.pagination?.current_page ===
                            advanceInvoices.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${advanceInvoices.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={advanceInvoices.meta?.pagination?.current_page - 1}
                    />
                )}
            </div>
        </>
    );
};
export default AdvanceInvoices;

const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: 1700px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
