import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../store/language';
import useTranslations from '../../hooks/useTranslation';
import Loader from '../../components/loader';
import ReportFilters from './filters';
import ReportsMainTable from './mainTable';
import communication from '../../communication';
import { ObjectKeys } from '../../types/objectKeys';
import Pagination from '../../components/pagination';
import moment from 'moment';
import ReportsClientsTable from './clientsTable';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

const Reports: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [page, setPage] = useState<number>(1);
    const [reportsData, setReportsData] = useState<ObjectKeys>([]);
    const [props, setProps] = useState<FilterProps>({
        start_date: moment().startOf('year').format('YYYY-MM-DD'),
        end_date: moment().endOf('year').format('YYYY-MM-DD'),
        type: 'total',
    });

    useEffect(() => {
        setLoaderVisible(true);
        communication
            .getAllReports(page, props)
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setReportsData(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setReportsData([]);
                }
            });
    }, [page, props]);

    const handleFilterChange = (filters: FilterProps): void => {
        setProps(filters);
    };

    return (
        <>
            {loaderVisible && <Loader />}
            <PageWrapper className="page">
                <Header>
                    <h1>{t('pages.reports.title').text}</h1>
                </Header>

                <Content>
                    <ReportFilters onFilterChange={handleFilterChange} />

                    {props.type === 'total' ? (
                        <>
                            <ReportsMainTable reports={reportsData} filters={props} />
                            {reportsData?.invoices?.data?.length > 0 &&
                                reportsData?.invoices?.meta?.pagination?.total_pages > 1 && (
                                    <Pagination
                                        pageCount={reportsData?.invoices?.meta?.pagination?.total_pages}
                                        onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                        nextLabel={`${
                                            reportsData?.invoices?.meta?.pagination?.current_page ===
                                            reportsData?.invoices?.meta?.pagination?.total_pages
                                                ? ''
                                                : '>'
                                        }`}
                                        previousLabel={`${
                                            reportsData?.invoices?.meta?.pagination?.current_page === 1 ? '' : '<'
                                        }`}
                                        breakLabel="..."
                                        initialPage={reportsData?.invoices?.meta?.pagination?.current_page - 1}
                                    />
                                )}
                        </>
                    ) : (
                        <>
                            <ReportsClientsTable reports={reportsData} filters={props} loading={loaderVisible} />
                            {reportsData?.client?.data?.length > 0 &&
                                reportsData?.client?.meta?.pagination?.total_pages > 1 && (
                                    <Pagination
                                        pageCount={reportsData?.client?.meta?.pagination?.total_pages}
                                        onPageChange={(e: ObjectKeys) => setPage(e.selected + 1)}
                                        nextLabel={`${
                                            reportsData?.client?.meta?.pagination?.current_page ===
                                            reportsData?.client?.meta?.pagination?.total_pages
                                                ? ''
                                                : '>'
                                        }`}
                                        previousLabel={`${
                                            reportsData?.client?.meta?.pagination?.current_page === 1 ? '' : '<'
                                        }`}
                                        breakLabel="..."
                                        initialPage={reportsData?.client?.meta?.pagination?.current_page - 1}
                                    />
                                )}
                        </>
                    )}
                </Content>
            </PageWrapper>
        </>
    );
};
const PageWrapper = styled.div``;
const Content = styled.div`
    margin-top: 50px;
`;
const Header = styled.div`
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    margin-bottom: 20px;
`;
export default Reports;
