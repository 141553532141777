import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import useLanguageStore from '../../../../store/language';
import useTranslations from '../../../../hooks/useTranslation';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import colors from '../../../../global/colors';
import communication from '../../../../communication';
import NoItems from '../../../../components/noItems';
import Modal from '../../../../components/modal';
import Loader from '../../../../components/loader';
import DownloadModal from '../downloadModal';
import Success from '../../../../components/successModal';

const Kpo: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    const [years, setYears] = useState([]);
    const [year, setYear] = useState('');
    const [downloadModal, setDownloadModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    useEffect(() => {
        setLoaderVisible(true);
        async function getYears(): Promise<void> {
            const res = await communication.getYears();
            setYears(res.data.years);
            setLoaderVisible(false);
        }

        getYears();
    }, []);

    const { pathname } = useLocation();

    return (
        <>
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {downloadModal && (
                <Modal modalVisible={true} closeModal={() => setDownloadModal(false)}>
                    <DownloadModal
                        year={year}
                        close={() => setDownloadModal(false)}
                        message={t('pages.kpo.downloadModal.question').text}
                        modalMessage={(mess: string) => {
                            setSuccess(true);
                            setModalMessage(mess);
                        }}
                    />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <Container>
                <Header>
                    <Link to={pathname.includes('e-invoices') ? '/e-invoices' : '/invoices'}>
                        <div className="back">
                            <FontAwesomeIcon icon={faChevronLeft} />
                        </div>
                    </Link>
                    <h1>{t('pages.kpo.title').text}</h1>
                </Header>
                {years?.length > 0 ? (
                    <Books>
                        {years?.map((y) => (
                            <Book
                                key={y}
                                onClick={() => {
                                    setYear(y);
                                    setDownloadModal(true);
                                }}
                            >
                                <div className="text">
                                    <span>{t('pages.kpo.download').text}</span>
                                    <span>
                                        {'\xa0' + y}
                                        {t('pages.kpo.year').text}
                                    </span>
                                </div>
                                <FontAwesomeIcon color={colors.gray} icon={faBook} />
                            </Book>
                        ))}
                    </Books>
                ) : (
                    <NoItems text={t('pages.kpo.noItems.description').text} />
                )}
            </Container>
        </>
    );
};
export default Kpo;
const Container = styled.div`
    vertical-align: top;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
`;
const Header = styled.div`
    position: relative;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
    display: flex;
    .back {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: var(--white);
        border: 1px solid var(--border-color);
        border-radius: 3px;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        color: var(--black);
        &:hover {
            background: var(--purple);
            border: 1px solid var(--purple);
            color: var(--white);
        }
    }
`;
const Books = styled.div`
    margin-top: 30px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 20px;
`;
const Book = styled.div`
    display: inline-block;
    position: relative;
    width: 16%;
    height: auto;
    margin: 0 26px 30px 0;
    padding: 16px 20px;
    background: var(--white);
    vertical-align: top;
    border-radius: 5px;
    border: 1px solid var(--purple);
    cursor: pointer;
    color: var(--gray);
    line-height: 25px;

    span {
        display: block;
        font-size: 13px;
    }

    svg {
        height: 25px;
        width: 22px;
        margin-top: 5px;
    }

    :hover {
        opacity: 0.7;
    }

    @media only screen and (max-width: 425px) {
        width: 100%;
        .text {
            display: flex;
        }
    }
`;
