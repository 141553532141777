import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';

const getAllProforma = (setProforma: Dispatch<SetStateAction<ObjectKeys>>): void => {
    communication
        .getProforma({
            limit: '10',
            page: 1,
            search: {},
            searchFields: '',
            searchJoin: 'and',
            find: '',
            year: '',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setProforma(res?.data);
            }
        });
};
export default getAllProforma;
