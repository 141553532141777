import fetch from './fetch';
import routes from './routes';
import { ObjectKeys } from '../types/objectKeys';
// import download from './download';
import getCurrentLanguage from '../functions/getCurrentLanguage';

interface FilterProps {
    start_date: string;
    end_date: string;
    type: string;
    client_id?: string;
    currency?: string;
}

export default class Currency {
    public getAllReports(page: number, props: FilterProps): ObjectKeys {
        return fetch({
            method: 'POST',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getAllReports(page),
            data: props,
        });
    }

    public getAllClientsForStatistic(userId: string): ObjectKeys {
        return fetch({
            method: 'GET',
            headers: { 'Content-Type': 'application/json; charset=UTF-8', 'Accept-Language': getCurrentLanguage() },
            url: routes.reports.getAllClients(userId),
        });
    }
    public downloadPdf(token: string): ObjectKeys | undefined {
        return fetch({
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json, text/plain, application/pdf, */*; charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.reports.downloadPdf,
            data: { end_date: '2023-12-31', start_date: '2023-01-01', type: 'total' },
        });
    }
    public downloadXlsx(token: string): ObjectKeys | undefined {
        return fetch({
            method: 'POST',
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                Authorization: 'Bearer ' + token,
                'Accept-Language': getCurrentLanguage(),
            },
            url: routes.reports.downloadXlsx,
            data: { end_date: '2023-12-31', start_date: '2023-01-01', type: 'total' },
        });
    }
}
