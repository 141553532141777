const getCurrentLanguage = (): string => {
    const currentLangName = localStorage.getItem('language');
    switch (currentLangName) {
        case 'English':
            return 'en';
        case 'Srpski':
            return 'rs';
        default:
            return 'rs';
    }
};
export default getCurrentLanguage;
