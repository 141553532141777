import { FunctionComponent, useEffect, useState, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faList, faPrint } from '@fortawesome/free-solid-svg-icons';
import communication from '../../../communication';
import { generateQrCode } from '../../../communication/qr';
import colors from '../../../global/colors';
import useTranslations from '../../../hooks/useTranslation';
import useOutsideClick from '../../../hooks/useOutsideClick';
import useProfileStore from '../../../store/profile';
import useLanguageStore from '../../../store/language';
import useGeneralStore from '../../../store/general';
import { ObjectKeys } from '../../../types/objectKeys';
import { SendDocumentFormData } from '../../../types/communication/document';
import { formatParams } from '../../../functions/formatParams';
import { getAccessToken } from '../../../functions/auth';
import { formatNumber } from '../../../functions/format';
import Button, { ButtonVariant } from '../../../components/button';
import InvoiceCard, { InvoiceCardComponentType } from '../../../components/invoiceCard';
import NoItems from '../../../components/noItems';
import Loader from '../../../components/loader';
import Modal from '../../../components/modal';
import Sidebar from '../../../components/sideModal';
import Pagination from '../../../components/pagination';
import Dropdown from '../../../components/dropdown';
import Success from '../../../components/successModal';
import getRestOfPayment from '../../../components/invoiceCard/getRestOfPayment';
import ErrorModal from '../../../components/errorModal';
import AccountExpired from '../../../components/accountExpired';
import Statistics from './statistics';
import Filters from './filters';
import PaymentModal from './paymentModal';
import PaymentUpdateModal from './paymentUpdateModal';
import CancelModal from './cancelModal';
import EInvoiceModal from './eInvoiceModal';
import DeleteModal from './deleteModal';
import SendDocumentModal from './sendModal';
import NoEInvoiceApiModal from './noEinvoiceApiModal';
import getAllInvoices from './getAllInvoices';
import getStatistics from './getStatistics';

const Invoices: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const { setGlobalModal, closeGlobalModal } = useGeneralStore();
    const ref = useRef<HTMLDivElement>(null);
    const { profile } = useProfileStore();
    const { addToast } = useToasts();

    const token = getAccessToken();
    const isAccountActive = profile?.active === 1 ? true : false;

    const [invoices, setInvoices] = useState<ObjectKeys>({});
    const [statistics, setStatistics] = useState<ObjectKeys>({});
    const [searchValue, setSearchValue] = useState('');

    const [loaderVisible, setLoaderVisible] = useState(false);
    const [openCancelModal, setOpenCancelModal] = useState(false);
    const [openEInvoiceModal, setOpenEInvoiceModal] = useState(false);
    const [openPaymentModal, setOpenPaymentModal] = useState(false);
    const [openPaymentUpdateModal, setOpenPaymentUpdateModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [success, setSuccess] = useState(false);
    const [clientEmail, setClientEmail] = useState('');
    const [invoicePaidValue, setInvoicePaidValue] = useState<string | number>('');
    const [addInvoiceDropdown, setAddInvoiceDropdown] = useState(false);
    const [noEInvoiceApiModal, setNoEInvoiceApiModal] = useState(false);
    const [errorModal, setErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const [display, setDisplay] = useState('list');
    const [clickedInvoice, setClickedInvoice] = useState('');
    const [clickedPayment, setClickedPayment] = useState('');

    const { handleClickOutside } = useOutsideClick(ref, setAddInvoiceDropdown);

    const [params, setParams] = useState({
        limit: '10',
        page: 1,
        search: {},
        searchFields: {},
        searchJoin: 'and',
    });

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const formattedParams = useMemo(() => formatParams(params.search), [params]);

    useEffect(() => {
        setLoaderVisible(true);

        communication
            .getInvoices({
                ...params,
                page: formattedParams ? 1 : params.page,
                search: 'is_prepaid:0;' + formattedParams,
                searchFields: 'is_prepaid:=;' + formattedParams,
            })
            .then((res: ObjectKeys) => {
                if (res.status === 200) {
                    setLoaderVisible(false);
                    setInvoices(res?.data);
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoaderVisible(false);
                    setInvoices([]);
                }
            });
    }, [formattedParams, params.limit, params.page]);

    //Invoice Change
    const handleChange = (invoiceId: string): void => {
        navigate(`/invoices/edit/${invoiceId}`);
    };

    //Invoice Copy
    const handleCopy = (invoiceId: string): void => {
        communication.copyInvoice(invoiceId).then((res: ObjectKeys) => {
            if (res) {
                navigate(`/invoices/edit/${res.data.data.id}`);
            }
        });
    };

    //Invoice Cancel Modal
    const handleCancelModal = (invoiceId: string): void => {
        setOpenCancelModal(true);
        setClickedInvoice(invoiceId);
    };

    //Invoice Download
    const handleDownloadInvoice = (
        invoiceId: string,
        agency: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
        invoice: ObjectKeys,
    ): void => {
        generateQrCode(
            agency.name,
            clientData.company_name,
            amount,
            invoiceNumber,
            bankAccount,
            currency,
            convert,
        ).then((res: ObjectKeys | any) => {
            if (res)
                communication.downloadInvoice(invoiceId, token, invoice.invoice_number, invoice.currency !== 'RSD');
        });
    };

    //Refresh eInvoice
    const handleRefreshEInvoice = async (eInvoiceId: string): Promise<void> => {
        setLoaderVisible(true);
        setClickedInvoice(eInvoiceId);
        const res = await communication.refreshEInvoice({ id: eInvoiceId });
        if (res.status === 200) setSuccess(true);
        setLoaderVisible(false);
    };

    const handleOpenEInvoiceModal = (invoiceId: string): void => {
        setOpenEInvoiceModal(true);
        setClickedInvoice(invoiceId);
    };

    const handleEInvoiceModal = async (invoiceId: string): Promise<void> => {
        if (profile.agency)
            if (profile.agency.data[0]?.e_invoice_api_key) handleOpenEInvoiceModal(invoiceId);
            else setNoEInvoiceApiModal(true);
        else {
            const prof = await communication.getProfile();
            if (prof.data.data.agency.data[0]?.e_invoice_api_key) handleOpenEInvoiceModal(invoiceId);
            else setNoEInvoiceApiModal(true);
        }
    };
    const handlePaymentModal = (invoiceId: string, paidValue: string | number): void => {
        setOpenPaymentModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    const handleClickedInvoice = (invoiceId: string): void => {
        setClickedInvoice(invoiceId);
    };

    //Invoice Delete Modal
    const handleDeleteModal = (invoiceNumber: string, id: string): void => {
        setGlobalModal(
            <DeleteModal
                t={t}
                clickedInvoice={id}
                invoiceNumber={invoiceNumber}
                closeGlobalModal={closeGlobalModal}
                setInvoices={setInvoices}
                setLoaderVisible={setLoaderVisible}
                setStatistics={setStatistics}
            />,
        );
    };

    const handleSendModal = (invoiceId: string, invoiceClientEmail: string): void => {
        setSendModal(true);
        setClickedInvoice(invoiceId);
        setClientEmail(invoiceClientEmail);
    };
    const handleSendInvoice = (): void => {
        setClickedInvoice('');
    };
    const onLoad = (loading: boolean): void => {
        setLoaderVisible(loading);
    };

    //Add new invoice
    const handleAddInvoice = (invoiceType: string): void => {
        navigate(`/invoices/add/${invoiceType}`);
    };
    const handleUpdatePayment = (paymentId: string, invoiceId: string, paidValue: string | number): void => {
        setClickedPayment(paymentId);
        setOpenPaymentUpdateModal(true);
        setClickedInvoice(invoiceId);
        setInvoicePaidValue(paidValue);
    };

    // Specific Payment Delete
    const handleDeletePayment = (paymentId: string, invoiceId: string): void => {
        setLoaderVisible(true);
        communication
            .deleteInvoiceSpecificPayment(invoiceId, paymentId)
            .then((res: ObjectKeys) => {
                if (res) {
                    getAllInvoices(setInvoices, formattedParams);
                    if (setStatistics) {
                        getStatistics(setStatistics);
                    }
                }
                setLoaderVisible(false);
            })
            .catch((error: ObjectKeys) => {
                setLoaderVisible(false);
                addToast(error.response.data.message, {
                    appearance: 'error',
                    autoDismiss: true,
                });
            });
    };

    return (
        <>
            {errorModal && (
                <Modal modalVisible={errorModal} closeModal={() => setErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendDocumentModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={clickedInvoice}
                        success={() => {
                            handleSendInvoice();
                        }}
                        loading={(e: boolean) => onLoad(e)}
                        clientEmail={clientEmail}
                        setInvoices={setInvoices}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {openPaymentModal ? (
                <Modal modalVisible={openPaymentModal} closeModal={() => setOpenPaymentModal(false)}>
                    <PaymentModal
                        setOpenPaymentModal={setOpenPaymentModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        setInvoices={setInvoices}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={setStatistics}
                        formattedParams={formattedParams}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openPaymentUpdateModal ? (
                <Modal modalVisible={openPaymentUpdateModal} closeModal={() => setOpenPaymentUpdateModal(false)}>
                    <PaymentUpdateModal
                        setOpenPaymentUpdateModal={setOpenPaymentUpdateModal}
                        t={t}
                        clickedInvoice={clickedInvoice}
                        invoicePaidValue={invoicePaidValue}
                        clickedPayment={clickedPayment}
                        setInvoices={setInvoices}
                        setLoaderVisible={setLoaderVisible}
                        setStatistics={setStatistics}
                        formattedParams={formattedParams}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openCancelModal ? (
                <Modal modalVisible={openCancelModal} closeModal={() => setOpenCancelModal(false)}>
                    <CancelModal
                        t={t}
                        setOpenCancelModal={setOpenCancelModal}
                        clickedInvoice={clickedInvoice}
                        setInvoices={setInvoices}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {openEInvoiceModal ? (
                <Modal modalVisible={openEInvoiceModal} closeModal={() => setOpenEInvoiceModal(false)}>
                    <EInvoiceModal
                        t={t}
                        setOpenEInvoiceModal={setOpenEInvoiceModal}
                        clickedInvoice={clickedInvoice}
                        setInvoices={setInvoices}
                    />
                </Modal>
            ) : (
                <></>
            )}
            {noEInvoiceApiModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setNoEInvoiceApiModal(false);
                    }}
                >
                    <NoEInvoiceApiModal
                        saveChanges={() => navigate('/agency/e-invoices')}
                        close={() => {
                            setNoEInvoiceApiModal(false);
                        }}
                        message={t('pages.invoices.noEInvoiceKeyModal.title').text}
                    />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={t('pages.eInvoices.refreshSuccess').text} />
                </Modal>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="invoices page">
                <Header>
                    <div>
                        <h1>{t('pages.invoices.title').text}</h1>
                        <AddButtonContainer>
                            <Button
                                variant={ButtonVariant.solid}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => {
                                    if (isAccountActive) {
                                        setAddInvoiceDropdown(!addInvoiceDropdown);
                                    }
                                }}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                {t('pages.invoices.newInvoice').text}
                            </Button>
                            {addInvoiceDropdown && (
                                <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                    <Dropdown
                                        arrowRight="4px"
                                        itemList={[
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.invoice').text,
                                                handler: () => handleAddInvoice('domestic'),
                                            },
                                            {
                                                label: t('pages.invoices.addInvoiceDropdown.foreign').text,
                                                handler: () => handleAddInvoice('foreign'),
                                            },
                                        ]}
                                    />
                                </DropdownContainer>
                            )}
                        </AddButtonContainer>
                    </div>
                    <div>
                        {isAccountActive ? (
                            <Button
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                                onClick={() => navigate('/clients')}
                            >
                                <FontAwesomeIcon
                                    className="icon"
                                    color={colors.purple}
                                    icon={faList}
                                    style={{ marginRight: '5px' }}
                                />
                                {t('buttons.clients').text}
                            </Button>
                        ) : (
                            <></>
                        )}
                        <Button
                            variant={ButtonVariant.outlined}
                            onClick={() => navigate('/invoices/kpo')}
                            color={colors.purple}
                            icon={true}
                            size={'auto'}
                        >
                            <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                            {t('pages.invoices.listOfBooksOfIncome').text}
                        </Button>
                    </div>
                </Header>
                {isAccountActive ? (
                    <Statistics setStatistics={setStatistics} statistics={statistics} />
                ) : (
                    <AccountExpired />
                )}
                <Filters
                    setParams={setParams}
                    params={params}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    setDisplay={setDisplay}
                />
                <InvoicesList className={`display-${display}`}>
                    {invoices?.data?.length > 0 ? (
                        invoices?.data?.map((invoice: ObjectKeys) => {
                            return (
                                <div key={invoice.id} className={`${display}`}>
                                    <InvoiceCard
                                        eInvoice={invoice.eInvoice ? true : undefined}
                                        type={
                                            invoice.eInvoice
                                                ? InvoiceCardComponentType.EInvoice
                                                : InvoiceCardComponentType.Invoice
                                        }
                                        cardData={invoice}
                                        cardDisplay={display}
                                        handleChange={() => handleChange(invoice.id)}
                                        handleCopy={() => handleCopy(invoice.id)}
                                        handleDelete={() => handleDeleteModal(invoice?.invoice_number, invoice.id)}
                                        handleCancel={() => handleCancelModal(invoice.id)}
                                        handleDownload={() =>
                                            handleDownloadInvoice(
                                                invoice?.id,
                                                invoice?.original_data?.agency,
                                                invoice?.client?.data,
                                                invoice.value_in_rsd,
                                                invoice.invoice_number,
                                                invoice.bank_account,
                                                invoice.currency,
                                                true,
                                                invoice,
                                            )
                                        }
                                        handleEInvoice={() => !invoice.eInvoice && handleEInvoiceModal(invoice.id)}
                                        handlePayment={() =>
                                            handlePaymentModal(
                                                invoice.id,
                                                invoice.payments
                                                    ? getRestOfPayment(invoice)
                                                    : formatNumber(Number(invoice.value), 4, 3, '.', ',').toString(),
                                            )
                                        }
                                        handleSend={() =>
                                            handleSendModal(
                                                invoice.id,
                                                invoice.client.data.email ? invoice.client.data.email : '',
                                            )
                                        }
                                        handleClick={() => handleClickedInvoice(invoice.id)}
                                        handleRefresh={() => handleRefreshEInvoice(invoice.id)}
                                        handleUpdatePayment={(e: string) => {
                                            const specificPaymentValue = invoice?.payments?.data.filter(
                                                (payment: ObjectKeys) => {
                                                    return payment.id === e;
                                                },
                                            );
                                            handleUpdatePayment(
                                                e,
                                                invoice.id,
                                                formatNumber(
                                                    Number(specificPaymentValue[0]?.value),
                                                    4,
                                                    3,
                                                    '.',
                                                    ',',
                                                ).toString(),
                                            );
                                        }}
                                        statusFilter={params?.search}
                                        handleDeletePayment={(e: string) => {
                                            handleDeletePayment(e, invoice.id);
                                        }}
                                    />
                                </div>
                            );
                        })
                    ) : (
                        <NoItems text={t('pages.invoices.noItems.description').text} />
                    )}
                </InvoicesList>
                {invoices?.data?.length > 0 && invoices.meta?.pagination?.total_pages > 1 && (
                    <Pagination
                        pageCount={invoices.meta?.pagination?.total_pages}
                        onPageChange={(e: ObjectKeys) => setParams({ ...params, page: e.selected + 1 })}
                        nextLabel={`${
                            invoices.meta?.pagination?.current_page === invoices.meta?.pagination?.total_pages
                                ? ''
                                : '>'
                        }`}
                        previousLabel={`${invoices.meta?.pagination?.current_page === 1 ? '' : '<'}`}
                        breakLabel="..."
                        initialPage={invoices.meta?.pagination?.current_page - 1}
                    />
                )}
            </PageWrapper>
        </>
    );
};
export default Invoices;

const PageWrapper = styled.div``;
const Header = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--border-color);
    justify-content: space-between;
    padding-bottom: 20px;
    margin-bottom: 20px;
    > div {
        display: flex;
        align-item: center;
        button {
            margin-left: 35px;
            height: 35px;
            font-size: 12px;
            line-height: initial;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
        > div {
            width: 100%;
            justify-content: space-between;
            button {
                margin-left: 0;
            }
            &:first-child {
                margin-bottom: 20px;
            }
        }
    }
`;
const InvoicesList = styled.div`
    &.display-grid {
        display: grid;
        gap: 10px;
        width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        @media screen and (max-width: 1700px) {
            grid-template-columns: 1fr 1fr 1fr;
        }
        @media screen and (max-width: 768px) {
            grid-template-columns: 1fr 1fr;
        }
        @media screen and (max-width: 500px) {
            grid-template-columns: 1fr;
        }
    }
`;

const AddButtonContainer = styled.div`
    position: relative;
`;

export const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        margin-left: 0;
    }
    .dropdown-container {
        height: auto;
        overflow-y: auto;
    }
`;
