import { FunctionComponent, useEffect, useState, SetStateAction, Dispatch } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { useToasts } from 'react-toast-notifications';
import moment from 'moment';
import colors from '../../../../../global/colors';
import communication from '../../../../../communication';
import useLanguageStore from '../../../../../store/language';
import useCurrencyStore from '../../../../../store/currency';
import useTranslations from '../../../../../hooks/useTranslation';
import { ObjectKeys } from '../../../../../types/objectKeys';
import useCharLengthValidation from '../../../../../hooks/useCharLengthValidation';
import Input, { InputComponentType } from '../../../../../components/input';
import logo from '../../../../../assets/pausal-logo-gray.png';
import ServiceModal from '../serviceModal';
import TextArea from '../../../../../components/input/textarea';

import {
    Container,
    Header,
    AddServiceCurrency,
    AddService,
    AddServiceButton,
    Agency,
    Comments,
    Footer,
    Row,
    Total,
    TotalAmount,
    Wrapper,
    ForeignInputs,
} from './style';
import WebService from './service/webService';
import MobileService from './service/mobileService';
import SelectComponent, { SelectOption } from '../../../../../components/select';
import { getAllMeasurementUnits } from '../measurementUnits';
import getAdvances from './getAdvances';
import CloseAdvanceModal from './closeAdvanceModal';
import PayedAdvance from './payedAdvance';
import countTotal from './countTotal';
import Modal from '../../../../../components/modal';
import ErrorModal from '../../../../../components/errorModal';
import { formatNumber } from '../../../../../functions/format';

interface Props {
    invoice: ObjectKeys;
    agency: ObjectKeys;
    formData: ObjectKeys;
    setFormData: Function;
    handleTotal: Function;
    isMobileWidth: boolean;
    newInvoice?: boolean;
    type?: string;
    name?: string;
    setLoading: Dispatch<SetStateAction<boolean>>;
    qrCode?: string;
    userId?: string;
}

const InvoiceEdit: FunctionComponent<Props> = ({
    invoice,
    agency,
    formData,
    setFormData,
    handleTotal,
    isMobileWidth,
    newInvoice,
    type,
    name,
    setLoading,
    qrCode,
    userId,
}) => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const units = getAllMeasurementUnits(t, type);
    const { currency } = useCurrencyStore();
    const { addToast } = useToasts();

    const [clickedField, setClickedField] = useState('');
    const [changeService, setChangeService] = useState(false);
    const [addService, setAddService] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [serviceIndex, setServiceIndex] = useState(0);
    const [services, setServices] = useState<ObjectKeys[]>([]);
    const [advances, setAdvances] = useState<SelectOption[]>([]);
    const [currencies, setCurrencies] = useState<SelectOption[]>([]);
    const [closeAdvanceModal, setCloseAdvanceModal] = useState(false);
    const [advanceValue, setAdvanceValue] = useState('');
    const [selectedAdvances, setSelectedAdvances] = useState<ObjectKeys[]>([]);
    const [closedAdvances, setClosedAdvances] = useState<ObjectKeys[]>([]);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    useEffect(() => {
        const currencyData = currency?.map((curr) => {
            return { value: curr.currency.toUpperCase(), label: curr.currency };
        });

        setCurrencies([...currencyData]);
    }, [currentLang]);

    const isPlaceValid = useCharLengthValidation(formData?.trading_place ? formData?.trading_place : '', 2);

    const [params] = useState({
        page: 1,
        limit: '500',
        search: `user_id:${localStorage.getItem('role') === 'admin' ? userId : agency?.user?.data?.id};type:service`,
        searchFields: 'user_id:=;is_foreign:=;type:=',
        searchJoin: 'and',
    });

    useEffect(() => {
        setFormData(invoice);

        async function getData(): Promise<void> {
            const res = await communication.getAllServices(params);
            setServices(res?.data?.data);
        }

        getData();
    }, [changeHappened]);

    useEffect(() => {
        invoice.prepaid_invoices &&
            !formData.prepaid_invoice_ids &&
            setClosedAdvances(
                invoice.prepaid_invoices
                    .map((m: ObjectKeys) => {
                        return {
                            id: m.id,
                            invoice_number: m.invoice_number,
                            value: m.pivot.value,
                        };
                    })
                    .concat(selectedAdvances),
            );
    }, [invoice]);

    useEffect(() => {
        getAdvances(setAdvances, invoice.client_id, invoice.currency);
    }, [invoice.client_id, invoice.currency]);

    const handleSaveAgencyData = (): void => {
        const dataToSend = { ...agency, iban: formData.iban, swift: formData.swift };
        setLoading(true);
        setClickedField('');
        communication
            .updateAgency(agency.id, dataToSend)
            .then((res: ObjectKeys) => {
                if (res) {
                    setLoading(false);
                    const agencyRes = res.data.data;
                    setFormData({ ...formData, iban: agencyRes.iban, swift: agencyRes.swift });
                }
            })
            .catch((error: ObjectKeys) => {
                if (error) {
                    setLoading(false);
                    addToast(t('pages.tryAgain.message').text, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            });
    };

    const accountOptions = [
        { value: agency?.first_account_number, label: agency?.first_account_number },
        { value: agency?.second_account_number, label: agency?.second_account_number },
    ];
    return (
        <>
            {modal && (
                <Modal modalVisible={true} closeModal={() => setModal(false)}>
                    <ErrorModal t={t} setOpenModal={() => setModal(false)} errorMessage={modalMessage} />
                </Modal>
            )}
            {formData && invoice && (
                <Wrapper>
                    {closeAdvanceModal && invoice && (
                        <CloseAdvanceModal
                            invoiceValue={advanceValue}
                            t={t}
                            setCloseAdvanceModal={setCloseAdvanceModal}
                            addAdvance={(v: ObjectKeys) => {
                                const arr = selectedAdvances;
                                arr.push(v);
                                setSelectedAdvances(arr);
                                closedAdvances.push({
                                    invoice_number: Number(v.invNo.split('A ')[1].split('/')[0]),
                                    value: v.value.replace(',', '.'),
                                    id: v.id,
                                });

                                //remove from array of advances for closing
                                const array = advances;
                                array.filter((x) => x.value.toString().split('-')[0] !== v.invoice_number);
                                setAdvances(array.filter((x) => x.value.toString().split('-')[0] !== v.invoice_number));

                                //setFormData
                                setFormData({
                                    ...formData,
                                    prepaid_invoice_ids: closedAdvances,
                                });
                            }}
                        />
                    )}
                    {addService && (
                        <ServiceModal
                            changeValue={() => {
                                setChangeHappened(!changeHappened);
                            }}
                            invoice={invoice}
                            t={t}
                            closeModal={() => setAddService(false)}
                            newRow
                            data={formData}
                            setData={setFormData}
                            services={services}
                            newInvoice={newInvoice}
                            nameRequiredModal={(message: string) => {
                                setModal(true);
                                setModalMessage(message);
                            }}
                            changed={() => setChangeHappened(!changeHappened)}
                        />
                    )}
                    {changeService && (
                        <ServiceModal
                            changeValue={(v: ObjectKeys) => {
                                setChangeHappened(true);
                                setFormData((prev: ObjectKeys) => {
                                    const newService = services.filter((service) => service.id === v.value);
                                    const copy = { ...prev };
                                    if (newService.length !== 0) {
                                        newService[0].pivot = copy.services[serviceIndex].pivot;
                                        copy.services[serviceIndex] = newService[0];
                                    } else {
                                        copy.services[serviceIndex].name = v.label;
                                        copy.services[serviceIndex].id = v.id;
                                    }

                                    return copy;
                                });
                            }}
                            invoice={invoice}
                            t={t}
                            closeModal={() => setChangeService(false)}
                            setData={setFormData}
                            index={serviceIndex}
                            services={services}
                            nameRequiredModal={(message: string) => {
                                setModal(true);
                                setModalMessage(message);
                            }}
                            changed={() => setChangeHappened(!changeHappened)}
                        />
                    )}

                    {invoice && agency && (
                        <Container>
                            <Header>
                                <Row className="inv-no">
                                    <h4>
                                        <div>
                                            {name === 'invoice'
                                                ? t('pages.invoices.addInvoiceDropdown.invoice').text
                                                : name === 'proforma'
                                                ? t('pages.proforma.addProformaDropdown.proforma').text
                                                : t('pages.advanceInvoices.addInvoiceDropdown.aInvoice').text}
                                        </div>
                                    </h4>
                                    <h3>
                                        {invoice.invoice_number
                                            ? invoice.invoice_number
                                            : t('pages.newInvoice.draft').text}
                                    </h3>
                                </Row>
                                <Row className="invoice-dates-text">
                                    <Input
                                        type={InputComponentType.Text}
                                        value={formData.trading_place ? formData.trading_place : ''}
                                        label={t('pages.editInvoice.previewModal.tradingPlace').text}
                                        onChange={(value: string) => setFormData({ ...formData, trading_place: value })}
                                        validation={clickedField === 'trading_place' ? isPlaceValid : ''}
                                        blurHandler={() => handleValidation('trading_place')}
                                    />
                                    <Input
                                        type={InputComponentType.Date}
                                        label={
                                            name === 'invoice'
                                                ? t('pages.editInvoice.previewModal.invoiceDate').text
                                                : name === 'proforma'
                                                ? t('pages.editProforma.proformaDate').text
                                                : t('pages.editAdvanceInvoice.editAdvanceInvoice').text
                                        }
                                        date={
                                            formData?.invoice_date
                                                ? new Date(
                                                      moment(formData?.invoice_date).format('YYYY-MM-DD').toString(),
                                                  )
                                                : formData?.quotation_date
                                                ? new Date(
                                                      moment(formData?.quotation_date).format('YYYY-MM-DD').toString(),
                                                  )
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                invoice_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                    />
                                    <Input
                                        type={InputComponentType.Date}
                                        label={t('pages.editInvoice.previewModal.valueDate').text}
                                        date={
                                            formData?.trading_date
                                                ? new Date(
                                                      moment(formData?.trading_date).format('YYYY-MM-DD').toString(),
                                                  )
                                                : new Date()
                                        }
                                        onChange={(value: string) => {
                                            setFormData({
                                                ...formData,
                                                trading_date: moment(value).format('YYYY-MM-DD').toString(),
                                            });
                                        }}
                                    />
                                </Row>
                            </Header>
                            {type === 'foreign' && (
                                <ForeignInputs>
                                    <Row>
                                        <div>
                                            <Input
                                                type={InputComponentType.Text}
                                                value={invoice.iban ? invoice.iban : ''}
                                                label={t('pages.agency.profile.iban').text}
                                                onChange={(value: string) => setFormData({ ...formData, iban: value })}
                                                blurHandler={() => handleValidation('foreign-inputs')}
                                            />
                                            <Input
                                                type={InputComponentType.Text}
                                                value={invoice.swift ? invoice.swift : ''}
                                                label={t('pages.agency.profile.swift').text}
                                                onChange={(value: string) => setFormData({ ...formData, swift: value })}
                                                blurHandler={() => handleValidation('foreign-inputs')}
                                            />
                                            <FontAwesomeIcon
                                                onClick={() => handleSaveAgencyData()}
                                                icon={faFloppyDisk}
                                                style={{ fontSize: '30px', paddingLeft: '15px' }}
                                                color={colors.purple}
                                                className="cursor-pointer"
                                            />
                                        </div>
                                        {clickedField === 'foreign-inputs' ? (
                                            <p className="validation-message">
                                                {t('pages.newInvoice.ibanSwiftValidation').text}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </Row>
                                </ForeignInputs>
                            )}
                            {agency && (
                                <Agency>
                                    <div>
                                        <h3>{agency.name}</h3>
                                        <p>{agency.full_name}</p>
                                        <p>{agency.street_address + '\xa0' + agency.street_number}</p>
                                        <p>{agency.city}</p>
                                        <p>{t('pages.editInvoice.previewModal.vatNo').text + '\xa0' + agency.pib}</p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.jmbg').text +
                                                '\xa0' +
                                                agency.identification_number}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.email').text}{' '}
                                            <a href={`mailto:${agency?.agency_email}`}>{agency?.agency_email}</a>
                                        </p>

                                        {type === 'foreign' ? (
                                            <>
                                                <p>
                                                    {t('pages.agency.profile.iban').text}:{' '}
                                                    {agency?.iban ? agency?.iban : '/'}
                                                </p>
                                                <p>
                                                    {t('pages.agency.profile.swift').text}:{' '}
                                                    {agency?.swift ? agency?.swift : '/'}
                                                </p>
                                            </>
                                        ) : (
                                            <>
                                                {agency?.second_account_number ? (
                                                    <Row className="accounts">
                                                        {t('pages.editInvoice.previewModal.bank').text}
                                                        {accountOptions.length === 2 && (
                                                            <SelectComponent
                                                                optionList={accountOptions}
                                                                defaultSelectedOption={
                                                                    invoice.bank_account
                                                                        ? {
                                                                              value: invoice.bank_account,
                                                                              label: invoice.bank_account,
                                                                          }
                                                                        : accountOptions[0]
                                                                }
                                                                handleSelectedValue={(value: string) => {
                                                                    setFormData({
                                                                        ...formData,
                                                                        bank_account: value,
                                                                    });
                                                                }}
                                                            />
                                                        )}
                                                    </Row>
                                                ) : (
                                                    <p className="agency-bank-account">
                                                        {`${t('pages.editInvoice.previewModal.bank').text + ' '}`}
                                                        <span>{agency?.first_account_number}</span>
                                                    </p>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div>
                                        <h3>{t('pages.editInvoice.previewModal.client').text}</h3>
                                        <p>
                                            {t('pages.editInvoice.previewModal.recipient').text +
                                                '\xa0' +
                                                (invoice?.client?.data?.company_name
                                                    ? invoice?.client?.data?.company_name
                                                    : '')}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.pib').text +
                                                '\xa0' +
                                                (invoice.client?.data?.pib
                                                    ? invoice.client?.data?.pib
                                                    : invoice.client?.data?.jmbg
                                                    ? invoice.client?.data?.jmbg
                                                    : '')}
                                        </p>
                                        <p>
                                            {invoice?.client?.data?.identification_number ? (
                                                t('pages.editInvoice.previewModal.jmbg').text +
                                                (invoice?.client?.data?.identification_number
                                                    ? invoice?.client?.data?.identification_number
                                                    : '')
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        <p>
                                            {type !== 'foreign' && invoice?.client?.data?.public_company_id ? (
                                                t('pages.editInvoice.previewModal.jbkjs').text +
                                                (invoice?.client?.data?.public_company_id
                                                    ? invoice?.client?.data?.public_company_id
                                                    : '')
                                            ) : (
                                                <></>
                                            )}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.city').text +
                                                '\xa0' +
                                                (invoice.client?.data?.city ? invoice.client?.data?.city : '')}
                                        </p>
                                        <p>
                                            {t('pages.editInvoice.previewModal.address').text +
                                                '\xa0' +
                                                (invoice.client?.data?.address ? invoice.client?.data?.address : '')}
                                        </p>
                                        <>
                                            {advances.length > 0 && (
                                                <Row style={{ alignItems: 'center' }}>
                                                    {t('pages.advanceInvoices.closeAdvance').text}
                                                    <SelectComponent
                                                        optionList={advances}
                                                        placeholder={t('pages.advanceInvoices.chooseAdvance').text}
                                                        handleSelectedValue={(value: string) => {
                                                            setCloseAdvanceModal(true);
                                                            setAdvanceValue(value);
                                                            advances.filter((a) => a.value !== value);
                                                        }}
                                                    />
                                                </Row>
                                            )}
                                        </>
                                        {type === 'foreign' ? (
                                            <p>
                                                {t('pages.editInvoice.country').text +
                                                    ' : ' +
                                                    (invoice?.client?.data?.country
                                                        ? invoice?.client?.data?.country
                                                        : '')}
                                            </p>
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Agency>
                            )}
                            {!isMobileWidth ? (
                                <Row className="services">
                                    <p className="type">{t('pages.editInvoice.previewModal.item').text}</p>
                                    <p className="unit">{t('pages.editInvoice.previewModal.unit').text}</p>
                                    <p className="price">{t('pages.editInvoice.previewModal.amount').text}</p>
                                    <p className="price">{t('pages.editInvoice.previewModal.unitPrice').text}</p>
                                    <p className="total">{t('pages.editInvoice.previewModal.total').text}</p>
                                </Row>
                            ) : (
                                <h3 className="services-title">
                                    {t('pages.editInvoice.addServiceModal.services').text}
                                </h3>
                            )}
                            {invoice?.services && invoice?.services?.length > 0 ? (
                                invoice?.services.map((service: ObjectKeys, index: number) =>
                                    isMobileWidth ? (
                                        <MobileService
                                            t={t}
                                            key={index}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            service={{
                                                ...service,
                                                pivot: {
                                                    ...service.pivot,
                                                    price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
                                                    quantity: service?.pivot?.quantity?.replace('.', ','),
                                                },
                                            }}
                                            services={services}
                                            setChangeService={setChangeService}
                                            setFormData={setFormData}
                                            setServiceIndex={setServiceIndex}
                                            units={units}
                                        />
                                    ) : (
                                        <WebService
                                            key={index}
                                            index={index}
                                            handleTotal={handleTotal}
                                            invoice={invoice}
                                            service={{
                                                ...service,
                                                pivot: {
                                                    ...service.pivot,
                                                    price_per_unit: service?.pivot?.price_per_unit?.replace('.', ','),
                                                    quantity: service?.pivot?.quantity?.replace('.', ','),
                                                },
                                            }}
                                            services={services}
                                            setChangeService={setChangeService}
                                            setFormData={setFormData}
                                            setServiceIndex={setServiceIndex}
                                            units={units}
                                            t={t}
                                        />
                                    ),
                                )
                            ) : (
                                <>
                                    <AddServiceButton onClick={() => setAddService(true)}>
                                        <div className="plus">
                                            <FontAwesomeIcon icon={faPlus} />
                                        </div>
                                        <p>{t('pages.editInvoice.addService').text}</p>
                                    </AddServiceButton>
                                </>
                            )}
                            <AddServiceCurrency>
                                <AddService onClick={() => setAddService(true)}>
                                    <FontAwesomeIcon icon={faPlus} />
                                    <p>{t('pages.editInvoice.addService').text}</p>
                                </AddService>
                                {type === 'foreign' && currencies && (
                                    <SelectComponent
                                        className="currency-select"
                                        label={t('pages.clients.modal.form.foreign.currency').text}
                                        handleSelectedValue={(value: string) => {
                                            setFormData({ ...invoice, currency: value });
                                        }}
                                        optionList={currencies}
                                        defaultSelectedOption={{ value: invoice.currency, label: invoice.currency }}
                                    />
                                )}
                            </AddServiceCurrency>
                            <Total>
                                <TotalAmount>
                                    <div>
                                        <p>
                                            {t('pages.editInvoice.previewModal.totalPayment').text} ({invoice.currency})
                                        </p>
                                        <h3>{formatNumber(countTotal(invoice.services), 2, 3, '.', ',')}</h3>
                                    </div>
                                </TotalAmount>

                                {(selectedAdvances.length > 0 || invoice?.prepaid_invoices?.length > 0) && (
                                    <PayedAdvance
                                        total={countTotal(invoice.services)}
                                        closedAdvances={closedAdvances}
                                        t={t}
                                        currency={invoice.currency}
                                        onDelete={(advance: ObjectKeys) => {
                                            //advances for displaying
                                            const advancesForDelete = closedAdvances;
                                            setClosedAdvances(
                                                advancesForDelete.filter(
                                                    (a: ObjectKeys) =>
                                                        a.invoice_number !== advance.invoice_number ||
                                                        a.value !== advance.value,
                                                ),
                                            );

                                            setFormData({
                                                ...formData,
                                                prepaid_invoice_ids: advancesForDelete.filter(
                                                    (a: ObjectKeys) =>
                                                        a.invoice_number !== advance.invoice_number ||
                                                        a.value !== advance.value,
                                                ),
                                            });
                                        }}
                                    />
                                )}
                            </Total>

                            <Row>
                                <Comments>
                                    <p>{t('pages.editInvoice.previewModal.comment').text}</p>
                                    <TextArea
                                        value={formData.comment ? formData.comment : ''}
                                        onChange={(value: string) => setFormData({ ...formData, comment: value })}
                                        placeholder={t('pages.editInvoice.previewModal.textAreaPlaceholder').text}
                                    />
                                </Comments>
                                <Comments>
                                    <p style={{ marginBottom: '20px' }}>
                                        {currentLangName !== 'English'
                                            ? name === 'invoice'
                                                ? t('pages.editInvoice.previewModal.invoiceDocumentValid').text
                                                : name === 'proforma'
                                                ? t('pages.editInvoice.previewModal.proformaDocumentValid').text
                                                : t('pages.editInvoice.previewModal.advanceInvoiceDocumentValid').text
                                            : t('pages.editInvoice.previewModal.documentValid').text}
                                    </p>
                                    <p style={{ marginBottom: '20px', wordBreak: 'break-all' }}>
                                        {t('pages.editInvoice.previewModal.id').text}
                                        {agency.signature}
                                    </p>
                                    <p>{t('pages.editInvoice.previewModal.note').text}</p>
                                    <p>{t('pages.editInvoice.previewModal.note1').text}</p>
                                    <p>
                                        {name === 'invoice'
                                            ? t('pages.editInvoice.previewModal.invoiceNote2').text
                                            : name === 'proforma'
                                            ? t('pages.editInvoice.previewModal.proformaNote2').text
                                            : t('pages.editInvoice.previewModal.advanceInvoiceNote2').text}
                                    </p>
                                    <p>
                                        {t('pages.editInvoice.previewModal.place').text} {agency.city}
                                    </p>
                                </Comments>
                            </Row>

                            {qrCode && (
                                <img
                                    width={'200px'}
                                    height={'200px'}
                                    data-v-f036a56e=""
                                    data-v-251216d5=""
                                    src={`data:image/jpeg;base64,${qrCode}`}
                                    data-v-52b191c5=""
                                ></img>
                            )}

                            <Footer>
                                <p>
                                    {name === 'proforma'
                                        ? t('pages.editInvoice.previewModal.footerProforma').text
                                        : t('pages.editInvoice.previewModal.footerInvoice').text}
                                    <a href="http://www.pausal.rs" target="_blank" rel="noreferrer">
                                        pausal.rs
                                    </a>
                                </p>
                                <img src={logo} height={'15px'} />
                            </Footer>
                        </Container>
                    )}
                </Wrapper>
            )}
        </>
    );
};

export default InvoiceEdit;
