import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';

const useEmailValidation = (email: string, emailsArray: Array<string>): string => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (emailsArray.length === 0 && email === '') {
        return t('pages.invoices.sendModal.enterEmail').text;
    } else if (emailsArray.filter((e) => e === email).length !== 0) {
        return t('pages.invoices.sendModal.sameEmail').text;
    }
    const isEmailValid =
        /(^$|^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$)/.test(
            String(email).toLowerCase(),
        );
    if (!isEmailValid) {
        return t('pages.validationMessages.emailInvalid').text;
    }

    return '';
};
export default useEmailValidation;
