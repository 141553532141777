import useTranslations from '../hooks/useTranslation';
import useLanguageStore from '../store/language';

const useNumberValidation = (num: string): string => {
    const res = Number(num.trim());
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    if (!Number.isNaN(res)) return '';
    else return t('navbar.nbs.numberField').text;
};
export default useNumberValidation;
