import { FunctionComponent, MouseEventHandler } from 'react';
import styled from 'styled-components';
import attachSize from '../../functions/size';

export enum ButtonVariant {
    solid = 'solid',
    outlined = 'outlined',
    link = 'link',
}

interface Props {
    variant: ButtonVariant;
    icon?: boolean;
    color: string;
    background?: string;
    size?: number | string;
    height?: number | string;
    onClick?: MouseEventHandler<HTMLButtonElement> & Function;
    disabled?: boolean;
    isLoading?: boolean;
    className?: string;
    href?: string;
    children?: string | any;
    textTransformNone?: boolean;
}

const Button: FunctionComponent<Props> = ({
    variant,
    icon,
    color,
    background,
    size,
    height,
    children,
    onClick,
    disabled,
    isLoading,
    className,
    href,
    textTransformNone,
}) => {
    return href ? (
        <a href={href}>
            <StyledButton
                className={`i btn-${variant} ${className ? className : ''} ${isLoading ? 'loading' : ''} ${
                    icon ? 'with-left-icon' : ''
                }`.trim()}
                variant={variant}
                color={color}
                background={background}
                size={size}
                height={height}
                onClick={(e) => {
                    onClick ? onClick(e) : null;
                }}
                disabled={disabled}
                href={href}
                textTransformNone={textTransformNone}
            >
                {isLoading ? (
                    <div className="spinner-wrapper">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    <div className="children">{children}</div>
                )}
            </StyledButton>
        </a>
    ) : (
        <StyledButton
            className={`i btn-${variant} ${className ? className : ''} ${isLoading ? 'loading' : ''} ${
                icon ? 'with-icon left-icon' : ''
            }`.trim()}
            variant={variant}
            color={color}
            background={background}
            size={size}
            height={height}
            onClick={(e) => {
                onClick ? onClick(e) : null;
            }}
            disabled={disabled}
            href={href}
            textTransformNone={textTransformNone}
        >
            {isLoading ? (
                <div className="spinner-wrapper">
                    <div className="spinner"></div>
                </div>
            ) : (
                <div className="children">{children}</div>
            )}
        </StyledButton>
    );
};

const StyledButton = styled.button<Props>`
    border: ${(props) => (props.variant !== ButtonVariant.link ? `1px solid ${props.color}` : 'none')};
    background-color: ${(props) => (props.variant === ButtonVariant.solid ? props.color : 'transparent')};
    color: ${(props) => (props.variant !== ButtonVariant.solid ? props.color : 'white')};
    font-size: 14px;
    line-height: 24px;
    padding: 9px 16px;
    border-radius: 5px;
    min-width: ${(props) => attachSize(props.size)};
    width: ${(props) => attachSize(props.size)};
    height: ${(props) => attachSize(props.height)};
    transition: ease all 0.3s;
    text-transform: ${(props) => (props.textTransformNone ? '' : 'uppercase')};
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    &:hover {
        opacity: 0.7;
    }
    &:disabled {
        opacity: 0.6;
        filter: grayscale(1);
        box-shadow: none !important;
    }
    &.btn-solid,
    &.btn-outlined {
        &:hover {
            box-shadow: var(--box-shadow);
        }
        &:active {
            box-shadow: 0 1px #666;
        }
    }
    &.btn-link {
        &:hover {
            filter: ${(props) => `drop-shadow(0 0 8px ${props.color})`};
        }
        &:active {
            transform: scale(0.95);
        }
    }
    &:hover {
        cursor: pointer;
        &:disabled {
            cursor: not-allowed;
        }
    }

    &.with-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
            width: 12px;
            height: 12px;
        }
        &.left-icon {
            svg {
                margin-right: 8px;
            }
        }
    }

    .children {
        transition: ease all 0.3s;
        opacity: 1;
        display: contents;
    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        pointer-events: none;
        opacity: 0;
        height: auto;
        transition: ease all 0.3s;
        .spinner {
            border: 3px solid ${(props) => (props.variant !== ButtonVariant.solid ? props.color : 'white')};
            border-top: 3px solid rgba(255, 255, 255, 0.2);
            border-radius: 50%;
            width: 20px;
            height: 20px;
            animation: spin 1s linear infinite;
        }
    }
    &.loading {
        pointer-events: none;
    }
    &.big {
        height: 68px;
        width: ${(props) => (props.size ? attachSize(props.size) : '200px')};
    }
    &.small {
        width: ${(props) => (props.size ? attachSize(props.size) : '150px')};
        height: 40px;
    }
    &.rounded {
        border-radius: 100px;
    }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }

        100% {
            transform: rotate(360deg);
        }
    }

    @keyframes pulse {
        0% {
            transform: scale(1);
        }

        50% {
            transform: scale(1.1);
        }

        100% {
            transform: scale(1);
        }
    }

    @keyframes fading {
        0% {
            opacity: 1;
            transform: scale(1);
        }

        100% {
            opacity: 0;
            transform: scale(1.3);
        }
    }
`;

export default Button;
