import { FunctionComponent, useState, useEffect } from 'react';
import Select, { StylesConfig } from 'react-select';
import styled from 'styled-components';
import CreatableSelect from 'react-select/creatable';
export type SelectOption = {
    value: string | number;
    label: string;
};
interface Props {
    label?: string;
    className?: string;
    placeholder?: string;
    optionList: SelectOption[];
    value?: string | null;
    handleSelectedValue?: Function;
    handleCreateOption?: (value: string) => void;
    width?: string;
    color?: string;
    defaultSelectedOption?: { value: string | number; label: string };
    isMultiple?: boolean;
    isCreatable?: boolean;
    isSearchable?: boolean;
}

interface SelectProps {
    width?: string;
}

const SelectComponent: FunctionComponent<Props> = ({
    label,
    className,
    placeholder,
    optionList,
    value,
    handleSelectedValue,
    width,
    color,
    defaultSelectedOption,
    isMultiple = false,
    isCreatable = false,
    handleCreateOption,
    isSearchable,
}) => {
    const [selectedOptions, setSelectedOptions] = useState(value ? optionList.find((v) => v.value === value) : null);
    const handleSelect = (data: SelectOption): void => {
        handleSelectedValue && handleSelectedValue(data.value);
        setSelectedOptions(data);
    };

    useEffect(() => {
        if (defaultSelectedOption) setSelectedOptions(defaultSelectedOption);
    }, [defaultSelectedOption]);

    const colourStyles: StylesConfig<any> = {
        control: (base) => ({
            ...base,
            backgroundColor: 'transparent',
            borderRadius: '5px',
            cursor: 'pointer',
            boxShadow: '0px',
            height: '42px',
            fontSize: '13px',
            border: '1px solid var(--border-color)',
            color: color,
            ':hover': {
                border: '1px solid var(--border-color)',
            },
        }),
        option: (styles, { isDisabled }) => {
            return {
                ...styles,
                backgroundColor: '',
                color: 'var(--black)',
                cursor: isDisabled ? 'not-allowed' : 'pointer',
                borderWidth: '2px',
                borderRadius: '0',
                borderBottom: '1px solid var(--border-color)',
                ':focus': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':active': {
                    backgroundColor: 'var(--purple)',
                    color: 'var(--white)',
                    ':hover': {
                        color: 'var(--white)',
                    },
                },
                ':hover': {
                    color: 'var(--gray)',
                },
            };
        },
        input: (styles) => ({
            ...styles,
            borderWidth: '2px',
            borderRadius: '5px',
            height: '34px',
            fontSize: '14px',
            color: color,
            lineHeight: '1.428571429',
            verticalAlign: 'middle',
            transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s',
        }),
        placeholder: (styles) => ({
            ...styles,
            color: color,
        }),
        singleValue: (styles) => ({
            ...styles,
            color: color,
        }),
        dropdownIndicator: (base, state) => ({
            ...base,
            transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
            transition: '250ms',
            width: '32px',
            height: '30px',
            color: color,
        }),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    };

    return (
        <StyledSelectContainer width={width} className={'select-wrapper'}>
            <div className={`${className ?? ''}`}>
                {label ? <label>{label}</label> : <></>}
                {isCreatable ? (
                    <CreatableSelect
                        className={className}
                        classNamePrefix="select"
                        options={optionList}
                        onCreateOption={handleCreateOption}
                        styles={colourStyles}
                        isClearable={false}
                        isSearchable={true}
                        isMulti={isMultiple}
                        placeholder={selectedOptions ? selectedOptions.label : placeholder}
                        defaultValue={optionList[0]}
                    />
                ) : (
                    <Select
                        classNamePrefix="select"
                        options={optionList}
                        onChange={handleSelect}
                        styles={colourStyles}
                        isClearable={false}
                        value={value}
                        isSearchable={isSearchable === false ? false : true}
                        isMulti={isMultiple}
                        placeholder={selectedOptions ? selectedOptions.label : placeholder}
                        // defaultValue={value === '1' ? optionList[1] : optionList[0]}
                    />
                )}
            </div>
        </StyledSelectContainer>
    );
};

const StyledSelectContainer = styled.div<SelectProps>`
    text-align-last: left;
    display: flex;
    font-family: inherit;
    border-radius: 5px;
    border-style: hidden;
    font-size: 16px;
    cursor: pointer;
    flex-direction: column;

    .einvoices {
        label {
            color: var(--gray);
            margin-bottom: 10px;
            padding: 0px;
            font-size: 15px;
        }
        .select__control {
            border-radius: 0;
            border: 2px solid var(--purple);
        }
        margin-bottom: 20px;
    }

    label {
        color: var(--light-gray);
        font-size: 13px;
        padding: 5px;
        line-height: 18px;
        letter-spacing: -0.3px;
    }
    width: ${(props) => (props.width ? `${props.width}` : '200px')};

    &option {
        background-color: red;

        &--is-focused {
            background-color: green;
        }
    }
`;
export default SelectComponent;
