import { FunctionComponent, useEffect, useState } from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { getAccessToken } from '../functions/auth';
import Navbar from '../components/navbar';
import styled from 'styled-components';
import useWindowSize from '../hooks/useResize';

const Private: FunctionComponent = () => {
    const token = getAccessToken();
    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    if (!token) {
        return <Navigate to="/login" />;
    }

    return (
        <>
            {!isMobileWidth ? (
                <>
                    <Navbar />
                    <Body className="body private">
                        <Outlet />
                    </Body>
                </>
            ) : (
                <>
                    <Navbar />
                    <MobileBody className="body private">
                        <Outlet />
                    </MobileBody>
                </>
            )}
        </>
    );
};

const Body = styled.div`
    margin-left: 248px;
    padding: 30px 100px 100px;
    background: var(--body-gray);
    overflow: auto;

    @media only screen and (max-width: 1079px) {
        padding: 30px 30px 100px !important;
        margin-left: 0px;
        margin-top: 84px;
    }
`;

const MobileBody = styled.div`
    margin-top: 84px;
    padding: 30px 15px;
    overflow: auto;

    padding-bottom: 104px;
    position: fixed;
    width: 100%;

    @media screen and (max-width: 768px) {
        background: var(--body-gray);
    }
`;
export default Private;
