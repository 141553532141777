import { FunctionComponent, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { isEmpty, some } from 'lodash';
import moment from 'moment';
import useTranslations from '../../../../hooks/useTranslation';
import useWindowSize from '../../../../hooks/useResize';
import { getAccessToken } from '../../../../functions/auth';
import { ObjectKeys } from '../../../../types/objectKeys';
import { SendDocumentFormData } from '../../../../types/communication/document';
import communication from '../../../../communication';
import { generateQrCode } from '../../../../communication/qr';
import useLanguageStore from '../../../../store/language';
import useAgencyStore from '../../../../store/agency';
import Modal from '../../../../components/modal';
import Success from '../../../../components/successModal';
import InvoiceClients from '../../../../components/invoiceClients';
import Sidebar from '../../../../components/sideModal';
import Loader from '../../../../components/loader';
import ChangeModal from '../../../../components/changeModal';
import ErrorModal from '../../../../components/errorModal';
import AddClientModal from '../../clients/addClientModal';
import SendModal from '../../invoices/sendModal';
import EditClientModal from '../../clients/editClientModal';
import MobileHeader from './header/mobileHeader';
import WebHeader from './header/webHeader';
import WebFooter from './footer/webFooter';
import PreviewInvoiceModal from './previewInvoiceModal';
import InvoiceEdit from './invoiceEdit';
import MobileFooter from './footer/mobileFooter';
import { Body, Container, Invoice } from './style';
import countTotal from './invoiceEdit/countTotal';
import InvoiceForeignEdit from './invoiceForeignEdit';

const CopyEInvoice: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const { agency } = useAgencyStore();
    const t = useTranslations(currentLang);
    const navigate = useNavigate();
    const token = getAccessToken();
    const size = useWindowSize();
    const { pathname } = useLocation();
    const invoiceId = useParams().id;

    const isAdvance = pathname.includes('advance');

    const [clients, setClients] = useState<ObjectKeys>([]);
    const [invoice, setInvoice] = useState<ObjectKeys>({});
    const [clickedClient, setClickedClient] = useState('');
    const [success, setSuccess] = useState(false);
    const [changeHappened, setChangeHappened] = useState(false);
    const [newCreatedClient, setNewCreatedClient] = useState<ObjectKeys>({});

    //modals
    const [modalMessage, setModalMessage] = useState('');
    const [menuVisible, setMenuVisible] = useState(false);
    const [editClientModal, setEditClientModal] = useState(false);
    const [addClientModal, setAddClientModal] = useState(false);
    const [deleteClientModal, setDeleteClientModal] = useState(false);
    const [deleteInvoiceModal, setDeleteInvoiceModal] = useState(false);
    const [sendModal, setSendModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [previewModal, setPreviewModal] = useState(false);
    const [openErrorModal, setOpenErrorModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [qrCode, setQrCode] = useState('');
    const [pastYearModal, setPastYearModal] = useState(false);

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });
    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    useEffect(() => {
        if (!isEmpty(newCreatedClient)) {
            setInvoice((prev: ObjectKeys) => {
                const newInvoiceData = { ...prev };
                newInvoiceData.client_id = newCreatedClient && newCreatedClient?.id;
                newInvoiceData.client.data = newCreatedClient;
                return newInvoiceData;
            });
        }
    }, [newCreatedClient]);

    const handleClientDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteClient(clickedClient);
            if (res.status === 200) setDeleteClientModal(false);
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };
    const handleInvoiceDelete = async (): Promise<void> => {
        try {
            const res = await communication.deleteInvoice(invoice.id);
            if (res.status === 200) {
                setDeleteInvoiceModal(false);
                navigate(-1);
            }
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const thisYear = parseInt(moment().format('YYYY'));

    const update = async (): Promise<any> => {
        try {
            setLoading(true);
            const updateInvoice = { ...invoice };
            updateInvoice.services = updateInvoice.services.map((service: ObjectKeys, i: number) => ({
                index: i,
                id: service.id,
                measurement_unit: service.pivot.measurement_unit,
                quantity: Number(service.pivot.quantity.replace(',', '.')),
                price_per_unit: Number(service.pivot.price_per_unit.replace(',', '.')),
            }));
            updateInvoice.invoice_number = updateInvoice.real_invoice_number;
            updateInvoice.value = Number(countTotal(invoice.services).replace(',', '.'));
            updateInvoice.value_in_rsd = Number(countTotal(invoice.services).replace(',', '.'));
            updateInvoice.trading_date = moment(updateInvoice.trading_date).format('YYYY-MM-DD').toString();
            updateInvoice.rate = Number(updateInvoice.rate);
            updateInvoice.paid_date = moment(updateInvoice.paid_date).format('YYYY-MM-DD').toString();
            updateInvoice.invoice_date = moment(updateInvoice.invoice_date).format('YYYY-MM-DD').toString();
            updateInvoice.cancelled = false;
            updateInvoice.cancel_number = false;
            updateInvoice.custom_number = false;
            updateInvoice.is_custom = false;
            updateInvoice.custom_text = false;
            updateInvoice.auto_increment = true;
            updateInvoice.invoice_per_owner = true;
            if (isAdvance) {
                updateInvoice.is_prepaid = true;
            }
            const res = await communication.editInvoice(invoice.id, updateInvoice);

            if (res) {
                setLoading(false);
                navigate(-1);
                return res?.data?.data?.id;
            }
        } catch (error: any) {
            setErrorMessage(error.response.data.message);
            setOpenErrorModal(true);
            setLoading(false);
        }
    };

    const handleUpdateInvoice = async (): Promise<any> => {
        const oldInvoiceData = await communication.getInvoice(invoiceId ? invoiceId : '');

        if (invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
        } else if (
            some(
                invoice.services,
                (s) =>
                    Math.ceil(Number(s.pivot.quantity.replace(',', '.'))) === 0 ||
                    Math.ceil(Number(s.pivot.price_per_unit.replace(',', '.'))) === 0,
            )
        ) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.invalidInvoice').text);
        } else if (
            new Date(invoice.invoice_date).getFullYear() < thisYear &&
            moment(oldInvoiceData.data.data.invoice_date).format('YYYY-MM-DD').toString() !==
                moment(invoice.invoice_date).format('YYYY-MM-DD').toString()
        ) {
            setPastYearModal(true);
        } else {
            return update();
        }
    };

    useEffect(() => {
        setLoading(true);
        if (invoiceId) {
            communication.getInvoice(invoiceId).then((res: ObjectKeys) => {
                setInvoice(res?.data?.data);
                setLoading(false);
                agency &&
                    handleGenerateQRCode(
                        agency,
                        res?.data?.data?.client?.data?.company_name,
                        res?.data?.data?.value,
                        res?.data?.data?.id,
                        res?.data?.data?.bank_account,
                        res?.data?.data?.currency,
                        true,
                    );
            });
        }
    }, [invoiceId]);

    //QR code
    const handleGenerateQRCode = (
        agencyData: ObjectKeys,
        clientData: ObjectKeys,
        amount: string,
        invoiceNumber: string,
        bankAccount: string,
        currency: string,
        convert: boolean,
    ): void => {
        try {
            generateQrCode(agencyData.name, clientData, amount, invoiceNumber, bankAccount, currency, convert)
                .then((response: ObjectKeys | any) => {
                    if (response.i) setQrCode(response.i);
                })
                .catch((error: any) => {
                    if (error) {
                        console.error(error);
                    }
                });
        } catch (e: any) {
            setErrorMessage(e.response.data.message);
            setOpenErrorModal(true);
        }
    };

    const handleSelectedClient = (client: ObjectKeys): void => {
        setChangeHappened(true);
        setInvoice((prev: ObjectKeys) => {
            const newInvoiceData = { ...prev };
            newInvoiceData.client_id = client?.id;
            newInvoiceData.client.data = client;
            return newInvoiceData;
        });
        agency &&
            invoice &&
            handleGenerateQRCode(
                agency,
                client.company_name,
                invoice.value,
                invoice.id,
                invoice.bank_account,
                invoice.currency,
                true,
            );
    };

    //Invoice Download
    const handleDownloadInvoice = (): void => {
        if (invoice.services && invoice.services.length === 0) {
            setSuccess(true);
            setModalMessage(t('pages.editInvoice.missingService').text);
        } else
            generateQrCode(
                invoice.original_data.agency.name,
                invoice.client.data ? invoice.client.data : {},
                invoice.value ? invoice.value.toString() : '0',
                invoice.invoice_number ? invoice.invoice_number : '',
                invoice.bank_account,
                invoice.currency,
                invoice.value ? false : true,
            )
                .then(async (res: ObjectKeys | any) => {
                    if (res) {
                        const invoiceWithNewDataId = await update();
                        if (invoiceWithNewDataId) {
                            await communication
                                .downloadInvoice(
                                    invoiceWithNewDataId,
                                    token,
                                    invoice.invoice_number,
                                    invoice.currency !== 'RSD',
                                )
                                .catch((error: ObjectKeys) => {
                                    if (error) {
                                        setErrorMessage(error.response.data.message);
                                        setOpenErrorModal(true);
                                        setLoading(false);
                                    }
                                });
                        }
                    }
                })
                .catch((error: ObjectKeys) => {
                    if (error) {
                        console.error(error);
                    }
                });
    };

    return (
        <>
            {loading && <Loader />}
            {openErrorModal && (
                <Modal modalVisible={openErrorModal} closeModal={() => setOpenErrorModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenErrorModal} errorMessage={errorMessage} />
                </Modal>
            )}
            {success && (
                <Modal modalVisible={true} closeModal={() => setSuccess(false)}>
                    <Success close={() => setSuccess(false)} message={modalMessage} />
                </Modal>
            )}
            {sendModal && (
                <Sidebar close={() => setSendModal(false)}>
                    <SendModal
                        close={() => setSendModal(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        id={invoice.id}
                        success={() => {
                            setSendModal(false);
                        }}
                        loading={(e: boolean) => setLoading(e)}
                        clientEmail={''}
                        setInvoices={setInvoice}
                        qrCode={qrCode}
                        handleAddNewInvoice={handleUpdateInvoice}
                        errorMessage={(mess: string) => {
                            setErrorMessage(mess);
                            setOpenErrorModal(true);
                        }}
                        modalVisible={sendModal}
                    />
                </Sidebar>
            )}
            {addClientModal && (
                <Sidebar close={() => setAddClientModal(false)}>
                    <AddClientModal
                        t={t}
                        clickedClient={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        userId={agency?.user_id}
                        setAddClientModal={setAddClientModal}
                        setClients={setClients}
                        tabIndex={0}
                        setNewCreatedClient={setNewCreatedClient}
                    />
                </Sidebar>
            )}
            {editClientModal && (
                <Sidebar close={() => setEditClientModal(false)}>
                    <EditClientModal
                        t={t}
                        clickedClient={clickedClient}
                        userId={agency?.user_id}
                        setEditClientModal={setEditClientModal}
                        setClients={setClients}
                        tabIndex={0}
                    />
                </Sidebar>
            )}
            {pastYearModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setPastYearModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={update}
                        close={() => {
                            setPastYearModal(false);
                        }}
                        message={t('pages.editInvoice.pastYearModal').text}
                        confirmText={t('buttons.ok').text}
                        closeText={t('buttons.close').text}
                    />
                </Modal>
            )}
            {deleteClientModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteClientModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleClientDelete}
                        close={() => {
                            setDeleteClientModal(false);
                        }}
                        message={t('pages.editInvoice.deleteClient').text}
                    />
                </Modal>
            )}
            {deleteInvoiceModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setDeleteInvoiceModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleInvoiceDelete}
                        close={() => {
                            setDeleteInvoiceModal(false);
                        }}
                        message={t('pages.editInvoice.deleteInvoice').text}
                    />
                </Modal>
            )}
            {previewModal && (
                <Modal
                    width="700px"
                    className="preview-modal"
                    modalVisible={true}
                    closeModal={() => {
                        setPreviewModal(false);
                    }}
                >
                    <PreviewInvoiceModal
                        agency={agency}
                        invoice={invoice}
                        title="invoice"
                        type={invoice.type === 'national' ? 'domestic' : 'foreign'}
                        note={invoice.comment}
                    />
                </Modal>
            )}

            <Container>
                {isMobileWidth ? (
                    <MobileHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                    />
                ) : (
                    <WebHeader
                        t={t}
                        handleUpdateInvoice={handleUpdateInvoice}
                        menuVisible={menuVisible}
                        pathname={pathname}
                        setDeleteInvoiceModal={setDeleteInvoiceModal}
                        setMenuVisible={setMenuVisible}
                        setPreviewModal={setPreviewModal}
                        setSendModal={setSendModal}
                        changeHappened={changeHappened}
                        handleInvoiceUpdate={handleUpdateInvoice}
                        invoice={invoice}
                        handleDownloadInvoice={handleDownloadInvoice}
                        title={pathname.includes('advance') ? 'advance' : 'invoice'}
                    />
                )}
                <Body className="edit-invoice">
                    <InvoiceClients
                        t={t}
                        setClients={setClients}
                        clients={clients}
                        setAddClientModal={setAddClientModal}
                        agency={agency}
                        handleSelectedClient={handleSelectedClient}
                        addClientModal={addClientModal}
                        deleteClientModal={deleteClientModal}
                        editClientModal={editClientModal}
                        setClickedClient={setClickedClient}
                        setEditClientModal={setEditClientModal}
                        setDeleteClientModal={setDeleteClientModal}
                        invoice={invoice}
                        type={invoice?.type === 'national' ? 'domestic' : invoice?.type === 'foreign' ? 'foreign' : ''}
                        loading={(isLoading: boolean) => setLoading(isLoading)}
                    />
                    {agency && invoice && (
                        <Invoice>
                            {invoice?.type === 'national' ? (
                                <InvoiceEdit
                                    qrCode={qrCode}
                                    name="invoice"
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    setLoading={setLoading}
                                    type={'domestic'}
                                />
                            ) : (
                                <InvoiceForeignEdit
                                    qrCode={qrCode}
                                    name="invoice"
                                    formData={invoice}
                                    setFormData={setInvoice}
                                    agency={agency}
                                    invoice={invoice}
                                    handleTotal={(sum: any) => {
                                        setInvoice({
                                            ...invoice,
                                            value: sum,
                                            value_in_rsd: sum,
                                            total_for_payment: sum,
                                            total_for_payment_in_rsd: sum,
                                        });
                                    }}
                                    isMobileWidth={isMobileWidth}
                                    setLoading={setLoading}
                                    type={'foreign'}
                                />
                            )}
                        </Invoice>
                    )}
                    {isMobileWidth ? (
                        <MobileFooter
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={setSendModal}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    ) : (
                        <WebFooter
                            t={t}
                            handleUpdateInvoice={handleUpdateInvoice}
                            setDeleteInvoiceModal={setDeleteInvoiceModal}
                            setPreviewModal={setPreviewModal}
                            setSendModal={setSendModal}
                            handleDownloadInvoice={handleDownloadInvoice}
                            invoice={invoice}
                        />
                    )}
                </Body>
            </Container>
        </>
    );
};
export default CopyEInvoice;
