import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Public from './public';
import Private from './private';
import Register from '../pages/register';
import NotFoundRoute from '../pages/error';
import Typography from '../pages/typography';
import Login from '../pages/login';
import Activation from '../pages/register/accountActivation';
import ForgotPassword from '../pages/forgot password';
import ResetPassword from '../pages/forgot password/reset password';
import ConfirmEmail from '../pages/register/confirmEmail';
import PersonalInformation from '../pages/myAgency/personalInformation';
import MyAgency from '../pages/myAgency';
import AgencyProfile from '../pages/myAgency/agencyProfile';
import SendData from '../pages/myAgency/sendData';
import Invoices from '../pages/pausal/invoices';
import Notifications from '../pages/myAgency/notifications';
import Documents from '../pages/myAgency/documents';
import ChangePassword from '../pages/myAgency/changePassword';
import TaxSolution from '../pages/myAgency/taxSolution';
import PaymentInstructions from '../pages/myAgency/paymentInstructions';
import HealthCards from '../pages/healthCards';
import Einvoices from '../pages/myAgency/einvoices';
import AddLogo from '../pages/myAgency/addLogo';
import EInvoices from '../pages/pausal/einvoices';
import Kpo from '../pages/pausal/einvoices/kpo';
import CopyEInvoice from '../pages/pausal/einvoices/copyEInvoice';
import Memorandums from '../pages/memorandums';
import NewMemorandum from '../pages/memorandums/new';
import EditMemorandum from '../pages/memorandums/edit/editMemorandum';
import Clients from '../pages/pausal/clients';
import Proforma from '../pages/pausal/proforma';
import AdvanceInvoices from '../pages/pausal/advanceInvoices';
import NewInvoice from '../pages/pausal/invoices/newInvoice';
import Users from '../pages/admin/users';
import InvoicesAdmin from '../pages/admin/invoices';
import EditInvoiceAdmin from '../pages/admin/invoices/editInvoice';
import ProformaAdmin from '../pages/admin/proforma';
import EditProformaAdmin from '../pages/admin/proforma/editProforma';
import NewProforma from '../pages/pausal/proforma/newProforma';
import EditProforma from '../pages/pausal/proforma/editProforma';
import TransferOrders from '../pages/transferOrders';
import TransferOrdersAdmin from '../pages/admin/transferOrderAdmin';
import Suggestions from '../pages/admin/suggestions';
import Transactions from '../pages/admin/transactions';
import AdminNotifications from '../pages/admin/notifications';
import Statistics from '../pages/admin/statistics';
import AdminTaxSolutions from '../pages/admin/taxSolutions';
import Reports from '../pages/reports';
import IndependenceTest from '../pages/independenceTest/';
import EAdvanceInvoices from '../pages/pausal/eAdvanceInvoices';

const content: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route element={<Public />}>
                <Route path="/register" element={<Register />} />
                <Route path="/login" element={<Login />} />
                <Route path="/account-activation" element={<Activation />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />
                <Route path="/confirm-email/:token" element={<ConfirmEmail />} />
            </Route>
            <Route element={<Private />}>
                <Route path="*" element={<NotFoundRoute />} />
                <Route path="/typography" element={<Typography />} />

                {/* Pausal pages start*/}

                {/* Invoices */}
                <Route path="/" element={<Invoices />} />
                <Route path="/invoices" element={<Invoices />} />
                <Route path="/invoices/kpo" element={<Kpo />} />
                <Route path={'/invoices/edit/:id'} element={<CopyEInvoice />} />
                <Route path={'/invoices/add/:type'} element={<NewInvoice />} />

                {/* E-invoices */}
                <Route path="/e-invoices" element={<EInvoices />} />
                <Route path="/e-invoices/kpo" element={<Kpo />} />
                <Route path={'/e-invoices/edit/:id'} element={<CopyEInvoice />} />

                {/* Advance-invoices */}
                <Route path="/advance-invoices" element={<AdvanceInvoices />} />
                <Route path={'/advance-invoices/edit/:id'} element={<CopyEInvoice />} />
                <Route path={'/advance-invoices/add/:type'} element={<NewInvoice />} />

                <Route path="/eadvance-invoices" element={<EAdvanceInvoices />} />

                {/* Clients */}
                <Route path="/clients" element={<Clients />} />

                {/* Proforma */}
                <Route path="/proforma" element={<Proforma />} />
                <Route path="/proforma/edit/:proformaId" element={<EditProforma />} />
                <Route path={'/proforma/add/:proformaType'} element={<NewProforma />} />

                <Route path="/health-cards" element={<HealthCards />} />
                <Route path="/transfer-orders" element={<TransferOrders />} />
                <Route path="/reports" element={<Reports />} />

                <Route>
                    <Route path="/memorandums" element={<Memorandums />} />
                    <Route path="/memorandums/new" element={<NewMemorandum />} />
                    <Route path="/memorandums/edit" element={<NewMemorandum />} />
                    <Route path="/memorandums/edit/:id/:copy" element={<EditMemorandum />} />
                    <Route path="/memorandums/edit/:id" element={<EditMemorandum />} />
                </Route>
                <Route element={<MyAgency />}>
                    <Route path="/agency/profile" element={<AgencyProfile />} />
                    <Route path="/agency/user" element={<PersonalInformation />} />
                    <Route path="/agency/send-data" element={<SendData />} />
                    <Route path="/agency/notifications" element={<Notifications />} />
                    <Route path="/agency/documents" element={<Documents />} />
                    <Route path="/agency/change-password" element={<ChangePassword />} />
                    <Route path="/agency/payment-instructions" element={<PaymentInstructions />} />
                    <Route path="/agency/tax-solution" element={<TaxSolution />} />
                    <Route path="/agency/e-invoices" element={<Einvoices />} />
                    <Route path="/agency/add-logo" element={<AddLogo />} />
                </Route>
                {/* Pausal pages end*/}

                <Route>
                    <Route path="/independenceTest" element={<IndependenceTest />} />
                </Route>

                {/* Admin pages start*/}
                <Route path="/admin/users" element={<Users />} />
                <Route path="/admin/invoices" element={<InvoicesAdmin />} />
                <Route path="/admin/invoices/edit/:invoiceId/:userId" element={<EditInvoiceAdmin />} />
                <Route path="/admin/proforma" element={<ProformaAdmin />} />
                <Route path="/admin/proforma/edit/:proformaId/:userId" element={<EditProformaAdmin />} />
                <Route path="/admin/transfer-orders" element={<TransferOrdersAdmin />} />
                <Route path="/admin/suggestions" element={<Suggestions />} />
                <Route path="/admin/transactions" element={<Transactions />} />
                <Route path="/admin/notifications" element={<AdminNotifications />} />
                <Route path="/admin/statistics" element={<Statistics />} />
                <Route path="/admin/tax" element={<AdminTaxSolutions />} />
                {/* Admin pages end*/}
            </Route>
        </Routes>
    );
};

export default content;
