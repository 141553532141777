import { Dispatch, FunctionComponent, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import communication from '../../../../communication';
import { ObjectKeys } from '../../../../types/objectKeys';
import useTranslations from '../../../../hooks/useTranslation';
import useLanguageStore from '../../../../store/language';
import { formatNumber } from '../../../../functions/format';
import ProgressBar from '../../../../components/progressBar';
import Tooltip from '../../../../components/tooltip';
import { formatToSerbianNumber } from '../../../../functions/format';
interface Props {
    statistics: ObjectKeys;
    setStatistics: Dispatch<SetStateAction<ObjectKeys>>;
}

const Statistics: FunctionComponent<Props> = ({ setStatistics, statistics }) => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    useEffect(() => {
        communication.getInvoiceStatistics().then((res: ObjectKeys) => {
            if (res?.status === 200) {
                setStatistics && setStatistics(res?.data);
            }
        });
    }, []);

    return (
        <>
            <StatisticsData>
                <div>
                    <h2>{formatNumber(statistics?.limit6issued, 2, 3, '.', ',')}</h2>
                    <span>{t('pages.invoices.statistics.sentInCurrentYear').text}</span>
                </div>
                <div>
                    <h2>{formatNumber(statistics?.limit6paid, 2, 3, '.', ',')}</h2>
                    <span>{t('pages.invoices.statistics.paidInCurrentYear').text}</span>
                </div>
            </StatisticsData>

            <Tooltip
                label={
                    statistics?.limit6issued !== 0
                        ? `${t('pages.invoices.statistics.sent').text} - ${formatToSerbianNumber(
                              statistics?.limit6issued,
                              4,
                          )} RSD`
                        : ''
                }
            >
                <ProgressBar progressLabels={['0', '3M', '6M']} progressValue={statistics?.limit6issued} />
            </Tooltip>
            <Tooltip
                label={
                    statistics?.limit6paid !== 0
                        ? `${t('pages.invoices.statistics.paid').text} - ${formatToSerbianNumber(
                              statistics?.limit6paid,
                              4,
                          )} RSD`
                        : ''
                }
            >
                <ProgressBar
                    progressLabels={['0', '3M', '6M']}
                    progressValue={statistics?.limit6paid}
                    barBg={'rgb(206, 188, 255)'}
                />
            </Tooltip>
            <Tooltip
                label={
                    statistics?.limit8issued !== 0
                        ? `${t('pages.invoices.statistics.sent').text} - ${formatToSerbianNumber(
                              statistics?.limit8issued,
                              4,
                          )} RSD`
                        : ''
                }
            >
                <ProgressBar
                    progressLabels={['0', '2M', '4M', '6M', '8M']}
                    progressValue={statistics?.limit8issued}
                    title={t('pages.invoices.statistics.billedInPrevious365Days').text}
                    barBg={'rgb(206, 188, 255)'}
                />
            </Tooltip>
        </>
    );
};

const StatisticsData = styled.div`
    display: flex;
    align-item: center;
    justify-content: space-between;
    @media only screen and (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
    }
    > div {
        text-align: center;
        width: 50%;
        padding: 50px 0 45px;
        @media only screen and (max-width: 1000px) {
            width: 100%;
        }
        h2 {
            color: var(--purple);
            line-height: 22px;
            margin-bottom: 5px;
        }
    }
`;
export default Statistics;
