import { FunctionComponent } from 'react';
import { MobileRow, MobileTotal, Row, StyledInput } from '../../style';
import SelectComponent from '../../../../../../../components/select';
import Input, { InputComponentType } from '../../../../../../../components/input';
import { ObjectKeys } from '../../../../../../../types/objectKeys';
import colors from '../../../../../../../global/colors';
import { formatToSerbianNumber } from '../../../../../../../functions/format';
import useServiceNumberValidation from '../../../../../../../hooks/useServiceNumberValidation';

interface Props {
    t: Function;
    index: number;
    service: ObjectKeys;
    services: ObjectKeys[];
    units: ObjectKeys[];
    setServiceIndex: Function;
    setChangeService: Function;
    setFormData: Function;
    handleTotal: Function;
    invoice: ObjectKeys;
}

const MobileService: FunctionComponent<Props> = ({
    t,
    index,
    service,
    services,
    units,
    setServiceIndex,
    setChangeService,
    setFormData,
    handleTotal,
    invoice,
}) => {
    const isQuantityValid = useServiceNumberValidation(service.pivot.quantity);
    const isPriceValid = useServiceNumberValidation(service.pivot.price_per_unit);

    return (
        <>
            <Row className="service-list" key={service.type}>
                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.item').text}</span>
                        <p
                            onClick={() => {
                                setServiceIndex(index);
                                setChangeService(true);
                            }}
                            className="type"
                        >
                            {service.name ? service.name : services.find((s: ObjectKeys) => s.id === service.id)?.name}
                        </p>
                    </div>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unit').text}</span>
                        <SelectComponent
                            handleSelectedValue={(v: ObjectKeys) => {
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services[index].pivot.measurement_unit = v;
                                    return copy;
                                });
                            }}
                            optionList={units.map((unit) => ({
                                label: unit.label,
                                value: unit.value,
                            }))}
                            value={units.find((u) => u.value === service.pivot.measurement_unit)?.value}
                            color={colors.lightGray}
                        />
                    </div>
                </MobileRow>

                <MobileRow>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unit').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Text}
                                    value={service.pivot.quantity !== '0' ? service.pivot.quantity : '0'}
                                    onChange={(v: any) => {
                                        {
                                            setFormData((prev: ObjectKeys) => {
                                                const copy = { ...prev };
                                                copy.services[index].pivot.quantity = v;
                                                copy.services[index].pivot.total_price =
                                                    Number(v.replace(',', '.')) *
                                                    Number(service.pivot.price_per_unit.replace(',', '.'));
                                                return copy;
                                            });
                                            handleTotal(
                                                invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                    return (
                                                        accumulator +
                                                        (isNaN(object.pivot.total_price)
                                                            ? 0
                                                            : Number(object.pivot.total_price))
                                                    );
                                                }, 0),
                                            );
                                        }
                                    }}
                                    placeholder={t('pages.editInvoice.enterQuantity').text}
                                    validation={isQuantityValid ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>x</span>
                        </MobileRow>
                    </div>
                    <div>
                        <span>{t('pages.editInvoice.previewModal.unit').text}</span>
                        <MobileRow>
                            <StyledInput className="price">
                                <Input
                                    type={InputComponentType.Text}
                                    value={service.pivot.price_per_unit ? service.pivot.price_per_unit : '0'}
                                    onChange={(v: any) => {
                                        setFormData((prev: ObjectKeys) => {
                                            const copy = { ...prev };
                                            copy.services[index].pivot.price_per_unit = v;
                                            copy.services[index].pivot.total_price =
                                                Number(service.pivot.quantity.replace(',', '.')) * v.replace(',', '.');
                                            return copy;
                                        });
                                        handleTotal(
                                            invoice.services?.reduce((accumulator: number, object: ObjectKeys) => {
                                                return (
                                                    accumulator +
                                                    (isNaN(object.pivot.total_price)
                                                        ? 0
                                                        : Number(object.pivot.total_price))
                                                );
                                            }, 0),
                                        );
                                    }}
                                    placeholder={t('pages.editInvoice.enterUnitPrice').text}
                                    validation={isPriceValid ? '' : 'error'}
                                />
                            </StyledInput>
                            <span>=</span>
                        </MobileRow>
                    </div>
                </MobileRow>

                <MobileTotal>
                    <span>{t('pages.editInvoice.previewModal.total').text}</span>
                    <StyledInput className="total">
                        <Input
                            type={InputComponentType.Text}
                            value={formatToSerbianNumber(
                                service.pivot.quantity?.replace(',', '.') *
                                    service.pivot.price_per_unit?.replace(',', '.'),
                            ).toString()}
                        />
                        <span
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                                setFormData((prev: ObjectKeys) => {
                                    const copy = { ...prev };
                                    copy.services.splice(index, 1);
                                    return copy;
                                })
                            }
                        >
                            x
                        </span>
                    </StyledInput>
                </MobileTotal>
            </Row>
        </>
    );
};

export default MobileService;
