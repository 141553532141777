import { FunctionComponent } from 'react';
import styled from 'styled-components';

interface Props {
    label: string;
    children: any;
    position?: string;
}

const Tooltip: FunctionComponent<Props> = ({ children, label, position }) => {
    return (
        <CustomTooltip position={position}>
            {children}
            {label && (
                <div className={'label'}>
                    <div>{label}</div>
                </div>
            )}
        </CustomTooltip>
    );
};

const CustomTooltip = styled.div<{ position: string | undefined }>`
    position: relative;
    .label {
        white-space: pre-wrap;
        z-index: 10;
        position: absolute;
        bottom: 40%;
        width: 100%;
        font-size: 15px;
        left: 0;
        transition: all 0.3s ease-in-out 0s;
        text-align: left;
        opacity: 0;
        display: flex;
        justify-content: ${(props) => (props.position ? props.position : 'center')};
        &:hover {
            opacity: 1;
        }
        > div {
            background-color: var(--white);
            width: 350px;
            border: 4px solid var(--purple);
            border-radius: 6px;
            padding: 5px;
        }
    }
`;

export default Tooltip;
