import { Dispatch, SetStateAction } from 'react';
import communication from '../../../communication';
import { ObjectKeys } from '../../../types/objectKeys';

const getAllProforma = (setProforma: Dispatch<SetStateAction<ObjectKeys>>): void => {
    communication
        .getProforma({
            page: 1,
            limit: '10',
            search: {},
            find: '',
            year: '',
            orderBy: 'created_at',
            sortedBy: 'desc',
            searchFields: '',
            searchJoin: 'and',
        })
        .then((res: ObjectKeys) => {
            if (res.status === 200) {
                setProforma(res?.data);
            }
        });
};
export default getAllProforma;
