import { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';

interface Props {
    close: Function;
    active?: boolean;
    className?: string;
    children?: string | any;
}
interface SidebarProps {
    active?: boolean;
    className?: string;
    children?: string | any;
}

const Sidebar: FunctionComponent<Props> = ({ close, active, children, className }) => {
    const [act, setActive] = useState(active);

    useEffect(() => {
        setActive(active);
    }, [active]);

    return (
        <Container className={`${act ? 'sidebar active' : 'sidebar'} ${className ? className : ''}`.trim()}>
            <div className="modal-backdrop" onClick={() => close()} />

            <StyledSidebar className={`${act ? 'active' : ''} sidebar-content`.trim()}>{children}</StyledSidebar>
        </Container>
    );
};
const Container = styled.div`
    z-index: 57;
    position: fixed;
    visibility: visible;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    margin-right: 460px;
    .active {
        transform: translate3d(0, 0, 0);
    }
    div {
        &.modal-backdrop {
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.6);
        }
    }
`;
const StyledSidebar = styled.div<SidebarProps>`
    position: fixed;
    z-index: 99;
    width: 460px;
    max-width: 100%;
    right: 0;
    top: 0;
    height: 100vh;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    left: auto;
    background: var(--white);
    padding: 17px 30px 50px;
    overflow: auto;

    transform: translateX(0);
    animation: slide-right 0.25s ease;
    @keyframes slide-right {
        0% {
            opacity: 0;
            transform: translateX(460px);
        }
        100% {
            opacity: 1;
            transform: translateX(0);
        }
    }

    .sidebar-inner-content {
        padding: 24px;
        box-sizing: border-box;
    }
    @media only screen and (max-width: 768px) {
        margin-top: 84px;
        padding-bottom: 184px;

        border-top: 1px solid rgba(229, 229, 229, 0.32);
        position: fixed;
        z-index: 200000;
        left: 0;
        right: 0;
    }
    @media only screen and (max-width: 768px) {
        top: 0;
    }
`;
export default Sidebar;
