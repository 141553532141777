import { faEnvelope, faEye, faPlus, faPrint, faSave, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { every } from 'lodash';
import { FunctionComponent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonVariant } from '../../../../../../components/button';
import colors from '../../../../../../global/colors';
import { Header, MenuButton } from '../../style';
import Modal from '../../../../../../components/modal';
import ChangeModal from '../../../../../../components/changeModal';
import BackLink from '../../../../../../components/backLink';
import { ObjectKeys } from '../../../../../../types/objectKeys';
import getModalMessage from '../getModalMessage';

interface Props {
    t: Function;
    pathname: string;
    setPreviewModal: Function;
    setSendModal: Function;
    setMenuVisible: Function;
    menuVisible: boolean;
    handleUpdateInvoice: Function;
    setDeleteInvoiceModal: Function;
    changeHappened: boolean;
    handleInvoiceUpdate: Function;
    invoice: ObjectKeys;
    newInvoice?: boolean;
    title?: string;
    handleDownloadInvoice: Function;
    handleCreateInvoiceFromProforma?: Function;
}

const WebHeader: FunctionComponent<Props> = ({
    t,
    setPreviewModal,
    setSendModal,
    setMenuVisible,
    menuVisible,
    handleUpdateInvoice,
    setDeleteInvoiceModal,
    changeHappened,
    handleInvoiceUpdate,
    invoice,
    newInvoice,
    title,
    handleDownloadInvoice,
    handleCreateInvoiceFromProforma,
}) => {
    const { pathname } = useLocation();
    const role = localStorage.getItem('role');
    const navigate = useNavigate();
    const [changeModal, setChangeModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');

    const handleModal = (): void => {
        setModal(true);
        const messageToShow = getModalMessage(title, t);
        setModalMessage(
            messageToShow
                ? messageToShow
                : !invoice.services
                ? t('pages.editInvoice.invalidInvoiceQuit').text
                : invoice.services.length === 0
                ? t('pages.editInvoice.invalidInvoiceQuit').text
                : t('pages.editInvoice.quitQuestion').text,
        );
    };

    return (
        <>
            {modal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setModal(false);
                    }}
                >
                    <ChangeModal
                        close={() => {
                            setModal(false);
                        }}
                        message={modalMessage}
                        saveChanges={() => navigate(-1)}
                    />
                </Modal>
            )}
            {changeModal && (
                <Modal
                    modalVisible={true}
                    closeModal={() => {
                        setChangeModal(false);
                    }}
                >
                    <ChangeModal
                        saveChanges={handleInvoiceUpdate}
                        close={() => {
                            setChangeModal(false);
                        }}
                        message={
                            !invoice.services
                                ? t('pages.editInvoice.invalidInvoiceQuit').text
                                : invoice.services.length === 0
                                ? t('pages.editInvoice.invalidInvoiceQuit').text
                                : t('pages.editInvoice.quitQuestion').text
                        }
                    />
                </Modal>
            )}
            <Header>
                <div className="left">
                    <BackLink
                        onClick={() => {
                            every(invoice.services, (s) => s.pivot.quantity === '0' && s.pivot.price_per_unit === '0')
                                ? handleModal()
                                : changeHappened
                                ? setChangeModal(true)
                                : pathname.includes('advance')
                                ? navigate('/advance-invoices')
                                : navigate(-1);
                        }}
                    />
                    <h1>
                        {(newInvoice &&
                            (title === 'invoice'
                                ? t('pages.newInvoice.title').text
                                : title === 'proforma'
                                ? t('pages.newProforma.title').text
                                : t('pages.newAdvanceInvoice.title').text)) ||
                            (title === 'invoice'
                                ? t('pages.editInvoice.title').text
                                : title === 'proforma'
                                ? t('pages.editProforma.title').text
                                : t('pages.editAdvanceInvoice.title').text)}
                    </h1>
                </div>
                <div className="right">
                    {role !== 'admin' ? (
                        <>
                            <Button
                                onClick={() => handleDownloadInvoice()}
                                variant={ButtonVariant.outlined}
                                color={colors.purple}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                                {t('pages.editInvoice.print').text}
                            </Button>
                            <Button
                                onClick={() => setSendModal(true)}
                                variant={ButtonVariant.solid}
                                color={colors.blue}
                                icon={true}
                                size={'auto'}
                                className="btn-send"
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                                {t('pages.editInvoice.send').text}
                            </Button>
                        </>
                    ) : (
                        <></>
                    )}
                    <Button
                        onClick={() => setMenuVisible(!menuVisible)}
                        variant={ButtonVariant.outlined}
                        color={colors.purple}
                        size={'auto'}
                    >
                        <FontAwesomeIcon className="icon" color={colors.purple} icon={faPlus} />
                    </Button>
                    {menuVisible && (
                        <MenuButton>
                            <Button
                                onClick={() => handleUpdateInvoice()}
                                variant={ButtonVariant.solid}
                                color={colors.green}
                                icon={true}
                                size={'auto'}
                            >
                                <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                                {t('pages.editInvoice.saveAsDraft').text}
                            </Button>
                            {title === 'proforma' ? (
                                <Button
                                    onClick={() => handleCreateInvoiceFromProforma && handleCreateInvoiceFromProforma()}
                                    variant={ButtonVariant.solid}
                                    icon={true}
                                    color={colors.green}
                                    size={'auto'}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.white} icon={faPlus} />
                                    {t('pages.invoiceCard.dropdownItems.invoice').text}
                                </Button>
                            ) : (
                                <></>
                            )}
                            {role !== 'admin' ? (
                                <Button
                                    variant={ButtonVariant.outlined}
                                    color={colors.purple}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => setPreviewModal(true)}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faEye} />
                                    {t('pages.editInvoice.preview').text}
                                </Button>
                            ) : (
                                <></>
                            )}
                            {invoice?.id ? (
                                <Button
                                    variant={ButtonVariant.outlined}
                                    color={colors.danger}
                                    icon={true}
                                    size={'auto'}
                                    onClick={() => setDeleteInvoiceModal(true)}
                                >
                                    <FontAwesomeIcon className="icon" color={colors.danger} icon={faTrashCan} />
                                    {t('pages.editInvoice.delete').text}
                                </Button>
                            ) : (
                                <></>
                            )}
                        </MenuButton>
                    )}
                </div>
            </Header>
        </>
    );
};

export default WebHeader;
