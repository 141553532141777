import { FunctionComponent, useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import styled from 'styled-components';
import colors from '../../global/colors';
import keysToBottomUnderscore from '../../functions/keysToUnderscore';
import useTranslations from '../../hooks/useTranslation';
import useEmailValidation from '../../hooks/useEmailValidation';
import usePasswordValidation from '../../hooks/usePasswordValidation';
import useWindowSize from '../../hooks/useResize';
import useLanguageStore from '../../store/language';
import useUserStore from '../../store/user';
import useAgencyStore from '../../store/agency';
import useProfileStore from '../../store/profile';
import initFetch from '../../functions/init';
import communication from '../../communication';
import Input, { InputComponentType } from '../../components/input';
import Button, { ButtonVariant } from '../../components/button';
import Modal from '../../components/modal';
import ErrorModal from '../../components/errorModal';
import Loader from '../../components/loader';
import CityPhoto from '../../assets/city.png';
import Logo from '../../assets/logo.png';

const Login: FunctionComponent = () => {
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const { agency } = useAgencyStore();
    const size = useWindowSize();
    const navigate = useNavigate();
    const { addToast } = useToasts();

    const [formData, setFormData] = useState({
        email: '',
        password: '',
    });
    const [openModal, setOpenModal] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loaderVisible, setLoaderVisible] = useState(false);
    const [showValidationMessage, setShowValidationMessage] = useState(false);
    const [isTabletWidth, setIsTabletWidth] = useState(!!size.width && size.width < 1000);
    const [clickedField, setClickedField] = useState('');

    const isEmailValid = useEmailValidation(formData.email);
    const isPasswordValid = usePasswordValidation(formData.password);

    const handleValidation = (inputName: string): void => {
        setClickedField(inputName);
    };

    const login = async (): Promise<void> => {
        setShowValidationMessage(true);
        setLoaderVisible(true);
        if (isEmailValid === '' && isPasswordValid === '') {
            try {
                const res = await communication.login({ email: formData.email, password: formData.password });
                const data = keysToBottomUnderscore(res?.data);

                localStorage.setItem('access_token', data.response_content.access_token);
                localStorage.setItem('refresh_token', data.response_content.refresh_token);
                localStorage.setItem('role', data.roles[0]);
                if (data.roles[0] !== 'admin') {
                    useUserStore.setState({ userInfo: agency?.user?.data });
                    useProfileStore.setState({ profile: agency });
                    await initFetch();
                    navigate('/invoices');
                } else {
                    await initFetch();
                    navigate('/admin/users');
                }
            } catch (error: any) {
                if (error?.response?.status === 422) {
                    addToast(
                        currentLangName === 'English'
                            ? error.response.data.message
                            : error.response.data.errors.email[0],
                        {
                            appearance: 'error',
                            autoDismiss: true,
                        },
                    );
                } else if (error?.response?.status === 409) {
                    setErrorMessage(currentLangName === 'English' ? error.message : error.response.data.message);
                    setOpenModal(true);
                } else {
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            }
        }
        setLoaderVisible(false);
    };

    useEffect(() => {
        setIsTabletWidth(!!size.width && size.width < 1000);
    }, [size.width]);

    return (
        <>
            {openModal ? (
                <Modal modalVisible={openModal} closeModal={() => setOpenModal(false)}>
                    <ErrorModal t={t} setOpenModal={setOpenModal} errorMessage={errorMessage} />
                </Modal>
            ) : (
                <></>
            )}
            {loaderVisible && <Loader />}
            <PageWrapper className="login page">
                <LoginInner>
                    {!isTabletWidth ? (
                        <LeftColumn>
                            <div className="content">
                                <h1>{t('pages.login.leftColumn.title').text}</h1>
                                <h3>{t('pages.login.leftColumn.subtitle').text}</h3>
                            </div>
                        </LeftColumn>
                    ) : (
                        <></>
                    )}
                    <RightColumn>
                        <div className="content">
                            <div className="top-block">
                                <h4>{t('pages.login.rightColumn.title').text}</h4>
                                <img src={Logo} alt="logo" />
                            </div>
                            <div className="form">
                                <Input
                                    type={InputComponentType.Text}
                                    label={t('pages.login.rightColumn.form.email').text}
                                    validation={showValidationMessage || clickedField === 'email' ? isEmailValid : ''}
                                    value={formData.email}
                                    blurHandler={() => handleValidation('email')}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, email: value });
                                    }}
                                />
                                <Input
                                    type={InputComponentType.Password}
                                    label={t('pages.login.rightColumn.form.password').text}
                                    validation={
                                        showValidationMessage || clickedField === 'password' ? isPasswordValid : ''
                                    }
                                    value={formData.password}
                                    onEnter={login}
                                    blurHandler={() => handleValidation('password')}
                                    onChange={(value: string) => {
                                        setFormData({ ...formData, password: value });
                                    }}
                                />
                                <div className="forgot-password-link">
                                    <Link to="/forgot-password">
                                        <span>{t('pages.login.rightColumn.form.forgotPasswordLink').text}</span>
                                    </Link>
                                </div>
                                <div className="login-btn">
                                    <Button
                                        variant={ButtonVariant.solid}
                                        color={colors.purple}
                                        className="big"
                                        size={200}
                                        onClick={login}
                                    >
                                        {t('buttons.login').text}
                                    </Button>
                                </div>
                            </div>
                            <div className="bottom-block">
                                <span>{t('pages.login.rightColumn.noAccount').text}</span>
                                <Button
                                    variant={ButtonVariant.solid}
                                    color={colors.purple}
                                    onClick={() => navigate('/register')}
                                    size={150}
                                >
                                    {t('buttons.register').text}
                                </Button>
                            </div>
                        </div>
                    </RightColumn>
                </LoginInner>
            </PageWrapper>
        </>
    );
};
export default Login;

const PageWrapper = styled.div`
    background: var(--purple);
    height: 100%;
    overflow-y: scroll;
`;
const LoginInner = styled.div`
    display: flex;
    height: 100%;
    max-width: 1800px;
    margin: 0 auto;
    padding: 10% 40px 0 40px;
    //Media Queries
    @media only screen and (max-width: 1000px) {
        padding: 5% 40px 0 40px;
    }
    @media only screen and (max-width: 425px) {
        padding: 5% 20px 0 20px;
    }
`;
const LeftColumn = styled.div`
    background: var(--purple);
    color: var(--white);
    flex: 2 1 60%;

    .content {
        max-width: 650px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        margin-top: 10%;
        margin-right: 40px;
        h1 {
            margin-bottom: 30px;
            font-size: 48px;
        }
        h3 {
            line-height: 1.2;
        }
    }
`;
const RightColumn = styled.div`
    background: var(--purple);
    flex: 2 1 40%;
    .content {
        background: var(--white);
        box-shadow: 11px 10px 34px -6px rgba(0, 0, 0, 0.75);
        max-width: 500px;
        //Media Queries
        @media only screen and (max-width: 1000px) {
            margin: 0 auto;
        }
        .top-block {
            background: url(${CityPhoto}) bottom no-repeat;
            background-size: 100% auto;
            border-bottom: 1px solid var(--border-color);
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            height: 160px;
            padding: 20px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
                height: 130px;
            }
            img {
                width: 110px;
                height: auto;
            }
        }
        .form {
            padding: 40px 45px;
            //Media Queries
            @media only screen and (max-width: 425px) {
                padding: 30px;
            }
            .input-container {
                width: 100%;
            }
            .forgot-password-link {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 20px;
                a {
                    color: var(--purple);
                }
            }
            .login-btn {
                display: flex;
                justify-content: center;
            }
        }
        .bottom-block {
            background: var(--light-gray);
            padding: 20px 45px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //Media Queries
            @media only screen and (max-width: 500px) {
                padding: 20px 30px;
                flex-direction: column;
                justify-content: center;
                text-align: center;
                span {
                    margin-bottom: 10px;
                }
            }
            span {
                color: var(--gray);
            }
        }
    }
`;
