import { FunctionComponent, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faCheckSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons';
import colors from '../../global/colors';
import { formatNumber } from '../../functions/format';
import useTranslations from '../../hooks/useTranslation';
import useWindowSize from '../../hooks/useResize';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import { ObjectKeys } from '../../types/objectKeys';
import Dropdown from '../dropdown';
import useDropdown from './useDropdown';
import getStatus from './getStatus';
import getNumberLabel from './getNumberLabel';
import InvoiceActionsWithoutDropdown from './invoiceActionsWithoutDropdown';
import useOutsideClick from '../../hooks/useOutsideClick';
import PaymentDropdown from './paymentDropdown';

export enum InvoiceCardComponentType {
    Invoice = 'invoice',
    EInvoice = 'eInvoice',
    Proforma = 'proforma',
    AdvanceInvoice = 'advanceInvoice',
    EAdvanceInvoice = 'eAdvanceInvoice',
}
interface Props {
    type: string;
    className?: string;
    handleChange?: Function;
    handleDownload?: Function;
    handleCopy?: Function;
    handleCancel?: Function;
    handleDelete?: Function;
    handleSend?: Function;
    handlePayment?: Function;
    handleEInvoice?: Function;
    handleInvoice?: Function;
    handleClick: Function;
    handleRefresh?: Function;
    cardDisplay: string;
    cardData: ObjectKeys;
    eInvoice?: boolean;
    statusFilter?: ObjectKeys;
    handleUpdatePayment?: Function;
    handleDeletePayment?: Function;
}
const InvoiceCard: FunctionComponent<Props> = ({
    type,
    className,
    handleChange,
    handleCancel,
    handleCopy,
    handleDelete,
    handleDownload,
    handleEInvoice,
    handlePayment,
    handleSend,
    handleClick,
    handleInvoice,
    cardDisplay,
    cardData,
    statusFilter,
    eInvoice,
    handleRefresh,
    handleUpdatePayment,
    handleDeletePayment,
}) => {
    const size = useWindowSize();
    const role = localStorage.getItem('role');
    const { currentLang, currentLangName } = useLanguageStore();
    const t = useTranslations(currentLang);
    const ref = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const { profile } = useProfileStore();

    const isAccountActive = profile?.active === 1 ? true : false;

    const [isLaptopWidth, setIsLaptopWidth] = useState(!!size.width && size.width < 1560);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [showPaymentDropdown, setShowPaymentDropdown] = useState(false);

    const { handleClickOutside } = useOutsideClick(ref, setDropdownVisible);

    const paidDropdown = useDropdown(
        cardData,
        type,
        'paid',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        handleDownload,
        handleEInvoice,
        undefined,
        eInvoice ? handleRefresh : undefined,
    );
    const sentDropdown = useDropdown(
        cardData,
        type,
        'sent',
        handleChange,
        handleCancel,
        handleCopy,
        handleDelete,
        handleSend,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
    );
    const draftDropdown = useDropdown(
        cardData,
        type,
        'draft',
        handleChange,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        undefined,
        undefined,
        undefined,
    );
    const partiallyDropdown = useDropdown(
        cardData,
        type,
        'partially',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
        eInvoice ? handleRefresh : undefined,
    );
    const cancelledDropdown = useDropdown(
        cardData,
        type,
        'cancelled',
        handleChange,
        handleCancel,
        handleCopy,
        handleDelete,
        handleSend,
        handlePayment,
        handleDownload,
        handleEInvoice,
        undefined,
    );
    const proformaDropdown = useDropdown(
        cardData,
        type,
        'proforma',
        handleChange,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        handleDownload,
        undefined,
        handleInvoice,
    );
    const closedDropdown = useDropdown(
        cardData,
        type,
        'closed',
        undefined,
        undefined,
        handleCopy,
        handleDelete,
        handleSend,
        undefined,
        handleDownload,
        handleEInvoice,
        undefined,
    );
    const adminDropdown = useDropdown(
        cardData,
        type,
        'admin',
        handleChange,
        undefined,
        undefined,
        handleDelete,
        undefined,
        undefined,
        handleDownload,
        undefined,
        undefined,
    );
    useEffect(() => {
        setIsLaptopWidth(!!size.width && size.width < 1560);
    }, [size.width]);

    const getUrlByType = (cardType: string): string => {
        switch (cardType) {
            case 'invoice':
                return 'invoices';
            case 'proforma':
                return 'proforma';
            case 'advanceInvoice':
                return 'advance-invoices';
            default:
                return 'invoices';
        }
    };

    // Close Payments dropdown when status filter changes
    useEffect(() => {
        if (statusFilter && statusFilter?.status !== 'paid,partially') {
            setShowPaymentDropdown(false);
        }
    }, [statusFilter]);

    return (
        <StyledInvoiceCard key={cardData.id} className={`card ${className ?? ''} card-display-${cardDisplay}`.trim()}>
            <CardContent className="card-content cursor-pointer">
                <InfoWrapper
                    onClick={() => {
                        if (cardData.status === 'open' || type === 'proforma') {
                            isAccountActive && navigate(`/${getUrlByType(type)}/edit/${cardData.id}`);
                        } else {
                            if (statusFilter?.status === 'paid,partially') {
                                setShowPaymentDropdown(!showPaymentDropdown);
                            } else handleDownload && handleDownload(cardData?.id);
                        }
                    }}
                    className="info-wrapper"
                >
                    <div className="name-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.name').text}</div>
                        <div className="name">
                            {statusFilter?.status === 'paid,partially' ? (
                                <FontAwesomeIcon
                                    icon={cardData.status === 'paid' ? faCheckSquare : faMinusSquare}
                                    style={{
                                        color: cardData.status === 'paid' ? colors.green : colors.danger,
                                        marginRight: '8px',
                                        fontSize: '18px',
                                    }}
                                />
                            ) : (
                                <></>
                            )}
                            {cardData?.client?.data?.company_name}
                        </div>
                    </div>
                    <div className="number-col">
                        <div className="label">{getNumberLabel(currentLangName, type, t)}</div>
                        <div className="number">
                            {(type === 'proforma' && cardData?.quotation_number) ||
                                (cardData?.invoice_number
                                    ? cardData?.invoice_number
                                    : getStatus(cardData?.status.toLowerCase(), t))}
                        </div>
                    </div>
                    <div className="date-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.date').text}</div>
                        <div className="date">
                            {moment(type === 'proforma' ? cardData?.quotation_date : cardData?.invoice_date)?.format(
                                'DD.MM.YYYY',
                            )}
                        </div>
                    </div>
                    <div className="amount-col">
                        <div className="label">{t('pages.invoiceCard.infoWrapper.amount').text}</div>
                        <div className="amount">
                            {formatNumber(cardData?.value, 2, 3, '.', ',') + ' ' + cardData?.currency}
                        </div>
                    </div>
                    {type !== 'proforma' && (
                        <>
                            <div className="status-col">
                                <div className="label">{t('pages.invoiceCard.infoWrapper.status').text}</div>
                                <div className={`status ${cardData?.status}`}>
                                    <span>{getStatus(cardData?.status.toLowerCase(), t)}</span>
                                </div>
                            </div>
                            <>
                                {cardData.eInvoice && (
                                    <div className="status-col">
                                        <div className="label">{t('pages.eInvoices.status.title').text}</div>
                                        <div className={`status ${cardData?.eInvoice.data.status.toLowerCase()}`}>
                                            <span>
                                                {getStatus(
                                                    cardData?.eInvoice.data.status.toLowerCase(),
                                                    t,
                                                    cardData.eInvoice,
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                )}
                            </>
                        </>
                    )}
                </InfoWrapper>
                {isAccountActive ? (
                    <ActionWrapper>
                        <div className="label"></div>
                        <div className="action">
                            {isLaptopWidth ? (
                                <Action>
                                    <FontAwesomeIcon
                                        icon={faEllipsisVertical}
                                        style={{ color: colors.gray }}
                                        className="cursor-pointer dots"
                                        onClick={() => {
                                            handleClick(cardData?.id);
                                            setDropdownVisible(!dropdownVisible);
                                        }}
                                    />
                                    {dropdownVisible && (
                                        <DropdownContainer ref={ref} onClick={handleClickOutside}>
                                            <Dropdown
                                                arrowRight="4px"
                                                itemList={
                                                    role !== 'admin'
                                                        ? cardData?.status === 'paid'
                                                            ? paidDropdown
                                                            : cardData?.status === 'sent'
                                                            ? sentDropdown
                                                            : cardData?.status === 'open' && type !== 'proforma'
                                                            ? draftDropdown
                                                            : cardData?.status === 'partially'
                                                            ? partiallyDropdown
                                                            : cardData?.status === 'cancelled'
                                                            ? cancelledDropdown
                                                            : cardData?.status === 'open' && type === 'proforma'
                                                            ? proformaDropdown
                                                            : cardData?.status === 'closed'
                                                            ? closedDropdown
                                                            : []
                                                        : adminDropdown
                                                }
                                            />
                                        </DropdownContainer>
                                    )}
                                </Action>
                            ) : (
                                <InvoiceActionsWithoutDropdown
                                    cardData={cardData}
                                    handleDownload={handleDownload}
                                    handleInvoice={handleInvoice}
                                    handleCopy={handleCopy}
                                    handlePayment={handlePayment}
                                    handleCancel={handleCancel}
                                    handleChange={handleChange}
                                    handleDelete={handleDelete}
                                    handleSend={handleSend}
                                    handleEInvoice={handleEInvoice}
                                    type={type}
                                    t={t}
                                />
                            )}
                        </div>
                    </ActionWrapper>
                ) : (
                    <></>
                )}
            </CardContent>
            {showPaymentDropdown ? (
                cardData?.payments?.data ? (
                    <PaymentDropdown
                        cardData={cardData}
                        t={t}
                        handlePayment={handlePayment}
                        type={type}
                        handleUpdatePayment={handleUpdatePayment}
                        handleDeletePayment={handleDeletePayment}
                    />
                ) : (
                    <></>
                )
            ) : (
                <></>
            )}
        </StyledInvoiceCard>
    );
};
const StyledInvoiceCard = styled.div`
    margin-bottom: 10px;
    padding: 16px 20px;
    border-radius: 5px;
    width: 100%;

    background: var(--white);
    border: 1px solid var(--purple);
    transition: all 0.5s ease-in-out;
    &:hover {
        box-shadow: 0 0 5px 5px #eee;
        .info-wrapper {
            opacity: 0.7;
        }
    }
    @media screen and (max-width: 768px) {
        align-items: start;
    }
    &.card-display-grid {
        display: flex;
        flex-direction: column;
        .card-content {
            grid-template-columns: 1fr;
            width: 100%;
            .info-wrapper {
                grid-template-columns: 2fr 2fr;
                > div {
                    &:nth-of-type(1) {
                        order: 3;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(2) {
                        order: 2;
                        margin-bottom: 20px;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                    }
                    &:nth-of-type(3) {
                        order: 4;
                        grid-column: 1 / 4;
                    }
                    &:nth-of-type(4) {
                        order: 4;
                    }
                    &.status-col {
                        order: 1;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
`;

const CardContent = styled.div`
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr;
    @media screen and (max-width: 1560px) {
        grid-template-columns: 8fr 1fr;
    }

    div {
        color: var(--black);
        &.number {
            text-transform: capitalize;
        }
        &.status {
            text-transform: capitalize;
            &.sent {
                span {
                    background: var(--blue);
                }
            }
            &.paid {
                span {
                    background: var(--green);
                }
            }
            &.cancelled {
                span {
                    background: var(--danger);
                }
            }
            &.partially {
                span {
                    background: linear-gradient(90deg, var(--green), var(--blue));
                }
            }
            &.approved {
                span {
                    background: var(--green);
                }
            }
            &.storno {
                span {
                    background: var(--danger);
                }
            }
            &.rejected {
                span {
                    background: var(--danger);
                }
            }
            span {
                padding: 0 10px 2px;
                background: var(--gray);
                color: var(--white);
                border-radius: 50px;
                font-size: 12px;
            }
        }
        &.action {
            display: flex;
            justify-content: flex-end;
        }
    }
`;
const InfoWrapper = styled.div`
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    display: grid;
    align-items: center;
    @media screen and (max-width: 768px) {
        grid-template-columns: 2fr 2fr;
    }

    .label {
        text-transform: capitalize;
        font-size: 12px;
        color: var(--gray);
        line-height: 25px;
    }
`;

const ActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;

    div {
        &.action {
            justify-content: center;
            margin-top: 20px;
        }
    }
`;
const Action = styled.div`
    position: relative;
    .dots {
        font-size: 25px;
        padding: 10px 17px 7px;
        transition: all 0.5s ease-in-out;
        &:hover {
            background: var(--light-gray);
        }
    }
`;
const DropdownContainer = styled.div`
    position: absolute;
    z-index: 1;
    right: 0;
    top: 55px;

    p {
        font-size: 16px;
        line-height: 20px;
    }
    .dropdown-container {
        svg {
            height: 20px;
            width: 20px;
        }
    }
`;

export default InvoiceCard;
