import { FunctionComponent, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Button, { ButtonVariant } from '../../../../components/button';
import colors from '../../../../global/colors';
import Input, { InputComponentType } from '../../../../components/input';
import communication from '../../../../communication';
import Checkbox from '../../../../components/checkbox';
import { SendDocumentFormData } from '../../../../types/communication/document';
import useProfileStore from '../../../../store/profile';
import useLanguageStore from '../../../../store/language';
import useSendMeEmail from '../../../../hooks/useSendMeEmail';
import useEmailValidation from '../../../pausal/invoices/sendModal/useEmailValidation';

interface Props {
    t: Function;
    formData: SendDocumentFormData;
    setFormData: Function;
    close: Function;
    success: Function;
    loading: Function;
    modalVisible: boolean;
    id?: string;
    memorandum?: boolean;
    handleSaveMemorandum?: Function;
    memoId?: string;
}

const SendDocumentModal: FunctionComponent<Props> = ({
    t,
    formData,
    setFormData,
    close,
    success,
    loading,
    id,
    memorandum,
    handleSaveMemorandum,
    memoId,
    modalVisible,
}) => {
    const [showError, setShowError] = useState(false);
    const [showEmails, setShowEmails] = useState(false);
    const [emailsChanged, setEmailsChanged] = useState(false);
    const [emails, setEmails] = useState(Array<string>);
    const [sendMe, setSendMe] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const { profile } = useProfileStore();
    const isEmailValid = useEmailValidation(formData.recipient, emails);
    const [fromInput, setFromInput] = useState(false);

    const { currentLangName } = useLanguageStore();

    useEffect(() => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
    }, [modalVisible]);

    const send = async (): Promise<void> => {
        if (memorandum) {
            loading();
            if (handleSaveMemorandum) {
                const memo = await handleSaveMemorandum();

                const res = await communication.sendMemorandum(
                    memo.id,
                    emails,
                    formData.message === ''
                        ? currentLangName === 'Srpski'
                            ? `Poštovani,\nHvala Vam što poslujete sa nama.\nAko je ovaj dokument poslat greškom, molimo Vas kontaktirajte ${profile.email}.\nS poštovanjem, ${profile.agency.data[0].full_name}.`
                            : `Dear,\nThank you for your business.\nIf this document was sent in error, please contact ${profile.email}.\nRegards, ${profile.agency.data[0].full_name}.`
                        : formData.message,
                    formData.subject === '' ? profile.agency.data[0].full_name : formData.subject,
                );

                if (res.status === 204) {
                    success();
                    closeModal();
                }
            } else {
                const res = await communication.sendMemorandum(
                    memoId,
                    emails,
                    formData.message === ''
                        ? currentLangName === 'Srpski'
                            ? `Poštovani,\nHvala Vam što poslujete sa nama.\nAko je ovaj dokument poslat greškom, molimo Vas kontaktirajte ${profile.email}.\nS poštovanjem, ${profile.agency.data[0].full_name}.`
                            : `Dear,\nThank you for your business.\nIf this document was sent in error, please contact ${profile.email}.\nRegards, ${profile.agency.data[0].full_name}.`
                        : formData.message,
                    formData.subject === '' ? profile.agency.data[0].full_name : formData.subject,
                );
                if (res.status === 204) {
                    success();
                    closeModal();
                }
            }
        } else if (emails.length > 0 && id) {
            loading();
            let res;
            if (profile.agency) {
                res = await communication.sendDataDocument(id, {
                    body:
                        formData.message === ''
                            ? currentLangName === 'Srpski'
                                ? `Poštovani,\nHvala Vam što poslujete sa nama.\nAko je ovaj dokument poslat greškom, molimo Vas kontaktirajte ${profile.email}.\nS poštovanjem, ${profile.agency.data[0].full_name}.`
                                : `Dear,\nThank you for your business.\nIf this document was sent in error, please contact ${profile.email}.\nRegards, ${profile.agency.data[0].full_name}.`
                            : formData.message,
                    title: formData.subject === '' ? profile.agency.data[0].full_name : formData.subject,
                    emails,
                });
            } else {
                const profileInfo = await communication.getProfile();
                res = await communication.sendDataDocument(id, {
                    body:
                        formData.message === ''
                            ? currentLangName === 'Srpski'
                                ? `Poštovani,\nHvala Vam što poslujete sa nama.\nAko je ovaj dokument poslat greškom, molimo Vas kontaktirajte ${profileInfo.data.data.email}.\nS poštovanjem, ${profileInfo.data.data.agency.data[0].full_name}.`
                                : `Dear,\nThank you for your business.\nIf this document was sent in error, please contact ${profileInfo.data.data.email}.\nRegards, ${profileInfo.data.data.agency.data[0].full_name}.`
                            : formData.message,
                    title: formData.subject === '' ? profileInfo.data.data.agency.data[0].full_name : formData.subject,
                    emails,
                });
            }

            if (res.status === 200) {
                success();
                closeModal();
            }
        } else setShowError(true);
    };

    const handleRemoveEmail = (e: string): void => {
        const reducedEmails = emails;
        const index = reducedEmails.indexOf(e);

        if (index > -1) {
            reducedEmails.splice(index, 1);
        }
        setEmails(reducedEmails);
        setEmailsChanged(!emailsChanged);
    };

    const closeModal = (): void => {
        setFormData({
            recipient: '',
            subject: '',
            message: '',
        });
        close();
    };

    useSendMeEmail(
        setUserEmail,
        setShowEmails,
        setEmails,
        setEmailsChanged,
        handleRemoveEmail,
        profile,
        sendMe,
        emailsChanged,
        userEmail,
        emails,
        fromInput,
        setFromInput,
    );

    return (
        <>
            <Container>
                <Header>
                    <h2>
                        {memorandum
                            ? t('pages.memos.sendMailTitle').text
                            : t('pages.agency.documents.sendDocument').text}
                    </h2>
                    <Icon onClick={() => closeModal()}>
                        <FontAwesomeIcon icon={faXmark} style={{ color: 'var(--gray)' }} />
                    </Icon>
                </Header>

                <label>{t('pages.agency.documents.sendModal.recipient').text}</label>
                <div className="emails">
                    {showEmails &&
                        emails.map((e) => (
                            <Email key={e}>
                                {e}
                                <span onClick={() => handleRemoveEmail(e)}>x</span>
                            </Email>
                        ))}
                </div>
                <Input
                    className="email"
                    type={InputComponentType.Text}
                    value={formData.recipient ? formData.recipient : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, recipient: value });
                    }}
                    hideBlurOnChange
                    validation={showError ? isEmailValid : ''}
                    handleBlurAction={() => {
                        setShowError(true);

                        if (emails.includes(formData.recipient)) {
                            setFormData({ ...formData, recipient: '' });
                            return;
                        }
                        if (formData.recipient !== '') {
                            setFromInput(true);
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    onEnter={() => {
                        if (formData.recipient !== '' && isEmailValid === '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                />

                <Input
                    type={InputComponentType.Text}
                    label={t('pages.agency.documents.sendModal.subject').text}
                    value={formData.subject ? formData.subject : ''}
                    blurHandler={() => {
                        if (formData.recipient !== '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                    hideBlurOnChange
                    onChange={(value: string) => {
                        setFormData({ ...formData, subject: value });
                    }}
                />
                <Input
                    type={InputComponentType.TextArea}
                    label={t('pages.agency.documents.sendModal.message').text}
                    value={formData.message ? formData.message : ''}
                    onChange={(value: string) => {
                        setFormData({ ...formData, message: value });
                    }}
                    blurHandler={() => {
                        if (formData.recipient !== '') {
                            setShowEmails(true);
                            setEmails([...emails, formData.recipient]);
                            setFormData({ ...formData, recipient: '' });
                        }
                    }}
                />
                <Footer>
                    <Icon className="attach">
                        <FontAwesomeIcon icon={faPaperclip} style={{ color: 'var(--gray)', marginRight: '3px' }} />
                        {memorandum
                            ? t('pages.memos.pdfAttached').text
                            : t('pages.agency.documents.sendModal.attached').text}
                    </Icon>
                    <div className="send-copy">
                        <Checkbox
                            onChange={() => {
                                setSendMe(!sendMe);
                                setFromInput(false);
                            }}
                        />
                        {t('pages.agency.documents.sendModal.sendMeCopy').text}
                    </div>
                </Footer>
                <div className="button-container">
                    <Button
                        variant={ButtonVariant.solid}
                        color={colors.purple}
                        className="small"
                        size={200}
                        onClick={send}
                    >
                        {t('pages.agency.documents.labels.send').text}
                    </Button>
                </div>
            </Container>
        </>
    );
};

export default SendDocumentModal;
const Container = styled.div`
    .button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-container {
        width: 100%;
    }
    label {
        color: var(--gray);
    }
    .emails {
        margin-top: 10px;
    }

    textarea {
        font-family: inherit;
        background: transparent;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.3px;
        border-radius: 2px;
        padding: 11px;
        border: none;
        border-bottom: 1px solid var(--border-color);
        height: 130px;
        padding: 5px;
        &:focus {
            outline: none;
            border-bottom: 1px solid var(--purple);
        }
        &::placeholder {
            color: var(--gray);
            font-weight: 400;
        }
    }
`;

export const Email = styled.div`
    display: inline-block;
    padding: 5px 10px;
    text-decoration: none;
    background: var(--purple);
    color: var(--white);
    margin-bottom: 6px;
    margin-right: 6px;
    font-size: 14px;
    span {
        margin-left: 10px;
        cursor: pointer;
    }
`;
const Header = styled.div`
    display: grid;
    grid-template-columns: 95% 5%;
    h2 {
        font-size: 24px;
        color: var(--gray);
        font-weight: 400;
        text-align: left;
    }
`;

const Footer = styled.div`
    margin-top: 20px;
    font-size: 13px;
    color: var(--gray);
    display: flow-root;
    .send-copy {
        float: right;
        display: flex;
    }
`;

const Icon = styled.div`
    float: right;
    font-size: 30px;
    margin-top: -8px;
    &.attach {
        font-size: 13px;
        color: var(--gray);
        float: left;
        margin-top: 0px;
    }
`;
