import { FunctionComponent, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import {
    faUsd,
    faIndustry,
    faRightLeft,
    faBuildingColumns,
    faGift,
    faRightFromBracket,
    faFileInvoice,
    faBook,
    faNotesMedical,
    faBriefcase,
    faPaste,
    faLifeRing,
    faRightToBracket,
} from '@fortawesome/free-solid-svg-icons';
import { faFileAlt, faEnvelope, faSquarePlus } from '@fortawesome/free-regular-svg-icons';
import communication from '../../communication';
import useWindowSize from '../../hooks/useResize';
import WebNavbar from './web';
import MobileNavbar from './mobile';
import useLanguageStore from '../../store/language';
import useProfileStore from '../../store/profile';
import useTranslations from '../../hooks/useTranslation';
import Modal from '../modal';
import Suggestion from './suggestion';
import { ObjectKeys } from '../../types/objectKeys';

const Navbar: FunctionComponent = () => {
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const role = localStorage.getItem('role');
    const { addToast } = useToasts();
    const navigate = useNavigate();

    const [expires, setExpires] = useState();
    const [suggestionModal, setSuggestionModal] = useState(false);

    const { profile } = useProfileStore();

    const isAccountActive = profile?.active === 1 ? true : false;

    const logoutHandler = async (): Promise<void> => {
        await communication
            .logout()
            .then((res: ObjectKeys) => {
                if (res) {
                    navigate('/login');
                }
            })
            .catch((error: any) => {
                if (error) {
                    addToast(error.response.data.message, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                }
            });
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('role');
    };

    const userDropdown = [
        {
            icon: faLifeRing,
            label: t('navbar.main.help').text,
            link: 'https://pausal.rs/pausal-app/pitanja/',
            newTab: true,
        },
        {
            icon: faPaste,
            label: t('navbar.main.privacy').text,
            link: '/Privacy.pdf',
            download: true,
        },
        { icon: faRightToBracket, label: t('navbar.main.logOut').text, handler: logoutHandler },
    ];

    const icons =
        role === 'admin'
            ? [
                  { text: t('navbar.admin.users').text, icon: faUsd, link: '/admin/users' },
                  { text: t('navbar.admin.invoices').text, icon: faFileAlt, link: '/admin/invoices' },
                  { text: t('navbar.admin.proInvoices').text, icon: faIndustry, link: '/admin/proforma' },
                  { text: t('navbar.admin.transfers').text, icon: faBuildingColumns, link: 'admin/transfer-orders' },
                  { text: t('navbar.admin.promoCodes').text, icon: faUsd, link: '/admin/promo-codes' },
                  { text: t('navbar.admin.transactions').text, icon: faRightLeft, link: '/admin/transactions' },
                  { text: t('navbar.admin.suggestions').text, icon: faEnvelope, link: '/admin/suggestions' },
                  { text: t('navbar.admin.tax').text, icon: faSquarePlus, link: '/admin/tax' },
                  { text: t('navbar.admin.notifications').text, icon: faEnvelope, link: '/admin/notifications' },
                  { text: t('navbar.admin.statistics').text, icon: faIndustry, link: '/admin/statistics' },
                  { text: t('navbar.admin.benefits').text, icon: faGift, link: '/admin/benefits' },
              ]
            : (isAccountActive && [
                  { text: t('navbar.pausal.invoices').text, icon: faFileAlt, link: '/invoices' },
                  { text: t('navbar.pausal.eInvoices').text, icon: faFileAlt, link: '/e-invoices' },
                  { text: t('navbar.pausal.proInvoices').text, icon: faFileInvoice, link: '/proforma' },
                  { text: t('navbar.pausal.prePayment').text, icon: faFileAlt, link: '/advance-invoices' },
                  { text: t('navbar.pausal.ePrePayment').text, icon: faFileAlt, link: '/eadvance-invoices' },
                  { text: t('navbar.pausal.memorandums').text, icon: faBook, link: '/memorandums' },
                  { text: t('navbar.pausal.transfers').text, icon: faBuildingColumns, link: '/transfer-orders' },
                  { text: t('navbar.pausal.healthCards').text, icon: faNotesMedical, link: '/health-cards' },
                  { text: t('navbar.pausal.reports').text, icon: faIndustry, link: '/reports' },
                  { text: t('navbar.pausal.myAgency').text, icon: faBriefcase, link: '/agency/profile' },
                  { text: t('navbar.pausal.suggestion').text, icon: faEnvelope },
                  { text: t('navbar.pausal.nbs').text, icon: faRightFromBracket },
              ]) || [
                  { text: t('navbar.pausal.invoices').text, icon: faFileAlt, link: '/invoices' },
                  { text: t('navbar.pausal.eInvoices').text, icon: faFileAlt, link: '/e-invoices' },
                  { text: t('navbar.pausal.prePayment').text, icon: faFileAlt, link: '/advance-invoices' },
                  { text: t('navbar.pausal.ePrePayment').text, icon: faFileAlt, link: '/eadvance-invoices' },
              ];
    const size = useWindowSize();

    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [isSmallScreenWidth, setIsSmallScreenWidth] = useState(!!size.width && size.width < 768);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
        setIsSmallScreenWidth(!!size.width && size.width < 1079 && size.width > 768);
    }, [size.width]);

    useEffect(() => {
        async function getAgency(): Promise<void> {
            if (role !== 'admin') {
                setExpires(profile.account_expiration_date);
            }
        }

        getAgency();
    }, []);

    return (
        <>
            {suggestionModal && (
                <Modal modalVisible={true} closeModal={() => setSuggestionModal(false)}>
                    <Suggestion close={() => setSuggestionModal(false)} />
                </Modal>
            )}

            {!(isMobileWidth || isSmallScreenWidth) ? (
                <WebNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    expires={expires}
                    role={role}
                    icons={icons}
                    userDropdown={userDropdown}
                    isAccountActive={isAccountActive}
                />
            ) : (
                <MobileNavbar
                    openSuggestionModal={() => setSuggestionModal(true)}
                    role={role}
                    icons={icons}
                    userDropdown={userDropdown}
                    expires={expires}
                    isAccountActive={isAccountActive}
                />
            )}
        </>
    );
};

export default Navbar;
