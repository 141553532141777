import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FileUploader } from 'react-drag-drop-files';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { dragAndDropIcon } from '../../../assets/base64';
import comunication from '../../../communication';
import Loader from '../../../components/loader';
import useLanguageStore from '../../../store/language';
import useTranslations from '../../../hooks/useTranslation';
import useWindowSize from '../../../hooks/useResize';
import Modal from '../../../components/modal';
import ErrorModal from '../../../components/errorModal';

const AddLogo: FunctionComponent = () => {
    const [logoUrl, setLogoUrl] = useState('');
    const [agencyId, setAgencyId] = useState('');
    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);
    const size = useWindowSize();
    const [isMobileWidth, setIsMobileWidth] = useState(!!size.width && size.width < 768);
    const [loader, setLoader] = useState(false);
    const [wrongTypeModal, setWrongTypeModal] = useState<boolean>(false);
    const [overSizeModal, setOverSizeModal] = useState<boolean>(false);

    useEffect(() => {
        setIsMobileWidth(!!size.width && size.width < 768);
    }, [size.width]);

    const getAgency = async (): Promise<void> => {
        setLoader(true);
        const res = await comunication.getAgency();
        setAgencyId(res.data.data.id);
        setLogoUrl(res.data.data.logo ? `https://dev.api.pausal.rs/${res.data.data.logo}` : '');
        setLoader(false);
    };

    const clearCacheData = (): void => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    useEffect(() => {
        getAgency();
    }, []);

    const handleDelete = async (): Promise<void> => {
        await comunication.deleteLogo(agencyId);
        window.location.reload();
    };

    const handleChange = async (file: [Blob]): Promise<void> => {
        if (file[0].size > 5242880) {
            setOverSizeModal(true);
        } else {
            await comunication.uploadAgencyLogo(file[0], agencyId);
            clearCacheData();
            window.location.reload();
        }
    };

    return (
        <Content className={isMobileWidth ? 'mobile' : 'desktop'}>
            {loader && <Loader />}
            {wrongTypeModal && (
                <Modal modalVisible={wrongTypeModal} closeModal={() => setWrongTypeModal(!wrongTypeModal)}>
                    <ErrorModal
                        t={t}
                        errorMessage={t('pages.agency.addLogo.wrongType').text}
                        setOpenModal={setWrongTypeModal}
                    />
                </Modal>
            )}
            {overSizeModal && (
                <Modal modalVisible={overSizeModal} closeModal={() => setOverSizeModal(!overSizeModal)}>
                    <ErrorModal t={t} errorMessage={t('pages.error.overSize').text} setOpenModal={setOverSizeModal} />
                </Modal>
            )}
            <div className="content-wrapper">
                <div className="preview-upload">
                    {logoUrl !== '' && (
                        <div className="preview">
                            <h4>{t('pages.agency.addLogo.currentLogo').text}</h4>
                            <img className={isMobileWidth ? 'mobile' : ''} key={logoUrl} src={logoUrl} />
                            <Icon>
                                <FontAwesomeIcon
                                    onClick={() => handleDelete()}
                                    icon={faTrashCan}
                                    style={{ color: 'var(--danger)' }}
                                    width={'33px'}
                                    height={'22px'}
                                />
                            </Icon>
                        </div>
                    )}
                    <div className={isMobileWidth ? 'upload mobile' : 'upload'}>
                        <h4>{t('pages.agency.addLogo.changeLogo').text}</h4>
                        <FileUploader
                            multiple={true}
                            handleChange={handleChange}
                            name="files"
                            types={['jpg', 'jpeg', 'png']}
                            onTypeError={() => {
                                setWrongTypeModal(true);
                            }}
                        >
                            <div className={isMobileWidth ? 'mobile dropzone' : 'dropzone'}>
                                <p>{t('pages.agency.addLogo.addLogo').text}</p>
                                <img src={dragAndDropIcon} />
                            </div>
                        </FileUploader>
                    </div>
                </div>

                <div className="example">
                    <img src={require('../../../assets/invoice-preview.png')} />
                </div>
            </div>
        </Content>
    );
};

const Content = styled.div`
    &.mobile {
        width: 100%;
    }

    &.desktop {
        width: 56%;
    }

    display: inline-block;
    vertical-align: top;
    color: var(--black);
    .content-wrapper {
        display: flex;
        h4 {
            font-size: 14px;
            text-transform: uppercase;
            color: var(--black);
            margin-bottom: 15px;
        }
        .preview-upload {
            width: 56%;
        }

        .preview {
            img {
                &.mobile {
                    width: 150px;
                }
                width: 200px;
                height: auto;
                display: inline-block;
            }
            margin-bottom: 20px;
        }
        .upload {
            label {
                display: flex;
                width: 165px;
                &.mobile {
                    width: 150px;
                }
            }
        }
        .dropzone {
            cursor: pointer;
            position: relative;
            width: 165px;
            min-height: 120px;
            border: 3px dashed #e7e7e7;
            border-radius: 10px;
            padding-top: 5px;
            padding-bottom: 0;
            color: #92a5ac;
            text-align: center;
            &.mobile {
                width: 150px;
            }
            p {
                margin-top: 10px !important;
                border-top: none;
                font-size: 12px;
            }
            img {
                position: absolute;
                width: 100%;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                margin: auto;
                width: 40px;
            }
        }
        .example {
            max-width: 43%;
            float: right;
            img {
                width: 100%;
            }
        }
    }
`;

const Icon = styled.div`
    width: 33px;
`;

export default AddLogo;
