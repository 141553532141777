import { FunctionComponent, useEffect, useRef, useState } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import communication from '../../../communication';
import Button, { ButtonVariant } from '../../../components/button';
import { useNavigate, useParams } from 'react-router-dom';
import QuillToolbar from '../toolbar';
import Input from '../../../components/input';
import colors from '../../../global/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faEnvelope, faPrint, faSave } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../../components/loader';
import useTranslations from '../../../hooks/useTranslation';
import useLanguageStore from '../../../store/language';
import { ButtonsBottom } from '../../pausal/einvoices/copyEInvoice/style';
import SendDocumentModal from '../../myAgency/documents/sendModal';
import Sidebar from '../../../components/sideModal';
import { SendDocumentFormData } from '../../../types/communication/document';

// enum MemorandumStatus {
//     sent,
//     preparation,
// }

const EditMemorandum: FunctionComponent = () => {
    const [memorandum, setMemorandum] = useState<any>();
    const [editorValue, setEditorValue] = useState<any>();
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [memorandumName, setMemorandumName] = useState<string>('');
    const [isLoader, setIsLoader] = useState<boolean>(false);
    const token = localStorage.getItem('access_token');
    const quillRef = useRef<any>();

    const [sendData, setSendData] = useState<SendDocumentFormData>({
        recipient: '',
        subject: '',
        message: '',
    });

    const { currentLang } = useLanguageStore();
    const t = useTranslations(currentLang);

    //QUILL Editor Configuration
    const Size = Quill.import('attributors/style/size');
    Size.whitelist = ['12pt', '14pt', '16pt', '18pt', '20pt', '22pt', '24pt', '26pt', '28pt'];
    const hr = Quill.import('blots/block/embed');
    const Block = Quill.import('blots/block');
    Block.tagName = 'DIV';
    Quill.register(Block, true);
    hr.blotName = 'divider';
    hr.tagName = 'hr';
    Quill.register(Size, true);
    const Break = Quill.import('blots/break');
    Break.blotName = 'break';
    Break.tagName = 'BR';
    Quill.register(Block, true);
    Quill.register({
        'formats/hr': hr,
    });

    const formats = [
        'header',
        'font',
        'size',
        'bold',
        'indent',
        'italic',
        'align',
        'list',
        'bullet',
        'image',
        'divider',
        'link',
        'h1',
        'p',
        'img',
    ];

    const handleChange = (value: any): void => {
        setEditorValue(value);
    };

    const navigate = useNavigate();
    const params = useParams();
    const { id, copy } = params;

    const getSpecificMemo = async (): Promise<void> => {
        const { data } = await communication.getSpecificMemorandum(id);
        setMemorandum(data.data);
        setEditorValue(data.data.template);
        setMemorandumName(data.data.heading);
    };

    useEffect(() => {
        getSpecificMemo();
    }, []);

    const handleSave = async (): Promise<void> => {
        setIsLoader(true);
        if (copy === 'copy') {
            const data = {
                template: editorValue,
                heading: memorandumName,
                memorandum_type: memorandumName,
            };
            await communication.createMemorandum(data);
        } else {
            const data = {
                id: memorandum.id,
                template: editorValue,
                heading: memorandumName,
            };
            await communication.updateMemorandum(data);
        }
        navigate(-1);
        setIsLoader(false);
    };

    const handlePrint = async (): Promise<void> => {
        await communication.downloadMemorandum(memorandum?.id, token);
    };

    const handleSend = (): void => {
        setModalVisible(true);
    };
    // Modules object for setting up the Quill editor
    const modules = {
        toolbar: {
            container: '#toolbar',
            history: {
                delay: 0,
                maxStack: 100,
                userOnly: true,
            },
        },
    };
    return (
        <PageWrapper>
            {isLoader && <Loader />}
            {modalVisible && (
                <Sidebar close={() => setModalVisible(false)}>
                    <SendDocumentModal
                        close={() => setModalVisible(false)}
                        setFormData={setSendData}
                        formData={sendData}
                        t={t}
                        success={() => {
                            setIsLoader(false);
                        }}
                        loading={() => setIsLoader(true)}
                        memorandum={true}
                        memoId={memorandum.id}
                        modalVisible={modalVisible}
                    />
                </Sidebar>
            )}

            <Header>
                <div
                    className={'back-button'}
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    <FontAwesomeIcon className={'icon'} icon={faAngleLeft} color={colors.gray} />
                </div>
                <h1> {t('pages.memos.titleSingle').text}</h1>
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSave();
                    }}
                    color={colors.green}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                    {t('pages.editInvoice.save').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handlePrint();
                    }}
                    color={colors.purple}
                    variant={ButtonVariant.outlined}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    {t('pages.editInvoice.print').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSend();
                    }}
                    color={colors.blue}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    {t('pages.editInvoice.send').text}
                </Button>
            </Header>
            <Input
                type="text"
                label={'Name'}
                className={'input'}
                value={memorandumName}
                onChange={(value: string) => setMemorandumName(value)}
            />
            <QuillToolbar quillRef={quillRef} />
            <ReactQuill
                className={'ql-editor'}
                ref={quillRef}
                theme="snow"
                value={editorValue}
                onChange={handleChange}
                placeholder={''}
                modules={modules}
                formats={formats}
            />
            <ButtonsBottom>
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSave();
                    }}
                    color={colors.green}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faSave} />
                    {t('pages.editInvoice.save').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handlePrint();
                    }}
                    color={colors.purple}
                    variant={ButtonVariant.outlined}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.purple} icon={faPrint} />
                    {t('pages.editInvoice.print').text}
                </Button>{' '}
                <Button
                    className={'action-button'}
                    onClick={() => {
                        handleSend();
                    }}
                    color={colors.blue}
                    variant={ButtonVariant.solid}
                    size={'20px'}
                    height={'20px'}
                    icon={true}
                >
                    <FontAwesomeIcon className="icon" color={colors.white} icon={faEnvelope} />
                    {t('pages.editInvoice.send').text}
                </Button>
            </ButtonsBottom>
        </PageWrapper>
    );
};
const PageWrapper = styled.div`
    min-height: 100vh;
    width: 60.67vw;
    display: inline-block;
    vertical-align: top;
    @media screen and (max-width: 1079px) {
        width: 100%;
    }
    .back-button {
        width: 30px;
        height: 30px;
        margin: 0 10px 0 0;
        vertical-align: top;
        background: #fff;
        border: 1px solid #e7e7e7;
        border-radius: 3px;
        text-align: center;
        display: block;
    }
    .action-button {
        width: auto;
        padding: 15px 20px;
        margin-left: 20px;
    }
    .quill {
        width: 60.67vw;
    }

    .ql-container {
        height: 100vh;
    }

    .ql-editor {
        background-color: var(--white);
        padding: 0px;
        //display: grid;
        height: 100%;
        @media screen and (max-width: 1079px) {
            width: 100%;
        }
        .ql-container {
            box-sizing: border-box;
            line-height: 1.42;
            height: 100%;
            outline: none;
            overflow-y: auto;
            padding: 12px 15px;
            tab-size: 4;
            text-align: left;
            white-space: pre-wrap;
            overflow-wrap: break-word;
        }
        .span {
            font-size: 12pt;
            line-height: 2;
        }
        img {
            height: auto;
            width: 6.7067vw;
            margin-left: auto !important;
            margin-right: auto !important;
            display: block;
        }
        h1 {
            line-height: 1.3em;
            text-align: center;
            color: #000;
            font-weight: 700;
            margin: 0.67em 0;
        }
        p {
            font-size: 1.38vw;
            line-height: 1.785vw;
            color: #000;
        }
        hr {
            height: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    .input {
        input {
            background-color: var(--white);
            &:hover {
                border-bottom: 1px solid var(--border-color);
            }
            &:focus {
                border-bottom: 1px solid var(--border-color);
            }
        }
    }
`;

const Header = styled.div`
    padding-bottom: 20px;
    position: relative;
    display: flex;
    align-items: center;
    h1 {
        font-size: 36px;
        display: inline-block;
    }
    .header-right {
        float: right;
        margin: 0 0 0 35px;
    }
    .header-options-button {
        display: inline-block;
    }
    .buttons {
        color: var(--purple);
        display: inline-block;
        &:first-child {
            margin-right: 10px;
        }
    }
`;
export default EditMemorandum;
