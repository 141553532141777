import communication from '../communication';
import useUserStore from '../store/user';
import useYearsStore from '../store/years';
import useCurrencyStore from '../store/currency';
import useAgencyStore from '../store/agency';
import useUserProfileStore from '../store/profile';
import useProfileStore from '../store/admin/profile';
import useAllUsersStore from '../store/admin/allUsers';
import { getAccessToken } from '../functions/auth';
import { ObjectKeys } from '../types/objectKeys';

const initFetch = async (): Promise<void> => {
    const role = localStorage.getItem('role');

    //Get activity codes
    await communication
        .getActivityCodes()
        .then((res: ObjectKeys) => {
            useUserStore.setState({ activityCodes: res?.data?.data });
        })
        .catch((error: ObjectKeys) => {
            if (error) {
                useUserStore.setState({ activityCodes: [] });
            }
        });

    //Get municipalities
    await communication
        .getMunicipalities()
        .then((res: ObjectKeys) => {
            useUserStore.setState({ municipalities: res?.data?.data });
        })
        .catch((error: ObjectKeys) => {
            if (error) {
                useUserStore.setState({ municipalities: [] });
            }
        });

    if (getAccessToken()) {
        //Get years
        const yearsData = await communication.getYears();
        useYearsStore.setState({ years: yearsData?.data?.years });

        //Get currency
        const currencyData = await communication.fetchCurrencies();
        useCurrencyStore.setState({ currency: currencyData?.data });
        if (role !== 'admin') {
            //Get user
            const userInfo = await communication.getUserProfile();
            useUserProfileStore.setState({ profile: userInfo?.data?.data });
            useAgencyStore.setState({
                agency: userInfo?.data?.data?.agency?.data[0],
            });
        }
        if (role === 'admin') {
            //Get profile info
            const profileInfo = await communication.getProfile();
            useProfileStore.setState({ profile: profileInfo?.data?.data });

            //Get all users info
            const allUsersInfo = await communication.getAllUsers();
            useAllUsersStore.setState({ allUsers: allUsersInfo?.data });
        }
    }
};

export default initFetch;
